import { EmailLink } from "@/email/components/EmailLink";
import { SiteRoutes } from "@/types/routes";
import { Column, Container, Section, Row } from "@react-email/components";

const footerLinkStyle = {
    fontSize: "14px",
    color: "#303330",
    fontWeight: 500,
    lineHeight: 1.2,
};

export const EmailFooter = () => {
    return (
        <Container
            style={{
                marginTop: "52px",
                borderTop: "2px solid #CED4E5",
                display: "flex",
                justifyContent: "flex-end",
                padding: "16px",
                textAlign: "center",
                fontSize: "14px",
            }}
        >
            <Section>
                <Row>
                    <Column>
                        <EmailLink
                            href="https://app.1template.io"
                            style={footerLinkStyle}
                        >
                            1Template
                        </EmailLink>
                    </Column>
                    <Column style={{ paddingLeft: "12px" }}>
                        <EmailLink
                            href="https://1template.io/about"
                            style={footerLinkStyle}
                        >
                            About
                        </EmailLink>
                    </Column>
                    <Column style={{ paddingLeft: "12px" }}>
                        <EmailLink
                            href={SiteRoutes.PrivacyPolicy}
                            style={footerLinkStyle}
                        >
                            Privacy
                        </EmailLink>
                    </Column>
                    <Column style={{ paddingLeft: "12px" }}>
                        <EmailLink
                            href={SiteRoutes.Terms}
                            style={footerLinkStyle}
                        >
                            Terms
                        </EmailLink>
                    </Column>
                </Row>
            </Section>
        </Container>
    );
};
