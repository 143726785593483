import { AssistantDocumentItem, AssistantItemSectionContentType, AssistantItemTypes, AssistantSectionItem } from "@/types/assistant";
import { RootState } from "../store";
import { createSelector } from "@reduxjs/toolkit";

const getAssistantState = (state: RootState) => state.assistant;

export const getAssistantTabState = createSelector(
  getAssistantState,
  assistant => assistant.activeTab,
);

const getSectionAssistantItems = createSelector(
  getAssistantState,
  (assistant) => assistant.sectionItems,
);

const getDocumentAssistantItems = createSelector(
  getAssistantState,
  (assistant) => assistant.documentItems,
);

const getActiveJobRole = createSelector(
  getAssistantState,
  (assistant) => assistant.activeJobRole,
);

export const getAssistantItemsByQuery = createSelector(
  [getSectionAssistantItems, getDocumentAssistantItems, (_: RootState, query: { type: AssistantItemTypes, id?: string; contentType?: AssistantItemSectionContentType; }) => query],
  (sections, documents, query) => {
    const { type, id, contentType } = query;
    if (!id) return [];
    const items = type === AssistantItemTypes.SECTION ? sections : documents;

    if (type === AssistantItemTypes.SECTION) {
      return items.filter(item => item.section === id && item.properties.contentType === contentType) as AssistantSectionItem[];
    }

    return items.filter(item => item.documentType === id) as AssistantDocumentItem[];
  },
);

export {
  getSectionAssistantItems,
  getDocumentAssistantItems,
  getActiveJobRole,
};
