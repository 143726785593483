import { Suspense } from "react";
import { Await, useLoaderData, useOutlet } from "react-router-dom";
import { MyEditorProvider } from "@/context/EditorProvider";
import ErrorBoundary from "@/components/ErrorBoundary/ErrorBoundary";
import { LoadingPage } from "@/components/LoadingPage/LoadingPage";

interface UserAuthData {
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    userPromise: Promise<any>;
}

export const AuthLayout = () => {
    const outlet = useOutlet();

    const { userPromise } = useLoaderData() as UserAuthData;

    return (
        <Suspense fallback={<LoadingPage />}>
            <ErrorBoundary>
                <Await resolve={userPromise}>
                    <MyEditorProvider>{outlet}</MyEditorProvider>
                </Await>
            </ErrorBoundary>
        </Suspense>
    );
};
