interface InputLabelProps {
    label: string;
    required?: boolean;
    description?: string;
    htmlFor?: string;
}

export const InputLabel = ({
    label,
    required,
    description,
    htmlFor,
}: InputLabelProps) => (
    <div className="flex flex-col justify-between">
        <label
            htmlFor={htmlFor}
            className="block text-sm font-medium leading-6 text-neutral-900"
        >
            {label}
            {required && <span className="text-red-600"> *</span>}
        </label>
        {description && (
            <span className="mb-2 text-xs text-neutral-700">{description}</span>
        )}
    </div>
);
