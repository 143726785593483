import "../../pages/ResumeBuilder/resumeStyles.css";
import "./styles.css";
import { ReadOnlyResumeView } from "./ReadOnlyResumeView/ReadOnlyResumeView";
import { TemplateActions } from "./TemplateActions";
import { TemplateDetails } from "./TemplateDetails";
import { Resume } from "@/types";
import { useAppDispatch } from "@/hooks/types";
import { useNavigate } from "react-router-dom";
import { Page } from "@/components/Page/Page";
import { useCallback } from "react";
import { useDevice } from "@/hooks/useDevice";
import { DeviceContextType } from "@/types/device";
import { openModal } from "@/store/modal/slice";
import { ModalTypes } from "@/types/modal";
import { duplicateResume } from "@/helper/duplicateResume";
import { AdminResumeDetails } from "@/components/Admin/AdminResumeDetails";

interface ResumePreviewProps {
    resume: Resume;
    onDelete: () => void;
    isTemplate?: boolean;
}

export const ResumePreview = ({ resume, onDelete }: ResumePreviewProps) => {
    const dispatch = useAppDispatch();
    const navigate = useNavigate();
    const { isDesktop } = useDevice() as DeviceContextType;

    const onDuplicate = useCallback(() => {
        duplicateResume({
            resume,
            isExampleResume: true,
            resumeName: `${resume.name} - Copy`,
        });
    }, [resume]);

    const onEdit = useCallback(() => {
        navigate(`/resume/${resume.id}`);
    }, [navigate, resume.id]);

    const onPreview = useCallback(() => {
        navigate(`/preview/${resume.id}`);
    }, [navigate, resume.id]);

    const onRename = useCallback(() => {
        dispatch(
            openModal({
                modalType: ModalTypes.RENAME_RESUME,
                props: { resumeId: resume.id },
            }),
        );
    }, [dispatch, resume.id]);

    const handleDocumentItemClick = useCallback(() => {
        if (isDesktop) {
            navigate(`/resume/${resume.id}`);
        } else {
            navigate(`/preview/${resume.id}`);
        }
    }, [navigate, resume.id, isDesktop]);

    return (
        <div
            key={resume.id}
            className="flex flex-col rounded-lg bg-white"
        >
            <li className="resume-container relative overflow-hidden rounded-lg border-2 border-neutral-400 duration-100 ease-in hover:ring-primary-700">
                <AdminResumeDetails resume={resume} />
                <div
                    onClick={handleDocumentItemClick}
                    className="resume-item group block cursor-pointer overflow-hidden rounded-lg"
                >
                    <Page
                        pageNumber={1}
                        overrideResumeStyles={resume.styles}
                    >
                        <ReadOnlyResumeView resume={resume} />
                    </Page>
                </div>
                <div className="absolute bottom-0 left-0 right-0 flex items-center justify-between gap-4 border-t-2 border-neutral-400 bg-white p-4">
                    <div style={{ flex: 1, minWidth: 0 }}>
                        <TemplateDetails
                            name={resume.name}
                            updatedAt={resume.updatedAt}
                        />
                    </div>
                    <div className="w-9">
                        <TemplateActions
                            resumeId={resume.id}
                            onDelete={onDelete}
                            onDuplicate={onDuplicate}
                            onEdit={onEdit}
                            onRename={onRename}
                            onPreview={onPreview}
                        />
                    </div>
                </div>
            </li>
        </div>
    );
};
