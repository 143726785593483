import userApi from "@/api/user";
import { Input } from "@/components/form/Input";
import { Suspense, useCallback, useMemo, useState } from "react";
import { Await, useLoaderData, useNavigate, useParams } from "react-router-dom";
import { useAppDispatch } from "@/hooks/types";
import { userSchema } from "./schema/user";
import { InputLabel } from "@/components/form/InputLabel";
import { NotificationMessageType, NotificationType, User } from "@/types";
import { addNotification } from "@/store/app/slice";
import { Loader } from "lucide-react";
import { Formik } from "formik";
import { Checkbox } from "@/components/form/Checkbox";
import { AdminNavigationItem } from "@/types/admin";
import { AdminFormContainer } from "@/pages/Admin/AdminFormContainer";
import { AdminFormFooter } from "@/pages/Admin/AdminFormFooter";
import { Select, SelectItem } from "@/components/form/Select";
import { InputError } from "@/components/form/InputError";
import { getIsProductionEnv } from "@/utils/env";

const userFormNavItems: AdminNavigationItem[] = [
    { id: "details", label: "Details" },
];

const userRoleItems: SelectItem[] = [
    { id: "user", name: "User" },
    { id: "admin", name: "Admin" },
];

export const UserForm = () => {
    const { getAdminUser } = useLoaderData() as {
        getAdminUser: Promise<User>;
    };

    return (
        <Suspense fallback={<Loader />}>
            <Await
                resolve={getAdminUser}
                errorElement={<div>Failed to load user</div>}
            >
                {resolvedValues => <UserFormFields user={resolvedValues} />}
            </Await>
        </Suspense>
    );
};

const formatDate = (dateString: string | Date) => {
    const date =
        typeof dateString === "string" ? new Date(dateString) : dateString;
    return date.toLocaleDateString("en-GB", {
        year: "numeric",
        month: "long",
        day: "numeric",
    });
};

export const UserFormFields = ({ user }: { user?: User }) => {
    const isProduction = getIsProductionEnv();
    const [activeTab, setActiveTab] = useState<string>(userFormNavItems[0].id);
    const initialValues: Partial<User> = useMemo(
        () => ({
            firstName: user?.firstName || "",
            lastName: user?.lastName || "",
            email: user?.email || "",
            role: user?.role || "user",
            isEmailVerified: user?.isEmailVerified || false,
            loginMethod: user?.loginMethod || undefined,
            stripeCustomerId: user?.stripeCustomerId || "",
            createdAt: user?.createdAt || new Date(),
            scheduledDeletionDate: user?.scheduledDeletionDate || null,
        }),
        [user],
    );
    const dispatch = useAppDispatch();

    const { userId: id } = useParams();
    const userId = id !== "new" ? id : undefined;

    const navigate = useNavigate();

    const handleError = useCallback(
        (err: unknown, title: string, messageType: NotificationMessageType) => {
            let errorMessage;
            if (typeof err === "string") {
                errorMessage = err;
            } else if (err instanceof Error) {
                errorMessage = err.message;
            }

            dispatch(
                addNotification({
                    title,
                    desc: errorMessage,
                    messageType,
                    type: NotificationType.ERROR,
                }),
            );
        },
        [dispatch],
    );

    const handleSubmit = async (user: Partial<User>) => {
        try {
            await userApi.saveUserApi(user, userId).then(() => {
                if (!userId) {
                    navigate("/admin/users", { replace: true }); // push user back to users list
                }

                dispatch(
                    addNotification({
                        title: "Successfully saved!",
                        desc: "Your details are successfully saved!!",
                        messageType: NotificationMessageType.ADMIN_USER_SAVE,
                        type: NotificationType.SUCCESS,
                    }),
                );
            });
        } catch (err: unknown) {
            handleError(
                err,
                "Failed to save",
                NotificationMessageType.ADMIN_USER_SAVE,
            );
        }
    };

    const handleDelete = useCallback(async () => {
        if (isProduction) {
            dispatch(
                addNotification({
                    title: "Unable to complete action",
                    desc: "You cannot delete users in production via the admin panel. Removing users must be done via the proper process",
                    messageType: NotificationMessageType.ADMIN_USER_DELETE,
                    type: NotificationType.WARNING,
                }),
            );
            return;
        }
        try {
            if (!userId) {
                navigate("/admin/users", { replace: true }); // push user back to sections list
                return;
            }
            await userApi.deleteUserApi(userId).then(() => {
                navigate("/admin/users", { replace: true });
                dispatch(
                    addNotification({
                        title: "Successfully deleted",
                        desc: "User successfully deleted",
                        messageType: NotificationMessageType.ADMIN_USER_DELETE,
                        type: NotificationType.SUCCESS,
                    }),
                );
            });
        } catch (e) {
            handleError(
                e,
                "Failed to delete",
                NotificationMessageType.ADMIN_USER_DELETE,
            );
        }
    }, [isProduction, dispatch, userId, navigate, handleError]);

    return (
        <AdminFormContainer
            navigationItems={userFormNavItems}
            onNavigationItemClick={id => setActiveTab(id)}
            activeItemId={activeTab}
            pageTitle="User"
        >
            <Formik
                initialValues={initialValues}
                validationSchema={userSchema}
                onSubmit={async values => {
                    await handleSubmit(values);
                }}
            >
                {({
                    values,
                    errors,
                    handleChange,
                    submitForm,
                    setFieldValue,
                }) => (
                    <>
                        {activeTab === "details" && (
                            <div className="grid grid-cols-8 items-end">
                                <div className="col-span-2 px-8">
                                    <InputLabel
                                        label="ID"
                                        htmlFor="id"
                                        description="Read only field"
                                    />
                                    <Input
                                        type="text"
                                        name="id"
                                        id="id"
                                        value={user?.id}
                                        disabled
                                    />
                                </div>
                                <div className="col-span-2 px-8 py-2">
                                    <InputLabel
                                        label="First Name"
                                        htmlFor="firstName"
                                        required
                                    />
                                    <Input
                                        type="text"
                                        name="firstName"
                                        id="firstName"
                                        value={values.firstName}
                                        formikChange={handleChange}
                                        error={errors.firstName}
                                    />
                                </div>
                                <div className="col-span-2 px-8 py-2">
                                    <InputLabel
                                        label="Last Name"
                                        htmlFor="lastName"
                                        required
                                    />
                                    <Input
                                        type="text"
                                        name="lastName"
                                        id="lastName"
                                        value={values.lastName}
                                        formikChange={handleChange}
                                        error={errors.lastName}
                                    />
                                </div>
                                <div className="col-span-2 mt-2 px-8 py-2">
                                    <InputLabel
                                        label="Email"
                                        htmlFor="email"
                                        required
                                    />
                                    <Input
                                        type="text"
                                        name="email"
                                        id="email"
                                        value={values.email}
                                        formikChange={handleChange}
                                        error={errors.email}
                                    />
                                </div>
                                <div className="col-span-2 mt-2 px-8 py-2">
                                    <InputLabel
                                        label="Role"
                                        htmlFor="role"
                                        required
                                        description={`"user" or "admin"`}
                                    />
                                    <Select
                                        value={userRoleItems.find(
                                            item => item.id === values.role,
                                        )}
                                        items={userRoleItems}
                                        onChange={item =>
                                            setFieldValue("role", item.id)
                                        }
                                    />
                                    <InputError error={errors.role} />
                                </div>
                                <div className="col-span-2 mt-2 px-8 py-2">
                                    <InputLabel
                                        label="Stripe Customer ID"
                                        htmlFor="stripeCustomerId"
                                        description="Read only field"
                                    />
                                    <Input
                                        type="text"
                                        name="role"
                                        id="role"
                                        value={user?.stripeCustomerId}
                                        disabled
                                    />
                                </div>
                                <div className="col-span-2 mt-2 px-8 py-2">
                                    <InputLabel
                                        label="Login method"
                                        htmlFor="loginMethod"
                                        description="Read only field"
                                    />
                                    <Input
                                        type="text"
                                        name="loginMethod"
                                        id="loginMethod"
                                        value={user?.loginMethod}
                                        disabled
                                    />
                                </div>
                                <div className="col-span-2 mb-2 px-8">
                                    <Checkbox
                                        id="isEmailVerified"
                                        label="Is Email Verified?"
                                        checked={!!values.isEmailVerified}
                                        onChange={(checked: boolean) =>
                                            setFieldValue(
                                                "isEmailVerified",
                                                checked,
                                            )
                                        }
                                    />
                                </div>
                                <div className="col-span-2 mt-2 px-8 py-2">
                                    <InputLabel
                                        label="Created At"
                                        htmlFor="createdAt"
                                        description="Read only field"
                                    />
                                    <Input
                                        type="text"
                                        name="loginMethod"
                                        id="createdAt"
                                        value={formatDate(values?.createdAt)}
                                        disabled
                                    />
                                </div>
                                {values?.scheduledDeletionDate && (
                                    <div className="col-span-2 mt-2 px-8 py-2">
                                        <InputLabel
                                            label="Created At"
                                            htmlFor="createdAt"
                                            description="Read only field"
                                        />
                                        <Input
                                            type="text"
                                            name="loginMethod"
                                            id="createdAt"
                                            value={formatDate(
                                                values?.scheduledDeletionDate,
                                            )}
                                            disabled
                                        />
                                    </div>
                                )}
                            </div>
                        )}
                        <AdminFormFooter
                            onSave={submitForm}
                            listHref="/admin/users"
                            onDelete={handleDelete}
                        />
                    </>
                )}
            </Formik>
        </AdminFormContainer>
    );
};
