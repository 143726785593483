import axios, { AxiosInstance } from "axios";
import { baseURL } from "./constants";
import { store } from "@/store/store";
import { resetTokens, setAccessToken } from "@/store/user/slice";
import { AppRoutes } from "@/types/routes";
import { getUserCountryCode } from "@/i18n";

export const registerInterceptor = (apiClient: AxiosInstance) =>
    // Add request interceptor
    apiClient.interceptors.request.use(
        async config => {
            // Get the token from wherever it's stored (e.g., local storage, state management)
            const localAccessToken =
                window.localStorage.getItem("accessToken") || "";
            const localRefreshToken =
                window.localStorage.getItem("refreshToken") || "";
            const { token, expires } = JSON.parse(localAccessToken) || {};
            const { token: refreshToken } = JSON.parse(localRefreshToken) || {};
            // Attach the token to the request headers
            config.headers.Authorization = `Bearer ${token}`;

            // Detect and set the X-Language header
            const detectedCountryCode = getUserCountryCode();
            config.headers['X-Country-Code'] = detectedCountryCode;

            if (token && expires && Date.now() >= new Date(expires).getTime()) {
                const dispatch = store.dispatch;

                try {
                    // Call your token refresh endpoint
                    const response = await axios.post(
                        `${baseURL}auth/refresh-tokens`,
                        {
                            refreshToken,
                        },
                    );

                    // Update the token in local storage
                    localStorage.setItem(
                        "accessToken",
                        JSON.stringify(response.data.access),
                    );

                    dispatch(setAccessToken(response.data.access));

                    // Update the request headers with the new token
                    config.headers.Authorization = `Bearer ${response.data.access.token}`;
                } catch (error) {
                    // Handle error refreshing token (e.g., redirect to login page)
                    console.error("Error refreshing token:", error);
                    // Update the token in local storage
                    localStorage.removeItem("accessToken");
                    localStorage.removeItem("refreshToken");
                    dispatch(resetTokens());
                    // Redirect to login page or show an error message
                    window.location.href = "/" + AppRoutes.Login;
                }
            }
            return config;
        },
        error => {
            return Promise.reject(error);
        },
    );
