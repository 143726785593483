import { RootState } from "../store";
import { ModalTypes } from "@/types/modal";
import { createSelector } from "@reduxjs/toolkit";

const getModalState = (state: RootState) => state.modal;

const getOpenModals = createSelector(
  getModalState,
  (modal) => modal.openModals,
);

export const getIsModalOpen = (modalType: ModalTypes) => createSelector(
  getOpenModals,
  (openModals) => !!openModals[modalType],
);

export const getModalProps = (modalType: ModalTypes) => createSelector(
  getModalState,
  (modal) => modal.modalProps[modalType],
);
