import resumeApi from "@/api/resume";
import { getDefaultSectionsDataForTemplate } from "@/helper/getDefaultSectionsDataForTemplate";
import { getDocumentTypes } from "@/store/documentType/selectors";
import { setActiveResume } from "@/store/resume/slice";
import { getSections } from "@/store/sections/selectors";
import { AppDispatch, store } from "@/store/store";
import { getTemplates } from "@/store/template/selectors";
import { setActiveTemplate } from "@/store/template/slice";
import { User } from "@/types";
import { ColumnLayout, DocumentVisibility, ResumeStyles } from "@/types/resume";
import { NavigateFunction } from "react-router-dom";

interface NewResumeOptions {
    styles?: ResumeStyles;
    columnLayout: ColumnLayout;
    showImage?: boolean;
    userDetails: User;
    createAsTemplate?: boolean;
}

export const createNewResume = (navigate: NavigateFunction, options?: NewResumeOptions) => {
    const { styles = {}, columnLayout = ColumnLayout.SINGLE, showImage = false, userDetails, createAsTemplate = false } = options || {};
    const dispatch = store.dispatch as AppDispatch;
    const state = store.getState();
    const templates = getTemplates(state);
    const documentTypes = getDocumentTypes(state);
    const sections = getSections(state);

    const resumeTemplate = templates.find(t => t.name === "resume")!;
    const resumeDocumentType = documentTypes.find(
        t => t.name === "resume",
    )!;
    const { defaultSectionContent, defaultStyles } =
        getDefaultSectionsDataForTemplate(sections, resumeTemplate, styles, columnLayout, showImage, userDetails);

    resumeApi
        .createResume({
            sections: defaultSectionContent,
            name: "New Resume",
            styles: defaultStyles,
            template: resumeTemplate.id,
            columnLayout,
            documentType: resumeDocumentType.id,
            isTemplate: createAsTemplate,
            visibility: DocumentVisibility.PRIVATE,
        })
        .then(resume => {
            dispatch(setActiveTemplate(resumeTemplate));
            dispatch(setActiveResume(resume));
            navigate(`/resume/${resume.id}`, { replace: true });
        });

};