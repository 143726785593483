import resumeApi from "@/api/resume";
import { openModal } from "@/store/modal/slice";
import { addResume, setActiveResume } from "@/store/resume/slice";
import { AppDispatch, store } from "@/store/store";
import { getUserReachedResumeLimit } from "@/store/user/selectors";
import { ModalTypes } from "@/types/modal";
import { DocumentVisibility, Resume } from "@/types/resume";
import { GA_EVENT_ACTIONS, GA_EVENT_CATEGORIES } from "@/types/tracking";
import { trackGAEvent } from "@/utils/tracking";
import { NavigateFunction } from "react-router-dom";

export const duplicateResume = ({
    navigate,
    resume,
    isExampleResume,
    resumeName,
}: {
    navigate?: NavigateFunction;
    resume: Resume;
    isExampleResume?: boolean;
    resumeName?: string;
}): Promise<Resume | null> => {
    const dispatch = store.dispatch as AppDispatch;
    const state = store.getState();
    const userReachedResumeLimit = getUserReachedResumeLimit(state);

    if (userReachedResumeLimit) {
        dispatch(
            openModal({
                modalType: ModalTypes.PLAN_LIMIT,
            }),
        );
        return Promise.resolve(null);
    }

    return resumeApi
        .createResume({
            sections: resume.sections,
            name: resumeName || "New Resume",
            styles: { ...resume.styles },
            template: resume.template,
            documentType: resume.documentType,
            columnLayout: resume.columnLayout,
            isTemplate: false,
            visibility: DocumentVisibility.PRIVATE,
            exampleId: isExampleResume ? resume.id : undefined,
        })
        .then(newResume => {
            dispatch(setActiveResume(newResume));
            dispatch(addResume(newResume));
            if (navigate) {
                navigate(`/resume/${newResume.id}`);
            }
            if (isExampleResume) {
                trackGAEvent(GA_EVENT_CATEGORIES.BUTTON, GA_EVENT_ACTIONS.CLICK, "Example resume selected");
            }
            return newResume;
        });
};