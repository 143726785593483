import { getActiveResume } from "@/store/resume/selectors";
import {
    SectionItemDetails,
    Resume,
    ResumeSections,
    ComponentPropsObject,
    ColumnLayout,
} from "@/types/resume";
import { useCallback, useRef, useEffect } from "react";
import { useAppDispatch, useAppSelector } from "./types";
import { setActiveResume, updateResumeById, updateSectionBodyField, updateSectionHeaderField } from "@/store/resume/slice";
import resumeApi from "@/api/resume";
import { SectionWidth } from "@/types/section";
import { getSections } from "@/store/sections/selectors";
import { addSectionToPage, removeSectionFromPage } from "@/store/pages/slice";
import { getDefaultSectionDetails } from "@/helper/getDefaultSectionDetails";
import { generateUniqueId } from "@/utils/string";
import { UnknownAction } from "@reduxjs/toolkit";

interface UpdateResumeOptions {
    newTemplateSections?: ResumeSections;
    newName?: string;
    updateResumeId?: string;
    newLayout?: ColumnLayout;
    cb?: (success: boolean) => void;
}

export const useUpdateTemplate = () => {
    const activeResume = useAppSelector(getActiveResume);
    const sections = useAppSelector(getSections);
    const dispatch = useAppDispatch();

    const saveAndUpdateTemplate = useCallback(
        async ({
            newTemplateSections,
            newName,
            updateResumeId,
            newLayout,
            cb,
        }: UpdateResumeOptions) => {
            const resumeId = activeResume?.id || updateResumeId;
            if (!resumeId) return;

            try {
                // Build resume updates object
                const resumeUpdates: Partial<Resume> = {
                    ...(newTemplateSections && { sections: newTemplateSections }),
                    ...(newName && { name: newName }),
                    ...(newLayout && { columnLayout: newLayout })
                };

                // Save resume and get updated version
                const updatedResume = await resumeApi.saveResume(resumeId, resumeUpdates);

                // Ensure sections exist
                const normalizedResume = {
                    ...updatedResume,
                    sections: updatedResume.sections || {}
                };

                // Update store
                const action = updateResumeId
                    ? updateResumeById({ id: updateResumeId, resume: normalizedResume })
                    : setActiveResume(normalizedResume);

                dispatch(action as unknown as UnknownAction);
                cb?.(true);
            } catch (error) {
                console.error("Failed to save resume template:", error);
                cb?.(false);
            }
        },
        [activeResume, dispatch]
    );

    const updateTemplateSectionAndSave = useCallback(
        (
            sectionId: string,
            key: "header" | "body" | "hiddenFields" | "activeWidth" | "columnIndex" | "layout",
            value: ComponentPropsObject | SectionItemDetails[] | string[] | SectionWidth,
            itemId?: string,
            itemIndex?: number,
            cb?: (success: boolean) => void,
        ) => {
            if (activeResume?.sections) {
                resumeApi.updateResumeSection(activeResume.id, sectionId, {
                    fieldKey: key,
                    itemId,
                    value,
                    itemIndex,
                }).then(newResume => {
                    if (key === "body") {
                        dispatch(updateSectionBodyField({
                            sectionId,
                            itemId,
                            value: value as SectionItemDetails[],
                            itemIndex,
                        }));
                    } else {
                        dispatch(setActiveResume(newResume));
                    }
                    cb?.(true);
                }).catch(() => {
                    cb?.(false);
                });
            }
        },
        [activeResume?.id, activeResume?.sections, dispatch],
    );

    const removeSection = useCallback((sectionId: string, cb?: (success: boolean) => void) => {
        if (!activeResume?.sections) return;
        const { [sectionId]: _removedSection, ...newSections } = activeResume.sections;

        // Update position indexes
        const updatedSections = Object.entries(newSections)
            .sort(([, a], [, b]) => a.positionIndex - b.positionIndex)
            .reduce((acc, [key, section], index) => ({
                ...acc,
                [key]: { ...section, positionIndex: index }
            }), {} as ResumeSections);

        // Save changes
        saveAndUpdateTemplate({
            newTemplateSections: updatedSections,
            cb: (success) => {
                if (success) {
                    dispatch(removeSectionFromPage({ sectionId }));
                    cb?.(true);
                }
                if (!success) {
                    console.error("Failed to remove section");
                    cb?.(false);
                }
            }
        });
    }, [activeResume?.sections, dispatch, saveAndUpdateTemplate]);

    const addSection = useCallback((sectionConfigId: string, cb: (success: boolean) => void) => {
        const newSections = { ...activeResume?.sections };
        const sectionConfig = sections.find((s) => s.id === sectionConfigId);
        if (!sectionConfig) return;

        const newSectionId = generateUniqueId();
        // Next position index available in left column as new sections are added to the left column by default
        const leftColumnPositionIndexes = Object.values(newSections).map(ns => ns.columnIndex === 0 ? ns.positionIndex : -1);
        const nextPositionIndex = leftColumnPositionIndexes.length ? Math.max(...leftColumnPositionIndexes) + 1 : 0;

        // Create the new section
        const newSection = getDefaultSectionDetails(sectionConfig, nextPositionIndex, 0);
        newSections[newSectionId] = newSection;

        // First save to API, then update local state
        saveAndUpdateTemplate({
            newTemplateSections: newSections,
            cb: (success) => {
                if (success) {
                    dispatch(addSectionToPage({ sectionId: newSectionId, section: newSection }));
                    newSectionRef.current = newSectionId;
                    cb?.(true);
                } else {
                    console.error("Failed to save new section");
                    cb?.(false);
                }
            }
        });
    }, [activeResume?.sections, sections, dispatch, saveAndUpdateTemplate]);

    // Middleware handles saving to API
    const updateField = useCallback((
        newValue: string | string[] | null,
        propKey: string,
        fieldsKey: "header" | "body",
        sectionId: string,
        itemId?: string,
        cb?: () => void,
    ) => {
        if (fieldsKey === "body") {
            dispatch(updateSectionBodyField({
                sectionId,
                itemId,
                value: { [propKey]: newValue }
            }));
        } else if (fieldsKey === "header") {
            dispatch(updateSectionHeaderField({
                sectionId,
                value: { [propKey]: newValue },
                unhideImage: propKey === "image" && !!newValue
            }));
        }
        cb?.();
    }, [dispatch]);

    const newSectionRef = useRef<string | null>(null);

    useEffect(() => {
        let timeoutId: NodeJS.Timeout;
        let retryCount = 0;
        const maxRetries = 5;

        const scrollToNewSection = () => {
            if (!newSectionRef.current) return;

            const newSectionElement = document.getElementById(newSectionRef.current);
            if (newSectionElement) {
                const offset = 150;
                const elementPosition = newSectionElement.getBoundingClientRect().top;
                const offsetPosition = elementPosition + window.scrollY - offset;

                window.scrollTo({
                    top: offsetPosition,
                    behavior: "smooth"
                });

                newSectionRef.current = null;
                if (timeoutId) clearInterval(timeoutId);
            } else if (retryCount < maxRetries) {
                retryCount++;
            } else {
                newSectionRef.current = null;
                if (timeoutId) clearInterval(timeoutId);
            }
        };

        if (newSectionRef.current) {
            timeoutId = setInterval(scrollToNewSection, 100);
        }

        return () => {
            if (timeoutId) {
                clearInterval(timeoutId);
            }
        };
    }, [activeResume?.sections]);

    const updateStyles = useCallback(<T extends Record<string, any>>(styleKey: string, styles: T) => {
        if (!activeResume?.id) return;

        const newStyles = {
            ...activeResume.styles[styleKey],
            ...styles,
        };

        const allStyles = {
            ...activeResume.styles,
            [styleKey]: newStyles,
        };

        dispatch(
            setActiveResume({
                ...activeResume,
                styles: allStyles,
            }),
        );

        return resumeApi.saveResume(activeResume.id, {
            styles: allStyles,
        });
    }, [activeResume, dispatch]);

    return {
        updateTemplateSectionAndSave,
        saveAndUpdateTemplate,
        removeSection,
        addSection,
        updateField,
        updateStyles,
    };
};
