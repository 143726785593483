import { Navigate } from "react-router-dom";
import { UsingReactChildren } from "@/types";
import { useAppSelector } from "@/hooks/types";
import { getUserDetails } from "@/store/user/selectors";
import { AppRoutes } from "@/types/routes";
import { getIsAdminRole } from "@/utils/getIsRole";

export const AdminProtectedRoute = ({ children }: UsingReactChildren) => {
    const user = useAppSelector(getUserDetails);
    if (!getIsAdminRole(user?.role)) {
        return <Navigate to={AppRoutes.Dashboard} />;
    }
    return children;
};
