import { resumeAccentColours } from "@/constants/resume";
import { Popover, PopoverButton, PopoverPanel } from "@headlessui/react";
import clsx from "clsx";

interface ColourPickerProps {
    onChange: (colour: string) => void;
    activeColour: string;
}

const ColourBlock = ({
    colour,
    isActive,
}: {
    colour: string;
    isActive: boolean;
}) => (
    <div
        className={clsx(
            "h-4 w-4 rounded ",
            colour === "#FFFFFF" && "border-2 border-neutral-900",
            isActive && "ring-2 ring-primary-900 ring-offset-2",
        )}
        style={{
            backgroundColor: colour,
        }}
    />
);

const ActiveItem = ({ colour }: { colour: string }) => (
    <div className="flex h-9 w-9 items-center justify-center rounded-lg ring-2 ring-inset ring-neutral-300">
        <ColourBlock
            colour={colour}
            isActive={false}
        />
    </div>
);

export const ColourPicker = ({
    onChange,
    activeColour = "#FFFFFF",
}: ColourPickerProps) => (
    <div className="flex justify-end">
        <Popover className="relative">
            <PopoverButton className="h-9 min-h-9 w-9">
                <ActiveItem colour={activeColour} />
            </PopoverButton>
            <PopoverPanel
                portal
                anchor={{
                    gap: 8,
                    to: "right start",
                }}
                className="z-50 rounded-md bg-white p-2 ring-2 ring-neutral-300"
            >
                <div className="grid grid-cols-6 gap-3">
                    {resumeAccentColours.map(colour => (
                        <button
                            key={colour}
                            onClick={() => onChange(colour)}
                            className="focus:outline-primary-900"
                        >
                            <ColourBlock
                                colour={colour}
                                isActive={colour === activeColour}
                            />
                        </button>
                    ))}
                </div>
            </PopoverPanel>
        </Popover>
    </div>
);
