import { PdfIcon } from "@/components/PdfDocument/PdfIcon";
import {
    GLOBAL_STYLE_KEY,
    resumeFontSizes,
    resumeIconSizes,
} from "@/constants/resume";
import { ResumeStyles } from "@/types/resume";
import { icons, LucideProps } from "lucide-react";

interface FieldIconProps extends Omit<LucideProps, "ref"> {
    name?: string;
    stylesConfig: ResumeStyles;
    isForPdf?: boolean;
}

export const FieldIcon = ({ name, stylesConfig, isForPdf }: FieldIconProps) => {
    if (!name) return null;
    const iconsEnabled = stylesConfig[GLOBAL_STYLE_KEY].showIcons;
    const LucideIcon = iconsEnabled ? icons?.[name] : undefined;
    const sizeKey =
        stylesConfig?.[GLOBAL_STYLE_KEY]?.fontSize || resumeFontSizes[0].value;
    const size = resumeIconSizes[sizeKey];

    if (!LucideIcon) return null;

    return isForPdf ? (
        <PdfIcon
            name={name}
            size={size}
        />
    ) : (
        <LucideIcon
            size={size}
            className="mr-1 mt-[2px] flex-shrink-0"
            height={size}
            width={size}
        />
    );
};
