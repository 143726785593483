import { SectionBodyItem } from "@/components/Section/SectionBodyItem";
import { GLOBAL_STYLE_KEY } from "@/constants/resume";
import { useAppSelector } from "@/hooks/types";
import { SectionItemContainer } from "@/pages/ResumeBuilder/SectionItemContainer";
import { getPages } from "@/store/pages/selectors";
import { getSectionBodyItemsForPage } from "@/store/pages/utils";
import {
    getActiveResumeColumnLayout,
    getActiveResumeSectionById,
    getActiveResumeDateLocationPosition,
    getResumeById,
    getActiveResumeStyles,
} from "@/store/resume/selectors";
import { getDateLocationPositionByColumnLayout } from "@/store/sections/utils";
import {
    ColumnLayout,
    ResumeStyles,
    SectionDetails,
    SectionItemDetails,
    SectionSpacingSize,
} from "@/types/resume";
import { useMemo } from "react";

interface SectionBodyProps {
    sectionId: string;
    pageNumber: number;
    isEditable: boolean;
    resumeId?: string;
    overrideSectionDetails?: SectionDetails;
    overrideResumeStyles?: ResumeStyles;
    shouldMeasure?: boolean;
    isDefaultMeasurement?: boolean;
}

export const SectionBody = ({
    pageNumber,
    isEditable,
    sectionId,
    resumeId,
    overrideSectionDetails,
    overrideResumeStyles,
    shouldMeasure,
    isDefaultMeasurement,
}: SectionBodyProps) => {
    const pages = useAppSelector(getPages);
    const resume = useAppSelector(state =>
        getResumeById(state, resumeId ?? ""),
    );
    const resumeSection = resume?.sections[sectionId];
    const activeResumeSection = useAppSelector(state =>
        getActiveResumeSectionById(state, sectionId),
    );
    const activeResumeStyles = useAppSelector(getActiveResumeStyles);
    const stylesConfig = overrideResumeStyles || activeResumeStyles;
    // Override section details are used for template loader
    // Resume section is used for the resume preview in dashboard
    const sectionDetails =
        overrideSectionDetails || resumeSection || activeResumeSection;
    const layoutProps = useMemo(
        () => JSON.parse(JSON.stringify(sectionDetails?.layout ?? {})),
        [sectionDetails?.layout],
    );
    const sectionConfigId = sectionDetails?.sectionConfigId;
    const activeResumeDateLocationPosition = useAppSelector(
        getActiveResumeDateLocationPosition,
    );
    const activeResumeColumnLayout = useAppSelector(
        getActiveResumeColumnLayout,
    );
    const columnLayout = resume?.columnLayout ?? activeResumeColumnLayout;
    const dateLocationPosition = getDateLocationPositionByColumnLayout(
        columnLayout ?? ColumnLayout.SINGLE,
        sectionDetails?.columnIndex ?? 0,
        overrideResumeStyles?.[GLOBAL_STYLE_KEY]?.dateLocationPosition ??
            activeResumeDateLocationPosition,
    );

    if (dateLocationPosition) {
        layoutProps.dateLocationPosition = dateLocationPosition;
    }

    const pageItemMeasurements = pages.find(
        page => page.pageNumber === pageNumber,
    )?.items;

    // If not editable, we need to use all the items for the loader.
    const pageItems = isEditable
        ? (getSectionBodyItemsForPage(
              pageItemMeasurements ?? [],
              sectionDetails,
          ) as SectionItemDetails[])
        : sectionDetails?.body;

    return (pageItems ?? []).map(item => {
        if (!item || Object.keys(item).length === 0) return null;
        const itemIndex = sectionDetails?.body?.findIndex(
            i => i.__id === item.__id,
        );
        const isLastItem =
            itemIndex === (sectionDetails?.body?.length ?? 0) - 1;

        return (
            <SectionItemContainer
                key={item.__id}
                shouldMeasure={shouldMeasure}
                itemId={item.__id}
                sectionId={sectionId}
                itemIndex={itemIndex ?? 0}
                isEditable={isEditable}
                isLastItem={isLastItem}
                sectionConfigId={sectionConfigId ?? ""}
                sectionSpacingSize={
                    stylesConfig?.[GLOBAL_STYLE_KEY]?.sectionSpacingSize ??
                    SectionSpacingSize.MD
                }
                isDefaultMeasurement={isDefaultMeasurement}
            >
                <SectionBodyItem
                    isEditable={isEditable}
                    sectionId={sectionId}
                    resumeId={resumeId}
                    overrideResumeStyles={overrideResumeStyles}
                    layoutProps={layoutProps}
                    sectionConfigId={sectionConfigId ?? ""}
                    item={item}
                />
            </SectionItemContainer>
        );
    });
};
