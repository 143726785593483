import {
    Button,
    ButtonVariant,
    ButtonColourStyles,
} from "@/components/Button/Button";
import {
    Bot,
    FileType2,
    LayoutTemplate,
    Palette,
    UsersRound,
} from "lucide-react";
import { useCallback } from "react";
import { useLocation } from "react-router-dom";

export const AdminNavigation = () => {
    const currentPath = useLocation();

    const getButtonProps = useCallback(
        (tab: string) => {
            if (currentPath.pathname.includes(tab)) {
                return {
                    variant: ButtonVariant.SOLID,
                    color: ButtonColourStyles.SOLID_SOFT_INDIGO,
                    className: "rounded-b-none",
                };
            }
            return {
                variant: ButtonVariant.TEXT,
                color: ButtonColourStyles.OUTLINE_PRIMARY,
            };
        },
        [currentPath],
    );

    return (
        <div className="flex items-center">
            <Button
                {...getButtonProps("document-types")}
                to="/admin/document-types"
                leftIcon={<FileType2 className="h-5 w-5" />}
            >
                Document Types
            </Button>
            <Button
                {...getButtonProps("sections")}
                to="/admin/sections"
                leftIcon={<LayoutTemplate className="h-5 w-5" />}
            >
                Sections
            </Button>
            <Button
                {...getButtonProps("users")}
                to="/admin/users"
                leftIcon={<UsersRound className="h-5 w-5" />}
            >
                Users
            </Button>
            <Button
                {...getButtonProps("templates")}
                to="/admin/templates"
                leftIcon={<LayoutTemplate className="h-5 w-5" />}
            >
                Templates
            </Button>
            <Button
                {...getButtonProps("assistant")}
                to="/admin/assistant"
                leftIcon={<Bot className="h-5 w-5" />}
            >
                Assistant Items
            </Button>
            <Button
                {...getButtonProps("styles")}
                to="/admin/styles"
                leftIcon={<Palette className="h-5 w-5" />}
            >
                Styles
            </Button>
        </div>
    );
};
