import React from "react";
import { Measurement, Page, ResumeSections, Section } from "@/types";

interface PageInfoProps {
    pageNumber: number;
    col1Height: number;
    col2Height: number;
    maxHeight: number;
    pageSections: Page["sections"];
    resumeSections: ResumeSections;
    sections: Section[];
    pageItems: Measurement[];
}

const toTwoDecimalPlaces = (num: number) => Math.round(num * 100) / 100;

export const PageInfo: React.FC<PageInfoProps> = ({
    pageNumber,
    col1Height,
    col2Height,
    maxHeight,
    pageSections,
    resumeSections,
    sections,
    pageItems,
}) => {
    return pageSections?.length > 0 ? (
        <>
            <div className="flex items-center justify-around pt-1">
                <p className="font-bold">Page {pageNumber}</p>
                <p className="text-xs text-neutral-600">
                    Max height: {maxHeight}px
                </p>
            </div>
            <div className="grid grid-cols-1">
                <div className="mb-4 grid grid-cols-2 gap-4">
                    <div className="rounded bg-gray-100 p-2">
                        <div className="flex items-center justify-between">
                            <h5 className="font-bold">Col 1</h5>
                            <p className="font-semibold text-blue-600">
                                {col1Height}px
                                <span className="ml-2 text-xs text-gray-600">
                                    (
                                    {toTwoDecimalPlaces(maxHeight - col1Height)}
                                    px left)
                                </span>
                            </p>
                        </div>
                    </div>
                    <div className="rounded bg-gray-100 p-2">
                        <div className="flex items-center justify-between">
                            <h5 className="font-bold">Col 2</h5>
                            <p className="font-semibold text-blue-600">
                                {col2Height}px
                                <span className="ml-2 text-xs text-gray-600">
                                    (
                                    {toTwoDecimalPlaces(maxHeight - col2Height)}
                                    px left)
                                </span>
                            </p>
                        </div>
                    </div>
                </div>
                <div className="col-span-2">
                    <table className="w-full text-sm">
                        <thead>
                            <tr className="bg-gray-100">
                                <th className="p-2 text-left">Name</th>
                                <th className="p-2 text-left">ID</th>
                                <th className="p-2 text-center">Column</th>
                                <th className="p-2 text-right">Height</th>
                                <th className="p-2 text-left">Type</th>
                                <th className="p-2 text-left">Item Index</th>
                            </tr>
                        </thead>
                        <tbody>
                            {pageItems?.map(measurement => {
                                const sectionId = measurement.section;
                                const resSection = resumeSections?.[sectionId];
                                const section = sections.find(
                                    s => s.id === resSection?.sectionConfigId,
                                );

                                return (
                                    <tr
                                        key={`${sectionId}-${measurement.type}-${measurement.itemId}`}
                                        className="border-b"
                                    >
                                        <td className="p-2">{section?.name}</td>
                                        <td className="p-2 text-gray-600">
                                            {sectionId.substring(0, 5)}
                                        </td>
                                        <td className="p-2 text-center">
                                            {measurement.columnIndex}
                                        </td>
                                        <td className="p-2 text-right">
                                            {measurement.height}px
                                        </td>
                                        <td className="p-2">
                                            {measurement.type}
                                        </td>
                                        <td className="p-2 text-left">
                                            {measurement.itemIndex ?? "-"}
                                        </td>
                                    </tr>
                                );
                            })}
                        </tbody>
                    </table>
                </div>
            </div>
        </>
    ) : null;
};
