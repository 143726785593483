import React, { useMemo, useCallback } from "react";
import {
    ChevronLeft,
    ChevronRight,
    ChevronsLeft,
    ChevronsRight,
} from "lucide-react";

interface PaginationProps {
    currentPage: number;
    totalPages: number;
    onPageChange: (page: number) => void;
}

export const Pagination: React.FC<PaginationProps> = ({
    currentPage,
    totalPages,
    onPageChange,
}) => {
    const pageNumbers = useMemo(() => {
        if (totalPages <= 5) {
            return Array.from({ length: totalPages }, (_, i) => i + 1);
        }
        if (currentPage <= 3) {
            return [1, 2, 3, 4, 5];
        }
        if (currentPage >= totalPages - 2) {
            return [
                totalPages - 4,
                totalPages - 3,
                totalPages - 2,
                totalPages - 1,
                totalPages,
            ];
        }
        return [
            currentPage - 2,
            currentPage - 1,
            currentPage,
            currentPage + 1,
            currentPage + 2,
        ];
    }, [currentPage, totalPages]);

    const handlePageChange = useCallback(
        (page: number) => {
            if (page !== currentPage && page >= 1 && page <= totalPages) {
                onPageChange(page);
            }
        },
        [currentPage, totalPages, onPageChange],
    );

    if (totalPages <= 1) return null;

    return (
        <nav className="flex items-center justify-center space-x-2 rounded-lg bg-white p-2 shadow-md">
            {totalPages > 5 && (
                <PaginationButton
                    onClick={() => handlePageChange(1)}
                    disabled={currentPage === 1}
                    title="First page"
                    icon={<ChevronsLeft className="h-4 w-4" />}
                />
            )}
            <PaginationButton
                onClick={() => handlePageChange(currentPage - 1)}
                disabled={currentPage === 1}
                title="Previous page"
                icon={<ChevronLeft className="h-4 w-4" />}
            />

            <div className="flex items-center space-x-1">
                {pageNumbers.map(number => (
                    <PaginationNumber
                        key={number}
                        number={number}
                        isActive={currentPage === number}
                        onClick={() => handlePageChange(number)}
                    />
                ))}
            </div>

            <PaginationButton
                onClick={() => handlePageChange(currentPage + 1)}
                disabled={currentPage === totalPages}
                title="Next page"
                icon={<ChevronRight className="h-4 w-4" />}
            />
            {totalPages > 5 && (
                <PaginationButton
                    onClick={() => handlePageChange(totalPages)}
                    disabled={currentPage === totalPages}
                    title="Last page"
                    icon={<ChevronsRight className="h-4 w-4" />}
                />
            )}
        </nav>
    );
};

interface PaginationButtonProps {
    onClick: () => void;
    disabled: boolean;
    title: string;
    icon: React.ReactNode;
}

const PaginationButton: React.FC<PaginationButtonProps> = ({
    onClick,
    disabled,
    title,
    icon,
}) => (
    <button
        onClick={onClick}
        disabled={disabled}
        title={title}
        className="flex h-8 w-8 items-center justify-center rounded-md text-gray-600 transition-colors hover:bg-gray-100 disabled:opacity-50"
    >
        {icon}
    </button>
);

interface PaginationNumberProps {
    number: number;
    isActive: boolean;
    onClick: () => void;
}

const PaginationNumber: React.FC<PaginationNumberProps> = ({
    number,
    isActive,
    onClick,
}) => (
    <button
        onClick={onClick}
        className={`h-8 w-8 rounded-md text-sm font-medium transition-colors ${
            isActive
                ? "bg-blue-600 text-white"
                : "text-gray-600 hover:bg-gray-100"
        }`}
    >
        {number}
    </button>
);
