import { Dropdown } from "@/components/Dropdown/Dropdown";
import { resumeFontSizes, resumeHeadingFontSizes } from "@/constants/resume";
import { useMemo } from "react";

const getFontItems = (isHeading?: boolean) => {
    const fontSizes = isHeading ? resumeHeadingFontSizes : resumeFontSizes;
    return fontSizes.map(({ value, displayName, pxValue }) => {
        return {
            title: (
                <span style={{ fontSize: `${pxValue}px` }}>{displayName}</span>
            ),
            id: value,
        };
    });
};
interface FontSizeSelectorProps {
    onSizeChange: (size: string) => void;
    size: string;
    isHeading?: boolean;
}

export const FontSizeSelector = ({
    onSizeChange,
    size,
    isHeading,
}: FontSizeSelectorProps) => {
    const fonts = useMemo(() => getFontItems(isHeading), [isHeading]);
    return (
        <Dropdown
            items={fonts}
            activeId={size}
            handleChange={(id: string) => onSizeChange(id)}
            title={fonts.find(item => item.id === size)?.title}
        />
    );
};
