import { TemplateSection } from "@/components/Section/TemplateSection";

interface PageColumnSectionProps {
    sectionId: string;
    pageNumber: number;
}

export const PageColumnSection = ({
    sectionId,
    pageNumber,
}: PageColumnSectionProps) => {
    return (
        <div className="relative bg-white">
            <TemplateSection
                sectionId={sectionId}
                shouldMeasure={true}
                pageNumber={pageNumber}
            />
        </div>
    );
};
