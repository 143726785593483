import { Button } from "@/components/Button/Button";
import { Input } from "@/components/form/Input";
import { useAppDispatch, useAppSelector } from "@/hooks/types";
import { changeNamesSchema } from "@/pages/Profile/schema/changeNames";
import { Form, Formik } from "formik";
import { useMemo, useState } from "react";
import userApi from "@/api/user";
import { addNotification } from "@/store/app/slice";
import { NotificationMessageType, NotificationType, User } from "@/types";
import { setUserDetails } from "@/store/user/slice";
import { getUserDetails } from "@/store/user/selectors";

interface ChangeNamesFormFields {
    firstName: string;
    lastName: string;
}

export const ChangeNames = () => {
    const dispatch = useAppDispatch();
    const userDetails = useAppSelector(getUserDetails);
    const [loading, setLoading] = useState(false);
    const initialValues: ChangeNamesFormFields = useMemo(
        () => ({
            firstName: userDetails?.firstName ?? "",
            lastName: userDetails?.lastName ?? "",
        }),
        [userDetails?.firstName, userDetails?.lastName],
    );

    const onSubmit = async (
        values: ChangeNamesFormFields,
        resetForm: (newUserDetails: User) => void,
    ) => {
        setLoading(true);
        await userApi
            .changeNamesApi({
                firstName: values.firstName,
                lastName: values.lastName,
            })
            .then(user => {
                dispatch(setUserDetails(user));

                dispatch(
                    addNotification({
                        title: "Successfully changed!",
                        desc: "Details have been changed successfully!!",
                        messageType: NotificationMessageType.EMAIL_CHANGED,
                        type: NotificationType.SUCCESS,
                    }),
                );
                resetForm(user);
            })
            .catch(() => {
                dispatch(
                    addNotification({
                        title: "Error",
                        desc: "Unable to update details. Please try again",
                        messageType: NotificationMessageType.EMAIL_CHANGED,
                        type: NotificationType.ERROR,
                    }),
                );
            })
            .finally(() => {
                setLoading(false);
            });
    };

    return (
        <Formik
            initialValues={initialValues}
            validationSchema={changeNamesSchema}
            onSubmit={async (values, { resetForm, setFieldValue }) => {
                const resetFields = (newDetails: User) => {
                    setFieldValue("firstName", newDetails.firstName);
                    setFieldValue("lastName", newDetails.lastName);
                    resetForm({
                        values: {
                            firstName: newDetails.firstName,
                            lastName: newDetails.lastName,
                        },
                        errors: {},
                    });
                };
                await onSubmit(values, resetFields);
            }}
            validateOnBlur={false}
            validateOnChange={false}
            validateOnMount={false}
        >
            {({ values, errors, handleChange, handleSubmit }) => (
                <Form>
                    <div className="mb-2 grid grid-cols-2">
                        <Input
                            label="First name"
                            name="firstName"
                            type="text"
                            required
                            formikChange={handleChange}
                            value={values.firstName}
                            error={errors.firstName}
                            containerClassName="mr-0 md:mr-3 col-span-2 md:col-span-1 mb-4 md:mb-0"
                        />
                        <Input
                            label="Last name"
                            name="lastName"
                            type="text"
                            required
                            formikChange={handleChange}
                            value={values.lastName}
                            error={errors.lastName}
                            containerClassName="col-span-2 md:col-span-1"
                        />
                        <div className="col-span-3 mt-4 text-right">
                            <Button
                                onClick={() => handleSubmit()}
                                disabled={loading}
                                loading={loading}
                            >
                                Save
                            </Button>
                        </div>
                    </div>
                </Form>
            )}
        </Formik>
    );
};
