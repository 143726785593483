// File: src/api/constants.ts

let apiUrl: string;

if (import.meta.env.MODE === 'test' || import.meta.env.VITE_USE_MOCK_API === 'true') {
  apiUrl = 'https://mock-api.example.com/v1/';
} else {
  apiUrl = import.meta.env.VITE_API_URL || 'https://new-staging.app.1template.io/v1/';
}

export const baseURL = apiUrl;
