import userApi from "@/api/user";
import {
    Button,
    ButtonColourStyles,
    ButtonVariant,
} from "@/components/Button/Button";
import { Input } from "@/components/form/Input";
import { useMemo, useState } from "react";
import { getErrorMessage } from "@/helper/getErrorMessage";
import { Form, Formik } from "formik";
import { object } from "yup";
import { Alert } from "@/components/Alert/Alert";
import { sendPasswordResetEmail } from "@/email/send";
import { NoNavPageContainer } from "@/layouts/NoNavPageContainer";
import { emailValidation } from "@/constants/schema";
import { AppText } from "@/components/Text/AppText";
import { PageHelmet } from "@/components/PageHelmet/PageHelmet";
import { AppRoutes } from "@/types/routes";

const forgotPasswordSchema = object({
    email: emailValidation,
});

interface ForgotPasswordFormFields {
    email: string;
}

export const ForgotPasswordPage = () => {
    const initialValues: ForgotPasswordFormFields = useMemo(
        () => ({
            email: "",
        }),
        [],
    );
    const [error, setError] = useState<string | null>("");
    const [successEmail, setSuccessEmail] = useState<string | null>("");
    const [loading, setLoading] = useState(false);

    const handleReset = async (values: ForgotPasswordFormFields) => {
        setError(null);
        setLoading(true);
        try {
            await userApi.forgotPasswordApi(values).then(res => {
                const resetPasswordLink = `${window.location.origin}/${AppRoutes.ResetPassword}?token=${res.token}`;
                if (!res.name) {
                    // Don't send an email as no account exists with that email
                    setSuccessEmail(values.email);
                    setLoading(false);
                    return;
                }

                sendPasswordResetEmail(
                    values.email,
                    resetPasswordLink,
                    res.name,
                )
                    .then(() => {
                        setSuccessEmail(values.email);
                        setLoading(false);
                    })
                    .catch(e => {
                        const errMsg = getErrorMessage(e, "Forgot Password");
                        setError(errMsg);
                        setLoading(false);
                    });
            });
        } catch (e) {
            setError(getErrorMessage(e, "Forgot Password"));
            setLoading(false);
        }
    };

    return (
        <NoNavPageContainer>
            <PageHelmet
                title="Forgot Password"
                robotsOverride="noindex, nofollow"
            />
            <div className="">
                <AppText
                    variant="headings"
                    className="mb-8 text-center"
                >
                    {successEmail
                        ? "Check your inbox"
                        : "Forgot your password?"}
                </AppText>
                <AppText
                    className="m-auto mb-4 block max-w-[450px] text-center text-neutral-900"
                    variant="regular"
                >
                    {successEmail ? (
                        <>
                            A password reset email with further instructions has
                            been sent to{" "}
                            <span className="font-semibold text-neutral-700">
                                {successEmail}
                            </span>
                            . If you don’t see the email, check your spam,{" "}
                            <span
                                className="cursor-pointer text-primary-700"
                                onClick={() => setSuccessEmail("")}
                            >
                                resend
                            </span>{" "}
                            or try a{" "}
                            <span
                                className="cursor-pointer text-primary-700"
                                onClick={() => setSuccessEmail("")}
                            >
                                different email address
                            </span>
                            .
                        </>
                    ) : (
                        "Enter the email address associated with your account and we’ll send a link to reset your password."
                    )}
                </AppText>
                <Formik
                    initialValues={initialValues}
                    onSubmit={handleReset}
                    validationSchema={forgotPasswordSchema}
                    validateOnBlur={false}
                    validateOnChange={false}
                    validateOnMount={false}
                >
                    {({ values, errors, handleChange, handleSubmit }) => (
                        <Form>
                            <div className="m-auto max-w-[450px]">
                                {error && (
                                    <Alert
                                        type="error"
                                        message={error}
                                        className="mb-6"
                                    />
                                )}
                                {!successEmail && (
                                    <>
                                        <div className="mb-6">
                                            <Input
                                                type="text"
                                                autoComplete="email"
                                                label="Email"
                                                name="email"
                                                value={values.email}
                                                formikChange={handleChange}
                                                error={errors.email}
                                            />
                                        </div>
                                        <Button
                                            onClick={() => handleSubmit()}
                                            className="mt-2 w-full"
                                            loading={loading}
                                        >
                                            Continue
                                        </Button>
                                        <Button
                                            to={`/${AppRoutes.Login}`}
                                            className="mt-2 w-full"
                                            variant={ButtonVariant.TEXT}
                                            color={
                                                ButtonColourStyles.OUTLINE_PRIMARY
                                            }
                                        >
                                            Return to sign in
                                        </Button>
                                    </>
                                )}
                            </div>
                        </Form>
                    )}
                </Formik>
            </div>
        </NoNavPageContainer>
    );
};
