import { Button, ButtonColourStyles } from "@/components/Button/Button";
import { AppText } from "@/components/Text/AppText";
import { usePrevious } from "@/hooks/usePrevious";
import { TemplateSectionColumn } from "@/pages/Admin/Templates/TemplateSectionColumn";
import { TemplateSectionItem } from "@/pages/Admin/Templates/TemplateSectionItem";
import { SectionGridItem } from "@/pages/Admin/Templates/types";
import { Section } from "@/types";
import { ColumnLayout } from "@/types/resume";
import { TemplateSection } from "@/types/template";
import {
    DragStartEvent,
    DragOverEvent,
    DragEndEvent,
    DragOverlay,
    closestCorners,
    DndContext,
} from "@dnd-kit/core";
import { arrayMove } from "@dnd-kit/sortable";
import { useCallback, useEffect, useState } from "react";

type BoardSections = {
    [key: string]: SectionGridItem[];
};

const findBoardSectionContainer = (
    boardSections: BoardSections,
    id: string,
) => {
    if (id in boardSections) {
        return id;
    }

    const container = Object.keys(boardSections).find(key =>
        boardSections[key].find(item => item.id === id),
    );
    return container;
};

const getSectionsByStatus = (
    sections: SectionGridItem[],
    column: string | number,
) => {
    return sections.filter(section => section.column === column);
};

const getSectionById = (sections: SectionGridItem[], id: string) => {
    return sections.find(section => section.id === id);
};

interface TemplateSectionsGridProps {
    onSave: (sections: TemplateSection[]) => void;
    columnLayout: ColumnLayout;
    templateSections: TemplateSection[];
    sectionConfigs: Section[];
}

const defaultGrids = {
    0: [],
    1: [],
    2: [],
};

export const TemplateSectionsGrid = ({
    onSave,
    templateSections,
    columnLayout,
    sectionConfigs,
}: TemplateSectionsGridProps) => {
    const [activeSectionItemId, setActiveSectionItemId] = useState<
        null | string
    >(null);
    const prevColumnLayout = usePrevious(columnLayout);
    const [grids, setGrids] = useState<{
        [key: string]: SectionGridItem[];
    }>(defaultGrids);
    const [allSectionItems, setAllSectionItems] = useState<SectionGridItem[]>(
        [],
    );

    const generateLayout = useCallback(() => {
        const activeSectionIds = templateSections?.map(({ id }) => id) ?? [];
        const unusedSections: SectionGridItem[] = sectionConfigs
            .filter(section => !activeSectionIds.some(id => id === section.id))
            .map(section => ({
                id: section.id,
                name: section.name,
                column: 0,
                isStaticHeader: section.isStaticHeader,
            }));

        const columnOneSections: SectionGridItem[] =
            templateSections
                ?.filter(({ columnIndex }) =>
                    columnLayout === ColumnLayout.SINGLE
                        ? true
                        : columnIndex === 1,
                )
                .map(({ id }) => id)
                .map(id => ({
                    id,
                    name: sectionConfigs.find(section => section.id === id)
                        ?.name,
                    column: 1,
                    isStaticHeader: sectionConfigs.find(
                        section => section.id === id,
                    )?.isStaticHeader,
                })) ?? [];

        const columnTwoSections: SectionGridItem[] =
            columnLayout === ColumnLayout.SINGLE
                ? []
                : templateSections
                      ?.filter(({ columnIndex }) => columnIndex === 2)
                      .map(({ id }) => id)
                      .map(id => ({
                          id,
                          name: sectionConfigs.find(
                              section => section.id === id,
                          )?.name,
                          column: 2,
                          isStaticHeader: sectionConfigs.find(
                              section => section.id === id,
                          )?.isStaticHeader,
                      })) ?? [];

        const gridData = {
            0: unusedSections,
            1: columnOneSections,
            2: columnTwoSections,
        };
        if (columnLayout === ColumnLayout.SINGLE) {
            delete gridData[2];
        }

        setGrids(gridData);
        setAllSectionItems([
            ...unusedSections,
            ...columnOneSections,
            ...columnTwoSections,
        ]);
    }, [columnLayout, sectionConfigs, templateSections]);

    const handleDragStart = ({ active }: DragStartEvent) => {
        setActiveSectionItemId(active.id as string);
    };

    const handleDragOver = ({ active, over }: DragOverEvent) => {
        // Find the containers
        const activeContainer = findBoardSectionContainer(
            grids,
            active.id as string,
        );
        const overContainer = findBoardSectionContainer(
            grids,
            over?.id as string,
        );

        if (
            !activeContainer ||
            !overContainer ||
            activeContainer === overContainer
        ) {
            return;
        }

        setGrids(grid => {
            const activeItems = grid[activeContainer];
            const overItems = grid[overContainer];

            // Find the indexes for the items
            const activeIndex = activeItems.findIndex(
                item => item.id === active.id,
            );
            const overIndex = overItems.findIndex(item => item.id !== over?.id);

            return {
                ...grid,
                [activeContainer]: [
                    ...grid[activeContainer].filter(
                        item => item.id !== active.id,
                    ),
                ],
                [overContainer]: [
                    ...grid[overContainer].slice(0, overIndex),
                    grids[activeContainer][activeIndex],
                    ...grid[overContainer].slice(
                        overIndex,
                        grid[overContainer].length,
                    ),
                ],
            };
        });
    };

    const handleDragEnd = ({ active, over }: DragEndEvent) => {
        const activeContainer = findBoardSectionContainer(
            grids,
            active.id as string,
        );
        const overContainer = findBoardSectionContainer(
            grids,
            over?.id as string,
        );

        if (
            !activeContainer ||
            !overContainer ||
            activeContainer !== overContainer
        ) {
            return;
        }

        const activeIndex = grids[activeContainer].findIndex(
            task => task.id === active.id,
        );
        const overIndex = grids[overContainer].findIndex(
            task => task.id === over?.id,
        );

        if (activeIndex !== overIndex) {
            setGrids(grid => {
                const newGrid = { ...grid };
                const newGridItem = newGrid[activeContainer][activeIndex];
                newGridItem.column = parseInt(overContainer, 10);

                return {
                    ...newGrid,
                    [overContainer]: arrayMove(
                        newGrid[overContainer],
                        activeIndex,
                        overIndex,
                    ),
                };
            });
        }

        setActiveSectionItemId(null);
    };

    const section = activeSectionItemId
        ? getSectionById(allSectionItems, activeSectionItemId)
        : null;

    useEffect(() => {
        if (prevColumnLayout !== columnLayout) {
            setGrids(defaultGrids);
        }
    }, [columnLayout, prevColumnLayout]);

    const handleSave = () => {
        const sections = Object.keys(grids)
            .reduce((acc, key) => {
                const column = parseInt(key, 10);
                if (column === 0) return acc;
                const sections = getSectionsByStatus(grids[key], column);
                return [...acc, ...sections];
            }, [] as SectionGridItem[])
            .map((section, index) => ({
                id: section.id,
                columnIndex: section.column,
                positionIndex: index,
            }));
        onSave(sections);
    };

    return (
        <div className="mt-4">
            <Button onClick={generateLayout}>Load Layout</Button>
            <Button
                className="ml-4"
                color={ButtonColourStyles.SOLID_GREY}
                onClick={handleSave}
                disabled={allSectionItems.length === 0 || grids[1].length === 0}
            >
                Save Layout
            </Button>
            <AppText
                variant="contextheading"
                className="ml-4 mt-4 inline-block text-primary-400"
            >
                Note: Header Section MUST be at the top of column 1.
            </AppText>
            <div className="mt-4">
                <DndContext
                    collisionDetection={closestCorners}
                    onDragStart={handleDragStart}
                    onDragOver={handleDragOver}
                    onDragEnd={handleDragEnd}
                >
                    <div className="grid grid-cols-3 gap-4">
                        {Object.keys(grids).map(grid => (
                            <div
                                className="grid"
                                key={grid}
                            >
                                <TemplateSectionColumn
                                    id={grid}
                                    title={grid}
                                    sections={grids[grid]}
                                />
                            </div>
                        ))}
                        <DragOverlay>
                            {section ? (
                                <TemplateSectionItem section={section} />
                            ) : null}
                        </DragOverlay>
                    </div>
                </DndContext>
            </div>
        </div>
    );
};
