import { setDatePickerId, setShowSideBar, setTemplateLoadingState } from "@/store/app/slice";
import { resetPages } from "@/store/pages/slice";
import { setActiveResume } from "@/store/resume/slice";
import { AppDispatch } from "@/store/store";
import { setActiveItemId, setActiveSection, setActiveTemplate } from "@/store/template/slice";
import { TemplateLoadingState } from "@/types/app";

export const resetResumeBuilderState = (dispatch: AppDispatch) => {
    dispatch(setActiveItemId(null));
    dispatch(setActiveSection());
    dispatch(setActiveResume(null));
    dispatch(setActiveTemplate());
    dispatch(resetPages());
    dispatch(setTemplateLoadingState(TemplateLoadingState.INITIAL));
    dispatch(setShowSideBar(false));
    dispatch(setDatePickerId(""));
};