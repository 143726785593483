import userApi from "@/api/user";
import { useCallback, useEffect, useState } from "react";
import { useAppDispatch } from "@/hooks/types";
import {
    setAccessToken,
    setRefreshToken,
    setUserDetails,
} from "@/store/user/slice";
import { getErrorMessage } from "@/helper/getErrorMessage";
import { CodeInput } from "@/components/CodeInput/CodeInput";
import { Button } from "@/components/Button/Button";
import { Alert } from "@/components/Alert/Alert";
import { useLocalStorage } from "@/hooks/useLocalStorage";
import { sendVerifyEmail } from "@/email/send";
import { AppText } from "@/components/Text/AppText";

interface VerifyCodeProps {
    onSuccess: () => void;
    email: string;
}

export const VerifyCode = ({ onSuccess, email }: VerifyCodeProps) => {
    const [error, setError] = useState<string | null>("");
    const [isVerifying, setIsVerifying] = useState<boolean>(false);
    const [success, setSuccess] = useState<boolean>(false);
    const dispatch = useAppDispatch();
    const [resendTimeLeft, setResendTimeLeft] = useState<number>(60);
    const [, setLocalAccessToken] = useLocalStorage("accessToken", null);
    const [, setLocalRefreshToken] = useLocalStorage("refreshToken", null);

    const handleVerify = useCallback(
        async (code: string) => {
            if (!isVerifying) {
                setIsVerifying(true);
                setError("");
                try {
                    await userApi
                        .verifyEmailApi(code, email)
                        .then(async res => {
                            const { user, tokens } = res;
                            dispatch(setAccessToken(tokens.access));
                            dispatch(setRefreshToken(tokens.refresh));
                            setLocalAccessToken(tokens.access);
                            setLocalRefreshToken(tokens.access);
                            dispatch(setUserDetails(user));
                            setSuccess(true);
                        });
                } catch (e) {
                    setError(getErrorMessage(e, "Verify Email"));
                    setIsVerifying(false);
                }
            }
        },
        [
            dispatch,
            email,
            isVerifying,
            setLocalAccessToken,
            setLocalRefreshToken,
        ],
    );

    const resendCode = useCallback(async () => {
        setError("");
        if (!email) return;

        try {
            setResendTimeLeft(60);
            await userApi.getVerifyCode(email).then(res => {
                sendVerifyEmail(email, res.code);
            });
        } catch (e) {
            setError(getErrorMessage(e, "Resend Verification Code"));
        }
    }, [email]);

    useEffect(() => {
        if (success) {
            const interval = setTimeout(() => {
                onSuccess();
            }, 1000);

            return () => clearInterval(interval);
        }
    }, [onSuccess, success]);

    useEffect(() => {
        if (resendTimeLeft === 0) return;
        const interval = setInterval(() => {
            setResendTimeLeft(prev => (prev === 0 ? 60 : prev - 1));
        }, 1000);

        return () => clearInterval(interval);
    }, [resendTimeLeft]);

    return (
        <div className="relative mt-10">
            {error && (
                <Alert
                    type="error"
                    message={error}
                    className="mx-auto mb-6 max-w-[450px]"
                />
            )}
            <CodeInput
                length={4}
                onComplete={newCode => handleVerify(newCode)}
                disabled={isVerifying}
                success={success}
            />
            <div className="text-center">
                <Button
                    className="mb-6 text-center"
                    disabled={isVerifying || resendTimeLeft > 0}
                    onClick={resendCode}
                    loading={isVerifying || !!success}
                >
                    Resend
                </Button>
                {resendTimeLeft > 0 && (
                    <AppText
                        variant="labelsbuttons"
                        className="mt-2 block text-center text-primary-500/25"
                    >
                        Didn’t receive a code? Resend in {resendTimeLeft}s
                    </AppText>
                )}
            </div>
        </div>
    );
};
