import { array, boolean, object, string } from "yup";

export const sectionSchema = object({
    name: string().required(),
    titleComp: string(),
    isStaticHeader: boolean(),
    component: string(),
    sectionType: string().required(),
    documentType: string().required(),
    id: string(),
    bodyFields: array().of(
        object({
            name: string().required(),
            type: string().required(),
            defaultValue: string().required(),
            placeholder: string().required(),
            format: string(),
            icon: string(),
        }),
    ),
    headerFields: array().of(
        object({
            name: string().required(),
            type: string().required(),
            defaultValue: string().required(),
            placeholder: string().required(),
            format: string(),
            icon: string(),
        }),
    ),
});
