import {
    Button,
    ButtonColourStyles,
    ButtonVariant,
} from "@/components/Button/Button";
import { SparklesIcon } from "lucide-react";

interface ModalFooterProps {
    primaryActionText: string;
    primaryActionOnClick: () => void;
    secondaryActionText?: string;
    secondaryActionOnClick?: () => void;
    primaryActionLoading?: boolean;
    primaryActionVariant?: ButtonVariant;
    secondaryActionVariant?: ButtonVariant;
    primaryActionColor?: ButtonColourStyles;
    secondaryActionColor?: ButtonColourStyles;
}

export const ModalFooter = ({
    primaryActionText,
    primaryActionOnClick,
    secondaryActionText,
    secondaryActionOnClick,
    primaryActionLoading,
    primaryActionVariant = ButtonVariant.SOLID,
    secondaryActionVariant = ButtonVariant.SOLID,
    primaryActionColor = ButtonColourStyles.SOLID_PRIMARY,
    secondaryActionColor = ButtonColourStyles.SOLID_GREY,
}: ModalFooterProps) => (
    <div className="mt-3 flex items-center justify-end gap-3">
        {secondaryActionText && (
            <Button
                onClick={secondaryActionOnClick}
                color={secondaryActionColor}
                variant={secondaryActionVariant}
            >
                {secondaryActionText}
            </Button>
        )}
        <Button
            onClick={primaryActionOnClick}
            loading={primaryActionLoading}
            variant={primaryActionVariant}
            color={primaryActionColor}
            leftIcon={
                primaryActionText === "Upgrade" && (
                    <SparklesIcon className="h-4 w-4" />
                )
            }
        >
            {primaryActionText}
        </Button>
    </div>
);
