import { CheckboxRadioContainer } from "@/components/form/CheckboxRadioContainer";
import clsx from "clsx";

interface CheckboxProps {
    id: string;
    label: string | React.ReactNode;
    description?: string;
    onChange: (checked: boolean) => void;
    checked: boolean;
    className?: string;
    borderless?: boolean;
    rounded?: boolean;
}

export const Checkbox = ({
    id,
    label,
    description,
    checked,
    onChange,
    className,
    borderless,
    rounded,
}: CheckboxProps) => {
    return (
        <CheckboxRadioContainer
            id={id}
            label={label}
            description={description}
            className={className}
            borderless={borderless}
            onClick={e => {
                if (e.target === e.currentTarget) {
                    onChange(!checked);
                }
            }}
        >
            <input
                id={id}
                name={id}
                type="checkbox"
                checked={checked}
                className={clsx(
                    "h-4 w-4 cursor-pointer rounded border-2 border-neutral-900 text-neutral-900 outline-none focus:ring-transparent",
                    rounded ? "rounded-full" : "rounded",
                )}
                onChange={e => {
                    e.stopPropagation();
                    onChange(e.currentTarget.checked);
                }}
            />
        </CheckboxRadioContainer>
    );
};
