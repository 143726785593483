import { AppText } from "@/components/Text/AppText";
import {
    Disclosure,
    DisclosureButton,
    DisclosurePanel,
    Transition,
} from "@headlessui/react";
import clsx from "clsx";
import { ChevronDown, ChevronUp } from "lucide-react";

interface AccordionProps {
    title: string;
    children: React.ReactNode;
    width?: string;
    variant?: "default" | "white";
    defaultOpen?: boolean;
}

const Accordion = ({
    title,
    children,
    width = "80",
    variant = "default",
    defaultOpen,
}: AccordionProps) => {
    return (
        <div className={`w-${width} max-w-lg rounded-2xl bg-white`}>
            <Disclosure defaultOpen={defaultOpen}>
                {({ open }) => (
                    <>
                        <DisclosureButton
                            className={clsx(
                                open && "rounded-b-none",
                                variant === "white"
                                    ? "bg-white"
                                    : "bg-neutral-100",
                                "flex w-full items-center justify-between rounded-lg px-4 py-2 text-left text-neutral-900 focus:outline-none",
                            )}
                        >
                            <AppText
                                variant="contextheading"
                                className="!mb-0"
                            >
                                {title}
                            </AppText>
                            {open ? (
                                <ChevronUp className="h-5 w-5 text-neutral-900" />
                            ) : (
                                <ChevronDown className="h-5 w-5 text-neutral-900" />
                            )}
                        </DisclosureButton>
                        <Transition
                            enter="duration-200 ease-out"
                            enterFrom="opacity-0 -translate-y-6"
                            enterTo="opacity-100 translate-y-0"
                            leave="duration-100 ease-out"
                            leaveFrom="opacity-100 translate-y-0"
                            leaveTo="opacity-0 -translate-y-6"
                        >
                            <DisclosurePanel
                                className={clsx(
                                    variant === "white"
                                        ? "bg-white"
                                        : "bg-neutral-100",
                                    "rounded-b-lg px-4 pb-2 pt-2",
                                )}
                            >
                                {children}
                            </DisclosurePanel>
                        </Transition>
                    </>
                )}
            </Disclosure>
        </div>
    );
};

export default Accordion;
