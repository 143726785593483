import {
    Button,
    ButtonColourStyles,
    ButtonVariant,
} from "@/components/Button/Button";
import { decodeHtml } from "@/pages/ResumeBuilder/utils";
import { isValidHtml } from "@/utils/isValidHtml";
import CodeEditor from "@uiw/react-textarea-code-editor";
import clsx from "clsx";
import { AlertCircle, Circle, CircleCheck } from "lucide-react";
import { useCallback, useEffect, useState } from "react";

interface InlineCodeEditorProps {
    html: string;
    onChange: (html: string) => void;
    language?: "html" | "jsx";
    isStructureProperty?: boolean;
    allowTextOnly?: boolean;
}

enum ValidationState {
    VALID = 1,
    INVALID = 2,
    UNKNOWN = 3,
}

export const InlineCodeEditor = ({
    html,
    onChange,
    language = "html",
    isStructureProperty,
    allowTextOnly,
}: InlineCodeEditorProps) => {
    const [localValue, setLocalValue] = useState(html);
    const [errorMessage, setErrorMessage] = useState("");
    const [successMessage, setSuccessMessage] = useState("");
    const [validationState, setValidationState] = useState(
        ValidationState.UNKNOWN,
    );

    const validateHtml = useCallback(() => {
        setSuccessMessage("");
        const { isStringValidHtml, error } = isValidHtml(
            localValue,
            !!allowTextOnly,
        );
        if (isStringValidHtml) {
            setErrorMessage("");
            setValidationState(ValidationState.VALID);
        } else {
            setErrorMessage(error);
            setValidationState(ValidationState.INVALID);
        }
    }, [allowTextOnly, localValue]);

    const handleDecode = useCallback(() => {
        setSuccessMessage("");
        setLocalValue(decodeHtml(localValue));
    }, [localValue]);

    const handleUpdate = useCallback(() => {
        setErrorMessage("");
        setSuccessMessage("");
        const { numCurlyOpen, numCurlyClosed, numNormalClosed, numNormalOpen } =
            localValue.split("").reduce(
                (totals, currentChar) => {
                    if (currentChar === "{") totals.numCurlyOpen++;
                    if (currentChar === "}") totals.numCurlyClosed++;
                    if (currentChar === "(") totals.numNormalOpen++;
                    if (currentChar === ")") totals.numNormalClosed++;
                    return totals;
                },
                {
                    numCurlyOpen: 0,
                    numCurlyClosed: 0,
                    numNormalOpen: 0,
                    numNormalClosed: 0,
                },
            );

        const hasEmptyVar = localValue.includes("${}");

        // Validation rules for invalid JSX
        if (numCurlyOpen !== numCurlyClosed) {
            return setErrorMessage(
                `Syntax Error: Missing Bracket "${numCurlyClosed > numCurlyOpen ? "{" : "}"}"`,
            );
        } else if (numNormalOpen !== numNormalClosed) {
            return setErrorMessage(
                `Syntax Error: Missing Bracket "${numNormalClosed > numNormalOpen ? "(" : ")"}"`,
            );
        } else if (hasEmptyVar) {
            return setErrorMessage("Syntax Error: Empty Variable ${}");
        } else if (isStructureProperty) {
            // const hasHeader = localValue.includes(HEADER_STRING);
            // const hasContent = localValue.includes(CONTENT_STRING);
            // const errors = [];
            // if (!hasHeader) errors.push(HEADER_STRING);
            // if (!hasContent) errors.push(CONTENT_STRING);
            // if (errors.length) {
            //     return setErrorMessage(`Missing fields: ${errors.join(", ")}`);
            // }
        }

        validateHtml();

        if (validationState === ValidationState.INVALID) return;
        setSuccessMessage("Saved");
        onChange(localValue);
    }, [
        localValue,
        isStructureProperty,
        validateHtml,
        validationState,
        onChange,
    ]);

    useEffect(() => {
        setLocalValue(html);
    }, [html]);

    return (
        <div>
            <div className="relative">
                <CodeEditor
                    value={localValue}
                    language={language}
                    data-color-mode="light"
                    onChange={evn => {
                        setLocalValue(evn.target.value);
                        if (validationState !== ValidationState.UNKNOWN)
                            setValidationState(ValidationState.UNKNOWN);
                    }}
                    padding={15}
                    minHeight={100}
                    style={{
                        borderRadius: "5px",
                        fontFamily:
                            "ui-monospace,SFMono-Regular,SF Mono,Consolas,Liberation Mono,Menlo,monospace",
                    }}
                />

                <div
                    className={clsx(
                        "absolute right-0 top-0 rounded-bl p-2 text-xs text-white",
                        validationState === ValidationState.VALID
                            ? "bg-green-400"
                            : validationState === ValidationState.INVALID
                              ? "bg-error-500"
                              : "bg-neutral-300",
                    )}
                >
                    {validationState === ValidationState.VALID ? (
                        <CircleCheck size={16} />
                    ) : validationState === ValidationState.INVALID ? (
                        <AlertCircle size={16} />
                    ) : (
                        <Circle size={16} />
                    )}
                </div>
            </div>
            <div className="mt-2 flex items-center justify-between">
                <div className="flex items-center">
                    <Button
                        className="mr-2"
                        onClick={() => validateHtml()}
                        variant={ButtonVariant.OUTLINE}
                        color={ButtonColourStyles.OUTLINE_PRIMARY}
                    >
                        Validate
                    </Button>
                    <Button
                        onClick={handleUpdate}
                        className="mr-2"
                        disabled={validationState === ValidationState.INVALID}
                    >
                        Save
                    </Button>
                    {errorMessage && (
                        <span className="px-2 text-sm font-medium text-red-600">
                            {errorMessage}
                        </span>
                    )}
                    {successMessage && (
                        <span className="px-2 text-sm font-medium text-success-600">
                            {successMessage}
                        </span>
                    )}
                </div>
                <div>
                    <Button
                        onClick={() => handleDecode()}
                        variant={ButtonVariant.OUTLINE}
                        color={ButtonColourStyles.OUTLINE_GREY}
                    >
                        Decode HTML
                    </Button>
                </div>
            </div>
        </div>
    );
};
