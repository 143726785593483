import { PresetOption } from "@/components/Presets/PresetOption";
import {
    ProfilePresetCenteredPreview,
    ProfilePresetLeftRowPreview,
    ProfilePresetLeftColumnPreview,
} from "@/components/Presets/PresetPreviews";
import { useAppSelector } from "@/hooks/types";
import { useUpdateTemplate } from "@/hooks/useUpdateTemplate";
import { getResumeSectionById } from "@/store/resume/selectors";
import { useEffect, useState, useCallback } from "react";

const presetOptions = [
    {
        id: "left-column",
        name: "Left List",
        preview: <ProfilePresetLeftColumnPreview />,
        values: {
            profileAlignment: "flex-start",
            detailsPosition: "column",
        },
    },
    {
        id: "left-row",
        name: "Left Row",
        preview: <ProfilePresetLeftRowPreview />,
        values: {
            profileAlignment: "flex-start",
            detailsPosition: "row",
        },
    },
    {
        id: "centered",
        name: "Centered",
        preview: <ProfilePresetCenteredPreview />,
        values: {
            profileAlignment: "center",
            detailsPosition: "row",
        },
    },
];

export const ProfilePresets = ({
    sectionId,
    setIsPresetsMenuOpen,
}: {
    sectionId: string;
    setIsPresetsMenuOpen: (isOpen: boolean) => void;
}) => {
    const [activePreset, setActivePreset] = useState<string | null>(null);
    const sectionDetails = useAppSelector(state =>
        getResumeSectionById(state, sectionId),
    );
    const { updateTemplateSectionAndSave } = useUpdateTemplate();

    const closePresetsMenu = useCallback(() => {
        setIsPresetsMenuOpen(false);
    }, [setIsPresetsMenuOpen]);

    useEffect(() => {
        if (activePreset === null && sectionDetails?.layout) {
            const currentLayout = sectionDetails?.layout;
            const currentPreset = presetOptions.find(
                preset =>
                    preset.values.profileAlignment ===
                        currentLayout?.profileAlignment &&
                    preset.values.detailsPosition ===
                        currentLayout?.detailsPosition,
            );
            if (currentPreset) {
                setActivePreset(currentPreset.id);
            }
        }
    }, [activePreset, sectionDetails?.layout]);

    const onPresetClick = (id: string) => {
        const preset = presetOptions.find(preset => preset.id === id);
        if (!preset) return;
        setActivePreset(id);
        updateTemplateSectionAndSave(sectionId, "layout", {
            ...sectionDetails?.layout,
            ...preset.values,
        });
        setTimeout(closePresetsMenu, 200);
    };

    useEffect(() => {
        return () => {
            clearTimeout(closePresetsMenu as unknown as number);
        };
    }, [closePresetsMenu]);

    return (
        <div className="flex gap-4">
            {presetOptions.map(option => (
                <PresetOption
                    key={option.id}
                    onClick={() => onPresetClick(option.id)}
                    active={activePreset === option.id}
                    {...option}
                />
            ))}
        </div>
    );
};
