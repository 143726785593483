import "../../../pages/ResumeBuilder/resumeStyles.css";
import { Fragment, useCallback, useEffect, useState } from "react";
import { useAppDispatch, useAppSelector } from "@/hooks/types";
import {
    getActiveResume,
    getActiveResumeHeaderImage,
    getSectionsByDocumentTypeId,
} from "@/store/resume/selectors";
import { getPages } from "@/store/pages/selectors";
import { PdfDocumentViewer } from "@/components/PdfDocument/PdfDocumentViewer";
import { addNotification } from "@/store/app/slice";
import {
    NotificationMessageType,
    NotificationType,
    Page,
    Resume,
} from "@/types";
import { ModalTypes } from "@/types/modal";
import { getIsModalOpen, getModalProps } from "@/store/modal/selectors";
import {
    Dialog,
    DialogPanel,
    Transition,
    TransitionChild,
} from "@headlessui/react";
import clsx from "clsx";
import { closeModal } from "@/store/modal/slice";
import { PdfDocumentPreview } from "@/components/PdfPreview/PdfDocumentPreview";
import { IconButton } from "@/components/Button/IconButton";
import { XIcon, ZoomIn, ZoomOut } from "lucide-react";
import {
    A4_PAGE_HEIGHT_PIXELS,
    A4_PAGE_WIDTH_PIXELS,
} from "@/store/pages/constants";
import { Button, ButtonColourStyles } from "@/components/Button/Button";
import { LoadingSpinner } from "@/components/LoadingSpinner/LoadingSpinner";
import { duplicateResume } from "@/helper/duplicateResume";
import { useNavigate } from "react-router-dom";

const MAX_SCALE = 1;
const MIN_SCALE = 0.5;
const DEFAULT_SCALE = 0.8;

const LoadingComponent = () => (
    <div
        className="loading-page mt-20 flex justify-center"
        style={{
            height: A4_PAGE_HEIGHT_PIXELS,
            width: A4_PAGE_WIDTH_PIXELS,
        }}
    >
        <LoadingSpinner />
    </div>
);

const PreviewResumeModal = () => {
    const isOpen = useAppSelector(getIsModalOpen(ModalTypes.RESUME_PREVIEW));
    const modalProps = useAppSelector(getModalProps(ModalTypes.RESUME_PREVIEW));
    const overridePages = modalProps?.resumePages;
    const overrideResume = modalProps?.resume;
    const isResumeExample = modalProps?.isResumeExample;
    const activeResumeImageSignedUrl = useAppSelector(
        getActiveResumeHeaderImage,
    );
    const [error, setError] = useState<string | null>(null);
    const [documentLoaded, setDocumentLoaded] = useState(false);
    const [scale, setScale] = useState(DEFAULT_SCALE);
    const activeResume = useAppSelector(getActiveResume);
    const resume: Resume = overrideResume ?? activeResume;
    const activeResumePages = useAppSelector(getPages);
    const pages: Page[] = overridePages ?? activeResumePages;
    const sectionsConfig = useAppSelector(state =>
        getSectionsByDocumentTypeId(state, resume?.documentType),
    );
    const dispatch = useAppDispatch();
    const navigate = useNavigate();
    const [loadingExample, setLoadingExample] = useState(false);

    const onClose = useCallback(() => {
        dispatch(closeModal(ModalTypes.RESUME_PREVIEW));
    }, [dispatch]);

    const onUseExample = useCallback(() => {
        if (!resume) return;
        setLoadingExample(true);
        duplicateResume({
            navigate,
            resume,
            isExampleResume: isResumeExample,
        }).finally(() => {
            setLoadingExample(false);
            onClose();
        });
    }, [resume, navigate, isResumeExample, onClose]);

    const onScale = useCallback(
        (e: React.MouseEvent<HTMLButtonElement>, direction: "in" | "out") => {
            e.stopPropagation();

            const newScale = direction === "in" ? scale + 0.1 : scale - 0.1;
            const roundedScale = Math.round(newScale * 10) / 10;
            let scaleTo = roundedScale;

            if (roundedScale <= MIN_SCALE) {
                scaleTo = MIN_SCALE;
            } else if (roundedScale >= MAX_SCALE) {
                scaleTo = MAX_SCALE;
            }

            setScale(scaleTo);
        },
        [scale],
    );

    useEffect(() => {
        if (error) {
            dispatch(
                addNotification({
                    title: "Something went wrong",
                    desc: "Unable to preview document.",
                    messageType: NotificationMessageType.PREVIEW_FAILED,
                    type: NotificationType.ERROR,
                }),
            );
            setError(null);
        }
    }, [dispatch, error]);

    return (
        <Transition
            show={isOpen}
            as={Fragment}
            afterLeave={() => {
                setScale(DEFAULT_SCALE);
            }}
        >
            <Dialog
                as="div"
                className="relative z-50"
                onClose={onClose}
            >
                <TransitionChild
                    as={Fragment}
                    enter="ease-out duration-300"
                    enterFrom="opacity-0"
                    enterTo="opacity-100"
                    leave="ease-in duration-200"
                    leaveFrom="opacity-100"
                    leaveTo="opacity-0"
                >
                    <div className="fixed inset-0 bg-gray-500 bg-opacity-75 transition-opacity" />
                </TransitionChild>
                <div className="fixed inset-0 z-10 overflow-hidden">
                    <div className="flex min-h-full flex-col items-center justify-center px-4 py-1">
                        <TransitionChild
                            as={Fragment}
                            enter="ease-out duration-300"
                            enterFrom="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
                            enterTo="opacity-100 translate-y-0 sm:scale-100"
                            leave="ease-in duration-200"
                            leaveFrom="opacity-100 translate-y-0 sm:scale-100"
                            leaveTo="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
                        >
                            <DialogPanel
                                className={clsx(
                                    "relative max-h-[90vh] overflow-hidden rounded-lg bg-transparent",
                                    "flex flex-col",
                                )}
                                style={{
                                    width: `${A4_PAGE_WIDTH_PIXELS + 32}px`,
                                }}
                            >
                                <div className="sticky top-0 z-10 flex items-center justify-between border-b bg-white px-3 py-2">
                                    <>
                                        {isResumeExample && documentLoaded && (
                                            <div className="absolute left-3 z-10 hidden lg:block">
                                                <Button
                                                    onClick={onUseExample}
                                                    disabled={loadingExample}
                                                    loading={loadingExample}
                                                >
                                                    Use this example
                                                </Button>
                                            </div>
                                        )}
                                        <div className="relative flex w-full items-center justify-center gap-2">
                                            <IconButton
                                                color={
                                                    ButtonColourStyles.SOLID_SOFT_INDIGO
                                                }
                                                onClick={e => onScale(e, "out")}
                                                disabled={scale === MIN_SCALE}
                                                className={
                                                    documentLoaded
                                                        ? "opacity-100"
                                                        : "opacity-0"
                                                }
                                            >
                                                <ZoomOut />
                                            </IconButton>
                                            <IconButton
                                                color={
                                                    ButtonColourStyles.SOLID_SOFT_INDIGO
                                                }
                                                onClick={e => onScale(e, "in")}
                                                disabled={scale === MAX_SCALE}
                                                className={
                                                    documentLoaded
                                                        ? "opacity-100"
                                                        : "opacity-0"
                                                }
                                            >
                                                <ZoomIn />
                                            </IconButton>
                                            <IconButton
                                                className="absolute right-0"
                                                color={
                                                    ButtonColourStyles.SOLID_GREY
                                                }
                                                onClick={onClose}
                                            >
                                                <XIcon />
                                            </IconButton>
                                        </div>
                                    </>
                                </div>
                                <div className="preview-modal overflow-y-auto bg-neutral-100 px-4 py-3">
                                    <div
                                        className="mx-auto flex flex-col items-center gap-4"
                                        style={{
                                            minHeight: A4_PAGE_HEIGHT_PIXELS,
                                        }}
                                    >
                                        {resume && isOpen && (
                                            <>
                                                <PdfDocumentPreview
                                                    numberOfPages={pages.length}
                                                    loadingComponent={
                                                        <LoadingComponent />
                                                    }
                                                    onLoadSuccess={() => {
                                                        setDocumentLoaded(true);
                                                    }}
                                                    scale={scale}
                                                >
                                                    <PdfDocumentViewer
                                                        resume={resume}
                                                        sectionsConfig={
                                                            sectionsConfig
                                                        }
                                                        pages={pages}
                                                        imageSrc={
                                                            activeResumeImageSignedUrl ??
                                                            ""
                                                        }
                                                    />
                                                </PdfDocumentPreview>
                                            </>
                                        )}
                                    </div>
                                </div>
                            </DialogPanel>
                        </TransitionChild>
                    </div>
                </div>
            </Dialog>
        </Transition>
    );
};

export default PreviewResumeModal;
