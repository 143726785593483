interface RangeSliderProps {
    min: number;
    max: number;
    value: number;
    step: number;
    onChange: (e: React.ChangeEvent<HTMLInputElement>) => void;
    id: string;
    label: string;
}

export const RangeSlider = ({
    min,
    max,
    value,
    step,
    onChange,
    id,
    label,
}: RangeSliderProps) => {
    return (
        <div className="mb-2 w-full">
            {label && (
                <label
                    htmlFor={id}
                    className="mb-2 block text-sm font-medium text-neutral-900"
                >
                    {label}
                </label>
            )}
            <div className="flex items-center">
                <input
                    id={id}
                    type="range"
                    min={min}
                    max={max}
                    value={value}
                    step={step}
                    onChange={onChange}
                    className="h-8 w-full cursor-pointer appearance-none bg-transparent
                    [&::-moz-range-thumb]:h-5 [&::-moz-range-thumb]:w-5
                    [&::-moz-range-thumb]:appearance-none [&::-moz-range-thumb]:rounded-full
                    [&::-moz-range-thumb]:border-0 [&::-moz-range-thumb]:bg-primary-900 [&::-moz-range-thumb]:transition-all [&::-moz-range-thumb]:duration-300 [&::-moz-range-thumb]:ease-in-out hover:[&::-moz-range-thumb]:ring-2 hover:[&::-moz-range-thumb]:ring-primary-200 [&::-moz-range-track]:h-2 [&::-moz-range-track]:bg-neutral-200 [&::-webkit-slider-runnable-track]:h-2 [&::-webkit-slider-runnable-track]:bg-neutral-200
                    [&::-webkit-slider-thumb]:mt-[-6px] [&::-webkit-slider-thumb]:h-5 [&::-webkit-slider-thumb]:w-5 [&::-webkit-slider-thumb]:appearance-none [&::-webkit-slider-thumb]:rounded-full [&::-webkit-slider-thumb]:bg-primary-900 [&::-webkit-slider-thumb]:transition-all [&::-webkit-slider-thumb]:duration-300 [&::-webkit-slider-thumb]:ease-in-out hover:[&::-webkit-slider-thumb]:ring-2 hover:[&::-webkit-slider-thumb]:ring-primary-200"
                />
            </div>
        </div>
    );
};
