import { getDefaultSectionDetails } from "@/helper/getDefaultSectionDetails";
import { ResumeSections, Section } from "@/types";
import { generateUniqueId } from "@/utils/string";

export const getDefaultSectionsDataDocumentType = (sections: Section[], docType: string) => {
    const docTypeSections = sections.filter((section) => section.documentType === docType);

    const defaultSectionContent = {} as ResumeSections;

    docTypeSections.forEach((sectionConfig, index) => {
        const sectionId = generateUniqueId();
        defaultSectionContent[sectionId] = {
            ...getDefaultSectionDetails(sectionConfig, index, 0),
            __isDefaultContent: true,
        };
    });

    return defaultSectionContent;
};