import { HeaderToolbar } from "@/components/HeaderToolbar/HeaderToolbar";
import { getSectionStyle } from "@/components/PdfDocument/utils/getStyles";
import { SECTION_CONTAINER_CLASS } from "@/constants/resume";
import { useAppSelector } from "@/hooks/types";
import { SectionSpacingDebugger } from "@/pages/ResumeBuilder/SectionSpacingDebugger";
import { getDebugSectionSpacing } from "@/store/app/selectors";
import { getPages } from "@/store/pages/selectors";
import { getActiveSection } from "@/store/template/selectors";
import { PageMarginSize, SectionSpacingSize } from "@/types/resume";
import { getIsAResumePreviewPage } from "@/utils/routes";
import clsx from "clsx";
import { ReactNode, useMemo } from "react";

interface SectionContainerProps {
    isStaticHeader: boolean;
    backgroundColour?: string;
    children: ReactNode;
    activeItemId: string | null;
    isPreviewingSection?: boolean;
    sectionId: string;
    onClick?: (e: React.MouseEvent<HTMLDivElement>) => void;
    sectionSpacingSize: SectionSpacingSize;
    pageMarginSize: PageMarginSize;
    pageNumber: number;
}

export const SectionContainer = ({
    isStaticHeader,
    backgroundColour,
    activeItemId,
    children,
    isPreviewingSection,
    sectionId,
    onClick,
    sectionSpacingSize,
    pageMarginSize,
    pageNumber,
}: SectionContainerProps) => {
    const isPreview = useMemo(
        () => isPreviewingSection || getIsAResumePreviewPage(),
        [isPreviewingSection],
    );
    const activeSectionId = useAppSelector(getActiveSection);
    const isActive = activeSectionId === sectionId;
    const debugSectionSpacing = useAppSelector(getDebugSectionSpacing);
    const pages = useAppSelector(getPages);

    // Last sections on page should not have a margin bottom.
    const isLastSectionOnPage = useMemo(() => {
        const currentPage = pages.find(page => page.pageNumber === pageNumber);
        const leftItems = currentPage?.items.filter(
            item => item.columnIndex === 0,
        );
        const rightItems = currentPage?.items.filter(
            item => item.columnIndex === 1,
        );
        const leftLastItem = leftItems?.[leftItems.length - 1];
        const rightLastItem = rightItems?.[rightItems.length - 1];

        return (
            leftLastItem?.section === sectionId ||
            rightLastItem?.section === sectionId
        );
    }, [pages, sectionId, pageNumber]);

    const sectionContainerStyles = useMemo(() => {
        return getSectionStyle({
            isStaticHeader: !!isStaticHeader,
            backgroundColor: backgroundColour,
            sectionSpacingSize,
            pageMarginSize,
            isLastSectionOnPage,
        });
    }, [
        backgroundColour,
        isStaticHeader,
        sectionSpacingSize,
        pageMarginSize,
        isLastSectionOnPage,
    ]);

    return (
        <div
            onClick={onClick}
            id={sectionId}
            className={clsx(
                SECTION_CONTAINER_CLASS,
                "group relative",
                isPreview
                    ? isStaticHeader
                        ? "rounded-t-lg pt-2"
                        : "rounded-lg"
                    : isStaticHeader
                      ? "rounded-t-lg"
                      : "break-words rounded-lg",
                isPreviewingSection ? "bg-transparent" : "bg-white",
                isActive
                    ? !activeItemId
                        ? `ring-2 ring-neutral-300 ${!isStaticHeader && "ring-offset-2"}`
                        : ""
                    : !isPreview &&
                          `ring-neutral-300 hover:ring-2 hover:ring-neutral-200  ${!isStaticHeader && "ring-offset-2"}`,
                isPreviewingSection && "!px-5",
            )}
            style={sectionContainerStyles}
        >
            {isStaticHeader && !isPreviewingSection && !isPreview && (
                <HeaderToolbar sectionId={sectionId} />
            )}
            {children}
            {debugSectionSpacing && (
                <SectionSpacingDebugger spacing={sectionContainerStyles} />
            )}
        </div>
    );
};
