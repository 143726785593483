// For now we will use the sectionType value to match to column
// TODO: Potentially use the supportedWidths value instead
// These are sections that on layout change, should NOT be automatically
// placed into the right hand column
export const LEFT_HAND_ONLY_COLUMN_SECTIONS = [
    "certification",
    "experience",
    "work experience",
    "profile",
    "projects",
    "summary",
    "volunteering",
];

export const DEFAULT_HEADER_IMAGE_PATH = "1template.com/static/default";
export const DEFAULT_IMAGE_SRC = "https://s3.ap-southeast-2.amazonaws.com/1template.com/static/default/default_user.png";