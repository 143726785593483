import { PdfSection } from "@/components/PdfDocument";
import { PageSections } from "@/components/PdfDocument/types/pdfTypes";
import { ResumeStyles } from "@/types/resume";
import { View } from "@react-pdf/renderer";

interface PdfSectionsRow {
    sectionIds: string[];
    pageSections: PageSections;
    resumeStyles: ResumeStyles;
    isTwoColumn: boolean;
}

export const PdfSectionsRow = ({
    sectionIds,
    pageSections,
    resumeStyles,
    isTwoColumn,
}: PdfSectionsRow) => {
    const isFullRow = isTwoColumn || sectionIds.length === 1;

    return (
        <View
            style={{
                flexDirection: isFullRow ? "column" : "row",
            }}
        >
            {sectionIds.map((sectionId, index) => {
                const {
                    details,
                    config,
                    isStaticHeader,
                    columnIndex,
                    isSplitSection,
                } = pageSections[sectionId];
                return (
                    <PdfSection
                        key={`${index}-${sectionId}`}
                        resumeStyles={resumeStyles}
                        config={config}
                        details={details}
                        isStaticHeader={isStaticHeader}
                        columnIndex={columnIndex}
                        isTwoColumn={isTwoColumn}
                        isSplitSection={isSplitSection}
                    />
                );
            })}
        </View>
    );
};
