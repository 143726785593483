import React, { useState, useEffect, useMemo } from "react";
import { Search, X } from "lucide-react";
import debounce from "lodash/debounce";

interface AdminSearchBarProps {
    setSearchTerm: (term: string) => void;
    placeholder?: string;
}

export const AdminSearchBar: React.FC<AdminSearchBarProps> = ({
    setSearchTerm,
    placeholder = "Search",
}) => {
    const [localSearchTerm, setLocalSearchTerm] = useState("");

    const debouncedSetSearchTerm = useMemo(
        () => debounce((term: string) => setSearchTerm(term), 300),
        [setSearchTerm],
    );

    useEffect(() => {
        debouncedSetSearchTerm(localSearchTerm);
        return () => debouncedSetSearchTerm.cancel();
    }, [localSearchTerm, debouncedSetSearchTerm]);

    const handleInputChange = (e: React.ChangeEvent<HTMLInputElement>) => {
        setLocalSearchTerm(e.target.value);
    };

    const handleClearSearch = () => {
        setLocalSearchTerm("");
        setSearchTerm("");
    };

    return (
        <div className="relative">
            <div className="pointer-events-none absolute inset-y-0 left-0 flex items-center pl-3">
                <Search
                    className="h-5 w-5 text-gray-400"
                    aria-hidden="true"
                />
            </div>
            <input
                type="text"
                name="search"
                id="search"
                className="block w-full rounded-md border border-gray-300 bg-white py-2 pl-10 pr-10 leading-5 placeholder-gray-500 focus:border-primary-500 focus:placeholder-gray-400 focus:outline-none focus:ring-1 focus:ring-primary-500 sm:text-sm"
                placeholder={placeholder}
                value={localSearchTerm}
                onChange={handleInputChange}
            />
            {localSearchTerm && (
                <button
                    type="button"
                    className="absolute inset-y-0 right-0 flex items-center pr-3"
                    onClick={handleClearSearch}
                >
                    <X
                        className="h-5 w-5 text-gray-400 hover:text-gray-500"
                        aria-hidden="true"
                    />
                </button>
            )}
        </div>
    );
};
