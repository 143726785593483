interface GeoJSResponse {
    country_code: string;
    country: string;
    continent_code: string;
    continent: string;
    region: string;
    city: string;
    timezone: string;
    language: {
        code: string;
        name: string;
    };
}

const geoApi = 'https://get.geojs.io/v1/ip/geo.json';
const COUNTRY_CODE_KEY = 'country_code';
const SUPPORTED_COUNTRY_CODES = ['US', 'CA', 'GB', 'AU', 'NZ'];
const DEFAULT_COUNTRY_CODE = 'US';
// Default to EU as a "country code" if user is in EUROPE
const EUROPE_CODE = "EU";
const SUPPORTED_CONTINENT_CODES = [EUROPE_CODE];

export async function detectUserCountry(): Promise<string> {
    // First, check if we have a detected locale in localStorage
    const storedCountry = localStorage.getItem(COUNTRY_CODE_KEY);
    if (storedCountry) {
        return storedCountry;
    }

    try {
        const response = await fetch(geoApi);
        const data: GeoJSResponse = await response.json();

        if (SUPPORTED_COUNTRY_CODES.includes(data.country_code)) {
            localStorage.setItem(COUNTRY_CODE_KEY, data.country_code);
            return data.country_code;
        }

        if (SUPPORTED_CONTINENT_CODES.includes(data.continent_code)) {
            localStorage.setItem(COUNTRY_CODE_KEY, EUROPE_CODE);
            return EUROPE_CODE;
        }

        localStorage.setItem(COUNTRY_CODE_KEY, DEFAULT_COUNTRY_CODE);
        return DEFAULT_COUNTRY_CODE;
    } catch (error) {
        return DEFAULT_COUNTRY_CODE;
    }
}

export const getUserCountryCode = () => {
    return localStorage.getItem(COUNTRY_CODE_KEY) || DEFAULT_COUNTRY_CODE;
};
