import { MemoEditableMapper } from "@/components/EditorComponents/EditableMapper";
import { MemoReadOnlyMapper } from "@/components/EditorComponents/ReadOnlyMapper";
import { SectionContainer } from "@/pages/ResumeBuilder/SectionContainer";
import { SectionItemContainer } from "@/pages/ResumeBuilder/SectionItemContainer";
import { Section } from "@/types";
import {
    PageMarginSize,
    SectionDetails,
    SectionSpacingSize,
    ResumeStyles,
} from "@/types/resume";
import { getSectionFieldsConfig } from "@/utils/section";
import { useMemo } from "react";

interface NewSectionPreviewProps {
    sectionDetails: SectionDetails;
    sectionConfig: Section;
    stylesConfig: ResumeStyles;
}

export const NewSectionPreview = ({
    sectionDetails,
    sectionConfig,
    stylesConfig,
}: NewSectionPreviewProps) => {
    const headerFieldsConfig = useMemo(
        () => getSectionFieldsConfig(sectionConfig?.headerFields),
        [sectionConfig?.headerFields],
    );
    const bodyFieldsConfig = useMemo(
        () => getSectionFieldsConfig(sectionConfig?.bodyFields),
        [sectionConfig?.bodyFields],
    );

    return (
        <SectionContainer
            activeItemId={null}
            sectionId={""}
            isPreviewingSection={true}
            isStaticHeader={!!sectionConfig.isStaticHeader}
            sectionSpacingSize={SectionSpacingSize.MD}
            pageMarginSize={PageMarginSize.MD}
            pageNumber={1}
        >
            <MemoEditableMapper
                html={sectionConfig.titleComp}
                propType="header"
                sectionId={sectionConfig.id}
                visibleProps={sectionDetails.header}
                fieldsConfig={headerFieldsConfig}
                stylesConfig={stylesConfig}
            />
            {sectionDetails.body.map((item, index) => (
                <SectionItemContainer
                    key={item.__id}
                    shouldMeasure={false}
                    itemId={item.__id}
                    sectionId={sectionConfig.id}
                    sectionConfigId={sectionConfig.id}
                    itemIndex={index}
                    isEditable={false}
                    isLastItem={true}
                    sectionSpacingSize={SectionSpacingSize.MD}
                >
                    <MemoReadOnlyMapper
                        html={sectionConfig.component}
                        propType="body"
                        sectionId={sectionConfig.id}
                        visibleProps={item}
                        fieldsConfig={bodyFieldsConfig}
                        stylesConfig={stylesConfig}
                    />
                </SectionItemContainer>
            ))}
        </SectionContainer>
    );
};
