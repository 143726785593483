import { isEmptyValueOrEmptyHtmlTag } from "@/components/PdfDocument/utils/getHtmlStringWithValues";
import { GLOBAL_STYLE_KEY } from "@/constants/resume";
import { decodeHtml } from "@/pages/ResumeBuilder/utils";
import { ComponentPropsObject, ResumeSections, ResumeStyles, SectionDetails, SectionItemDetails } from "@/types/resume";
import { LayoutField, Section, SectionField, SectionFieldsConfig, ToggleState } from "@/types/section";
import { generateUniqueId } from "@/utils/string";

export const getSectionById = (sections: Section[], id: string) => {
  if (!sections || !sections.length || !id) return null;
  return sections.find(section => section.id === id);
};

export const getSectionConfigBySectionId = (sections: Section[], sectionConfigId: string) => {
  return sections.find(section => section.id === sectionConfigId);
};

/**
 * Given a sections fields, transform them into a key value pair object
 * @param fields headerFields or bodyFields from a Section
 * @param values Current values that are on a template
 * @returns A key value pair object to be used in react components
 */
export const transformFieldsToProps = (appendIdAndHiddenFields: boolean, fields: SectionField[], values?: ComponentPropsObject): ComponentPropsObject => {
  return fields.reduce<ComponentPropsObject>((acc, curr) => {
    const value = values?.[curr.name];
    if (value) {
      acc[curr.name] = decodeHtml(value);
      return acc;
    }

    if (values && curr.name in values) {
      acc[curr.name] = "";
      return acc;
    }

    acc[curr.name] = decodeHtml(curr.defaultValue);

    if (appendIdAndHiddenFields) {
      if (!curr.__id) {
        acc.__id = generateUniqueId();
      }
      if (!acc.hiddenFields) {
        acc.hiddenFields = getDefaultHiddenFields(fields);
      }
    }

    return acc;
  }, {});
};

export const transformLayoutFieldsToProps = (fields: LayoutField[], values?: ComponentPropsObject) => {
  return fields.reduce<ComponentPropsObject>((acc, curr) => {
    const value = values?.[curr.name];
    if (value) {
      acc[curr.name] = value;
      return acc;
    }

    if (values && curr.name in values) {
      acc[curr.name] = "";
      return acc;
    }

    acc[curr.name] = curr.defaultValue;
    return acc;
  }, {});
};

/**
 * Only return props with values if they are not hidden
 * @param props - All props from a component
 * @param hiddenFields - List of field names that are hidden
 * @returns - A key value pair object with only visible props
 */
export const getVisibleProps = (props: ComponentPropsObject = {}, hiddenFields: string[]) => {
  const visibleProps: ComponentPropsObject = {};

  Object.keys(props).forEach((key) => {
    if (!hiddenFields.includes(key)) {
      visibleProps[key] = props[key];
    }
  });

  return visibleProps;
};

// Apply placeholder values to a section details object. This is used when viewing a read only resume.
const applyPlaceholderValues = (sectionConfig: Section, sectionDetails: SectionDetails) => {
  if (!sectionConfig || !sectionDetails) return sectionDetails;
  const { hiddenFields, header, body } = JSON.parse(JSON.stringify(sectionDetails)) as SectionDetails;

  Object.keys(header).forEach(key => {
    const isHidden = hiddenFields.includes(key);
    const isEmptyOrEmptyHtml = header[key] ? isEmptyValueOrEmptyHtmlTag(header[key]) : true;

    if (isHidden) {
      header[key] = "";
    } else if (isEmptyOrEmptyHtml) {
      header[key] = sectionConfig.headerFields.find(field => field.name === key)?.placeholder || "";
    }
  });

  body.forEach((item: SectionItemDetails) => {
    Object.keys(item).forEach(key => {
      const isHidden = hiddenFields.includes(key);

      if (key === "__id" || key === "hiddenFields") return;
      const isEmptyOrEmptyHtml = isEmptyValueOrEmptyHtmlTag(item[key]);

      if (isHidden) {
        item[key] = "";
      } else if (isEmptyOrEmptyHtml) {
        item[key] = sectionConfig.bodyFields.find(field => field.name === key)?.placeholder || "";
      }
    });
  });

  return {
    ...sectionDetails,
    header,
    body
  };
};


export const getSectionsByLayout = (resumeSections: ResumeSections, sectionConfigs: Section[]) => {
  const headerSectionConfig = sectionConfigs.find(section => section.isStaticHeader);
  const sectionDetailsKeys = Object.keys(resumeSections);
  const headerSectionDetailsId = sectionDetailsKeys.find(sectionId => resumeSections[sectionId].sectionConfigId === headerSectionConfig?.id);
  const headerSection: SectionDetails | null = headerSectionDetailsId ? resumeSections[headerSectionDetailsId] : null;

  const headerSectionWithPlaceholderValues = headerSection ? applyPlaceholderValues(headerSectionConfig, { ...headerSection }) : null;

  const sortedBodySections: ResumeSections = Object.fromEntries(
    Object.entries(resumeSections)
      .filter(([, sectionDetails]) => {
        const sectionConfig = getSectionById(sectionConfigs, sectionDetails.sectionConfigId);
        return !sectionConfig?.isStaticHeader;
      })
      .sort(([, a], [, b]) => (a.positionIndex ?? Infinity) - (b.positionIndex ?? Infinity))
  );

  const bodySectionsWithPlaceholderValues = Object.fromEntries(
    Object.entries(sortedBodySections)
      .map(([sectionId, sectionDetails]) => {
        const sectionConfig = getSectionById(sectionConfigs, sectionDetails.sectionConfigId);
        return [sectionId, applyPlaceholderValues(sectionConfig, { ...sectionDetails })];
      })
  );

  return { headerSection: headerSectionWithPlaceholderValues, bodySections: bodySectionsWithPlaceholderValues, headerSectionId: headerSectionDetailsId };
};

/**
 * Get all fields from a section that are not disabled
 * @param fields 
 * @returns SectionField[] - List of fields that are not disabled
 */
export const getFieldsByToggleState = (fields: SectionField[] | LayoutField[]) => {
  return fields.filter(field => field.toggleState !== ToggleState.DISABLED);
};

/**
 * Get all fields from a section that are hidden by default as set in the admin
 * @param fields 
 * @returns string[] - List of field names that are hidden by default
 */
export const getDefaultHiddenFields = (fields: SectionField[]) => {
  return fields
    .filter(field => field.toggleState === ToggleState.OFF)
    .map(field => field.name);
};

export const getSectionFieldsConfig = (fields?: SectionField[], visibleFields?: ComponentPropsObject) => {
  if (!fields) return {};
  return fields.reduce<SectionFieldsConfig>((acc, field) => {
    const isVisible = visibleFields?.[field.name];
    const placeholder = isVisible === undefined ? undefined : field.placeholder;
    acc[field.name] = {
      icon: field.icon,
      placeholder,
      type: field.type,
    };
    return acc;
  }, {});
};

export const getResumeStylesConfig = ({
  overrideResumeStyles, resumeStyles, activeResumeStyles, columnIndex,
}: {
  overrideResumeStyles?: ResumeStyles, resumeStyles?: ResumeStyles, activeResumeStyles?: ResumeStyles, columnIndex: number;
}) => {
  const stylesConfig =
    overrideResumeStyles ||
    resumeStyles ||
    activeResumeStyles ||
    {};

  if (columnIndex === 0) return stylesConfig;

  const copy: ResumeStyles = JSON.parse(JSON.stringify(stylesConfig));

  copy[GLOBAL_STYLE_KEY].dateLocationPosition = "column";
  return copy;
};

export const sortFieldsByOrder = (fields: SectionField[] | LayoutField[]) => {
  const newArr = fields ? [...fields] : [];
  return newArr.sort((a, b) => (a.order ?? 0) - (b.order ?? 0));
};

const keepFirstTextTag = (value: string): string => {
  const textTags = ["p", "h1", "h2", "h3", "h4", "h5", "h6", "span"];
  const regex = new RegExp(`<(${textTags.join("|")})[^>]*>.*?<\/\\1>`, "i");
  const match = value.match(regex);

  if (match) {
    return match[0].replace(/>.*?</, "><");
  }
  return "";
};

export const getNewEmptyItem = (fields: SectionField[]) => {
  const newItem = fields.reduce((acc, field) => {
    if (field.type.toLowerCase() === "date") {
      acc[field.name] = field.defaultValue;
    } else {
      const defaultValue = field.defaultValue;
      // If default value contains HTML, keep only the first text tag and remove its content
      if (defaultValue.includes("<")) {
        acc[field.name] = keepFirstTextTag(defaultValue);
      } else {
        acc[field.name] = "";
      }
    }
    return acc;
  }, {} as SectionItemDetails);
  newItem.__id = generateUniqueId();
  newItem.hiddenFields = getDefaultHiddenFields(fields);
  return newItem;
};
