import { Button, ButtonColourStyles } from "@/components/Button/Button";
import { AppText } from "@/components/Text/AppText";
import { sendFeedbackEmail } from "@/email/send";
import { useAppSelector } from "@/hooks/types";
import { getUserDetails } from "@/store/user/selectors";
import { useState } from "react";

export const ProfileContact = () => {
    const [feedback, setFeedback] = useState("");
    const [isSubmitting, setIsSubmitting] = useState(false);
    const [submitStatus, setSubmitStatus] = useState<{
        type: "success" | "error";
        message: string;
    } | null>(null);
    const user = useAppSelector(getUserDetails);
    const handleSubmitFeedback = async (e: React.FormEvent) => {
        e.preventDefault();
        setIsSubmitting(true);
        setSubmitStatus(null);

        try {
            await sendFeedbackEmail(feedback, window.location.href, user);
            setFeedback("");
            setSubmitStatus({
                type: "success",
                message: "Thank you for your feedback!",
            });
        } catch (error) {
            setSubmitStatus({
                type: "error",
                message: "Failed to send feedback. Please try again.",
            });
        } finally {
            setIsSubmitting(false);
        }
    };
    return (
        <div className="mb-4 flex flex-col">
            <AppText
                variant="headings"
                className="mb-8 hidden text-neutral-900 md:block"
            >
                Contact
            </AppText>
            <AppText
                variant="labelsbuttons"
                className="mb-2"
            >
                For any inquiries, please reach out to us at:
            </AppText>
            <a
                href="mailto:hello@1template.io"
                className="text-primary-500 underline hover:text-primary-600"
            >
                <AppText
                    variant="labelsbuttons"
                    className="text-primary-500 underline hover:text-primary-600"
                >
                    hello@1template.io
                </AppText>
            </a>
            <form
                onSubmit={handleSubmitFeedback}
                className="mt-4 space-y-4"
            >
                <div>
                    <AppText
                        variant="regular"
                        className="mb-2"
                    >
                        Or send us feedback directly:
                    </AppText>
                    <textarea
                        value={feedback}
                        onChange={e => setFeedback(e.target.value)}
                        className="w-full rounded-md border border-gray-300 p-2"
                        rows={4}
                    />
                </div>
                <Button
                    type="submit"
                    disabled={isSubmitting || !feedback.trim()}
                    color={ButtonColourStyles.SOLID_PRIMARY}
                >
                    {isSubmitting ? "Sending..." : "Send Feedback"}
                </Button>
                {submitStatus && (
                    <AppText
                        variant="regular"
                        className={
                            submitStatus.type === "success"
                                ? "text-success-600"
                                : "text-error-600"
                        }
                    >
                        {submitStatus.message}
                    </AppText>
                )}
            </form>
        </div>
    );
};
