import {
    HTMLInputTypeAttribute,
    useCallback,
    useEffect,
    useState,
} from "react";
import { Input } from "../form/Input";
import { useUpdateTemplate } from "@/hooks/useUpdateTemplate";
import clsx from "clsx";
import { CheckIcon, EditIcon, SaveIcon } from "lucide-react";
import { Button } from "@/components/Button/Button";
import { IconButton } from "@/components/Button/IconButton";
import { setActiveResume } from "@/store/resume/slice";
import { useAppDispatch } from "@/hooks/types";

interface ResumeNameProps {
    resumeId: string;
    resumeName: string;
    showSaveButton?: boolean;
    onSave?: () => void;
    isBuilderPage?: boolean;
}

export const ResumeName = ({
    resumeId,
    resumeName,
    showSaveButton,
    onSave,
    isBuilderPage,
}: ResumeNameProps) => {
    const { saveAndUpdateTemplate } = useUpdateTemplate();
    const [name, setName] = useState(resumeName);
    const [isNameEditable, setIsNameEditable] = useState(false);
    const [isSaveSuccess, setIsSaveSuccess] = useState(false);
    const dispatch = useAppDispatch();
    useEffect(() => {
        setName(resumeName);
    }, [resumeName]);

    const handleChange = (_: HTMLInputTypeAttribute, value: string) => {
        setName(value);
    };

    const saveResume = useCallback(() => {
        if (resumeId) {
            saveAndUpdateTemplate({ newName: name, updateResumeId: resumeId });
            if (isBuilderPage) {
                dispatch(setActiveResume({ name: name }));
            }
        }
        setIsNameEditable(!isNameEditable);
        setIsSaveSuccess(true);
        setTimeout(() => setIsSaveSuccess(false), 2000);
        onSave?.();
    }, [
        resumeId,
        isNameEditable,
        onSave,
        saveAndUpdateTemplate,
        name,
        isBuilderPage,
        dispatch,
    ]);

    return (
        <div className={clsx(showSaveButton && "flex items-center gap-3 ")}>
            <Input
                type="text"
                value={name}
                onChange={handleChange}
                onBlur={showSaveButton ? undefined : saveResume}
                className={clsx(
                    !isNameEditable && "cursor-pointer",
                    "mx-auto  basis-2/3 rounded ",
                    isBuilderPage
                        ? "!min-w-[150px] xl:!min-w-[300px]"
                        : "!min-w-[200px] lg:!min-w-[250px] xl:!min-w-[350px]",
                )}
                onClick={() => setIsNameEditable(true)}
                onKeyDown={(e: React.KeyboardEvent<HTMLInputElement>) => {
                    if (e.key === "Enter") {
                        e.currentTarget.blur();
                        if (showSaveButton) {
                            saveResume();
                        }
                    }
                }}
                trailingIcon={
                    <EditIcon
                        className="ml-3 h-5 w-5 text-neutral-500"
                        onClick={() => setIsNameEditable(true)}
                    />
                }
            />
            {showSaveButton && (
                <Button
                    onClick={e => {
                        e.preventDefault();
                        saveResume();
                    }}
                    className="hidden w-full lg:flex"
                    disabled={resumeName === name}
                >
                    {isSaveSuccess ? "Saved" : "Save"}
                </Button>
            )}
            {showSaveButton && (
                <IconButton
                    onClick={e => {
                        e?.preventDefault();
                        saveResume();
                    }}
                    className="flex lg:hidden"
                    disabled={resumeName === name}
                >
                    <div
                        className={clsx(
                            "relative h-6 w-6 transition-all duration-300 ease-in-out",
                            isSaveSuccess && "rotate-[360deg] transform",
                        )}
                    >
                        <SaveIcon
                            className={clsx(
                                "absolute inset-0 transition-all duration-300 ease-in-out",
                                isSaveSuccess
                                    ? "scale-0 opacity-0"
                                    : "scale-100 opacity-100",
                            )}
                        />
                        <CheckIcon
                            className={clsx(
                                "absolute inset-0 transition-all duration-300 ease-in-out",
                                isSaveSuccess
                                    ? "scale-100 opacity-100"
                                    : "scale-0 opacity-0",
                            )}
                        />
                    </div>
                </IconButton>
            )}
        </div>
    );
};
