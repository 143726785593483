import { DateDropdowns } from "@/components/DatePeriodPicker/DateDropdowns";
import { applyResumeLevelDateFormat } from "@/components/DatePeriodPicker/utils";
import { FieldIcon } from "@/components/FieldIcon/FieldIcon";
import { getTextStyle } from "@/components/PdfDocument/utils/getStyles";
import { TabButton } from "@/components/Tabs/TabButton";
import { Checkbox } from "@/components/form/Checkbox";
import { useAppDispatch, useAppSelector } from "@/hooks/types";
import { getDatePickerId } from "@/store/app/selectors";
import { setDatePickerId } from "@/store/app/slice";
import { setActiveEntities } from "@/store/template/slice";
import { DateFormat, ResumeStyles } from "@/types/resume";
import { TabGroup, TabList, TabPanels, TabPanel } from "@headlessui/react";
import clsx from "clsx";
import { useCallback, useEffect, useMemo, useRef, useState } from "react";
import { GLOBAL_STYLE_KEY } from "@/constants/resume";

interface DatePeriodPickerProps {
    value: string;
    onChange: (value: string) => void;
    editable: boolean;
    icon?: string;
    stylesConfig: ResumeStyles;
    sectionId: string;
    itemId: string;
    placeholder?: string;
}

export const DatePeriodPicker = ({
    value,
    onChange,
    editable,
    icon,
    stylesConfig,
    sectionId,
    itemId,
    placeholder,
}: DatePeriodPickerProps) => {
    const datePickerId = useAppSelector(getDatePickerId);
    const dateFormat =
        stylesConfig?.[GLOBAL_STYLE_KEY]?.dateFormat ?? DateFormat.LRG;
    const [localValue, setLocalValue] = useState(value);
    const now = useMemo(() => new Date(), []);
    const [isOpen, setIsOpen] = useState(false);
    const [localDateFormat, setLocalDateFormat] = useState(dateFormat);

    useEffect(() => {
        setLocalDateFormat(dateFormat);
    }, [dateFormat, value]);

    useEffect(() => {
        setIsOpen(datePickerId === itemId);
    }, [datePickerId, itemId]);

    const isDefault = useMemo(
        () => value.split(" - ")[0].includes("Date"),
        [value],
    );

    const isPresent = useMemo(
        () => value.split(" - ")[1] === "Present",
        [value],
    );

    const fromDate = useMemo(() => {
        if (isDefault) return `00/${now.getFullYear()}`;
        const fromValues = value.split(" - ")[0].split("/");
        const isFullDate = fromValues.length === 2;
        const fMonth = isFullDate ? fromValues[0] : "00";
        const fYear = isFullDate ? fromValues[1] : fromValues[0];
        return `${fMonth}/${fYear}`;
    }, [now, isDefault, value]);

    const toDate = useMemo(() => {
        const dateValues = value.split(" - ");
        if (isDefault || isPresent || dateValues.length === 1) return "00/0000";
        const toValues = dateValues[1].split("/");
        const isFullDate = toValues.length === 2;
        const tMonth = isFullDate ? toValues[0] : "00";
        const tYear = isFullDate ? toValues[1] : toValues[0];
        return `${tMonth}/${tYear}`;
    }, [isDefault, isPresent, value]);

    const [fromMonth, setFromMonth] = useState(fromDate.split("/")[0]);
    const [fromYear, setFromYear] = useState(fromDate.split("/")[1]);
    const [toMonth, setToMonth] = useState(toDate.split("/")[0]);
    const [toYear, setToYear] = useState(toDate.split("/")[1]);
    const [usingPresentDay, setUsingPresentDay] = useState(isPresent);
    const [hasUpdateValue, setHasUpdateValue] = useState(false);
    const pickerRef = useRef<HTMLDivElement>(null);
    const xPosition = pickerRef.current?.getBoundingClientRect().left;
    const windowWidth = window.innerWidth;
    const pickerPosition = useMemo(() => {
        if (xPosition && windowWidth) {
            if (xPosition < windowWidth / 2) {
                return "left-0";
            }
            return "right-0";
        }
        return "-translate-x-[50%]";
    }, [xPosition, windowWidth]);
    const dispatch = useAppDispatch();
    const getFormattedValue = useCallback(() => {
        const fromYearValue = fromYear === "0000" ? "" : `${fromYear}`;
        const toYearValue = usingPresentDay
            ? "Present"
            : toYear === "0000"
              ? ""
              : `${toYear}`;
        const fromMonthValue =
            !fromYearValue || fromMonth === "00" ? "" : `${fromMonth}`;
        const toMonthValue =
            usingPresentDay || !toYearValue || toMonth === "00"
                ? ""
                : `${toMonth}`;
        const fromSeparator = fromMonthValue && fromYearValue ? "/" : "";
        const toSeparator =
            !usingPresentDay && toMonthValue && toYearValue ? "/" : "";
        const separator =
            fromYearValue && (toYearValue || usingPresentDay) ? " - " : "";

        if (
            !fromMonthValue &&
            !fromYearValue &&
            !toMonthValue &&
            !toYearValue
        ) {
            return placeholder;
        }

        return `${fromMonthValue}${fromSeparator}${fromYearValue}${separator}${toMonthValue}${toSeparator}${toYearValue}`;
    }, [fromMonth, fromYear, placeholder, toMonth, toYear, usingPresentDay]);

    const resetDatePickerId = useCallback(() => {
        dispatch(setDatePickerId(""));
    }, [dispatch]);

    useEffect(() => {
        const handleClick = (e: MouseEvent) => {
            const isOutsidePickerClick =
                pickerRef.current &&
                !pickerRef.current.contains(e.target as Node);
            if (isOutsidePickerClick && isOpen) {
                resetDatePickerId();
                if (hasUpdateValue) {
                    onChange(getFormattedValue());
                }
            }
        };
        document.addEventListener("click", handleClick);
        return () => {
            document.removeEventListener("click", handleClick);
        };
    }, [
        onChange,
        getFormattedValue,
        hasUpdateValue,
        isOpen,
        resetDatePickerId,
    ]);

    useEffect(() => {
        if (isOpen && hasUpdateValue) {
            const formattedValue = getFormattedValue();
            setLocalValue(formattedValue);
        }
    }, [getFormattedValue, hasUpdateValue, isOpen]);

    useEffect(() => {
        if (isOpen) {
            dispatch(
                setActiveEntities({
                    activeItemId: itemId,
                    activeSection: sectionId,
                }),
            );
        }
    }, [dispatch, isOpen, itemId, sectionId]);

    const textStyle = useMemo(
        () =>
            getTextStyle(stylesConfig?.[GLOBAL_STYLE_KEY].fontSize, "p", false),
        [stylesConfig],
    );

    return editable ? (
        <div
            className="relative"
            id="date-period-picker"
            ref={pickerRef}
        >
            <p
                onClick={() => {
                    if (isOpen) {
                        resetDatePickerId();
                    } else {
                        dispatch(setDatePickerId(itemId));
                    }
                }}
                className={clsx(
                    "flex cursor-pointer items-center",
                    textStyle,
                    localValue.includes("Date") && "text-neutral-500",
                )}
                style={textStyle}
            >
                {icon && (
                    <FieldIcon
                        name={icon}
                        stylesConfig={stylesConfig}
                    />
                )}
                {applyResumeLevelDateFormat(localValue, localDateFormat)}
            </p>
            {isOpen && (
                <TabGroup
                    className={clsx(
                        "absolute z-10 w-80 translate-y-1 rounded-lg bg-white font-body shadow-tooltip ring-2 ring-primary-700",
                        pickerPosition,
                    )}
                >
                    <TabList className="flex gap-3 rounded-t-lg border-b-[1px] border-neutral-300 bg-white p-1 focus:border-indigo-500">
                        <TabButton>From</TabButton>
                        <TabButton>To</TabButton>
                    </TabList>
                    <TabPanels>
                        <TabPanel className="p-3">
                            <DateDropdowns
                                month={fromMonth}
                                year={fromYear}
                                onMonthChange={m => {
                                    setFromMonth(m);
                                    setHasUpdateValue(true);
                                }}
                                onYearChange={y => {
                                    setFromYear(y);
                                    setHasUpdateValue(true);
                                }}
                                disabled={false}
                            />
                            <div className="h-9" />
                        </TabPanel>
                        <TabPanel className="px-3 pb-1 pt-3">
                            <DateDropdowns
                                month={toMonth}
                                year={toYear}
                                onMonthChange={month => {
                                    setToMonth(month);
                                    setHasUpdateValue(true);
                                }}
                                onYearChange={year => {
                                    setToYear(year);
                                    setHasUpdateValue(true);
                                }}
                                disabled={usingPresentDay}
                            />
                            <div className="mt-2">
                                <Checkbox
                                    id="present"
                                    onChange={checked => {
                                        setUsingPresentDay(checked);
                                        setHasUpdateValue(true);
                                    }}
                                    label="Use present day"
                                    borderless
                                    className="!px-0"
                                    checked={usingPresentDay}
                                />
                            </div>
                        </TabPanel>
                    </TabPanels>
                </TabGroup>
            )}
        </div>
    ) : (
        <p
            className="flex items-center"
            style={textStyle}
        >
            {icon && (
                <FieldIcon
                    name={icon}
                    stylesConfig={stylesConfig}
                />
            )}
            {applyResumeLevelDateFormat(localValue, localDateFormat)}
        </p>
    );
};
