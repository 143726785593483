import { ReactNode } from "react";

interface PreferenceQuestionContainerProps {
    children: ReactNode;
}

export const PreferenceQuestionContainer = ({
    children,
}: PreferenceQuestionContainerProps) => (
    <div className="flex flex-col items-center">{children}</div>
);
