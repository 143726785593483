import { Page, Resume } from "@/types";

// Examples are single page resumes. In order to display a resume in a PDF preview, we need to convert it to an array of pages.
export const resumeToPages = (resume: Resume): Page[] => {
    const resumeSections = Object.keys(resume.sections).map(sectionId => {
        return {
            sectionId,
        };
    });

    return [{
        pageNumber: 0,
        sections: resumeSections,
        items: [],
        columnHeights: {},
    }];
};