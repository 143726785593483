import { transformToReactComponent } from "@/pages/ResumeBuilder/utils";
import parse from "html-react-parser";
import { ErrorBoundary } from "react-error-boundary";
import { ComponentPropsObject } from "@/types/resume";
import { useRef, useState } from "react";
import { SwitchToggle } from "@/components/SwitchToggle/SwitchToggle";
import { InputLabel } from "@/components/form/InputLabel";
import clsx from "clsx";
import "./previewStyles.css";
import { ExternalLink } from "lucide-react";
import { IconButton } from "@/components/Button/IconButton";
import { Modal } from "@/components/Modal/Modal";
import { LayoutField } from "@/types/section";
import { Select } from "@/components/form/Select";
import {
    DATE_LOCATION_LAYOUT_KEY,
    DATE_LOCATION_POSITION_KEY,
} from "@/constants/resume";
import { ModalTypes } from "@/types/modal";
import { openModal } from "@/store/modal/slice";
import { useAppDispatch } from "@/hooks/types";

interface PreviewComponentProps {
    titleComponent?: string;
    bodyFields?: ComponentPropsObject;
    component?: string;
    headerFields?: ComponentPropsObject;
    layoutFields?: LayoutField[];
}

const NoPreview = () => (
    <span className="fixed text-sm text-red-600">
        No component preview available
    </span>
);

const SectionComponent = ({
    activeColumnLayout,
    headerProps,
    bodyProps,
    component,
    titleComponent,
    bodyFields,
    headerFields,
}: {
    activeColumnLayout: string;
    headerProps: ComponentPropsObject;
    bodyProps: ComponentPropsObject;
    component: string;
    titleComponent: string;
    bodyFields: ComponentPropsObject;
    headerFields: ComponentPropsObject;
}) => (
    <ErrorBoundary fallback={<NoPreview />}>
        {component ? (
            <div
                className={clsx(
                    "preview-component border-r-5 rounded-md border-2 border-solid border-slate-100 p-3",
                    activeColumnLayout === "1" ? "w-[790px]" : "w-[263px]",
                )}
            >
                {parse(transformToReactComponent(titleComponent, headerFields))}
                {parse(transformToReactComponent(component, bodyFields))}
            </div>
        ) : (
            <NoPreview />
        )}
    </ErrorBoundary>
);

export const PreviewComponent = ({
    titleComponent = "",
    headerFields = {},
    bodyFields = {},
    layoutFields = [],
    component = "",
}: PreviewComponentProps) => {
    const allProps = {
        ...headerFields,
        ...bodyFields,
    };
    const [activeColumnLayout, setActiveColumnLayout] = useState<string>("1");
    const [toggles, setToggles] = useState(
        Object.keys(allProps).reduce((acc, key) => {
            if (key === "__id") return acc;
            return { ...acc, [key]: true };
        }, {}),
    );

    const [layoutFieldValues, setLayoutFieldValues] = useState(
        layoutFields.reduce((acc, field) => {
            return { ...acc, [field.name]: field.defaultValue };
        }, {}),
    );

    const [showModal, setShowModal] = useState(false);
    const previewRef = useRef<HTMLDivElement>(null);
    const [dateLocationPosition, setDateLocationPosition] =
        useState<boolean>(true);
    const [dateLocationLayout, setDateLocationLayout] = useState<boolean>(true);

    const dispatch = useAppDispatch();

    const openLayoutFieldOptionsModal = () => {
        setShowModal(true);
        dispatch(openModal({ modalType: ModalTypes.ADMIN_SECTION_PREVIEW }));
    };

    const onToggleChange = (isEnabled: boolean, toggleKey: string) => {
        setToggles({
            ...toggles,
            [toggleKey]: isEnabled,
        });

        if (previewRef.current) {
            const preview = previewRef.current;
            const toggleElement = preview.querySelector(`#${toggleKey}`);
            if (toggleElement) {
                toggleElement.style.display = isEnabled ? "flex" : "none";
            }
        }
    };

    const onLayoutFieldChange = (name: string, value: string) => {
        setLayoutFieldValues({
            ...layoutFieldValues,
            [name]: value,
        });
        if (previewRef.current) {
            const preview = previewRef.current;
            const layoutElement = preview.querySelector(`#${name}`);
            if (layoutElement) {
                layoutElement.style.flexDirection = value;
            }
        }
    };

    const onDateLocationPositionChange = (isEnabled: boolean) => {
        setDateLocationPosition(isEnabled);
        if (previewRef.current) {
            const preview = previewRef.current;
            const layoutElement = preview.querySelector(
                `#${DATE_LOCATION_POSITION_KEY}`,
            );
            if (layoutElement) {
                layoutElement.style.flexDirection = isEnabled
                    ? "column"
                    : "row";
            }
        }
    };

    const onDateLocationLayoutChange = (isEnabled: boolean) => {
        setDateLocationLayout(isEnabled);
        if (previewRef.current) {
            const preview = previewRef.current;
            const layoutElement = preview.querySelector(
                `#${DATE_LOCATION_LAYOUT_KEY}`,
            );
            if (layoutElement) {
                layoutElement.style.flexDirection = isEnabled
                    ? "column"
                    : "row";
            }
        }
    };

    return (
        <>
            <div>
                <div className="flex items-center gap-3 pb-6">
                    <h3 className="text-base font-semibold leading-7 text-gray-900">
                        Section preview
                    </h3>
                    <IconButton onClick={openLayoutFieldOptionsModal}>
                        <ExternalLink />
                    </IconButton>
                </div>
                <SectionComponent
                    activeColumnLayout={activeColumnLayout}
                    headerProps={headerFields}
                    bodyProps={bodyFields}
                    component={component}
                    titleComponent={titleComponent}
                    bodyFields={bodyFields}
                    headerFields={headerFields}
                />
            </div>
            <Modal
                modalType={ModalTypes.ADMIN_SECTION_PREVIEW}
                open={showModal}
                onClose={() => setShowModal(false)}
                title="Section preview"
                width="7xl"
            >
                <ErrorBoundary fallback={<NoPreview />}>
                    <div
                        className={clsx(
                            activeColumnLayout === "1"
                                ? "flex-col-reverse"
                                : "flex-row",
                            "flex h-[500px] items-center justify-around gap-x-2",
                        )}
                    >
                        <div className="flex flex-row gap-20">
                            <div className="grid grid-cols-2">
                                <div>
                                    <InputLabel label="Page Layout" />
                                    <InputLabel
                                        label={
                                            activeColumnLayout === "1"
                                                ? "Full Page"
                                                : "Narrow Column"
                                        }
                                    />

                                    <SwitchToggle
                                        onToggle={isEnabled =>
                                            setActiveColumnLayout(
                                                isEnabled ? "2" : "1",
                                            )
                                        }
                                        enabled={activeColumnLayout === "2"}
                                    />
                                </div>
                                <div>
                                    <InputLabel label="Date Location Position" />
                                    <InputLabel
                                        label={
                                            dateLocationPosition
                                                ? "Column"
                                                : "Row"
                                        }
                                    />
                                    <SwitchToggle
                                        onToggle={onDateLocationPositionChange}
                                        enabled={dateLocationPosition}
                                    />
                                </div>
                                <div>
                                    <InputLabel label="Date Location Layout" />
                                    <InputLabel
                                        label={
                                            dateLocationLayout
                                                ? "Column"
                                                : "Row"
                                        }
                                    />
                                    <SwitchToggle
                                        onToggle={onDateLocationLayoutChange}
                                        enabled={dateLocationLayout}
                                    />
                                </div>
                                <div className="pb-20">
                                    <InputLabel label="Layout fields" />
                                    {layoutFields.map(({ name, options }) => (
                                        <div key={name}>
                                            <InputLabel label={name} />
                                            <Select
                                                value={
                                                    options?.find(
                                                        o =>
                                                            o.value ===
                                                            layoutFieldValues[
                                                                name
                                                            ],
                                                    ) ??
                                                    "-- Select an option --"
                                                }
                                                onChange={value => {
                                                    onLayoutFieldChange(
                                                        name,
                                                        value.id,
                                                    );
                                                }}
                                                items={
                                                    options?.map(o => ({
                                                        id: o.value,
                                                        name: o.name,
                                                    })) || []
                                                }
                                            />
                                        </div>
                                    ))}
                                </div>
                            </div>
                            <div>
                                <InputLabel label="Toggle fields" />
                                <div className="grid grid-cols-3 gap-x-2">
                                    {Object.keys(toggles).map(toggleKey => (
                                        <div key={toggleKey}>
                                            <InputLabel
                                                label={`${toggleKey}`}
                                            />
                                            <SwitchToggle
                                                onToggle={enabled =>
                                                    onToggleChange(
                                                        enabled,
                                                        toggleKey,
                                                    )
                                                }
                                                enabled={toggles[toggleKey]}
                                            />
                                        </div>
                                    ))}
                                </div>
                            </div>
                        </div>
                        <div ref={previewRef}>
                            <SectionComponent
                                activeColumnLayout={activeColumnLayout}
                                headerProps={headerFields}
                                bodyProps={bodyFields}
                                component={component}
                                titleComponent={titleComponent}
                                bodyFields={bodyFields}
                                headerFields={headerFields}
                            />
                        </div>
                    </div>
                </ErrorBoundary>
            </Modal>
        </>
    );
};
