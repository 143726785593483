import userApi from "@/api/user";
import {
    Button,
    ButtonColourStyles,
    ButtonVariant,
} from "@/components/Button/Button";
import { Input } from "@/components/form/Input";
import { useEffect, useMemo, useState } from "react";
import { useNavigate, useSearchParams } from "react-router-dom";
import { getErrorMessage } from "@/helper/getErrorMessage";
import { Form, Formik } from "formik";
import { object, ref, string } from "yup";
import { Alert } from "@/components/Alert/Alert";
import { NoNavPageContainer } from "@/layouts/NoNavPageContainer";
import { getNewPasswordSchemaField } from "@/constants/schema";
import { AppText } from "@/components/Text/AppText";
import { PageHelmet } from "@/components/PageHelmet/PageHelmet";
import { AppRoutes } from "@/types/routes";
import { sendPasswordChangedEmail } from "@/email/send";
import { PasswordValidator } from "@/pages/Signup/PasswordValidator";

const resetPasswordSchema = object({
    password: getNewPasswordSchemaField("Please enter your new password"),
    confirmPassword: string()
        .required("Please confirm your new password")
        .oneOf([ref("password")], "Entered passwords do not match"),
});

interface ResetPasswordFormFields {
    password: string;
    confirmPassword: string;
}

export const ResetPasswordPage = () => {
    const [searchParams] = useSearchParams();
    const initialValues: ResetPasswordFormFields = useMemo(
        () => ({
            password: "",
            confirmPassword: "",
        }),
        [],
    );
    const [error, setError] = useState<string | null>("");
    const [successMessage, setSuccessMessage] = useState<string | null>("");
    const [shouldRedirect, setShouldRedirect] = useState(false);
    const navigate = useNavigate();
    const [loading, setLoading] = useState(false);

    useEffect(() => {
        if (shouldRedirect) {
            setTimeout(() => {
                navigate(`/${AppRoutes.Login}`, { replace: true });
            }, 3000);
        }
    }, [navigate, shouldRedirect]);

    const handleReset = async (values: ResetPasswordFormFields) => {
        setLoading(true);
        setError(null);

        const token = searchParams.get("token");
        if (!token) {
            setError("Unable to reset password without token");
            setLoading(false);
            return;
        }

        try {
            await userApi
                .resetPasswordApi({ token, password: values.password })
                .then(res => {
                    setLoading(false);
                    setSuccessMessage(
                        "Password successfully reset. Redirecting to login...",
                    );
                    sendPasswordChangedEmail(res.email, res.name).then(() => {
                        setShouldRedirect(true);
                    });
                });
        } catch (e) {
            // TODO: Capture relevant errors only
            setLoading(false);
            setError(getErrorMessage(e, "Reset Password"));
        }
    };

    return (
        <NoNavPageContainer>
            <PageHelmet
                title="Reset Password"
                robotsOverride="noindex, nofollow"
            />
            <div className="">
                <AppText
                    variant="headings"
                    className="mb-8 text-center"
                >
                    Reset your password
                </AppText>
                <Formik
                    initialValues={initialValues}
                    onSubmit={handleReset}
                    validationSchema={resetPasswordSchema}
                    validateOnBlur={false}
                    validateOnChange={false}
                    validateOnMount={false}
                >
                    {({ values, errors, handleChange, handleSubmit }) => (
                        <Form>
                            <div className="m-auto max-w-[450px]">
                                {error && (
                                    <Alert
                                        type="error"
                                        message={error}
                                        className="mb-6"
                                    />
                                )}
                                {successMessage && (
                                    <Alert
                                        type="info"
                                        message={successMessage}
                                        className="mb-6"
                                    />
                                )}
                                <div className="mb-6">
                                    <Input
                                        type="password"
                                        label="New password"
                                        name="password"
                                        formikChange={handleChange}
                                        value={values.password}
                                        error={errors.password}
                                        containerClassName="mb-2"
                                    />
                                    <PasswordValidator
                                        password={values.password}
                                    />
                                </div>
                                <div className="mb-6">
                                    <Input
                                        type="password"
                                        label="Confirm your password"
                                        name="confirmPassword"
                                        formikChange={handleChange}
                                        value={values.confirmPassword}
                                        error={errors.confirmPassword}
                                    />
                                </div>
                                <Button
                                    onClick={() => handleSubmit()}
                                    className="mt-2 w-full"
                                    loading={loading}
                                    disabled={!!successMessage}
                                >
                                    Reset Password
                                </Button>
                                <Button
                                    to={`/${AppRoutes.Login}`}
                                    className="mt-2 w-full"
                                    variant={ButtonVariant.TEXT}
                                    color={ButtonColourStyles.OUTLINE_PRIMARY}
                                >
                                    Return to sign in
                                </Button>
                            </div>
                        </Form>
                    )}
                </Formik>
            </div>
        </NoNavPageContainer>
    );
};
