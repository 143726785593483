import { AppRoutes } from "@/types/routes";

export const getIsDashboardPage = () => {
    const currentPath = window.location.pathname;
    return currentPath === AppRoutes.Dashboard;
};

export const getIsResumeBuilderPage = () => {
    const currentPath = window.location.pathname;
    return currentPath.includes("/resume");
};

export const getIsPreferencesPage = () => {
    const currentPath = window.location.pathname;
    return currentPath.includes(`/${AppRoutes.Preferences}`);
};

export const getIsProfilePage = () => {
    const currentPath = window.location.pathname;
    return currentPath.includes(`/${AppRoutes.Profile}`);
};

export const getIsNewResumePage = () => {
    const currentPath = window.location.pathname;
    return currentPath.includes(`/${AppRoutes.NewResume}`);
};

export const getIsSignUpPage = () => {
    const currentPath = window.location.pathname;
    return currentPath.includes(`/${AppRoutes.Signup}`);
};

export const getIsResumePage = () => {
    const currentPath = window.location.pathname;
    const isNewResumePage = getIsNewResumePage();
    return !isNewResumePage && currentPath.includes(`/resume`);
};

export const getIsDesktopOnlyRoute = () => {
    const isNewResumePage = getIsNewResumePage();
    const isResumePage = getIsResumePage();
    const isExamplesPage = getIsExamplesPage();

    return isNewResumePage || isResumePage || isExamplesPage;
};

export const getIsPreviewPage = () => {
    const currentPath = window.location.pathname;
    return currentPath.includes(`/preview`);
};

export const getIsExamplesPage = () => {
    const currentPath = window.location.pathname;
    return currentPath.includes(`/${AppRoutes.Examples}`);
};

export const getIsAResumePreviewPage = () => {
    const isDashboard = getIsDashboardPage();
    const isExamplesPage = getIsExamplesPage();
    return isDashboard || isExamplesPage;
};