/**
 * Below are just some example summaries for each job role.
 * This is to demonstrate what the summary section will look like when previewed.
 * In an attempt to display relevant information, we are randomly selecting a summary for the user,
 * that is relevant to their job roles preferences.
 */
const defaultText = "Results-driven marketing professional skilled in digital marketing strategies. Experienced in campaign management, SEO, and social media marketing to boost brand awareness.";

const summarySectionPreviewText = {
    "Banking and Investment": "Experienced financial professional with expertise in investment strategies and risk management. Skilled in portfolio analysis and client relationship management.",
    "Business Operations & Strategy": "Strategic business operations specialist adept at process efficiency and project management. Experienced in driving change and implementing growth strategies.",
    "Communications & Media": "Creative communications professional with expertise in content creation and media relations. Skilled in developing engaging narratives across various platforms.",
    "Consulting": "Results-oriented consultant with strong analytical and problem-solving skills. Experienced in delivering innovative solutions to complex business challenges across industries.",
    "Customer Support": "Dedicated customer support professional with excellent communication skills. Experienced in resolving complex issues and maintaining high customer satisfaction levels.",
    "Data & Analytics": "Data analyst skilled in SQL, Python, and data visualization tools. Experienced in extracting insights and driving data-informed decision-making across businesses.",
    "Design and Product Development": "Creative product designer with expertise in UX/UI. Passionate about crafting intuitive, user-centered solutions that balance form and function.",
    "Finance and Accounting": "Detail-oriented financial analyst with strong analytical skills. Experienced in financial modeling, budgeting, and providing strategic insights to drive business growth.",
    "Marketing": "Results-driven marketing professional skilled in digital marketing strategies. Experienced in campaign management, SEO, and social media marketing to boost brand awareness.",
    "Office Administration": "Efficient office administrator with strong personnel management skills. Experienced in managing schedules, coordinating events, and streamlining office operations for optimal productivity.",
    "People & Culture": "HR professional dedicated to fostering positive workplace cultures. Experienced in talent acquisition, employee development, and implementing diversity and inclusion initiatives.",
    "Sales & Customer Success": "Dynamic sales professional with a track record of exceeding targets. Skilled in building relationships, identifying client needs, and delivering tailored solutions.",
    "Software Development": "Experienced software engineer focused on full-stack development with React, Node.js, and AWS. Passionate about building scalable applications and mentoring junior developers.",
};

export const getSummarySectionPreviewText = (userJobRoles: string[]) => {
    const randomJobRole = userJobRoles.length
        ? userJobRoles[Math.floor(Math.random() * userJobRoles.length)]
        : "Software Development";
    const text = summarySectionPreviewText[
        randomJobRole as keyof typeof summarySectionPreviewText
    ];

    return text || defaultText;
};
