import { PageSections } from "@/components/PdfDocument/types/pdfTypes";

/** 
 * If a section is full width, it will be in its own column.
 * If a section is half width, it could be in a column with another half width section.
 * This function will format the sectionIds into groups based on their width.
 * Full width columns will be in their own array, half width sections will be in pairs.
 */
const formatSectionIdGroups = (sectionIds: string[], pageSections: PageSections) => {
    return sectionIds.reduce<string[][]>(
        (acc, sectionId, index) => {
            const section = pageSections[sectionId];
            if (section.details.activeWidth === "full") {
                acc.push([sectionId]);
            } else {
                const previousSection = pageSections[sectionIds[index - 1]];
                const previousArray = acc[acc.length - 1];
                if (previousSection && previousSection.details.activeWidth === "half" && previousArray.length < 2) {
                    acc[acc.length - 1].push(sectionId);
                } else {
                    acc.push([sectionId]);
                }
            }
            return acc;
        },
        [],
    );
};

/**
 * In order to map a pages documents, we need to split out the
 * sections into their appropriate columns. Header section will be returned
 * separately as it does not need to be formatted into columns.
 * - If a document is full page, all sections will be in one column.
 * In order to support half width sections, we must format the sections into pairs
 * of two, and wrap them in a container. Therefore columns will be an array of arrays 
 * of size 2.
 */
export const getPdfColumnsSections = (isTwoColumn: boolean, pageSections: PageSections) => {
    // Sort sections by positionIndex
    const pageSectionKeys = Object.keys(pageSections).sort(
        (a, b) => {
            return (
                pageSections[a].details.positionIndex -
                pageSections[b].details.positionIndex
            );
        },
    );

    const headerSectionId = pageSectionKeys.find(
        sectionId => pageSections[sectionId].isStaticHeader,
    );
    const headerSection = headerSectionId
        ? pageSections[headerSectionId]
        : undefined;

    const firstColumnSectionIds = isTwoColumn
        ? pageSectionKeys.filter(
            sectionId =>
                pageSections[sectionId].columnIndex === 0 &&
                sectionId !== headerSectionId,
        )
        : pageSectionKeys.filter(
            sectionId => sectionId !== headerSectionId,
        );

    const firstColumnSectionIdGroups = formatSectionIdGroups(firstColumnSectionIds, pageSections);

    const secondColumnSectionIds = isTwoColumn
        ? pageSectionKeys.filter(
            sectionId =>
                pageSections[sectionId].columnIndex === 1 &&
                sectionId !== headerSectionId,
        )
        : null;

    const secondColumnSectionIdGroups = secondColumnSectionIds ? formatSectionIdGroups(secondColumnSectionIds, pageSections) : null;

    const columns: { [key: string]: string[][] | null; } = {
        0: firstColumnSectionIdGroups,
    };

    if (isTwoColumn && secondColumnSectionIds) {
        columns[1] = secondColumnSectionIdGroups;
    }

    return {
        headerSection,
        columns
    };
};