import { AppText } from "@/components/Text/AppText";
import {
    Disclosure,
    DisclosureButton,
    DisclosurePanel,
} from "@headlessui/react";
import clsx from "clsx";
import { AnimatePresence, motion } from "framer-motion";
import { ChevronDown, ChevronRight, Copy, Check } from "lucide-react";
import { useState, useEffect, useCallback } from "react";

interface AssistantItemBlockProps {
    canCopy: boolean;
    isExpandable: boolean;
    content: string;
    isLastItem: boolean;
    title?: string;
}

export const AssistantItemBlock = ({
    canCopy,
    isExpandable,
    content,
    isLastItem,
    title,
}: AssistantItemBlockProps) => {
    const [isCopied, setIsCopied] = useState(false);

    const onCopy = useCallback(() => {
        setIsCopied(true);
        // Strip HTML tags. This simplifies the pasting process into editors
        navigator.clipboard.writeText(content.replace(/<[^>]*>?/g, ""));
    }, [content]);

    useEffect(() => {
        if (isCopied) {
            const timer = setTimeout(() => setIsCopied(false), 2000);
            return () => clearTimeout(timer);
        }
    }, [isCopied]);

    return (
        <Disclosure defaultOpen={!isExpandable}>
            {({ open }) => (
                <div
                    className={clsx(
                        "relative min-h-[44px] rounded-lg bg-neutral-50 p-2",
                        !isLastItem && "mb-3",
                    )}
                >
                    <DisclosureButton
                        className="flex items-center gap-2"
                        as="div"
                    >
                        {(isExpandable || canCopy) && (
                            <div>
                                <motion.div
                                    className={clsx(
                                        "absolute left-2 z-10 flex gap-2",
                                        open
                                            ? "pointer-events-none"
                                            : "cursor-pointer",
                                    )}
                                    initial={false}
                                    animate={{
                                        opacity: open ? 0 : 1,
                                    }}
                                    transition={{
                                        duration: 0.2,
                                        ease: "easeInOut",
                                        delay: open ? 0.3 : 0.1,
                                    }}
                                >
                                    {title ? (
                                        <AppText
                                            className="mr-20 mt-1 line-clamp-1 text-left text-neutral-900"
                                            variant="regular"
                                        >
                                            {title}
                                        </AppText>
                                    ) : (
                                        <div
                                            className="mr-20 line-clamp-1 pt-1 text-left text-app-regular-5-mobile text-neutral-900 md:text-app-regular-5-desktop"
                                            dangerouslySetInnerHTML={{
                                                __html: content,
                                            }}
                                        />
                                    )}
                                </motion.div>
                                <motion.div
                                    initial={false}
                                    animate={{
                                        top: open ? "auto" : 8,
                                        bottom: open ? 8 : "auto",
                                    }}
                                    transition={{
                                        duration: 0.2,
                                        ease: "easeInOut",
                                        delay: open ? 0.1 : 0,
                                    }}
                                    className="absolute right-2 z-10 flex gap-2 bg-neutral-50"
                                >
                                    {canCopy && (
                                        <button
                                            className={clsx(
                                                "flex h-7 w-7 items-center justify-center rounded-lg bg-primary-50 text-primary-700 hover:bg-primary-100",
                                                "transition-all duration-200 ease-in-out",
                                                isCopied && "scale-110",
                                            )}
                                            onClick={e => {
                                                e.stopPropagation();
                                                onCopy();
                                            }}
                                        >
                                            {isCopied ? (
                                                <Check
                                                    size={16}
                                                    strokeWidth={3}
                                                />
                                            ) : (
                                                <Copy
                                                    size={16}
                                                    strokeWidth={3}
                                                />
                                            )}
                                        </button>
                                    )}
                                    {isExpandable && (
                                        <div className="flex h-7 w-7 cursor-pointer items-center justify-center rounded-lg bg-neutral-100 text-neutral-900 hover:bg-neutral-200">
                                            {open ? (
                                                <ChevronDown
                                                    size={16}
                                                    strokeWidth={3}
                                                />
                                            ) : (
                                                <ChevronRight
                                                    size={16}
                                                    strokeWidth={3}
                                                />
                                            )}
                                        </div>
                                    )}
                                </motion.div>
                            </div>
                        )}
                    </DisclosureButton>
                    <AnimatePresence initial={false}>
                        {open && (
                            <DisclosurePanel
                                static
                                as={motion.div}
                                initial="collapsed"
                                animate="open"
                                exit="collapsed"
                                variants={{
                                    open: { opacity: 1, height: "auto" },
                                    collapsed: { opacity: 0, height: 0 },
                                }}
                                transition={{
                                    duration: 0.3,
                                    ease: "easeInOut",
                                }}
                                className="overflow-hidden"
                            >
                                {title && (
                                    <AppText
                                        className="mb-2 mt-1 text-neutral-900"
                                        variant="regular"
                                    >
                                        {title}
                                    </AppText>
                                )}
                                <div
                                    className={clsx(
                                        "pt-1 text-app-regular-5-mobile text-neutral-900 md:text-app-regular-5-desktop",
                                        canCopy || isExpandable
                                            ? "pb-8"
                                            : "pb-1",
                                    )}
                                    dangerouslySetInnerHTML={{
                                        __html: content,
                                    }}
                                />
                            </DisclosurePanel>
                        )}
                    </AnimatePresence>
                </div>
            )}
        </Disclosure>
    );
};
