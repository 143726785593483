import { EmailHeading } from "@/email/components/EmailHeading";
import { EmailWrapper } from "@/email/components/EmailWrapper";
import { User } from "@/types";
import { Section, Text } from "@react-email/components";

interface ErrorReportEmailProps {
    error: unknown;
    errorInfo: unknown;
    user?: User;
    url: string;
    feedback: string;
}

const ErrorReportEmail = ({
    error,
    errorInfo,
    user,
    feedback,
    url,
}: ErrorReportEmailProps) => {
    return (
        <EmailWrapper preview="1Template Error Report">
            <EmailHeading>Error Report</EmailHeading>
            <Section>
                <Text>Error: {JSON.stringify(error, null, 2)}</Text>
                <Text>Error Info:</Text>
                <Text style={{ fontSize: "14px" }}>
                    <pre style={{ whiteSpace: "pre-wrap", margin: 0 }}>
                        {JSON.stringify(errorInfo, null, 2)}
                    </pre>
                </Text>
            </Section>
            {user && (
                <Text>
                    Reported by: {user?.email} ({user?.id})
                </Text>
            )}
            {url && <Text>URL: {url}</Text>}
            {feedback && <Text>Additional Feedback: {feedback}</Text>}
        </EmailWrapper>
    );
};

export default ErrorReportEmail;
