import { AppText } from "@/components/Text/AppText";

interface NotificationDescriptionProps {
    description: string | string[];
}

export const NotificationDescription = ({
    description,
}: NotificationDescriptionProps) => {
    const descArr =
        typeof description === "string" ? [description] : description;

    return (
        <>
            {descArr.map((desc, index) => (
                <AppText
                    key={`notification-desc-${index}`}
                    className="mt-1 text-neutral-700"
                    variant="regular"
                >
                    {desc}
                </AppText>
            ))}
        </>
    );
};
