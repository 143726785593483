import { useEffect, useRef, useState } from "react";
import ReactGA from "react-ga4";

export const TrackingManager = () => {
    const [canLoad, setCanLoad] = useState(false);
    const hasInitialised = useRef(false);

    useEffect(() => {
        const checkConsent = () => {
            const consentGiven =
                window.localStorage.getItem("gtm_consent_given");
            if (consentGiven === "true" && !hasInitialised.current) {
                setCanLoad(true);
                hasInitialised.current = true;

                if (import.meta.env.VITE_GA_TRACKING_ID) {
                    ReactGA.initialize(import.meta.env.VITE_GA_TRACKING_ID);
                }
            } else if (consentGiven === "false") {
                setCanLoad(false);
                hasInitialised.current = false;
            }
        };

        // Check initial consent
        checkConsent();

        // Listen for changes in localStorage
        const handleStorageChange = (e: StorageEvent) => {
            if (e.key === "gtm_consent_given") {
                checkConsent();
            }
        };

        window.addEventListener("storage", handleStorageChange);
        return () => window.removeEventListener("storage", handleStorageChange);
    }, []);

    if (!canLoad) {
        return null;
    }

    return null;
};
