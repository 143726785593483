import { motion } from "framer-motion";

interface BurgerMenuProps {
    isOpen: boolean;
    toggle: () => void;
}

export const BurgerMenu = ({ isOpen, toggle }: BurgerMenuProps) => {
    return (
        <button
            onClick={toggle}
            className="flex h-9 w-9 flex-col items-center justify-center rounded-lg bg-neutral-100"
        >
            <motion.span
                animate={isOpen ? { rotate: 45, y: 0 } : { rotate: 0, y: -6 }}
                transition={{ duration: 0.3 }}
                className="absolute h-0.5 w-4 bg-neutral-900"
            />
            <motion.span
                animate={isOpen ? { opacity: 0 } : { opacity: 1 }}
                transition={{ duration: 0.3 }}
                className="absolute h-0.5 w-4 bg-neutral-900"
            />
            <motion.span
                animate={isOpen ? { rotate: -45, y: 0 } : { rotate: 0, y: 6 }}
                transition={{ duration: 0.3 }}
                className="absolute h-0.5 w-4 bg-neutral-900"
            />
        </button>
    );
};
