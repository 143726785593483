import { AppText } from "@/components/Text/AppText";
import { PlusIcon } from "lucide-react";

export const CreateResumeButton = ({ onClick }: { onClick: () => void }) => (
    <div className="hidden flex-col lg:flex">
        <li
            className="resume-container relative flex cursor-pointer flex-row items-center justify-center gap-1 rounded-lg bg-white ring-2 ring-inset ring-neutral-400 duration-100 ease-in hover:ring-primary-700"
            onClick={() => onClick()}
        >
            <div className="flex items-center justify-center gap-2 text-primary-700">
                <PlusIcon size={16} />

                <AppText
                    variant="labelsbuttons"
                    className="pt-[1px]"
                >
                    New Resume
                </AppText>
            </div>
        </li>
    </div>
);
