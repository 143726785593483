import { parseStyles } from "@/components/PdfDocument/utils/parseStyles";
import clsx from "clsx";
import { EditIcon, TriangleAlert } from "lucide-react";
import { useCallback, useEffect, useMemo, useRef, useState } from "react";
import resumeApi from "@/api/resume";
import { useAppDispatch, useAppSelector } from "@/hooks/types";
import { getDetailsFromImageSrc } from "@/components/Images/utils";
import { LoadingSpinner } from "@/components/LoadingSpinner/LoadingSpinner";
import { getResumeImages } from "@/store/resume/selectors";
import { addResumeImage } from "@/store/resume/slice";
import { AppText } from "@/components/Text/AppText";
import { DEFAULT_HEADER_IMAGE_PATH } from "@/store/resume/constants";

interface PreviewImageProps {
    handleClick?: () => void;
    src: string;
    color?: string;
    editable: boolean;
    inlineStyles?: string;
    overrideStyles?: React.CSSProperties;
}

export const PreviewImage = ({
    handleClick,
    src,
    editable,
    inlineStyles,
    overrideStyles,
}: PreviewImageProps) => {
    const resumeImages = useAppSelector(getResumeImages);
    const [previewUrl, setPreviewUrl] = useState<string | undefined>(
        resumeImages[src],
    );
    const [loading, setLoading] = useState(false);
    const [error, setError] = useState("");
    const initialFetchDone = useRef(false);
    const dispatch = useAppDispatch();

    const getSignedUrl = useCallback(
        async (src: string) => {
            if (resumeImages[src]) {
                setPreviewUrl(resumeImages[src]);
                return;
            }
            setLoading(true);
            setError("");
            const { resumeId } = getDetailsFromImageSrc(src);
            await resumeApi
                .getSignedUrl(resumeId, src)
                .then(({ signedUrl }) => {
                    setPreviewUrl(signedUrl);
                    dispatch(addResumeImage({ id: src, url: signedUrl }));
                })
                .catch(e => {
                    setError("Image Error");
                })
                .finally(() => {
                    setLoading(false);
                });
        },
        [src, resumeImages],
    );

    useEffect(() => {
        if (src.includes(DEFAULT_HEADER_IMAGE_PATH)) {
            setPreviewUrl(src);
            return;
        }
        if (!initialFetchDone.current) {
            initialFetchDone.current = true;
            getSignedUrl(src);
        }
    }, [getSignedUrl, src]);

    return (
        <div
            className={clsx(
                "group relative",
                !editable && "overflow-hidden object-cover",
            )}
            onClick={editable ? handleClick : undefined}
            style={{
                minWidth: "112px",
                minHeight: "112px",
                marginLeft: "12px",
                ...overrideStyles,
            }}
        >
            {loading || error ? (
                <div
                    className={clsx(
                        loading && "h-12 w-12",
                        "flex items-center justify-center",
                    )}
                    style={parseStyles(inlineStyles ?? "")}
                >
                    {loading ? (
                        <LoadingSpinner />
                    ) : (
                        <div className="flex flex-col items-center justify-center text-center">
                            <TriangleAlert
                                size={32}
                                className="text-error-500"
                            />
                            <AppText
                                variant="labelsbuttons"
                                className="text-error-500"
                            >
                                {error}
                            </AppText>
                        </div>
                    )}
                </div>
            ) : (
                <img
                    style={parseStyles(inlineStyles ?? "")}
                    src={previewUrl}
                />
            )}
            {editable && (
                <div className="absolute bottom-0 right-0 flex cursor-pointer items-center justify-center rounded-lg bg-primary-700 p-2 opacity-0 transition-opacity group-hover:opacity-100">
                    <EditIcon className="h-5 w-5 text-white " />
                </div>
            )}
        </div>
    );
};
