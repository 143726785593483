import { Copy, Ellipsis, Eye, FileEdit, PenLine, Trash2 } from "lucide-react";
import { IconButton } from "@/components/Button/IconButton";
import {
    Menu,
    MenuButton,
    MenuItem,
    MenuItems,
    Transition,
} from "@headlessui/react";
import { ButtonColourStyles } from "@/components/Button/Button";

interface TemplateActionsProps {
    resumeId: string;
    onDelete: () => void;
    onDuplicate: () => void;
    onEdit: () => void;
    onRename: () => void;
    onPreview: () => void;
}

export const TemplateActions = ({
    resumeId,
    onDelete,
    onDuplicate,
    onEdit,
    onRename,
    onPreview,
}: TemplateActionsProps) => {
    if (!resumeId) return null;
    return (
        <Menu
            as="div"
            className="relative inline-block text-left"
        >
            {({ open }) => (
                <>
                    <MenuButton>
                        <IconButton
                            color={ButtonColourStyles.SOLID_GREY}
                            aria-label="More actions"
                        >
                            <Ellipsis />
                        </IconButton>
                    </MenuButton>
                    <Transition
                        show={open}
                        enter="transition duration-100 ease-out"
                        enterFrom="transform scale-95 opacity-0"
                        enterTo="transform scale-100 opacity-100"
                        leave="transition duration-75 ease-out"
                        leaveFrom="transform scale-100 opacity-100"
                        leaveTo="transform scale-95 opacity-0"
                    >
                        <MenuItems
                            static
                            className="absolute -top-2 right-0 z-10 flex w-40 origin-top-right -translate-y-full transform flex-col overflow-hidden rounded-lg bg-neutral-50 shadow-lg ring-2 ring-neutral-300 focus:outline-none"
                        >
                            <MenuItem
                                as="button"
                                onClick={onEdit}
                                className="hidden h-10 w-full cursor-pointer items-center gap-2 border-b-2 border-neutral-300 px-3 text-app-labelsbuttons-4-mobile hover:bg-neutral-100 md:text-app-labelsbuttons-4-desktop lg:flex"
                            >
                                <FileEdit className="h-5 w-5" />
                                Edit
                            </MenuItem>
                            <MenuItem
                                as="button"
                                onClick={onPreview}
                                className="flex h-10 w-full cursor-pointer items-center gap-2 border-b-2 border-neutral-300 px-3 text-app-labelsbuttons-4-mobile hover:bg-neutral-100 md:text-app-labelsbuttons-4-desktop lg:hidden"
                            >
                                <Eye className="h-5 w-5" />
                                Preview
                            </MenuItem>
                            <MenuItem
                                as="button"
                                onClick={onRename}
                                className="flex h-10 w-full cursor-pointer items-center gap-2 border-b-2 border-neutral-300 px-3 text-app-labelsbuttons-4-mobile hover:bg-neutral-100 md:text-app-labelsbuttons-4-desktop"
                            >
                                <PenLine className="h-5 w-5" />
                                Rename
                            </MenuItem>
                            <MenuItem
                                as="button"
                                onClick={onDuplicate}
                                className="flex h-10 w-full cursor-pointer items-center gap-2 border-b-2 border-neutral-300 px-3 text-app-labelsbuttons-4-mobile hover:bg-neutral-100 md:text-app-labelsbuttons-4-desktop"
                            >
                                <Copy className="h-5 w-5" />
                                Duplicate
                            </MenuItem>
                            <MenuItem
                                as="button"
                                onClick={onDelete}
                                className="flex h-[39px] w-full cursor-pointer items-center gap-2 border-neutral-300 px-3 text-app-labelsbuttons-4-mobile hover:bg-neutral-100 md:text-app-labelsbuttons-4-desktop"
                            >
                                <Trash2 className="h-5 w-5" />
                                Delete
                            </MenuItem>
                        </MenuItems>
                    </Transition>
                </>
            )}
        </Menu>
    );
};
