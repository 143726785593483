import { useEffect, MouseEvent, useRef } from "react";

export const useOutsideClick = (callback: () => void, ignoreElementId?: string[]) => {
  const ref = useRef<HTMLDivElement>(null);

  useEffect(() => {
    const handleClickOutside = (event: MouseEvent | TouchEvent) => {
      const clickInIgnoredId = ignoreElementId?.some((id) => {
        const ignoredElement = document.getElementById(id);
        return ignoredElement?.contains(event.target as Node);
      });
      if (ref.current && !ref.current.contains(event.target as Node)) {
        if (clickInIgnoredId) return;
        callback();
      }
    };

    /** Attaching the listeners to the document breaks modals */
    // const root = document.getElementById('root');
    // TODO: Fix this - currently blocking clicks to other elements
    // root?.addEventListener('mouseup', handleClickOutside as EventListener);
    // root?.addEventListener('touchend', handleClickOutside as EventListener);

    return () => {
      const root = document.getElementById('root');

      root?.removeEventListener('mouseup', handleClickOutside as EventListener);
      root?.removeEventListener('touchend', handleClickOutside as EventListener);
    };
  }, [callback, ignoreElementId]);

  return ref;
};