import { bool, object, string } from "yup";

export const userSchema = object({
    firstName: string().required("First name is required"),
    lastName: string().required("Last name is required"),
    email: string().required("Email is required"),
    role: string().required().oneOf(["admin", "user"]),
    isEmailVerified: bool().required(),
    loginMethod: string().oneOf(["email", "google", undefined]),
    stripeCustomerId: string()
});
