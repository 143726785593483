import { EmailContainer } from "@/email/components/EmailContainer";
import { EmailFooter } from "@/email/components/EmailFooter";
import { EmailLogo } from "@/email/components/EmailLogo";
import { Body, Font, Head, Html, Preview } from "@react-email/components";

export const EmailWrapper = ({
    children,
    preview,
}: {
    children: React.ReactNode;
    preview: string;
}) => {
    return (
        <Html lang="en">
            <Head>
                <Font
                    fontFamily="Poppins"
                    fallbackFontFamily="Arial"
                    webFont={{
                        url: "https://fonts.gstatic.com/s/poppins/v5/pxiByp8kv8JHgFVrLDz8Z11lFc-K.woff2",
                        format: "woff2",
                    }}
                    fontWeight={400}
                    fontStyle="normal"
                />
            </Head>
            <Preview>{preview}</Preview>
            <Body
                style={{
                    backgroundColor: "#F0F1F5",
                    padding: "36px 16px",
                }}
            >
                <EmailLogo />
                <EmailContainer>{children}</EmailContainer>
                <EmailFooter />
            </Body>
        </Html>
    );
};
