import { configureStore } from "@reduxjs/toolkit";
import { userReducer } from "./user/slice";
import { resumeReducer } from "./resume/slice";
import { templateReducer } from "./template/slice";
import { setupListeners } from "@reduxjs/toolkit/query";
import { appReducer } from "./app/slice";
import { modalReducer } from "./modal/slice";
import { pagesReducer } from "./pages/slice";
import { documentTypeReducer } from "./documentType/slice";
import { sectionReducer } from "@/store/sections/slice";
import { wizardReducer } from "@/store/wizard/slice";
import { billingReducer } from "@/store/billing/slice";
import { recalculatePagesMiddleware } from "@/store/pages/middleware";
import { assistantReducer } from "@/store/assistant/slice";
import { saveFieldsMiddleware } from "@/store/resume/middleware";

export const store = configureStore({
    devTools: import.meta.env.MODE !== "production",
    reducer: {
        resume: resumeReducer,
        pages: pagesReducer,
        template: templateReducer,
        section: sectionReducer,
        user: userReducer,
        assistant: assistantReducer,
        wizard: wizardReducer,
        documentType: documentTypeReducer,
        app: appReducer,
        billing: billingReducer,
        modal: modalReducer,
    },
    middleware: (getDefaultMiddleware: any) =>
        getDefaultMiddleware().concat([recalculatePagesMiddleware, saveFieldsMiddleware]),
});

export type RootState = ReturnType<typeof store.getState>;
export type AppDispatch = typeof store.dispatch;

// optional, but required for refetchOnFocus/refetchOnReconnect behaviors
// see `setupListeners` docs - takes an optional callback as the 2nd arg for customization
setupListeners(store.dispatch);
