import { RangeSlider } from "@/components/RangeSlider/RangeSlider";
import { AppText } from "@/components/Text/AppText";
import { useState } from "react";
import AvatarEditor from "react-avatar-editor";

interface ImageEditorProps {
    src: string;
    editorRef: React.MutableRefObject<AvatarEditor | null>;
    borderRadius: number;
}

export const ImageEditor = ({
    src,
    editorRef,
    borderRadius,
}: ImageEditorProps) => {
    const [scale, setScale] = useState(1.5);
    return (
        <div className="flex flex-col justify-center">
            <AvatarEditor
                ref={editorRef}
                image={src}
                width={350}
                height={350}
                border={20}
                color={[234, 237, 240, 0.7]}
                scale={scale}
                borderRadius={borderRadius}
                backgroundColor="#fff"
            />
            <AppText
                variant="labelsbuttons"
                className="mt-2 text-center"
            >
                Zoom
            </AppText>
            <RangeSlider
                label=""
                id="scale"
                min={1}
                max={2}
                step={0.01}
                value={scale}
                onChange={e => setScale(parseFloat(e.target.value))}
            />
        </div>
    );
};
