import { Section } from "@/types/section";
import { PayloadAction, createSlice } from "@reduxjs/toolkit";

interface SectionsState {
    sections: Section[];
}

const initialState: SectionsState = {
    sections: [],
};

const sectionSlice = createSlice({
    name: "section",
    initialState,
    reducers: {
        setSections: (state, { payload }: PayloadAction<Section[]>) => {
            return {
                ...state,
                sections: payload,
            };
        },
        addSection: (state, { payload }: PayloadAction<Section>) => {
            return {
                ...state,
                sections: [...state.sections, payload],
            };
        },
        removeSection: (state, { payload }: PayloadAction<Section>) => {
            return {
                ...state,
                sections: state.sections.filter(
                    section => section.id !== payload.id,
                ),
            };
        },
    },
});

export const {
    setSections,
    addSection,
    removeSection,
} = sectionSlice.actions;
export const { reducer: sectionReducer } = sectionSlice;
