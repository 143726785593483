import { OneLogo } from "@/components/Icons/Icons";

export const LoadingPage = () => (
    <div
        className="absolute left-0 top-0 z-50 flex h-full w-full items-center justify-center bg-neutral-100"
        role="status"
    >
        <OneLogo className="h-24 w-24 animate-pulse" />
    </div>
);
