import { PreferenceQuestionContainer } from "@/pages/Preferences/components/PreferenceQuestionContainer";
import { StepContainer } from "@/pages/Preferences/components/StepContainer";
import { ReactNode } from "react";

interface PreferencesStepProps {
    children: ReactNode;
}

export const PreferencesStep = ({ children }: PreferencesStepProps) => {
    return (
        <StepContainer>
            <PreferenceQuestionContainer>
                {children}
            </PreferenceQuestionContainer>
        </StepContainer>
    );
};
