import { pdfStyles } from "@/components/PdfDocument/styles/pdfStyles";
import { parseStyles } from "@/components/PdfDocument/utils/parseStyles";
import { PROFILE_IMAGE_KEY } from "@/constants/resume";
import { ResumeStyles } from "@/types/resume";
import { Image, View } from "@react-pdf/renderer";

export interface PdfImageProps {
    node: any;
    documentStyles: ResumeStyles;
}

// Image won't be visible in DEV due to CORS.
// Disable CORs to test in DEV.
export const PdfImage = ({ node, documentStyles }: PdfImageProps) => {
    const src = node.attribs.src;
    const style = node.attribs?.style ? parseStyles(node.attribs.style) : {};
    const imageStyles = pdfStyles.image;
    const profileImageStyles = documentStyles[PROFILE_IMAGE_KEY];

    const allImageStyles = {
        ...style,
        ...imageStyles,
        width: "100%",
        height: "100%",
        borderRadius: profileImageStyles?.borderRadius
            ? parseInt(profileImageStyles.borderRadius)
            : undefined,
    };

    const outerContainerStyles = {
        position: "relative" as const,
        width: style.width || imageStyles.width,
        height: style.height || imageStyles.height,
        overflow: "hidden" as const,
    };

    const borderRadius = profileImageStyles?.borderRadius
        ? parseInt(profileImageStyles.borderRadius)
        : 0;

    const borderWidth = profileImageStyles?.borderWidth
        ? parseInt(profileImageStyles.borderWidth)
        : 0;

    const clipContainerStyles = {
        position: "absolute" as const,
        top: 0,
        left: 0,
        right: 0,
        bottom: 0,
        overflow: "hidden" as const,
        borderRadius,
    };

    const borderContainerStyles = {
        position: "absolute" as const,
        top: 0,
        left: 0,
        right: 0,
        bottom: 0,
        borderStyle: profileImageStyles?.borderStyle || "solid",
        borderWidth,
        borderColor: profileImageStyles?.borderColor || "transparent",
        borderRadius,
        pointerEvents: "none" as const,
    };

    // Layout is this way in order to display a border around the image.
    // Applying a border directly to the image doesn't work.
    return (
        <View style={outerContainerStyles}>
            <Image
                src={{
                    uri: src,
                    method: "GET",
                    headers: { "Cache-Control": "no-cache" },
                    body: "",
                }}
                style={allImageStyles}
            />
            <View style={clipContainerStyles}>
                <View style={borderContainerStyles} />
            </View>
        </View>
    );
};
