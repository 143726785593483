import { getActiveResumeDocumentType } from "@/store/resume/selectors";
import { RootState } from "../store";
import { createSelector } from "@reduxjs/toolkit";

export const getDocumentTypeState = (state: RootState) => state.documentType;

export const getDocumentTypes = createSelector(
    getDocumentTypeState,
    documentTypes => documentTypes.documentTypes,
);

export const getActiveDocumentTypeDetails = createSelector(
    getActiveResumeDocumentType,
    getDocumentTypes,
    (activeDocumentType, documentTypes) => {
        return documentTypes.find(documentType => documentType.id === activeDocumentType);
    });
