import { ButtonColourStyles, ButtonVariant } from "@/components/Button/Button";
import { IconButton } from "@/components/Button/IconButton";
import { useNavigate } from "react-router-dom";
import {
    getUserInitials,
    getUserPlanRequiresAttention,
} from "@/store/user/selectors";
import { useAppSelector } from "@/hooks/types";
import { AppRoutes } from "@/types/routes";
import { AppText } from "@/components/Text/AppText";

interface AccountSettingsButtonProps {
    onClick?: () => void;
}

export const AccountSettingsButton = ({
    onClick,
}: AccountSettingsButtonProps) => {
    const navigate = useNavigate();
    const goToProfile = () => {
        navigate(`/${AppRoutes.Profile}`);
        onClick?.();
    };
    const userInitials = useAppSelector(getUserInitials);
    const planRequiresAttention = useAppSelector(getUserPlanRequiresAttention);

    return (
        <IconButton
            variant={ButtonVariant.SOLID}
            color={ButtonColourStyles.SOLID_GREY}
            onClick={goToProfile}
            notificationDot={planRequiresAttention}
        >
            <AppText variant="labelsbuttons">{userInitials}</AppText>
        </IconButton>
    );
};
