import { RootState } from "../store";
import { createSelector } from "@reduxjs/toolkit";

export const getSectionState = (state: RootState) => state.section;

export const getSections = createSelector(
    getSectionState,
    section => section.sections,
);

export const getSectionConfigById = createSelector(
    [getSections, (_: RootState, id: string) => id],
    (sections, id) => sections.find(section => section.id === id),
);