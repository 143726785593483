import { motion } from "framer-motion";
import { AppText } from "@/components/Text/AppText";

interface SubscribeSuccessProps {
    message: string;
}

export const SubscribeSuccess = ({
    message = "Subscription successful!",
}: SubscribeSuccessProps) => {
    return (
        <div className="relative flex h-full min-h-[300px] flex-col items-center justify-center pt-5 lg:h-auto">
            <div className="relative">
                <motion.div
                    className="absolute -inset-2 rounded-full bg-gradient-to-r from-primary-300 via-primary-400 to-primary-700"
                    style={{ padding: "4px" }}
                    initial={{ rotate: 0 }}
                    animate={{ rotate: 360 }}
                    transition={{
                        duration: 2,
                        repeat: Infinity,
                        ease: "linear",
                    }}
                />
                <motion.div
                    className="relative z-10 flex h-28 w-28 items-center justify-center rounded-full bg-white"
                    initial={{ scale: 0 }}
                    animate={{ scale: 1 }}
                    transition={{ type: "spring", stiffness: 260, damping: 20 }}
                >
                    <motion.svg
                        className="h-16 w-16 text-primary-700"
                        viewBox="0 0 24 24"
                        initial={{ pathLength: 0, opacity: 0 }}
                        animate={{ pathLength: 1, opacity: 1 }}
                        transition={{ duration: 0.8, ease: "easeInOut" }}
                    >
                        <motion.path
                            fill="none"
                            stroke="currentColor"
                            strokeWidth="3"
                            strokeLinecap="round"
                            strokeLinejoin="round"
                            d="M20 6.5C15.5 11 12 14.5 9 17.5L4 12.5"
                        />
                    </motion.svg>
                </motion.div>
            </div>
            <motion.div
                initial={{ opacity: 0, y: 20 }}
                animate={{ opacity: 1, y: 0 }}
                transition={{ duration: 0.5, delay: 0.3 }}
                className="relative z-10 mt-8 text-center"
            >
                <AppText
                    variant="headings"
                    className="text-primary-700"
                >
                    {message}
                </AppText>
            </motion.div>
        </div>
    );
};
