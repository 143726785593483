import { AppText } from "@/components/Text/AppText";
import clsx from "clsx";

interface PasswordValidatorProps {
    password: string;
}

const check = () => (
    <svg
        className="size-4 shrink-0"
        xmlns="http://www.w3.org/2000/svg"
        width="24"
        height="24"
        viewBox="0 0 24 24"
        fill="none"
        stroke="currentColor"
        strokeWidth="2"
        strokeLinecap="round"
        strokeLinejoin="round"
    >
        <polyline points="20 6 9 17 4 12"></polyline>
    </svg>
);

const cross = () => (
    <svg
        className="size-4 shrink-0"
        xmlns="http://www.w3.org/2000/svg"
        width="24"
        height="24"
        viewBox="0 0 24 24"
        fill="none"
        stroke="currentColor"
        strokeWidth="2"
        strokeLinecap="round"
        strokeLinejoin="round"
    >
        <path d="M18 6 6 18"></path>
        <path d="m6 6 12 12"></path>
    </svg>
);

export const PasswordValidator = ({ password }: PasswordValidatorProps) => {
    const validPassword = password.length >= 8;
    const validUppercase = /[A-Z]/.test(password);
    const validLowercase = /[a-z]/.test(password);
    const validNumber = /[0-9]/.test(password);

    return (
        <div className="mb-4 flex flex-col">
            <AppText variant="labelsbuttons">
                Your password must contain:
            </AppText>
            <ul>
                <li className="flex items-center gap-2">
                    <span
                        className={clsx(
                            "h-full",
                            validPassword
                                ? "text-neutral-500"
                                : "text-neutral-700",
                        )}
                    >
                        {validPassword ? check() : cross()}
                    </span>
                    <AppText
                        variant="regular"
                        className={clsx(
                            validPassword
                                ? "text-neutral-500"
                                : "text-neutral-700",
                        )}
                    >
                        At least 8 characters
                    </AppText>
                </li>
                <li className="flex items-center gap-2">
                    <span
                        className={clsx(
                            "h-full",
                            validUppercase
                                ? "text-neutral-500"
                                : "text-neutral-700",
                        )}
                    >
                        {validUppercase ? check() : cross()}
                    </span>
                    <AppText
                        variant="regular"
                        className={clsx(
                            validUppercase
                                ? "text-neutral-500"
                                : "text-neutral-700",
                        )}
                    >
                        At least one uppercase letter
                    </AppText>
                </li>
                <li className="flex items-center gap-2">
                    <span
                        className={clsx(
                            "h-full",
                            validLowercase
                                ? "text-neutral-500"
                                : "text-neutral-700",
                        )}
                    >
                        {validLowercase ? check() : cross()}
                    </span>
                    <AppText
                        variant="regular"
                        className={clsx(
                            validLowercase
                                ? "text-neutral-500"
                                : "text-neutral-700",
                        )}
                    >
                        At least one lowercase letter
                    </AppText>
                </li>
                <li className="flex items-center gap-2">
                    <span
                        className={clsx(
                            "h-full",
                            validNumber
                                ? "text-neutral-500"
                                : "text-neutral-700",
                        )}
                    >
                        {validNumber ? check() : cross()}
                    </span>
                    <AppText
                        variant="regular"
                        className={clsx(
                            validNumber
                                ? "text-neutral-500"
                                : "text-neutral-700",
                        )}
                    >
                        At least one number
                    </AppText>
                </li>
            </ul>
        </div>
    );
};
