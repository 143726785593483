import { AppText } from "@/components/Text/AppText";
import { Tooltip } from "@/components/Tooltip/Tooltip";

interface TemplateDetailsProps {
    name: string;
    updatedAt: Date;
}

export const TemplateDetails = ({ name, updatedAt }: TemplateDetailsProps) => {
    return (
        <>
            <div className="group relative">
                <Tooltip
                    text={name}
                    positionClassName="-translate-x-1/2 -translate-y-[120%]"
                />
                <div className="flex flex-1">
                    <AppText
                        variant="subheadings"
                        className="cursor-default overflow-hidden text-ellipsis whitespace-nowrap"
                    >
                        {name}
                    </AppText>
                </div>
            </div>
            <AppText
                variant="labelsbuttons"
                className="text-ellipsis whitespace-nowrap"
            >
                Last edited: {updatedAt.toLocaleDateString()}
            </AppText>
        </>
    );
};
