import React, { useState, useEffect, useRef } from "react";
import { MinusIcon, PlusIcon } from "lucide-react";
import clsx from "clsx";
import { AppText } from "@/components/Text/AppText";

interface StepSliderProps {
    value: number;
    onChange: (value: number) => void;
    steps: number;
    label?: string;
    maxLabel?: string;
    minLabel?: string;
}

export const StepSlider = ({
    value,
    onChange,
    steps,
    label,
    maxLabel,
    minLabel,
}: StepSliderProps) => {
    const [currentStep, setCurrentStep] = useState(value);
    const [isDragging, setIsDragging] = useState(false);
    const sliderRef = useRef<HTMLDivElement>(null);

    useEffect(() => {
        setCurrentStep(value);
    }, [value]);

    const updateStep = (newStep: number) => {
        const clampedStep = Math.max(1, Math.min(steps, newStep));
        setCurrentStep(clampedStep);
        onChange(clampedStep);
    };

    const calculateStepFromPosition = (clientX: number) => {
        if (sliderRef.current) {
            const rect = sliderRef.current.getBoundingClientRect();
            const x = clientX - rect.left;
            const stepWidth = rect.width / (steps - 1);
            return Math.round(x / stepWidth) + 1;
        }
        return currentStep;
    };

    const handleMouseDown = (event: React.MouseEvent<HTMLDivElement>) => {
        setIsDragging(true);
        const classList = (event.target as HTMLElement).classList;
        if (!classList.contains("slider-label")) {
            updateStep(calculateStepFromPosition(event.clientX));
        }
    };

    const handleMouseMove = (event: React.MouseEvent<HTMLDivElement>) => {
        if (isDragging) {
            updateStep(calculateStepFromPosition(event.clientX));
        }
    };

    const handleMouseUp = () => {
        setIsDragging(false);
    };

    const handleStepChange = (increment: boolean) => {
        updateStep(increment ? currentStep + 1 : currentStep - 1);
    };

    useEffect(() => {
        const handleGlobalMouseUp = () => setIsDragging(false);
        document.addEventListener("mouseup", handleGlobalMouseUp);
        return () =>
            document.removeEventListener("mouseup", handleGlobalMouseUp);
    }, []);

    return (
        <div className="mb-2 w-full">
            {label && (
                <label className="mb-2 block text-sm font-medium text-neutral-900">
                    {label}
                </label>
            )}
            <div className="flex items-center">
                <MinusIcon
                    size={24}
                    onClick={() => handleStepChange(false)}
                    className="mr-5 cursor-pointer text-neutral-500 transition-colors hover:text-primary-900"
                />
                <div
                    className="relative h-12 w-full flex-grow cursor-pointer"
                    ref={sliderRef}
                    onMouseDown={handleMouseDown}
                    onMouseMove={handleMouseMove}
                    onMouseUp={handleMouseUp}
                    onMouseLeave={handleMouseUp}
                >
                    <div className="absolute top-5 h-2 w-full bg-neutral-200" />
                    {Array.from({ length: steps }, (_, i) => (
                        <div
                            key={i}
                            className={clsx(
                                "absolute top-4 h-4 w-4 rounded-full bg-neutral-50 ring-1 ring-primary-900 transition-all duration-300 ease-in-out hover:bg-primary-50",
                            )}
                            style={{
                                left: `calc(${(i / (steps - 1)) * 100}% - 8px)`,
                            }}
                        />
                    ))}
                    <div
                        className={clsx(
                            "absolute top-3.5 h-5 w-5 rounded-full bg-primary-900 transition-all duration-300 ease-in-out",
                            isDragging ? "ring-2 ring-primary-200" : "",
                        )}
                        style={{
                            left: `calc(${((currentStep - 1) / (steps - 1)) * 100}% - 10px)`,
                        }}
                    />
                    {maxLabel && (
                        <div
                            className="slider-label pointer-events-auto absolute -right-5 bottom-[-8px] w-10 cursor-pointer text-center text-neutral-900"
                            onClick={e => {
                                e.stopPropagation();
                                handleStepChange(true);
                            }}
                        >
                            <AppText
                                className="slider-label"
                                variant="footnote"
                            >
                                {maxLabel}
                            </AppText>
                        </div>
                    )}
                    {minLabel && (
                        <div
                            className="slider-label pointer-events-auto absolute -left-5 bottom-[-8px] w-10 cursor-pointer text-center text-neutral-900"
                            onClick={e => {
                                e.stopPropagation();
                                handleStepChange(false);
                            }}
                        >
                            <AppText
                                className="slider-label"
                                variant="footnote"
                            >
                                {minLabel}
                            </AppText>
                        </div>
                    )}
                </div>
                <PlusIcon
                    size={24}
                    onClick={() => handleStepChange(true)}
                    className="ml-5 cursor-pointer text-neutral-500 transition-colors hover:text-primary-900"
                />
            </div>
        </div>
    );
};
