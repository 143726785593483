import { AssistantItemSectionContentType, AssistantItemTypes } from "@/types/assistant";
import { object, string, bool } from "yup";

const itemDocumentPropertiesSchema = object({
    title: string().when("contentType", ([contentType]) => {
        switch (contentType) {
            case AssistantItemSectionContentType.DEFAULT:
                return string().required("Title is required");
            default:
                return string();
        }
    }),
    isExpandable: bool().default(false),
    content: string().required("Content is required"),
});

const itemSectionPropertiesSchema = object({
    content: string().required("Content is required"),
});

export const assistantItemSchema = object({
    name: string().required("Name is required"),
    type: string().oneOf(["document", "section"]).required(),
    jobRoleType: string().required("Job role type is required"),
    properties: object().when("type", ([type]) => {
        switch (type) {
            case AssistantItemTypes.DOCUMENT:
                return itemDocumentPropertiesSchema;
            case AssistantItemTypes.SECTION:
                return itemSectionPropertiesSchema;
            default:
                return object();
        }
    }).required(),
    section: string().when("type", ([type]) => {
        switch (type) {
            case AssistantItemTypes.SECTION:
                return string().required("Section is required");
            default:
                return string();
        }
    }),
    documentType: string().when("type", ([type]) => {
        switch (type) {
            case AssistantItemTypes.DOCUMENT:
                return string().required("Document type is required");
            default:
                return string();
        }
    }),
});
