import clsx from "clsx";
import { useWizard } from "react-use-wizard";

export const PreferencesHeader = ({
    sourcePath,
}: {
    sourcePath: string | null;
}) => {
    const { activeStep, stepCount } = useWizard();

    return (
        <div
            className={clsx(
                "flex flex-col items-center justify-center bg-white px-4 py-2",
                !sourcePath && activeStep === 0 ? "opacity-0" : "opacity-100",
            )}
        >
            <ol className="mb-4 flex items-center text-heading-6">
                {Array.from({ length: stepCount - 1 }).map((_, index) => (
                    <li
                        key={index}
                        className={clsx(
                            activeStep >= index
                                ? "text-primary-700"
                                : "text-neutral-500",
                            index < stepCount - 2
                                ? "after:w-4 after:content-['']"
                                : "after:hidden",
                            "flex items-center after:mx-1 after:inline-block after:h-0 after:rounded-lg after:border-2 after:border-primary-700",
                        )}
                    >
                        <span
                            className={clsx(
                                activeStep >= index + 1
                                    ? "border-primary-700 bg-primary-700 text-white"
                                    : "border-primary-700 bg-white text-primary-700",
                                "flex h-12 w-12 items-center justify-center rounded-full border-4 text-heading-6 transition-colors",
                            )}
                        >
                            {index + 1}
                        </span>
                    </li>
                ))}
            </ol>
        </div>
    );
};
