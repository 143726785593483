import { Dropdown } from "@/components/Dropdown/Dropdown";
import { dateFormatOptions } from "@/constants/resume";
import { DateFormat } from "@/types/resume";
import { useMemo } from "react";

interface DateFormatDropdownProps {
    onChange: (format: DateFormat) => void;
    activeFormat: DateFormat;
}

export const DateFormatDropdown = ({
    onChange,
    activeFormat = DateFormat.LRG,
}: DateFormatDropdownProps) => {
    const options = useMemo(() => {
        return dateFormatOptions.map(({ displayName, value }) => ({
            id: value,
            title: displayName,
        }));
    }, []);

    return (
        <Dropdown
            items={options}
            activeId={activeFormat}
            handleChange={(id: string) => onChange(id as DateFormat)}
            title={options.find(item => item.id === activeFormat)?.title}
        />
    );
};
