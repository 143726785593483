import { ReadOnlySection } from "@/components/Section/ReadOnlySection";
import { EditableSection } from "@/components/Section/EditableSection";
import { useMemo } from "react";
import { getIsAResumePreviewPage } from "@/utils/routes";

interface TemplateSectionProps {
    sectionId: string;
    shouldMeasure?: boolean;
    pageNumber: number;
}

export const TemplateSection = ({
    sectionId,
    shouldMeasure,
    pageNumber,
}: TemplateSectionProps) => {
    const isPreview = useMemo(() => getIsAResumePreviewPage(), []);

    return isPreview ? (
        <ReadOnlySection
            sectionId={sectionId}
            shouldMeasure={shouldMeasure}
            pageNumber={pageNumber}
        />
    ) : (
        <EditableSection
            sectionId={sectionId}
            pageNumber={pageNumber}
        />
    );
};
