import { getColumnStyle } from "@/components/PdfDocument/utils/getStyles";
import { PageMarginSize, SectionSpacingSize } from "@/types/resume";
import { View } from "@react-pdf/renderer";

export interface PdfColumnProps {
    isTwoColumn?: boolean;
    children: React.ReactNode;
    columnIndex: number;
    sectionSpacingSize?: SectionSpacingSize;
    pageMarginSize?: PageMarginSize;
}

export const PdfColumn = ({
    isTwoColumn,
    children,
    columnIndex,
    sectionSpacingSize,
    pageMarginSize,
}: PdfColumnProps) => {
    const columnStyles = getColumnStyle(
        !!isTwoColumn,
        columnIndex,
        true,
        sectionSpacingSize,
        pageMarginSize,
    );

    return <View style={columnStyles}>{children}</View>;
};
