import { Switch } from "@headlessui/react";
import clsx from "clsx";

interface SwitchProps {
    onToggle: (isEnabled: boolean) => void;
    enabled: boolean;
    disabled?: boolean;
}

export const SwitchToggle = ({ onToggle, enabled, disabled }: SwitchProps) => (
    <Switch
        checked={enabled}
        onChange={onToggle}
        disabled={disabled}
        className={clsx(
            enabled
                ? "bg-primary-100 hover:bg-primary-200"
                : "bg-neutral-200 hover:bg-neutral-300",
            "relative inline-flex h-5 w-10 flex-shrink-0 cursor-pointer items-center rounded-full transition-colors duration-200 ease-in-out focus:outline-none",
            disabled ? "cursor-not-allowed opacity-50" : "",
        )}
    >
        <span
            aria-hidden="true"
            className={clsx(
                enabled
                    ? "translate-x-4 bg-primary-900"
                    : "-translate-x-1 bg-primary-900 hover:bg-primary-900",
                "pointer-events-none ml-1 inline-block h-5 w-5 transform rounded-full bg-primary-900 shadow ring-0 transition duration-200 ease-in-out",
            )}
        />
    </Switch>
);
