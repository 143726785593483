import { Button, ButtonColourStyles } from "@/components/Button/Button";
import { NavLink } from "react-router-dom";

interface AdminFormFooter {
    onSave: () => void;
    listHref: string;
    onDelete: () => void;
}

export const AdminFormFooter = ({
    onSave,
    listHref,
    onDelete,
}: AdminFormFooter) => {
    return (
        <div className="fixed bottom-0 right-0 flex items-center justify-end gap-x-6 rounded-lg border border-gray-900/10 bg-white px-4 py-4 sm:px-8">
            <NavLink
                to={listHref}
                className="text-sm font-semibold leading-6 text-gray-900"
            >
                Cancel
            </NavLink>
            <Button
                color={ButtonColourStyles.SOLID_RED}
                onClick={onDelete}
            >
                Delete
            </Button>
            <Button
                className="rounded-md bg-indigo-600 px-3 py-2 text-sm font-semibold text-white shadow-sm hover:bg-indigo-500 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-indigo-600"
                onClick={onSave}
            >
                Save
            </Button>
        </div>
    );
};
