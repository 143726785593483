import { getStaticHeaderSectionStyle, getSectionYMargin, getPageVerticalMarginStyle } from "@/components/PdfDocument/utils/getStyles";
import { GLOBAL_STYLE_KEY } from "@/constants/resume";
import { A4_PAGE_HEIGHT_PIXELS } from "@/store/pages/constants";
import { ResumeSections, Measurement, Page, SectionDetails } from "@/types";
import { GroupedMeasurements, PageSection } from "@/types/pages";
import { SectionSpacingSize, PageMarginSize, ResumeStyles } from "@/types/resume";

export const getEmptyPage = (pageNumber: number): Page => {
    return {
        pageNumber,
        sections: [],
        columnHeights: { 0: 0, 1: 0 },
        items: [],
    };
};

export const getPageHeightByPageNumber = (pageNumber: number, resumeStyles: ResumeStyles) => {
    const pageMarginSize = resumeStyles[GLOBAL_STYLE_KEY].pageMarginSize ?? PageMarginSize.MD;
    const sectionSpacingSize = resumeStyles[GLOBAL_STYLE_KEY].sectionSpacingSize ?? SectionSpacingSize.MD;
    const headerPaddingTop = getStaticHeaderSectionStyle(sectionSpacingSize, pageMarginSize).paddingTop;

    const pageSpacing = getPageVerticalMarginStyle(pageNumber === 0, pageMarginSize);
    const spacingTopValue = pageNumber === 0 ? headerPaddingTop : pageSpacing.paddingTop;

    const pageMaxHeight = A4_PAGE_HEIGHT_PIXELS;
    const availablePageHeight = pageMaxHeight - pageSpacing.paddingBottom - (spacingTopValue);

    return availablePageHeight;
};

export const getColumnSortedResumeSections = (resumeSections: ResumeSections, columnIndex: number) => {
    // Get the left and right column sections, sorted by position index.
    const sortedResumeSections = Object.entries(resumeSections).filter(([_, section]) => section.columnIndex === columnIndex).sort((a, b) => (a[1].positionIndex ?? 0) - (b[1].positionIndex ?? 0));
    return sortedResumeSections;
};

const addSecIdToPageSections = (sections: PageSection[], sectionId: string) => {
    if (sections.find(s => s.sectionId === sectionId)) return sections;
    return [...sections, { sectionId }];
};

export const createNewPageWithMeasurement = (pageNumber: number, measurement: Measurement, columnIndex: number) => {
    const newPage = getEmptyPage(pageNumber);
    newPage.items.push(measurement);
    newPage.columnHeights[columnIndex] = measurement.height;
    newPage.sections = addSecIdToPageSections(newPage.sections, measurement.section);
    return newPage;
};

export const processColumnSections = (
    columnSections: [string, SectionDetails][],
    measurements: Measurement[],
    pages: Page[],
    resumeStyles: ResumeStyles,
    staticHeaderMeasurement?: Measurement,
) => {
    // Initialize first page with static header if provided and set column heights
    if (staticHeaderMeasurement && pages[0]) {
        const headerExists = pages[0].items.some(item => item.section === staticHeaderMeasurement.section);
        if (!headerExists) {
            pages[0].items.push(staticHeaderMeasurement);
            pages[0].columnHeights[0] = staticHeaderMeasurement.height;
            pages[0].columnHeights[1] = staticHeaderMeasurement.height;
            pages[0].sections.push({
                sectionId: staticHeaderMeasurement.section,
            });
        }
    }

    // Loop through the column sections and create pages
    for (const [sectionId, sectionDetails] of columnSections) {
        // Ignore section if its a staticHeader
        if (sectionId === staticHeaderMeasurement?.section) continue;
        const sectionColumnIndex = sectionDetails.columnIndex;
        const sectionMeasurements = measurements.filter(m => m.section === sectionId);
        const headerMeasurement = sectionMeasurements.find(m => m.type === "header");
        const bodyItemIds = sectionDetails.body?.map(item => item.__id) ?? [];
        const bodyItems = bodyItemIds
            .map(itemId => sectionMeasurements.find(m => m.itemId === itemId))
            .filter(Boolean) as Measurement[];

        let latestPage = pages[pages.length - 1];
        let latestPageMaxHeight = getPageHeightByPageNumber(latestPage.pageNumber, resumeStyles);

        if (headerMeasurement) {
            const firstItemHeight = bodyItems[0]?.height ?? 0;
            const headerHeight = headerMeasurement.height;
            const headerAndFirstItemHeight = headerHeight + firstItemHeight;
            const currentColumnHeight = latestPage.columnHeights[sectionColumnIndex];
            const headerCanFitWithOneItem = currentColumnHeight + headerAndFirstItemHeight <= latestPageMaxHeight;

            if (headerCanFitWithOneItem) {
                latestPage.items.push(headerMeasurement);
                latestPage.columnHeights[sectionColumnIndex] = latestPage.columnHeights[sectionColumnIndex] + headerHeight;
                latestPage.sections = addSecIdToPageSections(latestPage.sections, sectionId);
            } else {
                const nextPage = createNewPageWithMeasurement(latestPage.pageNumber + 1, headerMeasurement, sectionColumnIndex);
                pages.push(nextPage);
                latestPage = nextPage;
                latestPageMaxHeight = getPageHeightByPageNumber(nextPage.pageNumber, resumeStyles);
            }
        }

        if (bodyItems) {
            for (const bodyItem of bodyItems) {
                const itemMeasurement = measurements.find(m => m.itemId === bodyItem.itemId);

                if (itemMeasurement) {
                    const bodyItemCanFit = latestPage.columnHeights[sectionColumnIndex] + itemMeasurement.height <= latestPageMaxHeight;

                    if (bodyItemCanFit) {
                        latestPage.items.push(itemMeasurement);
                        latestPage.columnHeights[sectionColumnIndex] = latestPage.columnHeights[sectionColumnIndex] + itemMeasurement.height;
                        latestPage.sections = addSecIdToPageSections(latestPage.sections, sectionId);
                    } else {
                        const nextPage = createNewPageWithMeasurement(latestPage.pageNumber + 1, itemMeasurement, sectionColumnIndex);
                        pages.push(nextPage);
                        latestPage = nextPage;
                        latestPageMaxHeight = getPageHeightByPageNumber(nextPage.pageNumber, resumeStyles);
                    }
                }
            }
        }
    }

    return pages;
};

export const getColumnPages = (resumeSections: ResumeSections, measurements: Measurement[], resumeStyles: ResumeStyles) => {
    const leftColumnPages: Page[] = [getEmptyPage(0)];
    const rightColumnPages: Page[] = [getEmptyPage(0)];

    const leftColumnSections = getColumnSortedResumeSections(resumeSections, 0);
    const rightColumnSections = getColumnSortedResumeSections(resumeSections, 1);

    // Process left column with static header
    const staticHeaderMeasurement = measurements.find(m => m.type === "header" && m.isStaticHeader);
    processColumnSections(leftColumnSections, measurements, leftColumnPages, resumeStyles, staticHeaderMeasurement);

    // Process right column without static header
    processColumnSections(rightColumnSections, measurements, rightColumnPages, resumeStyles, staticHeaderMeasurement);

    return { leftColumnPages, rightColumnPages };
};

export const mergeColumnPages = (leftColumnPages: Page[], rightColumnPages: Page[]) => {
    // Merge the pages from both columns
    const maxPages = Math.max(leftColumnPages.length, rightColumnPages.length);
    const allPages = Array.from({ length: maxPages }, (_, pageNumber) => {
        const leftColumnPage = leftColumnPages[pageNumber];
        const rightColumnPage = rightColumnPages[pageNumber];

        const sections = [...(leftColumnPage?.sections || []), ...(rightColumnPage?.sections || [])];
        const items = [...(leftColumnPage?.items || []), ...(rightColumnPage?.items || [])];

        return {
            pageNumber,
            sections: sections.filter((section, index, self) =>
                section.sectionId && self.findIndex(s => s.sectionId === section.sectionId) === index
            ),
            items: Array.from(new Set(items)),
            columnHeights: {
                0: leftColumnPage?.columnHeights[0] || 0,
                1: rightColumnPage?.columnHeights[1] || 0
            }
        };
    });

    return allPages;
};

export const getSortedSectionIdsByPositionIndex = (sectionIds: string[], sections?: ResumeSections) => {
    const sorted = [...sectionIds].sort((a, b) => {
        if (!sections || !sections[a] || !sections[b]) return 0;
        return sections[a].positionIndex - sections[b].positionIndex;
    });

    return sorted;
};

// Only use paddingBottom of the header spacing. The paddingTop is considered a page margin and is deducted from the page height.
export const calculateSectionHeight = (height: number, isStaticHeader: boolean, sectionSpacingSize: SectionSpacingSize, pageMarginSize: PageMarginSize) => {
    const { marginBottom, paddingBottom = 0 } = isStaticHeader
        ? getStaticHeaderSectionStyle(sectionSpacingSize, pageMarginSize)
        : getSectionYMargin(sectionSpacingSize);

    const roundedHeight = Math.round((height + Number.EPSILON) * 100) / 100;
    return roundedHeight + marginBottom + paddingBottom;
};

export const groupAndSortMeasurements = (measurements: Measurement[]): GroupedMeasurements => {
    if (!measurements.length) return {};
    const grouped: GroupedMeasurements = {};
    // Ensure measurements are sorted by positionIndex as they are added to the grouped object
    const sortedMeasurementsByPositionIndex = measurements.sort((a, b) => (a.positionIndex ?? 0) - (b.positionIndex ?? 0));

    sortedMeasurementsByPositionIndex.forEach((measurement) => {
        if (!grouped[measurement.section]) {
            grouped[measurement.section] = { items: [] };
        }

        if (measurement.type === "header") {
            grouped[measurement.section].header = measurement;
        } else {
            grouped[measurement.section].items.push(measurement);
        }
    });

    // Sort items within each section by itemIndex
    Object.values(grouped).forEach((section) => {
        section.items.sort((a, b) => (a.itemIndex ?? 0) - (b.itemIndex ?? 0));
    });

    return grouped;
};

export const sortMeasurementsByColumnAndPositionIndex = (measurements: Measurement[]) => {
    if (!measurements.length) return [];
    const staticHeaders = measurements.filter(m => m.isStaticHeader);
    const nonStaticMeasurements = measurements.filter(m => !m.isStaticHeader);

    const groupedMeasurements = groupAndSortMeasurements(nonStaticMeasurements);

    const sortedSections = Object.entries(groupedMeasurements).sort((a, b) => {
        const headerA = a[1].header;
        const headerB = b[1].header;

        if (!headerA && !headerB) return 0;
        if (!headerA) return 1;
        if (!headerB) return -1;

        if (headerA.columnIndex !== headerB.columnIndex) {
            return (headerA.columnIndex ?? 0) - (headerB.columnIndex ?? 0);
        }
        return (headerA.positionIndex ?? 0) - (headerB.positionIndex ?? 0);
    });

    const sortedMeasurements: Measurement[] = [...staticHeaders];

    sortedSections.forEach(([_, section]) => {
        if (section.header) {
            sortedMeasurements.push(section.header);
        }
        sortedMeasurements.push(...section.items);
    });

    return sortedMeasurements;
};

export const getNextAvailablePositionIndex = (measurements: Measurement[], columnIndex: number) => {
    const columnMeasurements = measurements.filter(m => m.columnIndex === columnIndex);
    if (!columnMeasurements.length) return 0;
    const maxPositionIndex = Math.max(...columnMeasurements.map(m => m.positionIndex ?? 0));
    return maxPositionIndex + 1;
};

// Filter a section's body items to only include items that are present in a given pages items
export const getSectionBodyItemsForPage = (pageItemMeasurements: Measurement[], sectionDetails: SectionDetails | undefined) => {
    if (!pageItemMeasurements.length) return [];
    const sectionDetailsBody = sectionDetails?.body;
    const bodyItemsWithMeasurments = sectionDetailsBody?.filter(item => pageItemMeasurements.find(m => m.itemId === item.__id));

    return bodyItemsWithMeasurments;
};
