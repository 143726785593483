export const A4_PAGE_WIDTH_PIXELS = 794;
export const A4_PAGE_HEIGHT_PIXELS = 1123;


// Every section excluding the header section has a margin bottom of 12
// These values need to be included in measurements
export const SECTION_MARGIN_BOTTOM = 12;
// Header padding and margin is attached to the container which is not
// part of the measured areas. These are used to add to the height of the header content
export const HEADER_SECTION_MARGIN_BOTTOM = 20;
export const HEADER_SECTION_PADDING_BOTTOM = 20;
export const HEADER_SECTION_PADDING_TOP = 50;

export const REARRANGE_MODAL_SCALE = 0.5;