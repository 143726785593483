import { Navigate } from "react-router-dom";
import { UsingReactChildren } from "@/types";
import { getTokenHelper } from "@/helper/getTokens";
import { getIsDesktopOnlyRoute } from "@/utils/routes";
import { AppRoutes } from "@/types/routes";
import { useDevice } from "@/hooks/useDevice";
import { DeviceContextType } from "@/types/device";
import { useState, useEffect } from "react";

export const ProtectedRoute = ({ children }: UsingReactChildren) => {
    const token = getTokenHelper("accessToken");
    const isDesktopOnlyRoute = getIsDesktopOnlyRoute();
    const { isDesktop } = useDevice() as DeviceContextType;
    const [shouldRedirect, setShouldRedirect] = useState(false);

    useEffect(() => {
        if (isDesktopOnlyRoute && !isDesktop) {
            setShouldRedirect(true);
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []); // Purposefully leaving empty so only runs on mount of page.

    if (!token?.token) {
        // user is not authenticated
        return <Navigate to={`/${AppRoutes.Login}`} />;
    } else if (shouldRedirect) {
        window.location.href = "/";
        return null;
    }

    return children;
};
