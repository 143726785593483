import { Dropdown } from "@/components/Dropdown/Dropdown";
import { resumeFonts } from "@/constants/resume";
import { useMemo } from "react";

const getFontItems = () => {
    return resumeFonts.map(({ value, displayName }) => {
        return {
            title: <span style={{ fontFamily: value }}>{displayName}</span>,
            id: value,
        };
    });
};
interface FontSelectorProps {
    onFontChange: (font: string) => void;
    activeFont: string;
    className?: string;
}

export const FontSelector = ({
    onFontChange,
    activeFont,
    className,
}: FontSelectorProps) => {
    const fonts = useMemo(() => getFontItems(), []);
    return (
        <Dropdown
            items={fonts}
            activeId={activeFont}
            handleChange={(id: string) => onFontChange(id)}
            title={fonts.find(item => item.id === activeFont)?.title}
            className={className}
        />
    );
};
