import { GoogleReCaptchaProvider } from "react-google-recaptcha-v3";
import { GoogleOAuthProvider } from "@react-oauth/google";
import { Outlet } from "react-router-dom";

export const AuthWrapper = () => {
    return (
        <GoogleReCaptchaProvider
            reCaptchaKey={import.meta.env.VITE_REACT_APP_RECAPTCHA_SITE_KEY}
            scriptProps={{
                async: true,
                defer: true,
                appendTo: "head",
            }}
        >
            <GoogleOAuthProvider
                clientId={import.meta.env.VITE_OAUTH_CLIENT_ID}
            >
                <Outlet />
            </GoogleOAuthProvider>
        </GoogleReCaptchaProvider>
    );
};
