export enum ToggleState {
    OFF = 0,
    ON = 1,
    DISABLED = 2,
}

export interface SectionField {
    name: string;
    type: string;
    defaultValue: string;
    placeholder: string;
    format?: string;
    icon?: string;
    toggleState: ToggleState;
    displayName: string;
    order: number;
}

export interface LayoutFieldOption {
    name: string;
    value: string;
}

export interface LayoutField {
    name: string;
    type: "dropdown" | "toggle";
    defaultValue: string;
    options: LayoutFieldOption[];
    displayName: string;
    order: number;
    toggleState: ToggleState;
}

export type SectionWidth = "full" | "half" | "one-third" | "two-thirds";
export type SectionTag = "recommended";

export interface Section {
    name: string;
    titleComp: string;
    component: string;
    sectionType: string;
    documentType: string;
    id: string;
    bodyFields: SectionField[];
    headerFields: SectionField[];
    layoutFields: LayoutField[];
    supportedWidths: SectionWidth[];
    isStaticHeader?: boolean;
    isCustomSection?: boolean;
    tags: SectionTag[];
}

export interface IndexedSection {
    [key: string]: Section | undefined;
}

export interface SectionFieldsConfig {
    [key: string]: {
        icon?: string;
        placeholder?: string;
        type: string;
    };
}