import { ButtonColourStyles } from "@/components/Button/Button";
import { IconButton } from "@/components/Button/IconButton";
import { DialogTitle } from "@headlessui/react";
import { XIcon } from "lucide-react";

interface ModalHeaderProps {
    title: string;
    onClose: () => void;
}

export const ModalHeader = ({ title, onClose }: ModalHeaderProps) => (
    <div className="mb-2 flex items-center justify-between">
        <DialogTitle
            as="h1"
            className="pr-2 text-app-headings-1-mobile text-neutral-900 md:text-app-headings-1-desktop"
        >
            {title}
        </DialogTitle>
        <IconButton
            onClick={onClose}
            color={ButtonColourStyles.SOLID_GREY}
        >
            <XIcon size={16} />
        </IconButton>
    </div>
);
