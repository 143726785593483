import { Fragment } from "react";
import {
    Listbox,
    ListboxButton,
    ListboxOption,
    ListboxOptions,
    Transition,
} from "@headlessui/react";
import { CheckIcon, ChevronUpDownIcon } from "@heroicons/react/20/solid";
import clsx from "clsx";

export interface SelectItem {
    id: string;
    name: string;
}

interface SelectProps {
    value?: SelectItem;
    onChange: (item: SelectItem) => void;
    items: SelectItem[];
}

export const Select = ({ value, onChange, items }: SelectProps) => {
    return (
        <Listbox
            value={value}
            onChange={onChange}
        >
            {({ open }) => (
                <div className="relative">
                    <ListboxButton className="relative min-h-9 w-full cursor-default rounded-lg bg-white py-1.5 pl-3 pr-10 text-left text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 focus:outline-none focus:ring-2 focus:ring-indigo-600 sm:text-sm sm:leading-6">
                        <span className="block truncate">{value?.name}</span>
                        <span className="pointer-events-none absolute inset-y-0 right-0 flex items-center pr-2">
                            <ChevronUpDownIcon
                                className="h-5 w-5 text-gray-400"
                                aria-hidden="true"
                            />
                        </span>
                    </ListboxButton>

                    <Transition
                        show={open}
                        as={Fragment}
                        leave="transition ease-in duration-100"
                        leaveFrom="opacity-100"
                        leaveTo="opacity-0"
                    >
                        <ListboxOptions className="absolute z-10 mt-1 max-h-60 w-full overflow-auto rounded-lg bg-white py-1 text-base shadow-lg ring-1 ring-black ring-opacity-5 focus:outline-none sm:text-sm">
                            {items.map(item => (
                                <ListboxOption
                                    key={item.id}
                                    className="relative cursor-default select-none  py-2 pl-3 pr-9 text-gray-900 data-[active]:bg-primary-600 data-[active]:text-white"
                                    value={item}
                                >
                                    <>
                                        <span
                                            className={clsx(
                                                value
                                                    ? "font-semibold"
                                                    : "font-normal",
                                                "block truncate",
                                            )}
                                        >
                                            {item.name}
                                        </span>

                                        {item.id === value?.id ? (
                                            <span className="absolute inset-y-0 right-0 flex items-center pr-4 text-primary-600 data-[active]:text-white">
                                                <CheckIcon
                                                    className="h-5 w-5"
                                                    aria-hidden="true"
                                                />
                                            </span>
                                        ) : null}
                                    </>
                                </ListboxOption>
                            ))}
                        </ListboxOptions>
                    </Transition>
                </div>
            )}
        </Listbox>
    );
};
