import { UserPreferences } from "@/types/user";
import { PayloadAction, createSlice } from "@reduxjs/toolkit";

interface WizardState {
    userPreferences: UserPreferences;
}

const initialState: WizardState = {
    userPreferences: {
        industries: [],
        jobTypes: [],
        experienceLevel: "",
        jobRoles: [],
    },
};

const wizardSlice = createSlice({
    name: "wizard",
    initialState,
    reducers: {
        resetWizardUserPreferences: (state) => {
            return {
                ...state,
                userPreferences: initialState.userPreferences,
            };
        },
        setWizardUserPreference: (state, { payload }: PayloadAction<{ preference: string, value: string | string[]; }>) => {
            return {
                ...state,
                userPreferences: {
                    ...state.userPreferences,
                    [payload.preference]: payload.value,
                },
            };
        },
        setWizardUserPreferences: (state, { payload }: PayloadAction<UserPreferences | null>) => {
            if (!payload) {
                return {
                    ...state,
                    userPreferences: initialState.userPreferences,
                };
            }
            return {
                ...state,
                userPreferences: {
                    ...state.userPreferences,
                    ...payload,
                },
            };
        },
    },
});

export const { setWizardUserPreferences, setWizardUserPreference, resetWizardUserPreferences } = wizardSlice.actions;
export const { reducer: wizardReducer } = wizardSlice;
