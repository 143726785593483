import { applyResumeLevelDateFormat } from "@/components/DatePeriodPicker/utils";
import { FieldIcon } from "@/components/FieldIcon/FieldIcon";
import { getTextStyle } from "@/components/PdfDocument/utils/getStyles";
import { GLOBAL_STYLE_KEY } from "@/constants/resume";
import { DateFormat, ResumeStyles } from "@/types/resume";

interface ReadOnlyWidgetProps {
    // sectionId: string;
    // propId: string;
    // propType: "header" | "body";
    // editable: boolean;
    type: string;
    value: string;
    // itemId?: string;
    icon?: string;
    stylesConfig: ResumeStyles;
}

export const ReadOnlyWidget = ({
    // sectionId,
    // propId,
    // propType,
    // editable,
    // format,
    type,
    value,
    // itemId,
    icon,
    stylesConfig,
}: ReadOnlyWidgetProps) => {
    const textStyle = getTextStyle(
        stylesConfig?.[GLOBAL_STYLE_KEY].fontSize,
        "p",
        false,
    );
    const dateFormat = stylesConfig?.[GLOBAL_STYLE_KEY].dateFormat;
    switch (type.toLowerCase()) {
        case "date":
            return (
                <p
                    className="flex items-center"
                    style={textStyle}
                >
                    {icon && (
                        <FieldIcon
                            name={icon}
                            stylesConfig={stylesConfig}
                        />
                    )}
                    {applyResumeLevelDateFormat(
                        value,
                        dateFormat || DateFormat.LRG,
                    )}
                </p>
            );
        default:
            return null;
    }
};
