import { NavLink } from "../../../components/NavLink";
import classnames from "classnames";
import { useCallback, useEffect, useState } from "react";
import sectionApi from "@/api/section";
import documentTypeApi from "@/api/documentType";
import { Section, DocumentType, NotificationMessageType } from "@/types";
import { AdminListHeader } from "@/pages/Admin/AdminListHeader";
import { ButtonColourStyles, ButtonVariant } from "@/components/Button/Button";
import { IconButton } from "@/components/Button/IconButton";
import { Copy } from "lucide-react";
import { Tooltip } from "@/components/Tooltip/Tooltip";
import { handleAdminError } from "@/pages/Admin/utils/handleAdminError";
import { useAppDispatch } from "@/hooks/types";
import { useNavigate } from "react-router-dom";
import { sortByField } from "@/pages/Admin/utils/sortByField";

export const Sections = () => {
    const [sections, setSections] = useState<Section[]>([]);
    const [docTypes, setDocTypes] = useState<DocumentType[]>([]);
    const navigate = useNavigate();
    const dispatch = useAppDispatch();

    useEffect(() => {
        const getAllSections = async () => {
            const response = await sectionApi.getAllSections();
            setSections(sortByField(response.results, "name"));
        };
        const getAllDocumentTypes = async () => {
            const response = await documentTypeApi.getAllDocumentTypes();
            setDocTypes(response.results);
        };
        getAllSections();
        getAllDocumentTypes();
    }, []);

    const cloneSection = useCallback(
        (section: Section) => {
            const sectionCopy: Section = JSON.parse(JSON.stringify(section));
            sectionCopy.name = "CLONED SECTION";
            sectionCopy.sectionType = "CLONED SECTION";
            delete sectionCopy.id;
            delete sectionCopy.createdAt;
            delete sectionCopy.updatedAt;

            try {
                sectionApi
                    .saveSection(sectionCopy as Section)
                    .then(clonedSection => {
                        navigate(`/admin/sections/${clonedSection.id}`);
                    });
            } catch (err: unknown) {
                handleAdminError(
                    dispatch,
                    err,
                    "Failed to clone",
                    NotificationMessageType.ADMIN_SECTION_SAVE,
                );
            }
        },
        [dispatch, navigate],
    );

    return (
        <div className="px-4 sm:px-6 lg:px-8">
            <AdminListHeader
                title="Sections"
                description="A list of all the sections in your account including their name, section type and document type."
                buttonText="Add Section"
                to="new"
            />
            <div className="mt-8 flow-root">
                <div className="-mx-4 -my-2 sm:-mx-6 lg:-mx-8">
                    <div className="inline-block min-w-full py-2 align-middle">
                        <table className="min-w-full border-separate border-spacing-0">
                            <thead>
                                <tr>
                                    <th
                                        scope="col"
                                        className="sticky top-0 z-10 border-b border-gray-300 bg-white bg-opacity-75 py-3.5 pl-4 pr-3 text-left text-sm font-semibold text-gray-900 backdrop-blur backdrop-filter sm:pl-6 lg:pl-8"
                                    >
                                        ID
                                    </th>
                                    <th
                                        scope="col"
                                        className="sticky top-0 z-10 border-b border-gray-300 bg-white bg-opacity-75 py-3.5 pl-4 pr-3 text-left text-sm font-semibold text-gray-900 backdrop-blur backdrop-filter sm:pl-6 lg:pl-8"
                                    >
                                        Name
                                    </th>

                                    <th
                                        scope="col"
                                        className="sticky top-0 z-10 border-b border-gray-300 bg-white bg-opacity-75 px-3 py-3.5 text-left text-sm font-semibold text-gray-900 backdrop-blur backdrop-filter"
                                    >
                                        Section Type
                                    </th>
                                    <th
                                        scope="col"
                                        className="sticky top-0 z-10 border-b border-gray-300 bg-white bg-opacity-75 px-3 py-3.5 text-left text-sm font-semibold text-gray-900 backdrop-blur backdrop-filter"
                                    >
                                        Document Type
                                    </th>
                                    <th
                                        scope="col"
                                        className="sticky top-0 z-10 border-b border-gray-300 bg-white bg-opacity-75 px-3 py-3.5 text-left text-sm font-semibold text-gray-900 backdrop-blur backdrop-filter"
                                    >
                                        Custom/Static Header
                                    </th>
                                    <th
                                        scope="col"
                                        className="sticky top-0 z-10 border-b border-gray-300 bg-white bg-opacity-75 px-3 py-3.5 text-left text-sm font-semibold text-gray-900 backdrop-blur backdrop-filter"
                                    >
                                        {/* Custom/Static Header */}
                                    </th>
                                </tr>
                            </thead>
                            <tbody>
                                {sections &&
                                    sections.map((section, sectionIdx) => (
                                        <tr key={section.id}>
                                            <td
                                                className={classnames(
                                                    sectionIdx !==
                                                        sections.length - 1
                                                        ? "border-b border-gray-200"
                                                        : "",
                                                    "whitespace-nowrap py-4 pl-4 pr-3 text-sm font-medium text-gray-900 sm:pl-6 lg:pl-8",
                                                )}
                                            >
                                                <NavLink
                                                    href={`${section.id}`}
                                                    className="text-primary-600 hover:text-primary-900"
                                                >
                                                    {section.id}
                                                </NavLink>
                                            </td>
                                            <td
                                                className={classnames(
                                                    sectionIdx !==
                                                        sections.length - 1
                                                        ? "border-b border-gray-200"
                                                        : "",
                                                    "whitespace-nowrap py-4 pl-4 pr-3 text-sm font-medium text-gray-900 sm:pl-6 lg:pl-8",
                                                )}
                                            >
                                                <NavLink
                                                    href={`${section.id}`}
                                                    className="text-primary-600 hover:text-primary-900"
                                                >
                                                    {section.name}
                                                </NavLink>
                                            </td>

                                            <td
                                                className={classnames(
                                                    sectionIdx !==
                                                        sections.length - 1
                                                        ? "border-b border-gray-200"
                                                        : "",
                                                    "whitespace-nowrap px-3 py-4 text-sm text-gray-500",
                                                )}
                                            >
                                                {section.sectionType}
                                            </td>
                                            <td
                                                className={classnames(
                                                    sectionIdx !==
                                                        sections.length - 1
                                                        ? "border-b border-gray-200"
                                                        : "",
                                                    "whitespace-nowrap px-3 py-4 text-sm text-gray-500",
                                                )}
                                            >
                                                {docTypes
                                                    ? docTypes.find(
                                                          docType =>
                                                              docType.id ===
                                                              section.documentType,
                                                      )?.name
                                                    : section.documentType}{" "}
                                                ({section.documentType})
                                            </td>
                                            <td
                                                className={classnames(
                                                    sectionIdx !==
                                                        sections.length - 1
                                                        ? "border-b border-gray-200"
                                                        : "",
                                                    "whitespace-nowrap px-3 py-4 text-sm text-gray-500",
                                                )}
                                            >
                                                {section.isCustomSection
                                                    ? "Custom"
                                                    : section.isStaticHeader
                                                      ? "Header"
                                                      : ""}
                                            </td>
                                            <td
                                                className={classnames(
                                                    sectionIdx !==
                                                        sections.length - 1
                                                        ? "border-b border-gray-200"
                                                        : "",
                                                    "whitespace-nowrap px-3 py-4 text-sm text-gray-500",
                                                )}
                                            >
                                                <div className="group relative">
                                                    <IconButton
                                                        variant={
                                                            ButtonVariant.SOLID
                                                        }
                                                        color={
                                                            ButtonColourStyles.SOLID_WHITE
                                                        }
                                                        onClick={() =>
                                                            cloneSection(
                                                                section,
                                                            )
                                                        }
                                                    >
                                                        <Copy size={16} />
                                                    </IconButton>
                                                    <Tooltip
                                                        size="small"
                                                        text="Clone"
                                                        positionClassName="-translate-x-[90%] -translate-y-[150%]"
                                                    />
                                                </div>
                                            </td>
                                        </tr>
                                    ))}
                            </tbody>
                        </table>
                    </div>
                </div>
            </div>
        </div>
    );
};
