import { getColumnPages, mergeColumnPages } from "@/store/pages/utils";
import { setPages } from "@/store/pages/slice";
import { RootState } from "@/store/store";
import { EnhancedStore, Middleware } from "@reduxjs/toolkit";

// Each of these actions should trigger a recalculation of the pages.
enum ActionTypes {
    SET_SECTION_HEIGHT = "pages/setSectionHeight",
    BUILD_INITIAL_PAGES = "pages/buildInitialPages",
    ADD_SECTION_TO_PAGE = "pages/addSectionToPage",
    ADD_ITEM_MEASUREMENT = "pages/addItemMeasurement",
    REMOVE_ITEM_MEASUREMENT = "pages/removeItemMeasurement",
}

/**
 * The pages state has multiple triggers. This middleware is used to recalculate the pages when a trigger is detected. Mostly the triggers are when a states measurement has been changed.
 * Section measurements are used to calculate the pages.
 * 
 * Triggers include:
 * - [1] Section height change
 * - - This could be triggered by various actions. Editing content, toggling fields,
 * - - column layout changing, moving a section to a different column.
 * 
 * - [2] Section added/removed
 * - - Pages get updated when a section is added or removed to ensure content is on the
 * - - right page after add, or that other sections are after a remove.
 */
export const recalculatePagesMiddleware: (store: EnhancedStore<object>) => Middleware = (store) => (next: any) => (action: any) => {
    const result = next(action);
    const state: RootState = store.getState();

    switch (action.type) {
        case ActionTypes.BUILD_INITIAL_PAGES:
        case ActionTypes.ADD_SECTION_TO_PAGE:
        case ActionTypes.ADD_ITEM_MEASUREMENT:
        case ActionTypes.REMOVE_ITEM_MEASUREMENT:
        case ActionTypes.SET_SECTION_HEIGHT: {
            if (!state.pages.ready) {
                // Don't run this action until the buildInitialPages action has run which populates the pages state
                return;
            }
            const measurements = state.pages.measurements;
            const resumeSections = state.resume.activeResume?.sections ?? {};
            const resumeStyles = state.resume.activeResume?.styles ?? {};
            const { leftColumnPages, rightColumnPages } = getColumnPages(resumeSections, measurements, resumeStyles);
            const newPages = mergeColumnPages(leftColumnPages, rightColumnPages);

            if (newPages.length === 0) {
                // Ignore empty pages as they are part of loading state
                return;
            }
            next(setPages(newPages));
            break;
        }
        default:
            break;
    }

    return result;
};
