import { Link } from "@react-email/components";

export const EmailLink = ({
    href = "#",
    children,
    style,
}: {
    href: string;
    children: React.ReactNode;
    style?: React.CSSProperties;
}) => (
    <Link
        href={href}
        style={{
            fontSize: "18px",
            color: "#3D61CC",
            fontWeight: 500,
            lineHeight: 1.2,
            margin: "0 0 12px",
            ...style,
        }}
    >
        {children}
    </Link>
);
