import { ApiPaginationResponse, Template } from "@/types";
import { apiRequest } from "./request";

const getTemplate = (id: string) => apiRequest<Template>(`/template/${id}`, "GET");

const getAllTemplates = () => apiRequest<ApiPaginationResponse<Template[]>>(`/template/`, "GET");

const saveTemplate = (data: Partial<Template>, id?: string) =>
    apiRequest<Template>(
        id ? `/template/${id}` : "/template",
        id ? "PATCH" : "POST",
        data,
    );

const deleteTemplate = (id: string) => apiRequest(`/template/${id}`, "DELETE");

export default {
    saveTemplate,
    getTemplate,
    getAllTemplates,
    deleteTemplate,
};
