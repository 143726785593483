import { UsingReactChildren } from "@/types";
import clsx from "clsx";
import { Link } from "react-router-dom";
import { HashLink } from "react-router-hash-link";

interface NavLinkProps extends UsingReactChildren {
    href: string;
    className?: string;
}

export const NavLink = ({ href, children, className }: NavLinkProps) => {
    const isHashLink = href?.[0] === "#";

    return isHashLink ? (
        <HashLink
            to={href}
            className={clsx(
                "inline-block rounded-lg px-2 py-1 text-sm ",
                className ||
                    "text-slate-700 hover:bg-slate-100 hover:text-slate-900",
            )}
            smooth
        >
            {children}
        </HashLink>
    ) : (
        <Link
            to={href}
            className={clsx(
                "inline-block rounded-lg px-2 py-1 text-sm ",
                className ||
                    "text-slate-700 hover:bg-slate-100 hover:text-slate-900",
            )}
        >
            {children}
        </Link>
    );
};
