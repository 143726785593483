import { addNotification } from "@/store/app/slice";
import { AppDispatch } from "@/store/store";
import { NotificationMessageType, NotificationType } from "@/types";

export const handleAdminError = (dispatch: AppDispatch, err: unknown, title: string, messageType: NotificationMessageType) => {
    let errorMessage;
    if (typeof err === "string") {
        errorMessage = err;
    } else if (err instanceof Error) {
        errorMessage = err.message;
    }

    dispatch(
        addNotification({
            title,
            desc: errorMessage,
            messageType,
            type: NotificationType.ERROR,
        }),
    );
};