export const AdminNoResults = () => {
    return (
        <div className="flex flex-col items-center justify-center py-8 text-center">
            <svg
                className="mb-4 h-16 w-16 text-neutral-300"
                fill="none"
                viewBox="0 0 24 24"
                stroke="currentColor"
            >
                <path
                    strokeLinecap="round"
                    strokeLinejoin="round"
                    strokeWidth={2}
                    d="M9.172 16.172a4 4 0 015.656 0M9 10h.01M15 10h.01M21 12a9 9 0 11-18 0 9 9 0 0118 0z"
                />
            </svg>
            <h3 className="mb-1 text-lg font-medium text-neutral-900">
                No results found
            </h3>
            <p className="text-sm text-neutral-500">
                Try adjusting your search or filter to find what you're looking
                for.
            </p>
        </div>
    );
};
