import { Alert } from "@/components/Alert/Alert";
import { Button } from "@/components/Button/Button";
import { AppText } from "@/components/Text/AppText";
import { useAppDispatch, useAppSelector } from "@/hooks/types";
import { getActiveJobRole } from "@/store/assistant/selectors";
import { openModal } from "@/store/modal/slice";
import { ModalTypes } from "@/types/modal";
import clsx from "clsx";
import { Edit3 } from "lucide-react";
import { useCallback, useEffect, useState } from "react";

export const JobRoleSelector = ({
    noMargin = false,
}: {
    noMargin?: boolean;
}) => {
    const activeJobRole = useAppSelector(getActiveJobRole);
    const [selectedJobRole, setSelectedJobRole] = useState(activeJobRole);
    const dispatch = useAppDispatch();

    useEffect(() => {
        setSelectedJobRole(activeJobRole);
    }, [activeJobRole]);

    const openJobRoleModal = useCallback(() => {
        dispatch(openModal({ modalType: ModalTypes.ASSISTANT_JOB_ROLE }));
    }, [dispatch]);

    return (
        <div
            className={clsx(
                "rounded-lg bg-neutral-100 px-3 py-2",
                !selectedJobRole && "flex-col",
                noMargin ? "mx-0" : "mx-4",
            )}
        >
            <div className="flex items-center gap-3">
                {selectedJobRole && (
                    <AppText variant="labelsbuttons">Your Role:</AppText>
                )}
                <Button
                    className="!h-auto min-h-7 flex-1 !px-4 !py-1"
                    rightIcon={<Edit3 size={16} />}
                    onClick={openJobRoleModal}
                >
                    {selectedJobRole ? (
                        <AppText variant="footnote">{selectedJobRole}</AppText>
                    ) : (
                        <AppText variant="footnote">Choose a role</AppText>
                    )}
                </Button>
            </div>
            {!selectedJobRole && (
                <Alert
                    className="mt-4"
                    type="info"
                    message="Choosing a role helps tailor the Assistant to your needs."
                />
            )}
        </div>
    );
};
