type Styles = { [key: string]: string; };

export const buttonStyles: Styles = {
    // Varients
    solid: "h-9 min-w-24 group inline-flex items-center justify-center rounded-lg py-2 px-4 xl:px-6 font-semibold focus:outline-none disabled:opacity-25 focus-visible:outline-2 focus-visible:outline-offset-2 disabled:pointer-events-none",
    outline:
        "h-9 min-w-24 group inline-flex items-center justify-center rounded-lg py-2 px-4 xl:px-6 font-semibold focus:outline-none disabled:opacity-25 disabled:pointer-events-none ring-2 ring-inset",
    text: "h-9 min-w-24 group inline-flex items-center justify-center rounded-lg py-2 px-4 xl:px-6 font-semibold focus:outline-none disabled:opacity-25 disabled:pointer-events-none",

    // SOLID
    buttonSolidColourPrimary:
        "bg-primary-700 text-neutral-50 hover:bg-primary-800 focus-visible:outline-primary-900 disabled:hover:bg-primary-700 active:bg-primary-800",
    buttonSolidColourGrey:
        "bg-neutral-100 text-neutral-900 hover:bg-neutral-200 active:bg-neutral-200 focus-visible:outline-primary-900 disabled:hover:bg-neutral-100",
    buttonSolidColourWhite:
        "bg-white text-neutral-900 hover:bg-neutral-100 active:bg-neutral-100 focus-visible:outline-primary-900 disabled:hover:bg-white",
    buttonSolidColourRed:
        "bg-error-500 text-neutral-50 hover:bg-error-600 active:bg-error-400 focus-visible:outline-primary-900 disabled:hover:bg-error-500",
    buttonSolidColourSoftIndigo:
        "text-primary-700 bg-primary-100 hover:bg-primary-200 hover:text-primary-800 active:bg-primary-50 focus-visible:outline-primary-900 disabled:hover:bg-primary-100",
    buttonSolidColourAlert:
        "text-alert-800 bg-alert-100 hover:bg-alert-200 hover:text-alert-800 active:bg-alert-50 focus-visible:outline-alert-900 disabled:hover:bg-alert-100",

    // OUTLINE
    buttonOutlineColourPrimary:
        "ring-primary-700 text-primary-700 hover:text-primary-600 hover:ring-primary-600 active:ring-primary-400 active:text-primary-400 focus-visible:outline-primary-900",
    buttonOutlineColourWhite:
        "ring-neutral-700 text-neutral-50 hover:ring-slate-500 active:ring-slate-700 active:text-neutral-400 focus-visible:outline-white focus-visible:outline-primary-900",
    buttonOutlineColourGrey:
        "ring-neutral-300 text-neutral-900 hover:bg-neutral-100 active:ring-neutral-400 active:text-neutral-400 focus-visible:outline-primary-900",
    buttonOutlineColourSoftIndigo:
        "ring-neutral-300 text-primary-900 hover:bg-primary-50 active:ring-primary-400 active:text-primary-900 focus-visible:outline-primary-900",

};
