import { useAppSelector } from "@/hooks/types";
import { SideBarHeader } from "../SideBarHeader";
import { getActiveItemId, getActiveSection } from "@/store/template/selectors";
import { useCallback, useMemo } from "react";
import { SwitchField } from "@/components/SwitchToggle/SwitchField";
import {
    Button,
    ButtonColourStyles,
    ButtonVariant,
} from "@/components/Button/Button";
import { Trash2 } from "lucide-react";
import { useUpdateTemplate } from "@/hooks/useUpdateTemplate";
import { getActiveResumeSections } from "@/store/resume/selectors";
import { Image } from "@/components/EditorComponents/Image";
import {
    getDefaultHiddenFields,
    getFieldsByToggleState,
    sortFieldsByOrder,
} from "@/utils/section";
import { SideBarField } from "@/components/Sidebar/SideBarField";
import { SideBarFieldContainer } from "@/components/Sidebar/SideBarFieldContainer";
import { Alert } from "@/components/Alert/Alert";
import { SectionItemDetails } from "@/types/resume";
import { AppText } from "@/components/Text/AppText";
import { Dropdown } from "@/components/Dropdown/Dropdown";
import { getSectionConfigById } from "@/store/sections/selectors";
import { LayoutField, SectionField } from "@/types/section";

export const SectionSettings = () => {
    const activeResumeSections = useAppSelector(getActiveResumeSections);
    const { updateTemplateSectionAndSave, removeSection, updateField } =
        useUpdateTemplate();
    const activeSection = useAppSelector(getActiveSection);
    const activeItemId = useAppSelector(getActiveItemId);
    const activeSectionDetails = useMemo(
        () =>
            activeResumeSections && activeSection
                ? activeResumeSections?.[activeSection]
                : null,
        [activeSection, activeResumeSections],
    );
    const activeSectionConfig = useAppSelector(state =>
        getSectionConfigById(state, activeSectionDetails?.sectionConfigId),
    );

    const {
        hiddenFields: sectionHiddenFields,
        header,
        layout,
        body,
    } = activeSectionDetails ?? {};

    const onFieldToggle = useCallback(
        (
            fieldName: string,
            isVisible: boolean,
            hiddenFields: string[],
            itemId?: string,
        ) => {
            const newHiddenFields = [...hiddenFields];
            if (!isVisible) {
                newHiddenFields.push(fieldName);
            } else {
                const index = newHiddenFields.indexOf(fieldName);
                if (index > -1) {
                    newHiddenFields.splice(index, 1);
                }
            }

            if (itemId) {
                updateField(
                    newHiddenFields,
                    "hiddenFields",
                    "body",
                    activeSection!,
                    itemId,
                );
            } else {
                updateTemplateSectionAndSave(
                    activeSection!,
                    "hiddenFields",
                    newHiddenFields,
                    itemId,
                );
            }
        },
        [activeSection, updateField, updateTemplateSectionAndSave],
    );

    const onLayoutFieldChange = useCallback(
        (fieldName: string, value: string) => {
            updateTemplateSectionAndSave(activeSection!, "layout", {
                ...activeSectionDetails?.layout,
                [fieldName]: value,
            });
        },
        [
            activeSection,
            activeSectionDetails?.layout,
            updateTemplateSectionAndSave,
        ],
    );

    const activeItemDetails = activeItemId
        ? body?.find(item => item.__id === activeItemId)
        : null;
    const { hiddenFields: itemHiddenFields } = activeItemDetails ?? {};

    const headerFields = activeSectionConfig
        ? getFieldsByToggleState(
              sortFieldsByOrder(
                  activeSectionConfig.headerFields,
              ) as SectionField[],
          )
        : [];

    const itemFields = activeSectionConfig
        ? getFieldsByToggleState(
              sortFieldsByOrder(
                  activeSectionConfig.bodyFields,
              ) as SectionField[],
          )
        : [];

    const sortedLayoutFields = sortFieldsByOrder(
        getFieldsByToggleState(
            activeSectionConfig?.layoutFields ?? [],
        ) as LayoutField[],
    ) as LayoutField[];

    const resetItemSettings = useCallback(() => {
        if (!activeSection || !activeItemId) return;
        const defaultHiddenFields = getDefaultHiddenFields(
            activeSectionConfig?.bodyFields ?? [],
        );
        updateField(
            defaultHiddenFields,
            "hiddenFields",
            "body",
            activeSection!,
            activeItemId,
        );
    }, [
        activeSection,
        activeItemId,
        activeSectionConfig?.bodyFields,
        updateField,
    ]);

    const applySettingsToAll = useCallback(() => {
        if (!activeSection || !activeItemId || !body) return;
        const newBody = body.map(item => {
            return {
                ...item,
                hiddenFields: itemHiddenFields,
            };
        }) as SectionItemDetails[];
        updateTemplateSectionAndSave(activeSection!, "body", newBody);
    }, [
        activeSection,
        activeItemId,
        body,
        itemHiddenFields,
        updateTemplateSectionAndSave,
    ]);

    return !activeSection || !activeSectionDetails || !activeSectionConfig ? (
        <div>
            <SideBarHeader title="Settings" />
            <Alert
                type="info"
                message="Select a section or item in the resume to view available options"
                className="mx-4"
            />
        </div>
    ) : (
        <div className="relative flex h-full flex-col">
            <SideBarHeader title="Settings" />
            <div className="no-scrollbar mx-4 h-full overflow-y-scroll pb-10">
                <AppText
                    variant="contextheading"
                    className="mb-4"
                >
                    {activeSectionConfig.name} Settings
                </AppText>
                <SideBarFieldContainer>
                    {headerFields.map(field => (
                        <SideBarField
                            key={field.name}
                            title={field.displayName}
                        >
                            <SwitchField
                                key={field.name}
                                enabled={
                                    !sectionHiddenFields?.includes(field.name)
                                }
                                onToggle={enabled =>
                                    onFieldToggle(
                                        field.name,
                                        enabled,
                                        sectionHiddenFields ?? [],
                                    )
                                }
                            />
                        </SideBarField>
                    ))}
                    {!!sortedLayoutFields?.length && (
                        <AppText
                            variant="contextheading"
                            className="mb-4"
                        >
                            Layout
                        </AppText>
                    )}
                    {headerFields.some(field => field.name === "image") && (
                        <div>
                            <AppText
                                variant="contextheading"
                                className="mb-4"
                            >
                                Header Image
                            </AppText>
                            <Image
                                src={header?.image ?? ""}
                                sectionId={activeSection}
                                propId="image"
                                showPreview={false}
                                propType="header"
                                triggerType="button"
                                editable
                            />
                        </div>
                    )}
                    {sortedLayoutFields?.length > 0 &&
                        sortedLayoutFields.map(layoutField => {
                            const { options, defaultValue } = layoutField;
                            const layoutValue = layout?.[layoutField.name];
                            const selectedOption = options.find(
                                o => o.value === layoutValue,
                            );
                            const defaultOption = options.find(
                                option => option.value === defaultValue,
                            );
                            const activeOption =
                                selectedOption || defaultOption;
                            const dropdownOptions = options.map(option => ({
                                id: option.value,
                                title: option.name,
                            }));

                            return (
                                <SideBarField
                                    key={layoutField.name}
                                    title={layoutField.displayName}
                                >
                                    {layoutField.type === "dropdown" &&
                                        activeOption && (
                                            <Dropdown
                                                items={dropdownOptions}
                                                activeId={activeOption.value}
                                                handleChange={(
                                                    value: string,
                                                ) => {
                                                    onLayoutFieldChange(
                                                        layoutField.name,
                                                        value,
                                                    );
                                                }}
                                                title={activeOption.name}
                                            />
                                        )}
                                </SideBarField>
                            );
                        })}
                    {!activeSectionConfig.isStaticHeader && (
                        <>
                            <AppText
                                variant="contextheading"
                                className=""
                            >
                                Item Settings
                            </AppText>
                            {activeItemId ? (
                                <>
                                    {itemFields.map(field => (
                                        <SideBarField
                                            key={field.name}
                                            title={field.displayName}
                                        >
                                            <SwitchField
                                                key={field.name}
                                                enabled={
                                                    !itemHiddenFields?.includes(
                                                        field.name,
                                                    )
                                                }
                                                onToggle={enabled =>
                                                    onFieldToggle(
                                                        field.name,
                                                        enabled,
                                                        itemHiddenFields ?? [],
                                                        activeItemId,
                                                    )
                                                }
                                            />
                                        </SideBarField>
                                    ))}
                                    <div>
                                        <Button
                                            variant={ButtonVariant.SOLID}
                                            color={
                                                ButtonColourStyles.SOLID_SOFT_INDIGO
                                            }
                                            className="mb-2 w-full"
                                            onClick={resetItemSettings}
                                        >
                                            Use default settings
                                        </Button>
                                        <Button
                                            className="w-full"
                                            onClick={applySettingsToAll}
                                        >
                                            Apply to all items
                                        </Button>
                                    </div>
                                </>
                            ) : (
                                <Alert
                                    type="info"
                                    message="Select an item in a section to view available options"
                                    className="mx-4"
                                />
                            )}
                        </>
                    )}
                </SideBarFieldContainer>
            </div>
            {!activeSectionConfig.isStaticHeader && (
                <div className="mx-4 mt-auto bg-white pt-2">
                    <Button
                        variant={ButtonVariant.SOLID}
                        color={ButtonColourStyles.SOLID_GREY}
                        leftIcon={<Trash2 className="h-4 w-4" />}
                        className="w-full"
                        onClick={() => removeSection(activeSection)}
                    >
                        Remove section
                    </Button>
                </div>
            )}
        </div>
    );
};
