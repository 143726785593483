import React from "react";
import { LoadingSpinner } from "@/components/LoadingSpinner/LoadingSpinner";

interface LoadingOverlayProps {
    isLoading: boolean;
}

export const LoadingOverlay: React.FC<LoadingOverlayProps> = ({
    isLoading,
}) => {
    return (
        <div
            className={`absolute inset-0 z-10 flex items-center justify-center bg-white bg-opacity-75 transition-opacity duration-300 ease-in-out ${
                isLoading
                    ? "scale-100 opacity-100"
                    : "pointer-events-none scale-95 opacity-0"
            }`}
        >
            <LoadingSpinner size={48} />
        </div>
    );
};
