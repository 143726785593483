/**
 * Dates can have the following values (irrespecitve of date format)
 * From values represented in <> and to values represented in []
 * 1. Default value: "Date"
 * 2. From year only: <2024>
 * 3. From year and month: <01/2024>
 * 4. From year and to year: <2024> - [2024]
 * 5. From month and year to year: <01/2024> - [2024]
 * 6. From month and year to month and year: <01/2024> - [01/2025]
 * 7. From month and year to present: <01/2024> - ["Present"]
 * 8. From year to present: <2024> - ["Present"]
 * 
 * " - " is the delimiter used to separate the from and to dates if both are present
 * "/" is the delimiter used to separate the month and year in the date values, if both are present
 * "Present" is the value used to represent the present day
 * "Date" is used as the default value when a date has not been selected
 * 
 * Each of the above values that is a month or year can be formatted in the following ways, based on the date format selected:
 * 1. Months:
 *  - LRG: "January"
 *  - MED: "Jan"
 *  - SM: "01"
 *  - XS: "01"
 * 2. Year:
 *  - LRG: "2024"
 *  - MED: "2024"
 *  - SM: "2024"
 *  - XS: "24"
 * 
 * The separator to be applied is also dependent on the date format selected. A space for long dates, and a "/" for short dates.
 * - LRG: " "
 * - MED: " "
 * - SM: "/"
 * - XS: "/"
 */

import { DateFormat } from "@/types/resume";

const DEFAULT_VALUE = "Date";

const splitDateValue = (value: string) => {
    return value.split(" - ");
};
// Its possible to set only a from date and only a to date, meaning we need to return an array with two values
// If only one date is set, the second value should be undefined
// This means the first value could be either the from date or the to date
const getDatesValues = (value: string) => {
    const dates = splitDateValue(value);

    if (dates.length === 1) {
        return [dates[0], undefined];
    }

    return [dates[0], dates[1]];
};


// These arrays are used in their order (indexing)
const longMonths = ["January", "February", "March", "April", "May", "June", "July", "August", "September", "October", "November", "December"];
const shortMonths = ["Jan", "Feb", "Mar", "Apr", "May", "Jun", "Jul", "Aug", "Sep", "Oct", "Nov", "Dec"];
const numberedMonths = ["01", "02", "03", "04", "05", "06", "07", "08", "09", "10", "11", "12"];

const getIsLongYear = (yearValue: string) => yearValue.length === 4;

const getIsLongMonth = (monthValue: string) => longMonths.includes(monthValue);

// From a given value, detect if the date is formatted using DateFormat.LRG, DateFormat.MED, DateFormat.SM or DateFormat.XS
const detectDateFormat = (value: string) => {
    const [firstDate] = getDatesValues(value);
    const dateFormatSeparator = firstDate!.includes(" ") ? " " : "/";
    const isNumberedMonth = firstDate!.includes("/");
    const [monthValue, yearValue] = firstDate!.split(dateFormatSeparator);
    const isLongYear = yearValue ? getIsLongYear(yearValue) : false;
    const isLongMonth = getIsLongMonth(monthValue);

    if (isLongYear) {
        if (isLongMonth) {
            return DateFormat.LRG;
        } else if (isNumberedMonth) {
            return DateFormat.SM;
        } else {
            return DateFormat.MED;
        }
    } else {
        return DateFormat.XS;
    }
};

const getDateFormatSeparator = (dateFormat: DateFormat) => {
    return dateFormat === DateFormat.LRG || dateFormat === DateFormat.MED ? " " : "/";
};

const getMonthIndex = (monthValue: string, isLongMonth: boolean, isNumberedMonth: boolean): number => {
    if (isLongMonth) return longMonths.indexOf(monthValue);
    if (isNumberedMonth) return numberedMonths.indexOf(monthValue);
    return shortMonths.indexOf(monthValue);
};

const getNewMonthValue = (dateFormat: DateFormat, monthIndex: number): string => {
    switch (dateFormat) {
        case DateFormat.XS:
        case DateFormat.SM:
            return numberedMonths[monthIndex];
        case DateFormat.LRG:
            return longMonths[monthIndex];
        default:
            return shortMonths[monthIndex];
    }
};

const formatDate = (dateValue: string, dateFormat: DateFormat, previousDateFormat: DateFormat) => {
    const previousDateFormatSeparator = getDateFormatSeparator(previousDateFormat);
    const dateFormatSeparator = getDateFormatSeparator(dateFormat);
    const isYearValueOnly = dateValue.length === 4;
    const [monthValue, yearValue] = isYearValueOnly ? [undefined, dateValue] : dateValue.split(previousDateFormatSeparator);
    const yearRequiresUpdate = yearValue && yearValue.length === 4 && (dateFormat === DateFormat.XS);
    const newYearValue = yearRequiresUpdate ? yearValue.slice(-2) : yearValue; // Remove leading 2 characters from year value

    // Dates can have no month set. They can't have a month set without a year however
    if (!monthValue) {
        return newYearValue;
    }

    const isNumberedMonth = dateValue.includes("/");
    const isLongMonth = getIsLongMonth(monthValue);

    const currentMonthIndex = getMonthIndex(monthValue, isLongMonth, isNumberedMonth);
    const newMonthValue = getNewMonthValue(dateFormat, currentMonthIndex);

    const formattedDate = `${newMonthValue}${dateFormatSeparator}${newYearValue}`;

    return formattedDate;
};

// Given a value that used a different or same date format, apply the resume level date format to the value
export const applyResumeLevelDateFormat = (value: string, dateFormat: DateFormat = DateFormat.LRG) => {
    // If the value is the default value, return it
    if (value.includes(DEFAULT_VALUE)) {
        return value;
    }

    const previousDateFormat = detectDateFormat(value);

    // Date format has not changed, so return the value as is
    if (previousDateFormat === dateFormat) {
        return value;
    }

    const [firstDate, secondDate] = getDatesValues(value);

    const hasToDate = !!secondDate;
    const isPresentUsed = firstDate === "Present" || secondDate === "Present";

    let formattedFirstDate: string;
    if (!hasToDate && isPresentUsed) {
        formattedFirstDate = "Present";
    } else {
        formattedFirstDate = formatDate(firstDate!, dateFormat, previousDateFormat);
    }

    let formattedSecondDate = "";
    if (hasToDate) {
        formattedSecondDate = isPresentUsed ? "Present" : formatDate(secondDate, dateFormat, previousDateFormat);
    }

    // Determine the separator
    const datesSeparator = firstDate && secondDate ? " - " : "";

    // Combine the formatted dates
    const formattedDates = `${formattedFirstDate}${datesSeparator}${formattedSecondDate}`;

    return formattedDates;
};