import { AppText } from "@/components/Text/AppText";

interface PreferenceTitleProps {
    title: string;
}

export const PreferenceTitle = ({ title }: PreferenceTitleProps) => {
    return (
        <AppText
            variant="subheadings"
            className="mb-8 text-center text-neutral-900 lg:h-16 lg:px-20"
        >
            {title}
        </AppText>
    );
};
