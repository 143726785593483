import {
    getPageHorizontalMarginStyle,
    getPageVerticalMarginStyle,
    getStaticHeaderSectionPaddingYStyle,
} from "@/components/PdfDocument/utils/getStyles";
import { GLOBAL_STYLE_KEY } from "@/constants/resume";
import { useAppSelector } from "@/hooks/types";
import { getPageHeightByPageNumber } from "@/store/pages/utils";
import { getActiveResumeStyles } from "@/store/resume/selectors";
import { SectionSpacingSize } from "@/types/resume";

export const PageMarginsDebugger = ({ pageNumber }: { pageNumber: number }) => {
    const activeResumeStyle = useAppSelector(getActiveResumeStyles);
    const globalStyles = activeResumeStyle?.[GLOBAL_STYLE_KEY] ?? {};
    const verticalMarginStyle = getPageVerticalMarginStyle(
        pageNumber === 0,
        globalStyles.pageMarginSize,
    );
    const pageHorizontalMargins = getPageHorizontalMarginStyle(
        globalStyles.pageMarginSize,
    );
    const pageOneTopStyle = getStaticHeaderSectionPaddingYStyle(
        globalStyles.sectionSpacingSize ?? SectionSpacingSize.MD,
    );

    const pageMaxHeight = getPageHeightByPageNumber(
        pageNumber,
        activeResumeStyle ?? {},
    );

    return (
        <>
            {/* Top */}
            <div
                className="absolute left-0 right-0 top-0 border-2 border-dashed border-error-500 bg-error-200/30"
                style={{
                    height:
                        pageNumber === 0
                            ? pageOneTopStyle.paddingTop
                            : verticalMarginStyle.paddingTop,
                }}
            >
                <span className="text-primary-500">
                    {pageNumber === 0
                        ? pageOneTopStyle.paddingTop
                        : verticalMarginStyle.paddingTop}
                    px
                </span>
            </div>
            {/* Right */}
            <div
                className="absolute bottom-0 right-0 top-0 border-2 border-dashed border-error-500 bg-error-200/30"
                style={{ width: pageHorizontalMargins.marginRight }}
            />
            {/* Bottom */}
            <div
                className="absolute bottom-0 left-0 right-0 flex items-end justify-end border-2 border-dashed border-error-500 bg-error-200/30"
                style={{ height: verticalMarginStyle.paddingBottom }}
            >
                <span className="text-error-500">
                    {verticalMarginStyle.paddingBottom}px
                </span>
            </div>
            {/* Left */}
            <div
                className="absolute bottom-0 left-0 top-0 flex items-end justify-end border-2 border-dashed border-error-500 bg-error-200/30"
                style={{ width: pageHorizontalMargins.marginLeft }}
            >
                <span className="pb-20 text-error-500">
                    {pageHorizontalMargins.marginLeft}px
                </span>
            </div>
            {/* Page max height */}
            <div
                className="absolute left-0 right-0 border-2 border-dashed border-primary-500 bg-primary-200/30"
                style={{
                    height: pageMaxHeight,
                    top:
                        pageNumber === 0
                            ? pageOneTopStyle.paddingTop
                            : verticalMarginStyle.paddingTop,
                }}
            >
                <span className="text-primary-500">{pageMaxHeight}px</span>
            </div>
        </>
    );
};
