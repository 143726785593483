export const getIsProductionEnv = () => import.meta.env.VITE_NODE_ENV === "production";
export const getIsDevelopmentEnv = () => import.meta.env.VITE_NODE_ENV === "development";
export const getIsStagingEnv = () => import.meta.env.VITE_NODE_ENV === "staging";
export const getIsNonProductionEnv = () => import.meta.env.VITE_NODE_ENV !== "production";
export const getEnv = () => import.meta.env.VITE_NODE_ENV;

export const isMacPlatform = () => {
    if (!navigator) return false;
    if (navigator.userAgentData) {
        // Use the modern API if available
        return navigator.userAgentData.platform.toLowerCase() === 'macos';
    } else {
        // Fallback for browsers that don't support userAgentData
        const userAgent = navigator.userAgent.toLowerCase();
        return userAgent.includes('mac');
    }
};