import {
    DATE_LOCATION_ALIGNMENT_KEY,
    DATE_LOCATION_LAYOUT_KEY,
    DATE_LOCATION_POSITION_KEY,
    DETAILS_POSITION_KEY,
    GLOBAL_STYLE_KEY,
    PROFILE_ALIGNMENT_KEY,
} from "@/constants/resume";
import { getDateLocationLayoutAlignment } from "@/store/sections/utils";
import { ComponentPropsObject, ResumeStyles } from "@/types/resume";
import { useMemo, memo, CSSProperties } from "react";

interface BlockProps {
    style?: string;
    children: React.ReactNode;
    className: string;
    id?: string;
    value?: string;
    stylesConfig: ResumeStyles;
    layoutProps?: ComponentPropsObject;
}

/**
 * Returns a div with applied styles.
 * The layout fields of a section are applied here to allow the user to define
 * what styles should be applied to the containing div.
 * A div MUST have an `id` prop set for the value to be found.
 * i.e. <div id="exampleId" style="color: exampleId;">...</div>
 * In this example, the property set on `{exampleId: blue}` will be applied to the div.
 */
export const Block = ({
    style,
    className,
    children,
    id,
    value,
    stylesConfig,
    layoutProps,
}: BlockProps) => {
    const additionalStyles = useMemo(() => ({}) as CSSProperties, []);
    const globalStyleConfig = stylesConfig?.[GLOBAL_STYLE_KEY] ?? {};

    const cssInObject = useMemo(() => {
        return (style ?? "")
            .split(";")
            .map(cur => cur.split(":"))
            .reduce(
                (acc, val) => {
                    let [key, styleValue] = val;
                    key = key?.trim();
                    styleValue = styleValue?.trim();
                    if (!key || !styleValue) return acc;
                    key = key.replace(/-./g, css => css.toUpperCase()[1]);

                    // Update value based on settings or config.
                    if (styleValue === DATE_LOCATION_POSITION_KEY) {
                        acc[key] =
                            globalStyleConfig?.dateLocationPosition === null
                                ? "column"
                                : globalStyleConfig?.dateLocationPosition;
                    } else if (styleValue === DATE_LOCATION_LAYOUT_KEY) {
                        acc[key] =
                            globalStyleConfig?.dateLocationLayout === null
                                ? "column"
                                : globalStyleConfig?.dateLocationLayout;
                    } else if (styleValue === DATE_LOCATION_ALIGNMENT_KEY) {
                        acc[key] = getDateLocationLayoutAlignment(
                            globalStyleConfig?.dateLocationPosition ?? "",
                            globalStyleConfig?.dateLocationLayout ?? "",
                        );
                    } else if (styleValue === DETAILS_POSITION_KEY) {
                        // If profile alignment is set to center, then details position should have justify-content: center set
                        if (layoutProps?.[PROFILE_ALIGNMENT_KEY] === "center") {
                            additionalStyles.justifyContent = "center";
                        }
                        acc[key] = value;
                    } else if (styleValue === id) {
                        acc[key] = value;
                    } else {
                        acc[key] = styleValue;
                    }

                    return acc;
                },
                {} as Record<string, string>,
            );
    }, [
        style,
        id,
        globalStyleConfig?.dateLocationPosition,
        globalStyleConfig?.dateLocationLayout,
        layoutProps,
        value,
        additionalStyles,
    ]);

    return (
        <div
            className={className}
            style={{ ...cssInObject, ...additionalStyles }}
            id={id}
        >
            {children}
        </div>
    );
};

export const MemoBlock = memo(Block);
