import { useAppDispatch, useAppSelector } from "@/hooks/types";
import { getActiveResume } from "@/store/resume/selectors";
import resumeApi from "@/api/resume";
import { useCallback, useEffect, useState } from "react";
import { setActiveResume } from "@/store/resume/slice";
import { Dropdown } from "@/components/Dropdown/Dropdown";
import { DocumentVisibility, Resume } from "@/types/resume";
import { AppText } from "@/components/Text/AppText";
import { SwitchToggle } from "@/components/SwitchToggle/SwitchToggle";
import { Button } from "@/components/Button/Button";
import { visibilityItems } from "@/constants/adminControls";
import {
    getDebugBuilder,
    getDebugPageMargins,
    getDebugSectionSpacing,
} from "@/store/app/selectors";
import {
    setDebugBuilder,
    setDebugPageMargins,
    setDebugSectionSpacing,
} from "@/store/app/slice";

export const AdminBuilderSettings = ({
    resume,
    afterSave,
}: {
    resume?: Resume;
    afterSave?: () => void;
}) => {
    const activeResume = useAppSelector(getActiveResume);
    const debugBuilder = useAppSelector(getDebugBuilder);
    const debugPageMargins = useAppSelector(getDebugPageMargins);
    const debugSectionSpacing = useAppSelector(getDebugSectionSpacing);
    const dispatch = useAppDispatch();
    const sourceResume = resume ?? activeResume;
    const [visibility, setVisibility] = useState(
        sourceResume?.visibility ?? DocumentVisibility.PRIVATE,
    );
    const [isTemplate, setIsTemplate] = useState(!!sourceResume?.isTemplate);
    const [isSaving, setIsSaving] = useState(false);

    useEffect(() => {
        setVisibility(sourceResume?.visibility ?? DocumentVisibility.PRIVATE);
        setIsTemplate(!!sourceResume?.isTemplate);
    }, [sourceResume]);

    const toggleDebugBuilder = () => {
        dispatch(setDebugBuilder(!debugBuilder));
    };

    const onSaveSettings = useCallback(() => {
        if (!sourceResume) return;
        setIsSaving(true);

        if (activeResume) {
            dispatch(
                setActiveResume({
                    ...sourceResume,
                    visibility,
                    isTemplate,
                }),
            );
        }

        resumeApi
            .saveResume(sourceResume.id, {
                visibility,
                isTemplate,
            })
            .finally(() => {
                setIsSaving(false);
                afterSave?.();
            });
    }, [
        sourceResume,
        dispatch,
        isTemplate,
        visibility,
        afterSave,
        activeResume,
    ]);

    return (
        <div className="flex min-h-64 flex-col justify-between gap-2">
            <div className="flex flex-col gap-2">
                <AppText variant="labelsbuttons">Visibility</AppText>
                <Dropdown
                    items={visibilityItems}
                    activeId={visibility}
                    handleChange={id => setVisibility(id as DocumentVisibility)}
                    title={
                        visibilityItems.find(item => item.id === visibility)
                            ?.title
                    }
                />
                <AppText variant="labelsbuttons">Use as Template?</AppText>
                <SwitchToggle
                    enabled={isTemplate ?? false}
                    onToggle={checked => setIsTemplate(checked)}
                />
                <AppText
                    variant="labelsbuttons"
                    className="mt-2"
                >
                    Usage Count: {sourceResume?.templateUsageCount}
                </AppText>
            </div>
            <Button
                disabled={isSaving}
                onClick={onSaveSettings}
                loading={isSaving}
            >
                Save
            </Button>
            <Button onClick={toggleDebugBuilder}>
                {debugBuilder ? "Hide Debugger" : "Show Debugger"}
            </Button>
            <AppText variant="labelsbuttons">Debug Page Margins</AppText>
            <SwitchToggle
                enabled={debugPageMargins ?? false}
                onToggle={checked => dispatch(setDebugPageMargins(checked))}
            />
            <AppText variant="labelsbuttons">Debug Section Spacing</AppText>
            <SwitchToggle
                enabled={debugSectionSpacing ?? false}
                onToggle={checked => dispatch(setDebugSectionSpacing(checked))}
            />
        </div>
    );
};
