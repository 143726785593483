import clsx from "clsx";
import { Link } from "react-router-dom";
import { UsingReactChildren } from "@/types";
import { buttonStyles } from "./buttonStyles";
import { ReactNode } from "react";
import { AppText } from "@/components/Text/AppText";
import { InlineLoader } from "@/components/Icons/Icons";

export enum ButtonVariant {
    SOLID = "solid",
    OUTLINE = "outline",
    TEXT = "text",
}

export enum ButtonColourStyles {
    SOLID_PRIMARY = "buttonSolidColourPrimary",
    SOLID_RED = "buttonSolidColourRed",
    SOLID_SOFT_INDIGO = "buttonSolidColourSoftIndigo",
    SOLID_GREY = "buttonSolidColourGrey",
    SOLID_WHITE = "buttonSolidColourWhite",
    SOLID_ALERT = "buttonSolidColourAlert",
    OUTLINE_PRIMARY = "buttonOutlineColourPrimary",
    OUTLINE_GREY = "buttonOutlineColourGrey",
    OUTLINE_SOFT_INDIGO = "buttonOutlineColourSoftIndigo",
}

interface ButtonProps extends UsingReactChildren {
    variant?: ButtonVariant;
    color?: ButtonColourStyles;
    className?: string;
    to?: string;
    loading?: boolean;
    onClick?: (e: React.MouseEvent) => void;
    type?: "submit" | "reset" | "button";
    disabled?: boolean;
    leftIcon?: ReactNode;
    rightIcon?: ReactNode;
    target?: "_blank";
    contentClassName?: string;
}

export const Button = ({
    variant = ButtonVariant.SOLID,
    color = ButtonColourStyles.SOLID_PRIMARY,
    className,
    to,
    loading,
    children,
    onClick,
    type = "button",
    disabled,
    leftIcon,
    rightIcon,
    target,
    contentClassName,
}: ButtonProps) => {
    const buttonClassName = clsx(
        buttonStyles[variant],
        buttonStyles[color],
        className,
    );

    return to ? (
        <Link
            to={to}
            className={clsx(buttonClassName, "button-as-link")}
            onClick={onClick}
            target={target}
            rel={target === "_blank" ? "noreferrer" : undefined}
        >
            {leftIcon && <span className="mr-2">{leftIcon}</span>}
            <AppText variant="labelsbuttons">{children}</AppText>
        </Link>
    ) : (
        <button
            onClick={onClick}
            type={type}
            className={clsx(buttonClassName, "button-as-button", "relative")}
            disabled={disabled || loading}
        >
            <span
                className={clsx(
                    "flex items-center justify-center",
                    loading && "opacity-0",
                    contentClassName,
                )}
            >
                {leftIcon && <span className="mr-2">{leftIcon}</span>}
                <AppText variant="labelsbuttons">{children}</AppText>
                {rightIcon && <span className="ml-2">{rightIcon}</span>}
            </span>
            {loading && (
                <span className="absolute inset-0 flex items-center justify-center">
                    <InlineLoader />
                </span>
            )}
        </button>
    );
};
