export const MIN_YEAR = 1950;

export const monthOptions = [
    { id: "00", title: "-" },
    { id: "01", title: "January" },
    { id: "02", title: "February" },
    { id: "03", title: "March" },
    { id: "04", title: "April" },
    { id: "05", title: "May" },
    { id: "06", title: "June" },
    { id: "07", title: "July" },
    { id: "08", title: "August" },
    { id: "09", title: "September" },
    { id: "10", title: "October" },
    { id: "11", title: "November" },
    { id: "12", title: "December" },
];