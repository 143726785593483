import {
    Button,
    ButtonColourStyles,
    ButtonVariant,
} from "@/components/Button/Button";
import { IconButton } from "@/components/Button/IconButton";
import { VerticalDivider } from "@/components/Divider/VerticalDivider";
import { DateLocationPresets } from "@/components/Presets/DateLocation/DateLocationPresets";
import { PresetIconButton } from "@/components/Presets/PresetIconButton";
import { AppText } from "@/components/Text/AppText";
import { EditorContext } from "@/context/EditorContext";
import { useAppDispatch, useAppSelector } from "@/hooks/types";
import { useUpdateTemplate } from "@/hooks/useUpdateTemplate";
import {
    getDatePickerId,
    getShowSideBar,
    getSideBarState,
} from "@/store/app/selectors";
import {
    addNotification,
    setDatePickerId,
    setShowSideBar,
    setSideBarState,
} from "@/store/app/slice";
import { getAssistantTabState } from "@/store/assistant/selectors";
import { setAssistantTabState } from "@/store/assistant/slice";
import { addItemMeasurement, removeItemMeasurement } from "@/store/pages/slice";
import {
    getActiveResumeSections,
    getSectionsByActiveDocumentType,
} from "@/store/resume/selectors";
import { getActiveSection, getActiveItemId } from "@/store/template/selectors";
import { setActiveItemId, setActiveSection } from "@/store/template/slice";
import {
    NotificationMessageType,
    NotificationType,
    SideBarState,
} from "@/types/app";
import { AssistantTabState } from "@/types/assistant";
import { EditorContextType } from "@/types/editor";
import { ComponentPropsObject, SectionItemDetails } from "@/types/resume";
import { getNewEmptyItem, getSectionConfigBySectionId } from "@/utils/section";
import clsx from "clsx";
import {
    Calendar,
    ChevronDown,
    ChevronUp,
    Bot,
    Maximize2Icon,
    Minimize2,
    PlusIcon,
    Settings2,
    Trash2Icon,
} from "lucide-react";
import { useCallback, useContext, useMemo, useState } from "react";

interface ActionsToolbarProps {
    sectionItemId?: string;
    itemIndex?: number;
    isLastItem?: boolean;
}

export const ActionsToolbar = ({
    sectionItemId,
    itemIndex,
    isLastItem,
}: ActionsToolbarProps) => {
    const datePickerId = useAppSelector(getDatePickerId);
    const dispatch = useAppDispatch();
    const { updateTemplateSectionAndSave, removeSection } = useUpdateTemplate();
    const [isAddingItem, setIsAddingItem] = useState<boolean>(false);
    const [isRemovingContent, setIsRemovingContent] = useState<boolean>(false);
    const activeSection = useAppSelector(getActiveSection);
    const activeResumeSections = useAppSelector(getActiveResumeSections);
    const resumeSection = activeSection
        ? activeResumeSections?.[activeSection]
        : undefined;
    const sideBarState = useAppSelector(getSideBarState);
    const sideBarIsOpen = useAppSelector(getShowSideBar);
    const assistantState = useAppSelector(getAssistantTabState);
    const sectionConfigId = resumeSection?.sectionConfigId;
    const activeItemId = useAppSelector(getActiveItemId);
    const sectionConfigs = useAppSelector(getSectionsByActiveDocumentType);
    const sectionConfig = getSectionConfigBySectionId(
        sectionConfigs,
        sectionConfigId,
    );
    const { setActiveEditor } = useContext(EditorContext) as EditorContextType;
    const hasDateField = useMemo(() => {
        if (activeItemId) {
            // This means this is a toolbar for an item, not the section as a whole
            return sectionConfig?.bodyFields?.some(
                field => field.type.toLowerCase() === "date",
            );
        } else {
            return sectionConfig?.headerFields?.some(
                field => field.type.toLowerCase() === "date",
            );
        }
    }, [activeItemId, sectionConfig?.bodyFields, sectionConfig?.headerFields]);

    // const columnLayout = useAppSelector(getActiveResumeColumnLayout);
    // const canChangeWidth = useMemo(
    //     () =>
    //         sectionConfig?.supportedWidths?.find(w => w === "half") &&
    //         columnLayout === ColumnLayout.SINGLE,
    //     [columnLayout, sectionConfig?.supportedWidths],
    // );
    const canChangeWidth = false; // Disabling functionality as not supported yet.
    const [isDeleting, setIsDeleting] = useState<boolean>(false);

    const totalItems = useMemo(
        () => resumeSection?.body?.length ?? 0,
        [resumeSection?.body?.length],
    );

    const handleToggleDatePicker = useCallback(() => {
        if (!datePickerId || (datePickerId && datePickerId !== sectionItemId)) {
            dispatch(setDatePickerId(sectionItemId));
        } else {
            dispatch(setDatePickerId(""));
        }
    }, [sectionItemId, dispatch, datePickerId]);

    const handleAddNewItem = useCallback(() => {
        setIsAddingItem(true);
        const newEmptyItem = getNewEmptyItem(sectionConfig?.bodyFields ?? []);
        const newItemIndex = (itemIndex ?? 0) + 1;

        if (activeResumeSections && activeSection) {
            updateTemplateSectionAndSave(
                activeSection,
                "body",
                newEmptyItem,
                newEmptyItem.__id,
                newItemIndex,
                success => {
                    if (success) {
                        dispatch(setActiveItemId(newEmptyItem.__id));
                        dispatch(
                            addItemMeasurement({
                                sectionId: activeSection,
                                item: newEmptyItem,
                                sectionConfigId: sectionConfigId ?? "",
                                itemIndex: newItemIndex,
                                columnIndex: resumeSection?.columnIndex ?? 0,
                            }),
                        );
                        setIsAddingItem(false);
                    } else {
                        dispatch(
                            addNotification({
                                messageType:
                                    NotificationMessageType.RESUME_ADD_ITEM,
                                title: "Error adding item",
                                desc: "An error occurred while adding the item, please try again.",
                                type: NotificationType.ERROR,
                            }),
                        );
                        setIsAddingItem(false);
                    }
                },
            );
        }
    }, [
        sectionConfig?.bodyFields,
        itemIndex,
        activeResumeSections,
        activeSection,
        updateTemplateSectionAndSave,
        dispatch,
        sectionConfigId,
        resumeSection?.columnIndex,
    ]);

    const removeItem = useCallback(
        (sectionId: string) => {
            if (!activeResumeSections) return;
            setIsRemovingContent(true);
            const body: SectionItemDetails[] = JSON.parse(
                JSON.stringify(activeResumeSections[sectionId].body),
            );
            const itemIndex = body.findIndex(b => b.__id === activeItemId);
            body.splice(itemIndex, 1);
            updateTemplateSectionAndSave(
                sectionId,
                "body",
                body,
                undefined,
                undefined,
                success => {
                    if (success) {
                        dispatch(setActiveItemId(null));
                        dispatch(removeItemMeasurement({ id: activeItemId }));
                        setIsRemovingContent(false);
                    } else {
                        dispatch(
                            addNotification({
                                messageType:
                                    NotificationMessageType.RESUME_REMOVE_ITEM,
                                title: "Error removing item",
                                desc: "An error occurred while removing the item, please try again.",
                                type: NotificationType.ERROR,
                            }),
                        );
                    }
                },
            );
        },
        [
            activeItemId,
            activeResumeSections,
            dispatch,
            updateTemplateSectionAndSave,
        ],
    );

    const onDelete = useCallback(() => {
        if (!activeSection || !activeResumeSections) return;
        dispatch(setActiveItemId(null));
        setIsRemovingContent(true);
        const shouldDeleteSection =
            !activeItemId ||
            !activeResumeSections?.[activeSection].body ||
            activeResumeSections?.[activeSection].body.length === 1;

        if (shouldDeleteSection) {
            removeSection(activeSection, success => {
                if (success) {
                    dispatch(setActiveSection());
                    setIsRemovingContent(false);
                } else {
                    dispatch(
                        addNotification({
                            messageType:
                                NotificationMessageType.RESUME_REMOVE_SECTION,
                            title: "Error removing section",
                            desc: "An error occurred while removing the section, please try again.",
                            type: NotificationType.ERROR,
                        }),
                    );
                }
            });
        } else {
            removeItem(activeSection);
        }
        setActiveEditor();
    }, [
        activeSection,
        activeResumeSections,
        dispatch,
        activeItemId,
        removeSection,
        setActiveEditor,
        removeItem,
    ]);

    const moveItem = useCallback(
        (direction: "up" | "down", itemId: string) => {
            if (
                !activeSection ||
                !activeResumeSections ||
                activeItemId === null
            ) {
                return;
            }

            const body: ComponentPropsObject[] = JSON.parse(
                JSON.stringify(activeResumeSections[activeSection].body),
            );

            const currentIndex = body.findIndex(item => item.__id === itemId);

            if (direction === "up" && currentIndex > 0) {
                const temp = body[currentIndex];
                body[currentIndex] = body[currentIndex - 1];
                body[currentIndex - 1] = temp;
            } else if (
                direction === "down" &&
                currentIndex < (body?.length ?? 0) - 1
            ) {
                const temp = body[currentIndex];
                body[currentIndex] = body[currentIndex + 1];
                body[currentIndex + 1] = temp;
            }

            dispatch(setActiveItemId(itemId));
            updateTemplateSectionAndSave(activeSection, "body", body);
        },
        [
            activeSection,
            dispatch,
            activeResumeSections,
            activeItemId,
            updateTemplateSectionAndSave,
        ],
    );

    const handleResize = useCallback(() => {
        const newWidth =
            resumeSection?.activeWidth === "full" ? "half" : "full";
        updateTemplateSectionAndSave(activeSection!, "activeWidth", newWidth);
    }, [
        activeSection,
        resumeSection?.activeWidth,
        updateTemplateSectionAndSave,
    ]);

    return (
        <div
            id="actions-toolbar"
            className="absolute -top-16 left-1/2 z-10 flex h-[52px] w-max -translate-x-1/2 transform items-center justify-center gap-2 rounded-lg bg-neutral-50 px-2 font-body shadow-tooltip ring-2 ring-neutral-300"
        >
            {isDeleting ? (
                <>
                    <AppText variant="regular">
                        Confirm delete{" "}
                        {activeItemId && totalItems > 1 ? "item" : "section"}?
                    </AppText>
                    <VerticalDivider className="max-h-9" />
                </>
            ) : (
                <>
                    {totalItems > 0 && (
                        <>
                            <Button
                                variant={ButtonVariant.OUTLINE}
                                color={ButtonColourStyles.OUTLINE_PRIMARY}
                                leftIcon={<PlusIcon size={16} />}
                                onClick={handleAddNewItem}
                                loading={isAddingItem}
                                disabled={isAddingItem}
                            >
                                New item
                            </Button>
                            <VerticalDivider className="max-h-9" />
                        </>
                    )}
                    {canChangeWidth && (
                        <>
                            <IconButton
                                variant={ButtonVariant.SOLID}
                                color={ButtonColourStyles.SOLID_WHITE}
                                onClick={handleResize}
                            >
                                {resumeSection?.activeWidth === "full" ? (
                                    <Minimize2
                                        size={16}
                                        className="rotate-45"
                                    />
                                ) : (
                                    <Maximize2Icon
                                        size={16}
                                        className="rotate-45"
                                    />
                                )}
                            </IconButton>
                            <VerticalDivider className="max-h-9" />
                        </>
                    )}
                    {totalItems > 1 && activeItemId && sectionItemId && (
                        <>
                            <IconButton
                                variant={ButtonVariant.SOLID}
                                color={ButtonColourStyles.SOLID_WHITE}
                                disabled={itemIndex === 0}
                                onClick={() => moveItem("up", sectionItemId)}
                            >
                                <ChevronUp size={16} />
                            </IconButton>
                            <IconButton
                                variant={ButtonVariant.SOLID}
                                color={ButtonColourStyles.SOLID_WHITE}
                                disabled={isLastItem}
                                onClick={() => moveItem("down", sectionItemId)}
                            >
                                <ChevronDown size={16} />
                            </IconButton>
                            <VerticalDivider className="max-h-9" />
                        </>
                    )}
                </>
            )}
            {!isDeleting && (
                <>
                    <IconButton
                        onClick={() => {
                            if (
                                sideBarState === SideBarState.EDIT &&
                                sideBarIsOpen
                            ) {
                                dispatch(setShowSideBar(false));
                            } else {
                                dispatch(setSideBarState(SideBarState.EDIT));
                                dispatch(setShowSideBar(true));
                            }
                        }}
                        variant={ButtonVariant.SOLID}
                        color={ButtonColourStyles.SOLID_WHITE}
                        className={clsx(
                            sideBarState === SideBarState.EDIT &&
                                sideBarIsOpen &&
                                "!bg-neutral-200",
                        )}
                    >
                        <Settings2 size={16} />
                    </IconButton>
                    <IconButton
                        onClick={() => {
                            if (
                                sideBarState === SideBarState.ASSISTANT &&
                                assistantState === AssistantTabState.SECTION &&
                                sideBarIsOpen
                            ) {
                                dispatch(setShowSideBar(false));
                            } else {
                                dispatch(
                                    setAssistantTabState(
                                        AssistantTabState.SECTION,
                                    ),
                                );
                                dispatch(
                                    setSideBarState(SideBarState.ASSISTANT),
                                );
                                dispatch(setShowSideBar(true));
                            }
                        }}
                        variant={ButtonVariant.SOLID}
                        color={ButtonColourStyles.SOLID_WHITE}
                        className={clsx(
                            sideBarState === SideBarState.ASSISTANT &&
                                assistantState === AssistantTabState.SECTION &&
                                sideBarIsOpen &&
                                "!bg-neutral-200",
                        )}
                    >
                        <Bot size={16} />
                    </IconButton>
                    {hasDateField && (
                        <>
                            <VerticalDivider className="max-h-9" />
                            <IconButton
                                variant={ButtonVariant.SOLID}
                                color={ButtonColourStyles.SOLID_WHITE}
                                onClick={handleToggleDatePicker}
                                className={clsx(
                                    datePickerId === sectionItemId &&
                                        "!bg-neutral-200",
                                )}
                            >
                                <Calendar size={16} />
                            </IconButton>
                            <PresetIconButton
                                title="Section Presets"
                                anchorTo="top"
                            >
                                <DateLocationPresets />
                            </PresetIconButton>
                        </>
                    )}
                    <VerticalDivider className="max-h-9" />
                </>
            )}
            <IconButton
                onClick={() => {
                    if (isDeleting) {
                        onDelete();
                    } else {
                        setIsDeleting(true);
                    }
                }}
                variant={ButtonVariant.SOLID}
                loading={isRemovingContent}
                disabled={isRemovingContent}
                color={
                    isDeleting
                        ? ButtonColourStyles.SOLID_RED
                        : ButtonColourStyles.SOLID_WHITE
                }
            >
                <Trash2Icon size={16} />
            </IconButton>
        </div>
    );
};
