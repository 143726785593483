import { pdfStyles } from "@/components/PdfDocument/styles/pdfStyles";
import { getPageVerticalMarginStyle } from "@/components/PdfDocument/utils/getStyles";
import { useAppSelector } from "@/hooks/types";
import { getActiveResumeGlobalStyles } from "@/store/resume/selectors";
import clsx from "clsx";
import { forwardRef } from "react";

interface PageContentProps {
    id: string;
    children?: React.ReactNode;
    style?: React.CSSProperties;
    className?: string;
    pageNumber: number;
}

export const PageContent = forwardRef<HTMLDivElement, PageContentProps>(
    ({ id, children, style, className, pageNumber }, ref) => {
        const activeResumeStyle = useAppSelector(getActiveResumeGlobalStyles);
        const pageStyles = getPageVerticalMarginStyle(
            pageNumber === 0,
            activeResumeStyle.pageMarginSize,
        );

        let pageContentStyles = {
            ...pageStyles,
            ...pdfStyles.pageLayout,
            ...pdfStyles.pageGap,
        };

        if (style) {
            pageContentStyles = { ...pageContentStyles, ...style };
        }

        return (
            <div
                id={id}
                ref={ref}
                className={clsx(className, "rounded-lg bg-white")}
                style={pageContentStyles}
            >
                {children}
            </div>
        );
    },
);

PageContent.displayName = "PageContent";
