import assistantApi from "@/api/assistant";
import sectionApi from "@/api/section";
import documentTypeApi from "@/api/documentType";
import { Input } from "@/components/form/Input";
import { Suspense, useCallback, useEffect, useMemo, useState } from "react";
import { Await, useLoaderData, useNavigate, useParams } from "react-router-dom";
import { useAppDispatch } from "@/hooks/types";
import { assistantItemSchema } from "./schema/assistantItem";
import { InputLabel } from "@/components/form/InputLabel";
import {
    AssistantItem,
    DocumentType,
    NotificationMessageType,
    NotificationType,
    Section,
} from "@/types";
import { addNotification } from "@/store/app/slice";
import { LightbulbIcon, Loader, SquareCheckBig } from "lucide-react";
import { Formik } from "formik";
import { AdminNavigationItem } from "@/types/admin";
import { AdminFormContainer } from "@/pages/Admin/AdminFormContainer";
import { AdminFormFooter } from "@/pages/Admin/AdminFormFooter";
import { InputError } from "@/components/form/InputError";
import {
    AssistantItemDocumentType,
    AssistantItemSectionContentType,
    AssistantItemSectionType,
    AssistantItemStatus,
    AssistantItemTypes,
} from "@/types/assistant";
import { Select, SelectItem } from "@/components/form/Select";
import { jobRoles } from "@/pages/Preferences/constants";
import { sortByField } from "@/pages/Admin/utils/sortByField";
import { InlineCodeEditor } from "@/pages/Admin/InlineCodeEditor/InlineCodeEditor";
import { Checkbox } from "@/components/form/Checkbox";
import { DocumentAssistantItem } from "@/components/Sidebar/Assistant/DocumentAssistantItem";
import { AssistantItemBlock } from "@/components/Sidebar/Assistant/AssistantItemBlock";
import { AssistantItemsContainer } from "@/components/Sidebar/Assistant/AssistantItemsContainer";

const assistantItemFormNavItems: AdminNavigationItem[] = [
    { id: "details", label: "Details" },
];

const assistantItemTypeOptions: SelectItem[] = [
    { name: "-- Select Type --", id: "" },
    { name: "Document", id: AssistantItemTypes.DOCUMENT.toString() },
    { name: "Section", id: AssistantItemTypes.SECTION.toString() },
];

const jobRoleTypeOptions: SelectItem[] = [
    { name: "All", id: "all" },
    ...sortByField(jobRoles, "label").map(({ label, value }) => ({
        name: label,
        id: value,
    })),
];

const sectionContentTypeOptions: SelectItem[] = [
    { name: "Default", id: AssistantItemSectionContentType.DEFAULT },
    { name: "Example", id: AssistantItemSectionContentType.EXAMPLE },
];

const statusOptions: SelectItem[] = [
    { name: "Draft", id: AssistantItemStatus.DRAFT },
    { name: "Published", id: AssistantItemStatus.PUBLISHED },
];

export const AssistantItemForm = () => {
    const [docTypes, setDocTypes] = useState<DocumentType[]>([]);
    const [sectionConfigs, setSectionConfigs] = useState<Section[]>([]);

    const { getAdminAssistantItem } = useLoaderData() as {
        getAdminAssistantItem: Promise<AssistantItem>;
    };

    useEffect(() => {
        const getDocTypes = async () => {
            const response = await documentTypeApi.getAllDocumentTypes();
            setDocTypes(response.results);
        };
        const getSectionConfigs = async () => {
            const response = await sectionApi.getAllSections();
            setSectionConfigs(response.results);
        };
        getDocTypes();
        getSectionConfigs();
    }, []);

    return (
        <Suspense fallback={<Loader />}>
            <Await
                resolve={getAdminAssistantItem}
                errorElement={<div>Failed to load assistant item</div>}
            >
                {resolvedValues => (
                    <AssistantItemFormFields
                        assistantItem={resolvedValues}
                        documentTypes={docTypes}
                        sectionConfigs={sectionConfigs}
                    />
                )}
            </Await>
        </Suspense>
    );
};

export const AssistantItemFormFields = ({
    assistantItem,
    documentTypes,
    sectionConfigs,
}: {
    assistantItem?: AssistantItem;
    documentTypes: DocumentType[];
    sectionConfigs: Section[];
}) => {
    const [activeTab, setActiveTab] = useState<string>(
        assistantItemFormNavItems[0].id,
    );
    const [activeType, setActiveType] = useState<string>(
        assistantItem?.type || "",
    );
    const documentTypeOptions = useMemo(() => {
        const options = sortByField(documentTypes, "name").map(
            ({ id, name }) => ({
                id,
                name,
            }),
        );
        options.unshift({ id: "", name: "-- Select Doc Type --" });
        return options;
    }, [documentTypes]);

    const sectionOptions = useMemo(() => {
        const options = sortByField(sectionConfigs, "name").map(
            ({ id, name }) => ({
                id,
                name,
            }),
        );
        options.unshift({ id: "", name: "-- Select Section --" });
        return options;
    }, [sectionConfigs]);

    const initialValues: Partial<AssistantItem> = useMemo(
        () => ({
            name: assistantItem?.name || "",
            type: assistantItem?.type || "",
            jobRoleType: assistantItem?.jobRoleType || jobRoleTypeOptions[0].id,
            properties: assistantItem?.properties || {},
            section: assistantItem?.section || "",
            documentType: assistantItem?.documentType || "",
            status: assistantItem?.status || AssistantItemStatus.DRAFT,
        }),
        [assistantItem],
    );
    const dispatch = useAppDispatch();
    const { assistantItemId: id } = useParams();
    const assistantItemId = id !== "new" ? id : undefined;

    const navigate = useNavigate();

    const handleError = useCallback(
        (err: unknown, title: string, messageType: NotificationMessageType) => {
            let errorMessage;
            if (typeof err === "string") {
                errorMessage = err;
            } else if (err instanceof Error) {
                errorMessage = err.message;
            }

            dispatch(
                addNotification({
                    title,
                    desc: errorMessage,
                    messageType,
                    type: NotificationType.ERROR,
                }),
            );
        },
        [dispatch],
    );

    const handleSubmit = async (assistantItem: Partial<AssistantItem>) => {
        try {
            const formattedItem: Partial<AssistantItem> = {
                ...assistantItem,
            };
            if (assistantItem.type === AssistantItemTypes.DOCUMENT) {
                delete formattedItem.section;
                formattedItem.properties = {
                    ...assistantItem.properties,
                    isExpandable:
                        assistantItem.properties?.isExpandable ?? false,
                };
                delete formattedItem.properties.contentType;
            }
            if (assistantItem.type === AssistantItemTypes.SECTION) {
                delete formattedItem.documentType;
                formattedItem.properties = {
                    ...assistantItem.properties,
                    isExpandable:
                        assistantItem.properties?.isExpandable ||
                        assistantItem.properties?.contentType ===
                            AssistantItemSectionContentType.EXAMPLE,
                };
            }

            await assistantApi
                .saveAssistantItem(formattedItem, assistantItemId)
                .then(() => {
                    if (!assistantItemId) {
                        navigate("/admin/assistant", { replace: true }); // push user back to assistant items list
                    }

                    dispatch(
                        addNotification({
                            title: "Successfully saved!",
                            desc: "Your details are successfully saved!!",
                            messageType:
                                NotificationMessageType.ADMIN_ASSISTANT_ITEM_SAVE,
                            type: NotificationType.SUCCESS,
                        }),
                    );
                });
        } catch (err: unknown) {
            handleError(
                err,
                "Failed to save",
                NotificationMessageType.ADMIN_ASSISTANT_ITEM_SAVE,
            );
        }
    };

    const handleDelete = useCallback(async () => {
        try {
            if (!assistantItemId) {
                navigate("/admin/assistant", { replace: true }); // push user back to assistant items list
                return;
            }
            await assistantApi.deleteAssistantItem(assistantItemId).then(() => {
                navigate("/admin/assistant", { replace: true });
                dispatch(
                    addNotification({
                        title: "Successfully deleted",
                        desc: "Assistant item successfully deleted",
                        messageType:
                            NotificationMessageType.ADMIN_ASSISTANT_ITEM_DELETE,
                        type: NotificationType.SUCCESS,
                    }),
                );
            });
        } catch (e) {
            handleError(
                e,
                "Failed to delete",
                NotificationMessageType.ADMIN_ASSISTANT_ITEM_DELETE,
            );
        }
    }, [assistantItemId, dispatch, navigate, handleError]);

    return (
        <AdminFormContainer
            navigationItems={assistantItemFormNavItems}
            onNavigationItemClick={id => setActiveTab(id)}
            activeItemId={activeTab}
            pageTitle={initialValues?.name || "New Assistant Item"}
        >
            <Formik
                initialValues={initialValues}
                validationSchema={assistantItemSchema}
                validateOnBlur={false}
                validateOnChange={false}
                validateOnMount={false}
                onSubmit={async values => {
                    await handleSubmit(values);
                }}
            >
                {({
                    values,
                    errors,
                    handleChange,
                    submitForm,
                    setFieldValue,
                    setErrors,
                }) => (
                    <>
                        <div className="min-h-[500px] px-8 pb-32">
                            {activeTab === "details" && (
                                <div className="space-y-8">
                                    <div className="grid grid-cols-1 gap-6 border-b-2 border-gray-200 pb-8 md:grid-cols-2 lg:grid-cols-4">
                                        <FormField
                                            label="Name"
                                            required
                                            error={errors.name}
                                        >
                                            <Input
                                                type="text"
                                                name="name"
                                                id="name"
                                                value={values.name}
                                                formikChange={handleChange}
                                            />
                                        </FormField>

                                        <FormField
                                            label="Type"
                                            required
                                            error={errors.type}
                                        >
                                            <Select
                                                value={
                                                    assistantItemTypeOptions.find(
                                                        option =>
                                                            option.id ===
                                                            values.type,
                                                    ) ??
                                                    assistantItemTypeOptions[0]
                                                }
                                                onChange={option => {
                                                    setFieldValue(
                                                        "type",
                                                        option.id,
                                                    );
                                                    setActiveType(option.id);
                                                    setFieldValue(
                                                        "documentType",
                                                        "",
                                                    );
                                                    setFieldValue(
                                                        "section",
                                                        "",
                                                    );
                                                    setFieldValue(
                                                        "properties",
                                                        {},
                                                    );
                                                    setErrors({});
                                                }}
                                                items={assistantItemTypeOptions}
                                            />
                                        </FormField>

                                        <FormField
                                            label="Job Role Type"
                                            required
                                            error={errors.jobRoleType}
                                        >
                                            <Select
                                                value={
                                                    jobRoleTypeOptions.find(
                                                        option =>
                                                            option.id ===
                                                            values.jobRoleType,
                                                    ) ?? jobRoleTypeOptions[0]
                                                }
                                                onChange={option =>
                                                    setFieldValue(
                                                        "jobRoleType",
                                                        option.id,
                                                    )
                                                }
                                                items={jobRoleTypeOptions}
                                            />
                                        </FormField>

                                        <FormField
                                            label="Status"
                                            required
                                            error={errors.status}
                                            description="If the assistant item is published, it will be visible to users"
                                        >
                                            <Select
                                                value={
                                                    statusOptions.find(
                                                        option =>
                                                            option.id ===
                                                            values.status,
                                                    ) ?? statusOptions[0]
                                                }
                                                onChange={option =>
                                                    setFieldValue(
                                                        "status",
                                                        option.id,
                                                    )
                                                }
                                                items={statusOptions}
                                            />
                                        </FormField>
                                    </div>

                                    {!activeType && (
                                        <div className="text-error-500">
                                            Please select a type
                                        </div>
                                    )}

                                    {activeType ===
                                        AssistantItemTypes.DOCUMENT.toString() && (
                                        <DocumentTypeFields
                                            values={values}
                                            errors={errors}
                                            documentTypeOptions={
                                                documentTypeOptions
                                            }
                                            setFieldValue={setFieldValue}
                                            handleChange={handleChange}
                                        />
                                    )}

                                    {activeType ===
                                        AssistantItemTypes.SECTION.toString() && (
                                        <SectionTypeFields
                                            values={values}
                                            errors={errors}
                                            sectionOptions={sectionOptions}
                                            setFieldValue={setFieldValue}
                                        />
                                    )}
                                </div>
                            )}
                            <AdminFormFooter
                                onSave={submitForm}
                                listHref="/admin/assistant"
                                onDelete={handleDelete}
                            />
                        </div>
                    </>
                )}
            </Formik>
        </AdminFormContainer>
    );
};

const FormField = ({
    label,
    required,
    error,
    description,
    children,
    className,
}: {
    label: string;
    required?: boolean;
    error?: string;
    description?: string;
    children: React.ReactNode;
    className?: string;
}) => (
    <div className={className}>
        <InputLabel
            label={label}
            htmlFor={label.toLowerCase()}
            required={required}
            description={description}
        />
        {children}
        <InputError error={error} />
    </div>
);

const DocumentTypeFields = ({
    values,
    errors,
    documentTypeOptions,
    setFieldValue,
    handleChange,
}: {
    values: Partial<AssistantItem>;
    errors: Record<string, string>;
    documentTypeOptions: DocumentType[];
    setFieldValue: (field: string, value: string) => void;
    handleChange: (e: React.ChangeEvent<HTMLInputElement>) => void;
}) => (
    <>
        <div className="flex gap-6 space-y-6">
            <FormField
                label="Document Type"
                required
                error={errors.documentType}
                description="Type of document this section is for. E.g. resume"
                className="w-64"
            >
                <Select
                    value={
                        documentTypeOptions.find(
                            option => option.id === values.documentType,
                        ) ?? { id: "", name: "" }
                    }
                    onChange={option =>
                        setFieldValue("documentType", option.id)
                    }
                    items={documentTypeOptions}
                />
            </FormField>

            <FormField
                className="w-64"
                label="Title"
                required
                error={errors.properties?.title}
                description="Title of section in assistant tab"
            >
                <Input
                    type="text"
                    name="properties.title"
                    id="properties.title"
                    value={values.properties?.title}
                    formikChange={handleChange}
                />
            </FormField>

            <FormField
                label="Is Expandable?"
                description="If true, content will be shown in a collapsible section. If false, content will be static."
                className="w-80"
            >
                <Checkbox
                    label="Show as expandable"
                    id="properties.isExpandable"
                    checked={!!values.properties?.isExpandable}
                    onChange={(checked: boolean) =>
                        setFieldValue("properties.isExpandable", checked)
                    }
                />
            </FormField>
        </div>
        <div>
            <FormField
                label="Content"
                required
                error={errors.properties?.content}
                description="This is the value that will be displayed in the assistant under the resume tab. Limit formatting to li, p, strong tags"
            >
                <InlineCodeEditor
                    html={values.properties?.content ?? ""}
                    onChange={(html: string) =>
                        setFieldValue("properties.content", html)
                    }
                    language="html"
                    allowTextOnly
                />
            </FormField>
        </div>
        <div className="min-h-32 w-side-bar rounded-lg ring-1 ring-neutral-300">
            <h3 className="mb-2 px-2 text-lg font-semibold">Preview</h3>
            <div className="assistant-sections resume-assistant gap-4 ">
                <DocumentAssistantItem
                    properties={values.properties as AssistantItemDocumentType}
                    defaultOpen={true}
                />
            </div>
        </div>
    </>
);

const SectionTypeFields = ({
    values,
    errors,
    sectionOptions,
    setFieldValue,
}: {
    values: Partial<AssistantItem>;
    errors: Record<string, string>;
    sectionOptions: Section[];
    setFieldValue: (field: string, value: string | boolean) => void;
}) => (
    <>
        <div className="space-y-6">
            <div className="grid grid-cols-4 gap-2">
                <FormField
                    label="Section"
                    required
                    error={errors.section}
                    className="w-64"
                >
                    <Select
                        value={
                            sectionOptions.find(
                                option => option.id === values.section,
                            ) ?? sectionOptions[0]
                        }
                        onChange={option => setFieldValue("section", option.id)}
                        items={sectionOptions}
                    />
                </FormField>
                <FormField
                    label="Content Type"
                    required
                    error={errors.properties?.contentType}
                    className="w-64"
                    description="Type of content this item is related to. It is either a generic piece of advice or a specific example"
                >
                    <Select
                        value={
                            sectionContentTypeOptions.find(
                                option =>
                                    option.id ===
                                    values.properties?.contentType,
                            ) ?? sectionContentTypeOptions[0]
                        }
                        onChange={option => {
                            setFieldValue("properties.contentType", option.id);
                            if (
                                option.id ===
                                AssistantItemSectionContentType.EXAMPLE
                            ) {
                                setFieldValue("properties.isExpandable", true);
                            }
                        }}
                        items={sectionContentTypeOptions}
                    />
                </FormField>
                <FormField
                    label="Title"
                    required={
                        values.properties?.contentType !==
                        AssistantItemSectionContentType.EXAMPLE
                    }
                    error={errors.properties?.title}
                    className="w-64"
                    description="Title of accordion/section"
                >
                    <Input
                        type="text"
                        name="properties.title"
                        id="properties.title"
                        value={values.properties?.title}
                        formikChange={(
                            e: React.ChangeEvent<HTMLInputElement>,
                        ) => setFieldValue("properties.title", e.target.value)}
                    />
                </FormField>
                <FormField
                    label="Is Expandable?"
                    description="If true, content will be shown in a collapsible section. If false, content will be static. Examples are always expandable."
                    className="w-64"
                >
                    <Checkbox
                        label="Show as expandable"
                        id="properties.isExpandable"
                        checked={
                            values.properties?.contentType ===
                                AssistantItemSectionContentType.EXAMPLE ||
                            !!values.properties?.isExpandable
                        }
                        onChange={(checked: boolean) =>
                            setFieldValue("properties.isExpandable", checked)
                        }
                    />
                </FormField>
            </div>

            <FormField
                label="Content"
                required
                error={errors.properties?.content}
                description="This is the value that will be displayed in the assistant under the section tab. Limit formatting to li, p, strong tags"
            >
                <InlineCodeEditor
                    html={values.properties?.content ?? ""}
                    onChange={(html: string) =>
                        setFieldValue("properties.content", html)
                    }
                    language="html"
                    allowTextOnly
                />
            </FormField>
        </div>
        <div className="min-h-32 w-side-bar rounded-lg px-2 pb-2 ring-1 ring-neutral-300">
            <h3 className="mb-2 text-lg font-semibold">Preview</h3>
            <AssistantItemsContainer
                icon={
                    values.type === AssistantItemTypes.SECTION ? (
                        <LightbulbIcon size={16} />
                    ) : (
                        <SquareCheckBig size={16} />
                    )
                }
                title={
                    values.type === AssistantItemTypes.DOCUMENT
                        ? values.properties?.title ?? ""
                        : values.properties?.contentType ===
                            AssistantItemSectionContentType.EXAMPLE
                          ? "Example"
                          : "Advice"
                }
            >
                <AssistantItemBlock
                    canCopy={
                        values.properties?.contentType ===
                        AssistantItemSectionContentType.EXAMPLE
                    }
                    isExpandable={!!values.properties?.isExpandable}
                    content={values.properties?.content ?? ""}
                    title={values.properties?.title ?? ""}
                    isLastItem={true}
                />
            </AssistantItemsContainer>
        </div>
    </>
);
