import { EmailButton } from "@/email/components/EmailButton";
import { EmailHeading } from "@/email/components/EmailHeading";
import { EmailLink } from "@/email/components/EmailLink";
import { EmailText } from "@/email/components/EmailText";
import { EmailWrapper } from "@/email/components/EmailWrapper";
import { Section } from "@react-email/components";

interface ResetPasswordEmailProps {
    resetPasswordLink: string;
    name?: string;
}

const ResetPasswordEmail = ({
    resetPasswordLink,
    name = "User",
}: ResetPasswordEmailProps) => {
    return (
        <EmailWrapper preview="1Template password reset">
            <Section>
                <EmailHeading noMarginBottom>Reset your password</EmailHeading>
                <Section>
                    <EmailButton href={resetPasswordLink}>
                        Reset password
                    </EmailButton>
                    <EmailText>Hi {name},</EmailText>
                </Section>
                <EmailText>
                    We received a request to reset your password.
                </EmailText>
                <EmailText>
                    Please click on the link above to reset your password within
                    the next 2 hours.
                </EmailText>
                <EmailText>
                    If you did not request a password reset, please contact us
                    at{" "}
                    <EmailLink href="mailto:hello@1template.io">
                        hello@1template.io
                    </EmailLink>
                    .
                </EmailText>
            </Section>
        </EmailWrapper>
    );
};

ResetPasswordEmail.PreviewProps = {
    resetPasswordLink: "https://1template.io",
} as ResetPasswordEmailProps;

export default ResetPasswordEmail;
