import documentTypeApi from "@/api/documentType";
import { Input } from "@/components/form/Input";
import { Suspense, useCallback, useMemo, useState } from "react";
import { Await, useLoaderData, useNavigate, useParams } from "react-router-dom";
import { useAppDispatch } from "@/hooks/types";
import { documentTypeSchema } from "./schema/documentType";
import { InputLabel } from "@/components/form/InputLabel";
import {
    DocumentType,
    NotificationMessageType,
    NotificationType,
} from "@/types";
import { addNotification } from "@/store/app/slice";
import { Loader } from "lucide-react";
import { FieldArray, Formik } from "formik";
import { AdminNavigationItem } from "@/types/admin";
import { AdminFormContainer } from "@/pages/Admin/AdminFormContainer";
import { AdminFormFooter } from "@/pages/Admin/AdminFormFooter";
import { AdminFieldContainer } from "@/pages/Admin/AdminFieldContainer";
import { InputError } from "@/components/form/InputError";
import { InlineCodeEditor } from "@/pages/Admin/InlineCodeEditor/InlineCodeEditor";
import { Checkbox } from "@/components/form/Checkbox";
import { Button } from "@/components/Button/Button";

const documentTypeFormNavItems: AdminNavigationItem[] = [
    { id: "details", label: "Details" },
    { id: "assistantSections", label: "Assistant sections" },
];

export const DocumentTypeForm = () => {
    const { getAdminDocumentType } = useLoaderData() as {
        getAdminDocumentType: Promise<DocumentType>;
    };

    return (
        <Suspense fallback={<Loader />}>
            <Await
                resolve={getAdminDocumentType}
                errorElement={<div>Failed to load document type</div>}
            >
                {resolvedValues => (
                    <DocumentTypeFormFields documentType={resolvedValues} />
                )}
            </Await>
        </Suspense>
    );
};

export const DocumentTypeFormFields = ({
    documentType,
}: {
    documentType?: DocumentType;
}) => {
    const [activeTab, setActiveTab] = useState<string>(
        documentTypeFormNavItems[0].id,
    );
    const initialValues: Partial<DocumentType> = useMemo(
        () => ({
            name: documentType?.name || "",
            assistantSections: documentType?.assistantSections || [],
        }),
        [documentType],
    );
    const dispatch = useAppDispatch();
    const { documentTypeId: id } = useParams();
    const documentTypeId = id !== "new" ? id : undefined;

    const navigate = useNavigate();

    const handleError = useCallback(
        (err: unknown, title: string, messageType: NotificationMessageType) => {
            let errorMessage;
            if (typeof err === "string") {
                errorMessage = err;
            } else if (err instanceof Error) {
                errorMessage = err.message;
            }

            dispatch(
                addNotification({
                    title,
                    desc: errorMessage,
                    messageType,
                    type: NotificationType.ERROR,
                }),
            );
        },
        [dispatch],
    );

    const handleSubmit = async (documentType: Partial<DocumentType>) => {
        try {
            await documentTypeApi
                .saveDocumentType(documentType, documentTypeId)
                .then(() => {
                    if (!documentTypeId) {
                        navigate("/admin/document-types", { replace: true }); // push user back to document types list
                    }

                    dispatch(
                        addNotification({
                            title: "Successfully saved!",
                            desc: "Your details are successfully saved!!",
                            messageType:
                                NotificationMessageType.ADMIN_DOCUMENT_TYPE_SAVE,
                            type: NotificationType.SUCCESS,
                        }),
                    );
                });
        } catch (err: unknown) {
            handleError(
                err,
                "Failed to save",
                NotificationMessageType.ADMIN_DOCUMENT_TYPE_SAVE,
            );
        }
    };

    const handleDelete = useCallback(async () => {
        try {
            if (!documentTypeId) {
                navigate("/admin/document-types", { replace: true }); // push user back to doc types list
                return;
            }
            dispatch(
                addNotification({
                    title: "Document Types cannot be delete in the admin",
                    desc: "For safety reasons this functionality has been removed. Please contact a developer to delete the document type.",
                    messageType:
                        NotificationMessageType.ADMIN_DOCUMENT_TYPE_DELETE,
                    type: NotificationType.WARNING,
                }),
            );
        } catch (e) {
            handleError(
                e,
                "Failed to delete",
                NotificationMessageType.ADMIN_DOCUMENT_TYPE_DELETE,
            );
        }
    }, [documentTypeId, dispatch, navigate, handleError]);

    return (
        <AdminFormContainer
            navigationItems={documentTypeFormNavItems}
            onNavigationItemClick={id => setActiveTab(id)}
            activeItemId={activeTab}
            pageTitle={initialValues?.name || "New Document Type"}
        >
            <Formik
                initialValues={initialValues}
                validationSchema={documentTypeSchema}
                validateOnBlur={false}
                validateOnChange={false}
                validateOnMount={false}
                onSubmit={async values => {
                    await handleSubmit(values);
                }}
            >
                {({
                    values,
                    errors,
                    handleChange,
                    submitForm,
                    setFieldValue,
                }) => (
                    <>
                        <div className="px-8">
                            {activeTab === "details" && (
                                <div className="flex max-w-56 flex-col gap-4 bg-white">
                                    <InputLabel
                                        label="Name"
                                        htmlFor="name"
                                        required
                                    />
                                    <Input
                                        type="text"
                                        name="name"
                                        id="name"
                                        value={values.name}
                                        formikChange={handleChange}
                                        error={errors.name}
                                    />
                                </div>
                            )}
                            {activeTab === "assistantSections" && (
                                <FieldArray
                                    name="assistantSections"
                                    render={({ push, remove }) => (
                                        <>
                                            <InputError
                                                error={errors.assistantSections}
                                            />
                                            {values.assistantSections?.map(
                                                (assistantSection, index) => (
                                                    <AdminFieldContainer
                                                        key={`assitant-section-${index}`}
                                                        title={
                                                            assistantSection.title ||
                                                            `Section ${index + 1}`
                                                        }
                                                        onDelete={() =>
                                                            remove(index)
                                                        }
                                                    >
                                                        <div className="relative col-span-2 mb-3 grid grid-cols-6 items-baseline gap-x-4 rounded-lg bg-white px-4">
                                                            <div className="col-span-2 my-2 py-2">
                                                                <InputLabel
                                                                    label="Title"
                                                                    htmlFor={`assistantSections.${index}.title`}
                                                                    required
                                                                    description="Title for section"
                                                                />
                                                                <Input
                                                                    type="text"
                                                                    name={`assistantSections.${index}.title`}
                                                                    id={`assistantSections.${index}.title`}
                                                                    value={
                                                                        assistantSection.title
                                                                    }
                                                                    formikChange={
                                                                        handleChange
                                                                    }
                                                                />
                                                            </div>
                                                            <div className="col-span-2 my-2 py-2">
                                                                <Checkbox
                                                                    id={`assistantSections.${index}.isExpandable`}
                                                                    label="Is Expandable?"
                                                                    description="If true, content will be shown in a collapsible section. If false, content will be static."
                                                                    checked={
                                                                        !!values
                                                                            .assistantSections?.[
                                                                            index
                                                                        ]
                                                                            .isExpandable
                                                                    }
                                                                    onChange={(
                                                                        checked: boolean,
                                                                    ) =>
                                                                        setFieldValue(
                                                                            `assistantSections.${index}.isExpandable`,
                                                                            checked,
                                                                        )
                                                                    }
                                                                />
                                                            </div>
                                                            <div className="col-span-4">
                                                                <InputLabel
                                                                    label="Section content"
                                                                    htmlFor={`assistantSections.${index}.content`}
                                                                    required
                                                                    description="Assitant content to be displayed in assistant tab. Supports HTML. Use 'assistant-item' class to distinguish new items with borders applied."
                                                                />
                                                                <InlineCodeEditor
                                                                    html={
                                                                        values
                                                                            .assistantSections?.[
                                                                            index
                                                                        ]
                                                                            .content ??
                                                                        ""
                                                                    }
                                                                    onChange={(
                                                                        html: string,
                                                                    ) =>
                                                                        setFieldValue(
                                                                            `assistantSections.[${index}].content`,
                                                                            html,
                                                                        )
                                                                    }
                                                                    language="html"
                                                                />
                                                            </div>
                                                        </div>
                                                    </AdminFieldContainer>
                                                ),
                                            )}
                                            <Button
                                                onClick={() =>
                                                    push({
                                                        isExpandable: true,
                                                    })
                                                }
                                            >
                                                Add new section
                                            </Button>
                                        </>
                                    )}
                                />
                            )}
                            <AdminFormFooter
                                onSave={submitForm}
                                listHref="/admin/document-types"
                                onDelete={handleDelete}
                            />
                        </div>
                    </>
                )}
            </Formik>
        </AdminFormContainer>
    );
};
