export const SectionSpacingDebugger = ({
    spacing,
}: {
    spacing: React.CSSProperties;
}) => {
    if (!spacing) return null;
    const paddingLeft = spacing.paddingLeft;
    const paddingRight = spacing.paddingRight;
    const paddingTop = spacing.paddingTop;
    const marginBottom = spacing.marginBottom;

    return (
        <>
            {/* Top */}
            {!!paddingTop && (
                <div
                    className="absolute left-0 right-0 top-0 border-2 border-dashed border-alert-500 bg-alert-200/30"
                    style={{
                        height: paddingTop,
                    }}
                />
            )}
            {/* Right */}
            <div
                className="absolute bottom-0 right-0 top-0 border-2 border-dashed border-alert-500 bg-alert-200/30"
                style={{ width: paddingRight }}
            />
            {/* Bottom */}
            {!!marginBottom && (
                <div
                    className="absolute left-0 right-0 border-2 border-dashed border-alert-500 bg-alert-200/30"
                    style={{
                        height: marginBottom,
                        bottom: `-${marginBottom}px`,
                    }}
                />
            )}
            {/* Left */}
            <div
                className="absolute bottom-0 left-0 top-0 border-2 border-dashed border-alert-500 bg-alert-200/30"
                style={{ width: paddingLeft }}
            />
        </>
    );
};
