import { Google } from "@/components/Icons/Icons";
import { Tokens } from "@/types/token";
import {
    CredentialResponse,
    GoogleLogin,
    useGoogleLogin,
} from "@react-oauth/google";
import userApi from "@/api/user";
import { User } from "@/types";
import { AppText } from "@/components/Text/AppText";
import { UserPlan } from "@/types/billing";

export type SSOLogin = {
    setError: (error: string) => void;
    onSuccess: (user: User, tokens: Tokens, plan: UserPlan) => void;
};

export const SSOLogin = ({ setError, onSuccess }: SSOLogin) => {
    const onOneTapSuccess = async (credentialResponse: CredentialResponse) => {
        const { tokens, user, plan } = await userApi.ssoLoginApi(
            "credential",
            credentialResponse.credential ?? "",
            "google",
        );
        onSuccess(user, tokens, plan);
    };

    const onOneTapError = () => {
        setError("Failed to login with Google");
    };

    const loginWithGoogle = useGoogleLogin({
        flow: "auth-code",
        onSuccess: async ({ code }) => {
            const { tokens, user, plan } = await userApi.ssoLoginApi(
                "code",
                code,
                "google",
            );
            onSuccess(user, tokens, plan);
        },
        onError: e => {
            if (e?.error !== "access_denied") {
                setError("Failed to login with Google");
            }
        },
    });

    return (
        <div>
            <div className="relative my-3">
                <div className="mx-auto w-[90%] border-t border-neutral-900 px-4" />
            </div>
            <div>
                <button
                    onClick={() => {
                        loginWithGoogle();
                    }}
                    className="mx-auto flex h-10 items-center justify-center rounded-lg bg-neutral-50 pl-[2px] pr-3 ring-2 ring-[#35475a33] hover:bg-[#F2F2F2]"
                >
                    <Google className="h-10 w-10" />
                    <AppText variant="labelsbuttons">
                        Continue with Google
                    </AppText>
                </button>
            </div>
            <div className="hidden">
                <GoogleLogin
                    useOneTap
                    onSuccess={onOneTapSuccess}
                    onError={onOneTapError}
                    cancel_on_tap_outside={false}
                />
            </div>
        </div>
    );
};
