import userApi from "@/api/user";
import { Input } from "@/components/form/Input";
import { useMemo, useState } from "react";
import { createSearchParams, useNavigate } from "react-router-dom";
import {
    Button,
    ButtonColourStyles,
    ButtonVariant,
} from "../../components/Button/Button";
import { useAppDispatch, useAppSelector } from "@/hooks/types";
import {
    setAccessToken,
    setRefreshToken,
    setUserDetails,
} from "@/store/user/slice";
import { getErrorMessage } from "@/helper/getErrorMessage";
import { useLocalStorage } from "@/hooks/useLocalStorage";
import { Form, Formik } from "formik";
import { bool, object, ref, string } from "yup";
import { Alert } from "@/components/Alert/Alert";
import { RegisterRequest, User } from "@/types";
import { Tokens } from "@/types/token";
import { sendVerifyEmail } from "@/email/send";
import { getUserPreferenceWizard } from "@/store/wizard/selectors";
import { NoNavPageContainer } from "@/layouts/NoNavPageContainer";
import { SSOLogin } from "@/components/SSO/SSOLogin";
import {
    emailValidation,
    firstNameValidation,
    getNewPasswordSchemaField,
    lastNameValidation,
} from "@/constants/schema";
import { AppText } from "@/components/Text/AppText";
import { PageHelmet } from "@/components/PageHelmet/PageHelmet";
import { AppRoutes, SiteRoutes } from "@/types/routes";
import { InputError } from "@/components/form/InputError";
import { Checkbox } from "@/components/form/Checkbox";
import { MarketingChannels, MarketingSources } from "@/types/user";
import { PasswordValidator } from "@/pages/Signup/PasswordValidator";
import { trackMetaEvent } from "@/utils/tracking";
import { META_EVENT_ACTIONS } from "@/types/tracking";
import { isFeatureEnabled } from "@/utils/featureFlags";
import { useGoogleReCaptcha } from "react-google-recaptcha-v3";

const signUpSchema = object({
    email: emailValidation,
    password: getNewPasswordSchemaField("Password is required"),
    confirmPassword: string()
        .required("Please confirm your password")
        .oneOf([ref("password")], "Entered passwords do not match"),
    firstName: firstNameValidation,
    lastName: lastNameValidation,
    acceptedTerms: bool().oneOf(
        [true],
        "You must accept the terms and conditions",
    ),
    marketingOptIn: bool(),
});

interface SignUpFormFields {
    email: string;
    password: string;
    firstName: string;
    lastName: string;
    confirmPassword: string;
    acceptedTerms: boolean;
    marketingOptIn: boolean;
}

export const SignUpPage = () => {
    const initialValues: SignUpFormFields = useMemo(
        () => ({
            email: "",
            password: "",
            confirmPassword: "",
            firstName: "",
            lastName: "",
            acceptedTerms: false,
            marketingOptIn: false,
        }),
        [],
    );
    const [error, setError] = useState<string | null>("");
    const [, setLocalAccessToken] = useLocalStorage("accessToken", null);
    const [, setLocalRefreshToken] = useLocalStorage("refreshToken", null);
    const dispatch = useAppDispatch();
    const navigate = useNavigate();
    const userPreferencesWizard = useAppSelector(getUserPreferenceWizard);
    const [loading, setLoading] = useState(false);
    const isSSOEnabled = useMemo(
        () => isFeatureEnabled("VITE_FEATURE_GOOGLE_SSO"),
        [],
    );
    const { executeRecaptcha } = useGoogleReCaptcha();

    const onLoginSuccess = (user: User, tokens: Tokens) => {
        if (user.isEmailVerified) {
            dispatch(setAccessToken(tokens.access));
            dispatch(setRefreshToken(tokens.refresh));
            setLocalAccessToken(tokens.access);
            setLocalRefreshToken(tokens.access);
            dispatch(setUserDetails(user));
            const hasAPreferenceSet =
                (user.preferences?.jobTypes ?? []).length > 0 ||
                (user.preferences?.industries ?? []).length > 0 ||
                (user.preferences?.jobRoles ?? []).length > 0 ||
                !!user.preferences?.experienceLevel;
            if (!hasAPreferenceSet) {
                navigate(`/${AppRoutes.Preferences}`);
            } else {
                navigate(AppRoutes.Dashboard);
            }
        }
    };

    const handleSignUp = async (values: SignUpFormFields) => {
        setError("");
        setLoading(true);

        if (!executeRecaptcha) {
            setError(
                "ReCAPTCHA not available. Please refresh the page and try again.",
            );
            setLoading(false);
            return;
        }

        try {
            const recaptchaToken = await executeRecaptcha("signup");

            const data: RegisterRequest = {
                email: values.email,
                password: values.password,
                firstName: values.firstName,
                lastName: values.lastName,
                preferences: userPreferencesWizard,
                acceptedTerms: values.acceptedTerms,
                marketingOptIns: [],
                recaptchaToken,
            };

            if (values.marketingOptIn) {
                data.marketingOptIns = [
                    {
                        channel: MarketingChannels.EMAIL,
                        optedIn: true,
                        timestamp: new Date(),
                        source: MarketingSources.SIGNUP,
                    },
                ];
            }

            await userApi.registerApi(data).then(async res => {
                const { user, tokens, verifyCode } = res;
                if (verifyCode) {
                    sendVerifyEmail(user.email, verifyCode);
                    navigate({
                        pathname: `/${AppRoutes.Verify}`,
                        search: createSearchParams({
                            email: user.email,
                        }).toString(),
                    });
                    return;
                }

                await userApi
                    .loginApi({
                        email: values.email,
                        password: values.password,
                    })
                    .then(() => {
                        onLoginSuccess(user, tokens);
                        trackMetaEvent(
                            META_EVENT_ACTIONS.COMPLETED_REGISTRATION,
                            {
                                content_name: "Signup",
                                status: true,
                            },
                        );
                    });
            });
            setLoading(false);
        } catch (e) {
            if (e instanceof Error && e.message === "Email already taken") {
                setError("An account with this email already exists");
            } else {
                setError(getErrorMessage(e, "Register"));
            }
        }
    };

    return (
        <NoNavPageContainer>
            <PageHelmet
                title="Sign up"
                description="Create Account"
                robotsOverride="noindex, nofollow"
            />
            <AppText
                variant="headings"
                className="mb-8 text-center"
            >
                Create Account
            </AppText>
            <Formik
                initialValues={initialValues}
                onSubmit={handleSignUp}
                validationSchema={signUpSchema}
                validateOnBlur={false}
                validateOnChange={false}
                validateOnMount={false}
            >
                {({ values, errors, handleChange }) => (
                    <Form>
                        <div className="m-auto max-w-[450px]">
                            {error && (
                                <Alert
                                    type="error"
                                    message={error}
                                    className="mb-6"
                                />
                            )}
                            <div className="mb-4 flex w-full gap-4">
                                <Input
                                    type="text"
                                    autoComplete="firstName"
                                    label="First Name"
                                    name="firstName"
                                    formikChange={handleChange}
                                    error={errors.firstName}
                                    value={values.firstName}
                                    containerClassName="w-full"
                                />
                                <Input
                                    type="text"
                                    autoComplete="lastName"
                                    label="Last Name"
                                    name="lastName"
                                    formikChange={handleChange}
                                    error={errors.lastName}
                                    value={values.lastName}
                                    containerClassName="w-full"
                                />
                            </div>
                            <Input
                                type="text"
                                autoComplete="email"
                                label="Email"
                                name="email"
                                formikChange={handleChange}
                                error={errors.email}
                                value={values.email}
                                containerClassName="mb-4"
                            />
                            <Input
                                type="password"
                                autoComplete="password"
                                label="Password"
                                name="password"
                                formikChange={handleChange}
                                error={errors.password}
                                value={values.password}
                                containerClassName="mb-2"
                            />
                            <PasswordValidator password={values.password} />
                            <Input
                                type="password"
                                label="Confirm password"
                                name="confirmPassword"
                                formikChange={handleChange}
                                error={errors.confirmPassword}
                                value={values.confirmPassword}
                                containerClassName="mb-4"
                            />
                            <Checkbox
                                label={
                                    <AppText variant="labelsbuttons">
                                        I agree to{" "}
                                        <a
                                            href={SiteRoutes.Terms}
                                            target="_blank"
                                            rel="noreferrer"
                                            className="text-primary-700"
                                        >
                                            Terms of Service
                                        </a>{" "}
                                        and{" "}
                                        <a
                                            href={SiteRoutes.PrivacyPolicy}
                                            target="blank"
                                            rel="noreferrer"
                                            className="text-primary-700"
                                        >
                                            Privacy Policy
                                        </a>
                                    </AppText>
                                }
                                id={"acceptedTerms"}
                                borderless
                                className="px-0 py-2 lg:py-0"
                                checked={values.acceptedTerms}
                                onChange={checked => {
                                    handleChange({
                                        target: {
                                            name: "acceptedTerms",
                                            value: checked,
                                        },
                                    });
                                }}
                            />
                            <InputError error={errors.acceptedTerms} />
                            <Checkbox
                                label="Sign me up for emails and updates from 1Template"
                                id={"marketingOptIn"}
                                borderless
                                className="px-0 py-2 lg:py-0"
                                checked={values.marketingOptIn}
                                onChange={checked => {
                                    handleChange({
                                        target: {
                                            name: "marketingOptIn",
                                            value: checked,
                                        },
                                    });
                                }}
                            />
                            <Button
                                className="mt-4 w-full"
                                type="submit"
                                disabled={loading}
                                loading={loading}
                            >
                                Create account
                            </Button>
                            <Button
                                to={`/${AppRoutes.Login}`}
                                color={ButtonColourStyles.OUTLINE_PRIMARY}
                                variant={ButtonVariant.TEXT}
                                className="mt-2 w-full"
                            >
                                Already have an account? Sign in
                            </Button>
                        </div>
                    </Form>
                )}
            </Formik>
            {isSSOEnabled && (
                <SSOLogin
                    setError={setError}
                    onSuccess={onLoginSuccess}
                />
            )}
        </NoNavPageContainer>
    );
};
