import { AppText } from "@/components/Text/AppText";
import { Calendar, MapPin } from "lucide-react";

const PresetBlock = ({
    width,
    height = 12,
}: {
    width: number;
    height?: number;
}) => (
    <div
        className="rounded-sm bg-neutral-100"
        style={{
            width: `${width}px`,
            height: `${height}px`,
        }}
    />
);

const PresetContainer = ({ children }: { children: React.ReactNode }) => (
    <div className="h-[80px] w-[80px] overflow-hidden rounded-lg text-neutral-900 ring-2 ring-neutral-300 group-hover:ring-2 group-hover:ring-neutral-200">
        {children}
    </div>
);

export const ProfilePresetCenteredPreview = () => (
    <PresetContainer>
        <div className="flex h-full flex-col items-center justify-center overflow-hidden">
            <AppText variant="contextheading">Name</AppText>
            <div className="flex gap-1">
                <PresetBlock width={32} />
                <PresetBlock width={32} />
                <PresetBlock width={32} />
            </div>
        </div>
    </PresetContainer>
);

export const ProfilePresetLeftRowPreview = () => (
    <PresetContainer>
        <div className="flex h-full flex-col items-start justify-center overflow-hidden pl-2">
            <AppText variant="contextheading">Name</AppText>
            <div className="flex gap-1">
                <PresetBlock width={32} />
                <PresetBlock width={32} />
            </div>
        </div>
    </PresetContainer>
);

export const ProfilePresetLeftColumnPreview = () => (
    <PresetContainer>
        <div className="flex h-full flex-col items-start justify-center overflow-hidden pl-2">
            <AppText variant="contextheading">Name</AppText>
            <div className="flex flex-col gap-1">
                <PresetBlock width={32} />
                <PresetBlock width={65} />
                <PresetBlock width={50} />
            </div>
        </div>
    </PresetContainer>
);

const IconBlock = ({
    width,
    icon,
}: {
    width: number;
    icon: React.ReactNode;
}) => (
    <div className="flex items-center gap-1">
        {icon}
        <PresetBlock
            width={width}
            height={8}
        />
    </div>
);

export const DateLocationPresetLeftColumnPreview = () => (
    <PresetContainer>
        <div className="flex h-full flex-col items-start overflow-hidden pl-2">
            <AppText
                variant="footnote"
                className="mb-1 pt-2 !font-semibold"
            >
                Heading
            </AppText>
            <div className="mb-1 flex flex-col gap-1">
                <IconBlock
                    width={28}
                    icon={<Calendar size={8} />}
                />
                <IconBlock
                    width={28}
                    icon={<MapPin size={8} />}
                />
            </div>

            <div className="flex flex-col gap-1">
                <PresetBlock
                    width={100}
                    height={70}
                />
            </div>
        </div>
    </PresetContainer>
);

export const DateLocationPresetLeftRowPreview = () => (
    <PresetContainer>
        <div className="flex h-full flex-col items-start overflow-hidden pl-2">
            <AppText
                variant="footnote"
                className="mb-1 pt-2 !font-semibold"
            >
                Heading
            </AppText>
            <div className="mb-1 flex gap-1">
                <IconBlock
                    width={28}
                    icon={<Calendar size={8} />}
                />
                <IconBlock
                    width={28}
                    icon={<MapPin size={8} />}
                />
            </div>
            <div className="flex flex-col gap-1">
                <PresetBlock
                    width={100}
                    height={90}
                />
            </div>
        </div>
    </PresetContainer>
);

export const DateLocationPresetRightColumnPreview = () => (
    <PresetContainer>
        <div className="flex h-full flex-col items-end overflow-hidden pr-2">
            <div className="mb-1 flex flex-col gap-1 pt-2.5">
                <IconBlock
                    width={28}
                    icon={<Calendar size={8} />}
                />
                <IconBlock
                    width={28}
                    icon={<MapPin size={8} />}
                />
            </div>
            <div className="flex flex-col gap-1">
                <PresetBlock
                    width={100}
                    height={140}
                />
            </div>
        </div>
    </PresetContainer>
);

export const DateLocationPresetRightRowPreview = () => (
    <PresetContainer>
        <div className="flex h-full flex-col items-end overflow-hidden pr-2">
            <div className="mb-1 flex gap-1 pt-4">
                <IconBlock
                    width={28}
                    icon={<Calendar size={8} />}
                />
                <IconBlock
                    width={28}
                    icon={<MapPin size={8} />}
                />
            </div>
            <div className="flex flex-col gap-1">
                <PresetBlock
                    width={100}
                    height={140}
                />
            </div>
        </div>
    </PresetContainer>
);
