import { AppText } from "@/components/Text/AppText";
import clsx from "clsx";
import { NavLink } from "react-router-dom";

interface NavigationLinkProps {
    to: string;
    children: React.ReactNode;
    target?: string;
    rel?: string;
    className?: string;
}

export const NavigationLink = ({
    to,
    children,
    target,
    rel,
    className,
}: NavigationLinkProps) => (
    <NavLink
        to={to}
        target={target}
        rel={rel}
    >
        <AppText
            variant="regular"
            className={clsx(
                "px-2 !font-medium text-neutral-900 hover:text-neutral-800",
                className,
            )}
        >
            {children}
        </AppText>
    </NavLink>
);
