import { motion, type Variants } from "framer-motion";
import { type ReactNode, useEffect, memo } from "react";
import { useWizard } from "react-use-wizard";

const variants: Variants = {
    enter: (direction: number) => {
        return {
            x: direction > 0 ? 800 : -800,
            opacity: 0,
        };
    },
    center: {
        zIndex: 1,
        x: 0,
        opacity: 1,
    },
    exit: (direction: number) => {
        return {
            zIndex: 0,
            x: direction < 0 ? 800 : -800,
            opacity: 0,
        };
    },
};

interface AnimatedStepProps {
    previousStep: React.MutableRefObject<number>;
    children: ReactNode;
}

const AnimatedStep = memo(
    ({ children, previousStep: previousStepIndex }: AnimatedStepProps) => {
        const { activeStep } = useWizard();

        useEffect(() => {
            return () => {
                previousStepIndex.current = activeStep;
            };
        }, [activeStep, previousStepIndex]);

        return (
            <motion.div
                custom={activeStep - previousStepIndex.current}
                variants={variants}
                initial="enter"
                animate="center"
                exit="exit"
                transition={{
                    type: "tween",
                    stiffness: 50,
                    damping: 10,
                    duration: 0.2,
                }}
            >
                {children}
            </motion.div>
        );
    },
);

export default AnimatedStep;

AnimatedStep.displayName = "AnimatedStep";
