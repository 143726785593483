import { ButtonColourStyles, ButtonVariant } from "@/components/Button/Button";
import { buttonStyles } from "@/components/Button/buttonStyles";
import { InlineLoader } from "@/components/Icons/Icons";
import { UsingReactChildren } from "@/types";
import clsx from "clsx";
import { ChevronUp } from "lucide-react";
import { Link } from "react-router-dom";

const getIconButtonStyles = (size = "9", hasChevron = false) =>
    `!h-${size} !w-${size} ${hasChevron ? "!min-w-12" : "!min-w-9"} !min-h-9 !p-0 outline-none focus:outline-none cursor-pointer`;

interface IconButtonProps extends UsingReactChildren {
    variant?: ButtonVariant;
    color?: ButtonColourStyles;
    className?: string;
    to?: string;
    onClick?: (e?: React.MouseEvent) => void;
    disabled?: boolean;
    size?: string;
    notificationDot?: boolean;
    notificationDotColor?: string;
    loading?: boolean;
    chevronDirection?: "up" | "down";
}

export const IconButton = ({
    children,
    variant = ButtonVariant.SOLID,
    color = ButtonColourStyles.SOLID_PRIMARY,
    className,
    onClick,
    disabled,
    size,
    notificationDot = false,
    notificationDotColor = "bg-error-500",
    to,
    loading = false,
    chevronDirection,
    ...props
}: IconButtonProps) => {
    const buttonClassName = clsx(
        buttonStyles[variant],
        buttonStyles[color],
        className,
        getIconButtonStyles(size, !!chevronDirection),
    );

    const content = (
        <div className="relative flex flex-row items-center gap-[2px]">
            {children}
            {notificationDot && (
                <div
                    className={`absolute -right-[13px] -top-2 h-2 w-2 rounded-full ${notificationDotColor}`}
                />
            )}
            {chevronDirection && (
                <ChevronUp
                    size={12}
                    className={clsx(
                        chevronDirection === "up" ? "rotate-180" : "",
                        "transition-transform duration-100",
                    )}
                />
            )}
        </div>
    );

    return onClick ? (
        <button
            type="button"
            className={clsx(className, buttonClassName)}
            onClick={onClick}
            disabled={disabled}
            {...props}
        >
            {loading ? <InlineLoader /> : content}
        </button>
    ) : to ? (
        <Link
            to={to}
            className={clsx(className, buttonClassName)}
            {...props}
        >
            {loading ? <InlineLoader /> : content}
        </Link>
    ) : (
        <div
            className={clsx(className, buttonClassName)}
            {...props}
        >
            {loading ? <InlineLoader /> : content}
        </div>
    );
};
