import { Button } from "@/components/Button/Button";
import { Input } from "@/components/form/Input";
import { useAppDispatch, useAppSelector } from "@/hooks/types";
import { changePasswordSchema } from "@/pages/Profile/schema/changePassword";
import { Form, Formik } from "formik";
import { useMemo, useState } from "react";
import userApi from "@/api/user";
import { addNotification } from "@/store/app/slice";
import { NotificationMessageType, NotificationType } from "@/types";
import { PasswordValidator } from "@/pages/Signup/PasswordValidator";
import { getUserDetails } from "@/store/user/selectors";

interface ChangePasswordFormFields {
    currentPassword: string;
    newPassword: string;
    confirmPassword: string;
}

export const ChangePassword = () => {
    const userEmail = useAppSelector(getUserDetails)?.email;
    const dispatch = useAppDispatch();
    const initialValues: ChangePasswordFormFields = useMemo(
        () => ({
            currentPassword: "",
            newPassword: "",
            confirmPassword: "",
        }),
        [],
    );
    const [incorrectPassword, setIncorrectPassword] = useState(false);
    const [loading, setLoading] = useState(false);

    const onSubmit = async (
        values: ChangePasswordFormFields,
        resetForm: () => void,
    ) => {
        setLoading(true);
        await userApi
            .changePasswordApi({
                newPassword: values.newPassword,
                oldPassword: values.currentPassword,
            })
            .then(() => {
                dispatch(
                    addNotification({
                        title: "Successfully changed!",
                        desc: "Password has been changed successfully!!",
                        messageType: NotificationMessageType.PASSWORD_CHANGED,
                        type: NotificationType.SUCCESS,
                    }),
                );
                resetForm();
            })
            .catch(error => {
                if (error.message === "Incorrect password") {
                    setIncorrectPassword(true);
                } else {
                    dispatch(
                        addNotification({
                            title: "Error",
                            desc: "Unable to change password. Please try again",
                            messageType:
                                NotificationMessageType.PASSWORD_CHANGED,
                            type: NotificationType.ERROR,
                        }),
                    );
                }
            })
            .finally(() => {
                setLoading(false);
            });
    };

    return (
        <Formik
            initialValues={initialValues}
            validationSchema={changePasswordSchema}
            onSubmit={async (values, { resetForm, setFieldValue }) => {
                setIncorrectPassword(false);
                const resetFields = () => {
                    setFieldValue("currentPassword", "");
                    setFieldValue("oldPassword", "");
                    setFieldValue("confirmPassword", "");
                    resetForm({
                        values: initialValues,
                        errors: {},
                    });
                    setIncorrectPassword(false);
                };

                await onSubmit(values, resetFields);
            }}
            validateOnBlur={false}
            validateOnChange={false}
            validateOnMount={false}
        >
            {({ values, errors, handleChange }) => (
                <Form>
                    <input
                        type="email"
                        name="email"
                        autoComplete="username"
                        defaultValue={userEmail}
                        className="hidden"
                        aria-hidden="true"
                        tabIndex={-1}
                    />
                    <div className="mb-4">
                        <Input
                            label="Current Password"
                            name="currentPassword"
                            type="password"
                            autoComplete="current-password"
                            required
                            value={values.currentPassword}
                            formikChange={handleChange}
                            error={
                                errors.currentPassword || incorrectPassword
                                    ? "Incorrect password"
                                    : undefined
                            }
                        />
                    </div>
                    <div className="mb-4">
                        <Input
                            label="New Password"
                            autoComplete="new-password"
                            name="newPassword"
                            value={values.newPassword}
                            type="password"
                            required
                            formikChange={handleChange}
                            error={errors.newPassword}
                            containerClassName="mb-2"
                        />
                        <PasswordValidator password={values.newPassword} />
                    </div>

                    <div className="mb-4">
                        <Input
                            label="Confirm Password"
                            autoComplete="new-password"
                            name="confirmPassword"
                            value={values.confirmPassword}
                            type="password"
                            required
                            formikChange={handleChange}
                            error={errors.confirmPassword}
                        />
                    </div>

                    <div className="text-right">
                        <Button
                            type="submit"
                            disabled={loading}
                            loading={loading}
                        >
                            Confirm Password Change
                        </Button>
                    </div>
                </Form>
            )}
        </Formik>
    );
};
