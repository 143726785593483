import { AssistantSectionItem, AssistantDocumentItem } from '@/types';
import { AssistantItem, AssistantItemTypes, AssistantTabState } from '@/types/assistant';
import { createSlice, PayloadAction } from '@reduxjs/toolkit';

interface AssistantState {
  sectionItems: AssistantSectionItem[];
  documentItems: AssistantDocumentItem[];
  activeTab: AssistantTabState;
  activeJobRole: string;
}

const initialState: AssistantState = {
  activeTab: AssistantTabState.DOCUMENT,
  sectionItems: [],
  documentItems: [],
  activeJobRole: '',
};

const assistantSlice = createSlice({
  name: 'assistant',
  initialState,
  reducers: {
    setAssistantItems: (state, action: PayloadAction<AssistantItem[]>) => {
      state.sectionItems = action.payload.filter((item) => item.type === AssistantItemTypes.SECTION) as AssistantSectionItem[];
      state.documentItems = action.payload.filter((item) => item.type === AssistantItemTypes.DOCUMENT) as AssistantDocumentItem[];
    },
    setSectionItems: (state, action: PayloadAction<AssistantSectionItem[]>) => {
      state.sectionItems = action.payload;
    },
    setDocumentItems: (state, action: PayloadAction<AssistantDocumentItem[]>) => {
      state.documentItems = action.payload;
    },
    setAssistantTabState: (state, { payload }: PayloadAction<AssistantTabState>) => {
      return {
        ...state,
        activeTab: payload,
      };
    },
    setActiveJobRole: (state, { payload }: PayloadAction<string>) => {
      return {
        ...state,
        activeJobRole: payload,
      };
    },
  },
});


export const { setAssistantItems, setSectionItems, setDocumentItems, setAssistantTabState, setActiveJobRole } = assistantSlice.actions;
export const { reducer: assistantReducer } = assistantSlice;
