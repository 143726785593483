import React from "react";
import { useOutlet } from "react-router-dom";
import { Navigation } from "@/components/Navigation/Navigation";
import { PageHelmet } from "@/components/PageHelmet/PageHelmet";

export const AdminDashboardLayout = () => {
    const outlet = useOutlet();

    return (
        <React.Fragment>
            <PageHelmet
                title="Admin"
                robotsOverride="noindex, nofollow"
            />
            <div className="relative bg-neutral-50">
                <Navigation />
                <div className="mt-10 min-h-[calc(100vh-100px)] bg-white pb-8">
                    {outlet}
                </div>
            </div>
        </React.Fragment>
    );
};
