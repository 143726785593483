import { getActiveResumeSections, getSectionsByActiveDocumentType } from "@/store/resume/selectors";
import { RootState } from "../store";
import { createSelector } from "@reduxjs/toolkit";
import { SectionDetails } from "@/types/resume";
import { getSectionConfigBySectionId } from "@/utils/section";
import { getAllResumeSectionsWithSectionConfigId } from "@/store/sections/utils";

export const getPagesState = (state: RootState) => state.pages;

export const getMeasurements = createSelector(
    getPagesState,
    app => app.measurements,
);

export const getPages = createSelector(
    getPagesState,
    app => app.pages,
);

export const getFirstPageHasContent = createSelector(
    getPages,
    pages => {
        return pages[0]?.sections.length > 0;
    },
);

export const getResumeMoveableSections = createSelector(
    [getActiveResumeSections, getSectionsByActiveDocumentType],
    (resumeSections, sectionConfigs) => {
        return sectionConfigs.reduce<Record<string, SectionDetails>>((acc, sectionConfig) => {
            if (sectionConfig.isStaticHeader) return acc;

            const allSectionsForConfigId = getAllResumeSectionsWithSectionConfigId(sectionConfig.id, resumeSections);
            acc = { ...acc, ...allSectionsForConfigId };

            return acc;
        }, {});
    },
);

export const getPageHeaderSection = createSelector(
    [getPages, getSectionsByActiveDocumentType, getActiveResumeSections, (_: RootState, pageNumber: number) => pageNumber],
    (pages, sections, activeResumeSections, pageNumber) => {
        const sectionIds = pages.find(page => page.pageNumber === pageNumber)?.sections ?? [];

        const staticSection = sectionIds.find(pageSection => {
            const sectionId = pageSection.sectionId;
            const sectionDetails = activeResumeSections?.[sectionId];
            const sectionConfig = getSectionConfigBySectionId(sections, sectionDetails?.sectionConfigId);
            return sectionConfig?.isStaticHeader;
        });
        if (!staticSection) return null;

        return staticSection;
    },
);

export const getPageSectionsByColumnIndex = createSelector(
    [
        getActiveResumeSections,
        getPages,
        (_: RootState, pageNumber: number, columnIndex: number) => pageNumber,
        (_: RootState, pageNumber: number, columnIndex: number) => columnIndex,
    ],
    (resumeSections, pages, pageNumber, columnIndex) => {
        const page = pages.find(page => page.pageNumber === pageNumber);
        const pageStaticHeaderId = page?.items.find(item => item.type === "header" && item.isStaticHeader)?.section;
        const pageSections = page?.sections ?? [];

        return Object.keys(resumeSections ?? {})
            .filter(sectionId =>
                resumeSections?.[sectionId]?.columnIndex === columnIndex &&
                pageSections.some(ps => ps.sectionId === sectionId) &&
                sectionId !== pageStaticHeaderId
            )
            .sort((a, b) => (resumeSections?.[a]?.positionIndex ?? 0) - (resumeSections?.[b]?.positionIndex ?? 0));
    }
);