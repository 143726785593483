import {
    ChangePasswordRequest,
    LoginAndRegisterResponse,
    LoginRequest,
    RegisterRequest,
    User,
    ChangeEmailRequest,
    ApiPaginationRequest,
    ApiPaginationResponse
} from "@/types";
import { apiRequest } from "./request";
import { Tokens } from "@/types/token";
import { ChangeNamesRequest, UpdatedOptInsRequest, VerifyChangeEmailRequest } from "@/types/user";

const loginApi = (data: LoginRequest) =>
    apiRequest<LoginAndRegisterResponse>("/auth/login", "POST", data);

const ssoLoginApi = (type: "code" | "credential", value: string, method: "google" | "linkedin") =>
    apiRequest<LoginAndRegisterResponse>("/sso", "POST", { type, value, method });

const registerApi = (data: RegisterRequest) =>
    apiRequest<LoginAndRegisterResponse>("/auth/register", "POST", data);

const getMeApi = () => apiRequest<User>("/users/me", "GET");

const saveMeApi = (data: Partial<User>) =>
    apiRequest<User>("/users/me", "PATCH", data);

const getUsersApi = ({ page = 1, limit = 50, sortBy = "", search = "" }: ApiPaginationRequest) => {
    const requestParams: ApiPaginationRequest = { page, limit, sortBy };
    if (search) {
        requestParams.search = search;
    }

    return apiRequest<ApiPaginationResponse<User[]>>(
        "/users",
        "GET",
        requestParams,
    );
};

const getUserApi = (id: string) => apiRequest<User>(`/users/${id}`, "GET");

const changePasswordApi = (data: ChangePasswordRequest) =>
    apiRequest("/auth/change-password", "POST", data);

const resetPasswordApi = (data: { token: string, password: string; }) =>
    apiRequest<{ email: string, name: string; }>(`/auth/reset-password`, "POST", data);

const forgotPasswordApi = (data: { email: string; }) =>
    apiRequest<{ token: string, name: string; }>("/auth/forgot-password", "POST", data);

const changeEmailApi = (data: ChangeEmailRequest) =>
    apiRequest<User>("/auth/change-email", "POST", data);

const verifyChangeEmailApi = (data: VerifyChangeEmailRequest) =>
    apiRequest<{ code: number; }>("/auth/verify-change-email", "POST", data);

const changeNamesApi = (data: ChangeNamesRequest) =>
    apiRequest<User>("/auth/change-names", "POST", data);

const updateMarketingOptInds = (data: UpdatedOptInsRequest) => apiRequest<User>("/auth/marketing-opt-ins", "PATCH", data);

const saveUserApi = (data: Partial<User>, id?: string) =>
    id
        ? apiRequest<User>(`/users/${id}`, "PATCH", data)
        : apiRequest<User>("/users", "POST", data);

const deleteUserApi = (id: string) => apiRequest(`/users/${id}`, "DELETE");

const verifyEmailApi = (code: string, email: string) => apiRequest<{ user: User, tokens: Tokens; }>(`/auth/verify`, "POST", { code, email });

const getVerifyCode = (email: string) => apiRequest<{ code: string; }>(`/auth/get-code`, "POST", { email });

const deleteRequestApi = (id: string) => apiRequest<{ user: User; }>("/users/delete-request", "POST", { id });

export default {
    loginApi,
    registerApi,
    getMeApi,
    saveMeApi,
    changePasswordApi,
    changeEmailApi,
    getUsersApi,
    getUserApi,
    deleteUserApi,
    saveUserApi,
    ssoLoginApi,
    forgotPasswordApi,
    resetPasswordApi,
    verifyEmailApi,
    getVerifyCode,
    changeNamesApi,
    verifyChangeEmailApi,
    updateMarketingOptInds,
    deleteRequestApi
};
