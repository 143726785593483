import { Button } from "@/components/Button/Button";
import { Logo } from "@/components/Icons";
import { PageHelmet } from "@/components/PageHelmet/PageHelmet";
import { AppText } from "@/components/Text/AppText";
import { useDevice } from "@/hooks/useDevice";
import { DeviceContextType } from "@/types/device";
import { AppRoutes } from "@/types/routes";
import { MonitorSmartphone } from "lucide-react";
import { useCallback, useEffect, useRef, useState } from "react";

export const DesktopOnly = () => {
    const { isDesktop } = useDevice() as DeviceContextType;
    const timeoutRef = useRef<NodeJS.Timeout | null>(null);
    const [showScreen, setShowScreen] = useState(!isDesktop);

    const goToHome = useCallback(() => {
        window.location.pathname = AppRoutes.Dashboard;
    }, []);

    const triggerFunction = () => {
        setShowScreen(false);
    };

    useEffect(() => {
        if (!isDesktop) {
            setShowScreen(true);
        } else {
            setShowScreen(false);
        }
    }, [isDesktop]);

    const startTimer = useCallback(() => {
        timeoutRef.current = setTimeout(() => {
            triggerFunction();
        }, 3000);
    }, []);

    const clearTimer = useCallback(() => {
        if (!timeoutRef.current) return;
        clearTimeout(timeoutRef.current);
        timeoutRef.current = null;
    }, []);

    const handleMouseDown = useCallback(() => startTimer(), [startTimer]);
    const handleMouseUp = useCallback(() => clearTimer(), [clearTimer]);
    const handleMouseLeave = useCallback(() => clearTimer(), [clearTimer]);
    const handleTouchStart = useCallback(() => startTimer(), [startTimer]);
    const handleTouchEnd = useCallback(() => clearTimer(), [clearTimer]);
    const handleTouchCancel = useCallback(() => clearTimer(), [clearTimer]);

    if (showScreen) {
        return (
            <>
                <PageHelmet
                    title="Desktop Only"
                    robotsOverride="noindex, nofollow"
                />
                <div className="fixed bottom-0 left-0 right-0 top-0 z-[100] bg-neutral-100">
                    <div className="relative z-10 flex h-full flex-col items-center p-6">
                        <span onClick={goToHome}>
                            <Logo className="h-8 w-44 cursor-pointer text-left" />
                        </span>
                        <div className="-mt-20 flex h-full w-4/5 flex-col items-center justify-center">
                            <div className="mt-12 text-center">
                                <MonitorSmartphone
                                    className="mx-auto mb-4"
                                    size={60}
                                />
                                <AppText
                                    variant="headings"
                                    className="mb-4"
                                >
                                    <div
                                        className="inline"
                                        onMouseDown={handleMouseDown}
                                        onMouseUp={handleMouseUp}
                                        onMouseLeave={handleMouseLeave}
                                        onTouchStart={handleTouchStart}
                                        onTouchEnd={handleTouchEnd}
                                        onTouchCancel={handleTouchCancel}
                                    >
                                        1Template
                                    </div>{" "}
                                    builder is currently only available on
                                    desktop.
                                </AppText>
                                <AppText
                                    variant="labelsbuttons"
                                    className="mb-2 block text-neutral-700"
                                >
                                    You can preview and download your resumes on
                                    mobile via the Dashboard, but to get the
                                    most out of editing resumes, visit 1Template
                                    on a desktop device.
                                </AppText>
                                <AppText
                                    variant="labelsbuttons"
                                    className="block text-neutral-700"
                                >
                                    We are working on a mobile version and will
                                    communicate those releases via email and on
                                    socials.
                                </AppText>
                            </div>
                            <Button
                                className="mt-4"
                                onClick={goToHome}
                            >
                                Go to Dashboard
                            </Button>
                        </div>
                    </div>
                </div>
            </>
        );
    }

    return null;
};
