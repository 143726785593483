import { DatePeriodPicker } from "@/components/DatePeriodPicker/DatePeriodPicker";
import { useUpdateTemplate } from "@/hooks/useUpdateTemplate";
import { ResumeStyles } from "@/types/resume";

interface WidgetProps {
    sectionId: string;
    propId: string;
    propType: "header" | "body";
    editable: boolean;
    type: string;
    value: string;
    itemId?: string;
    icon?: string;
    stylesConfig: ResumeStyles;
    placeholder?: string;
}

export const Widget = ({
    sectionId,
    propId,
    propType,
    editable,
    format,
    type,
    value,
    itemId,
    icon,
    stylesConfig,
    placeholder,
}: WidgetProps) => {
    const { updateField } = useUpdateTemplate();

    const handleFieldChange = (newValue: string) => {
        updateField(newValue, propId, propType, sectionId, itemId);
    };

    switch (type.toLowerCase()) {
        case "date":
            return (
                <DatePeriodPicker
                    value={value}
                    onChange={value => {
                        handleFieldChange(value);
                    }}
                    editable={editable}
                    icon={icon}
                    stylesConfig={stylesConfig}
                    sectionId={sectionId}
                    itemId={itemId}
                    placeholder={placeholder}
                />
            );
        default:
            return null;
    }
};
