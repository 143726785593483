import resumeApi from "@/api/resume";
import { Button } from "@/components/Button/Button";
import { useCallback, useContext, useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { ResumePreview } from "./ResumePreview";
import { useAppDispatch, useAppSelector } from "@/hooks/types";
import { getResumesWithDates } from "@/store/resume/selectors";
import { CreateResumeButton } from "./CreateResumeButton/CreateResumeButton";
import { Resume } from "@/types";
import { Dropdown } from "@/components/Dropdown/Dropdown";
import { formatTemplateTimestamps, sortResumes } from "./utils";
import {
    ArrowDownAZ,
    Eye,
    Hash,
    InfoIcon,
    PencilLine,
    PlusIcon,
} from "lucide-react";
import { AppText } from "@/components/Text/AppText";
import { AppRoutes } from "@/types/routes";
import { PageHelmet } from "@/components/PageHelmet/PageHelmet";
import { EditorContext } from "@/context/EditorContext";
import { EditorContextType } from "@/types/editor";
import { useDevice } from "@/hooks/useDevice";
import { DeviceContextType } from "@/types/device";
import { Alert } from "@/components/Alert/Alert";
import { getUserReachedResumeLimit } from "@/store/user/selectors";
import { ModalTypes } from "@/types/modal";
import { openModal } from "@/store/modal/slice";
import { AdminControlPanel } from "@/components/Admin/AdminControlPanel";
import { SwitchToggle } from "@/components/SwitchToggle/SwitchToggle";

const sortOptions = [
    { id: "1", title: "Last modified", icon: <PencilLine size={20} /> },
    { id: "2", title: "Name", icon: <ArrowDownAZ size={20} /> },
];

const adminSortOptions = [
    { id: "1", title: "Last modified", icon: <PencilLine size={20} /> },
    { id: "2", title: "Name", icon: <ArrowDownAZ size={20} /> },
    { id: "3", title: "Usage Count", icon: <Hash size={20} /> },
    { id: "4", title: "Visibility", icon: <Eye size={20} /> },
];

export const Dashboard = () => {
    const resumes = useAppSelector(getResumesWithDates);
    const { destroyEditors } = useContext(EditorContext) as EditorContextType;
    const dispatch = useAppDispatch();
    const [sortBy, setSortBy] = useState(sortOptions[0].id);
    const [sortedResumes, setSortedResumes] = useState<Resume[]>(
        sortResumes(resumes, sortBy),
    );
    const [sortByOptions, setSortByOptions] = useState(sortOptions);
    const [showTemplates, setShowTemplates] = useState(false);
    const [templates, setTemplates] = useState<Resume[]>([]);
    const { isDesktop } = useDevice() as DeviceContextType;
    const reachedResumeLimit = useAppSelector(getUserReachedResumeLimit);

    useEffect(() => {
        if (showTemplates) {
            setTemplates(sortResumes(templates, sortBy));
        } else {
            setSortedResumes(sortResumes(resumes, sortBy));
        }
    }, [resumes, showTemplates, sortBy, templates]);

    const onShowTemplates = useCallback(() => {
        const newState = !showTemplates;
        setShowTemplates(newState);
        if (newState) {
            setSortByOptions(adminSortOptions);
            setSortBy(adminSortOptions[0].id);
        } else {
            setSortByOptions(sortOptions);
            setSortBy(sortOptions[0].id);
        }
    }, [showTemplates]);

    const navigate = useNavigate();

    const handleCreateNewResume = () => {
        if (reachedResumeLimit) {
            dispatch(openModal({ modalType: ModalTypes.PLAN_LIMIT }));
        } else {
            navigate(`/${AppRoutes.NewResume}`);
        }
    };

    const openDeleteResumeModal = useCallback(
        (resumeId: string) => {
            dispatch(
                openModal({
                    modalType: ModalTypes.DELETE_RESUME,
                    props: { resumeId },
                }),
            );
        },
        [dispatch],
    );

    const onFetchTemplates = useCallback(async () => {
        const res = await resumeApi.getAllResume(true);
        const formattedTemplates = formatTemplateTimestamps(res.results);
        // Sort templates by visibility status

        // Then sort by the sortBy variable
        const fullySortedTemplates = sortResumes(formattedTemplates, sortBy);

        setTemplates(fullySortedTemplates);
    }, [sortBy]);

    useEffect(() => {
        // Ensure we kill all editors when navigating between resumes
        destroyEditors();
    }, [destroyEditors]);

    return (
        <>
            <PageHelmet title="Your Resumes" />
            <div className="mx-10 mt-2 block lg:hidden">
                <Alert
                    message={
                        <div className="flex w-full flex-col items-center gap-1">
                            <AppText
                                variant="labelsbuttons"
                                className="text-center"
                            >
                                The mobile version of 1Template allows you to
                                preview and download your resumes.
                            </AppText>
                            <AppText
                                variant="labelsbuttons"
                                className="text-center"
                            >
                                {" "}
                                To create or edit resumes, please use 1Template
                                on a desktop device.
                            </AppText>
                        </div>
                    }
                    type="info"
                    className="mb-4"
                    dismissable
                />
            </div>
            <main className="mt-6 px-8 pb-16 lg:mt-16">
                <div className="mx-0 mb-6 flex w-full max-w-6xl flex-col justify-between px-0 lg:mx-auto lg:mb-12 lg:flex-row lg:px-20 xl:mb-16 xl:w-2/3 xl:px-0">
                    <AppText
                        variant="headings"
                        className="hidden text-neutral-900 md:block"
                    >
                        Your Resumes
                    </AppText>
                    <div className="mt-0 flex flex-col-reverse items-center gap-3 md:mt-6 lg:my-0 lg:flex-row">
                        <Dropdown
                            title={
                                sortByOptions.find(o => o.id === sortBy)?.title
                            }
                            items={sortByOptions}
                            handleChange={id => setSortBy(id)}
                            activeId={sortBy}
                            className="!w-[240px]"
                            leftIcon={
                                sortByOptions.find(o => o.id === sortBy)?.icon
                            }
                        />
                        {isDesktop && (
                            <Button
                                leftIcon={<PlusIcon size={16} />}
                                onClick={handleCreateNewResume}
                            >
                                New Resume
                            </Button>
                        )}
                    </div>
                </div>
                {!isDesktop && sortedResumes.length === 0 && (
                    <Alert
                        icon={<InfoIcon size={20} />}
                        message="You can view your created resumes here. To create or edit resumes, please use 1Template on a desktop device."
                        type="info"
                        className="mb-4"
                    />
                )}
                <ul
                    role="list"
                    className="dashboard-resume-grid mx-auto grid max-w-6xl justify-center gap-x-4 gap-y-12 sm:gap-x-6 xl:gap-x-8"
                >
                    <CreateResumeButton onClick={handleCreateNewResume} />
                    {showTemplates
                        ? templates.map(template => (
                              <ResumePreview
                                  key={template.id}
                                  resume={template}
                                  onDelete={() =>
                                      openDeleteResumeModal(template.id)
                                  }
                              />
                          ))
                        : sortedResumes &&
                          sortedResumes.length > 0 &&
                          sortedResumes.map(resume => (
                              <ResumePreview
                                  key={resume.id}
                                  resume={resume}
                                  onDelete={() =>
                                      openDeleteResumeModal(resume.id)
                                  }
                              />
                          ))}
                </ul>
                <AdminControlPanel topPosition={80}>
                    <div className="flex flex-col gap-2">
                        <div className="mb-2 flex flex-col gap-1 text-xs">
                            <AppText
                                variant="footnote"
                                className="!font-semibold text-primary-500"
                            >
                                Public
                            </AppText>
                            <AppText variant="footnote">
                                Visible to all, listed on examples page
                            </AppText>
                            <AppText
                                variant="footnote"
                                className="!font-semibold text-error-500"
                            >
                                Private
                            </AppText>
                            <AppText variant="footnote">
                                Visible to admins, listed on examples page
                            </AppText>
                            <AppText
                                variant="footnote"
                                className="!font-semibold text-neutral-500"
                            >
                                Unlisted
                            </AppText>
                            <AppText variant="footnote">
                                Visible to admins, not on examples page
                            </AppText>
                        </div>
                        <div className="mb-2 flex items-center gap-2">
                            <AppText variant="labelsbuttons">
                                Show Templates?
                            </AppText>
                            <SwitchToggle
                                enabled={showTemplates}
                                disabled={!templates.length}
                                onToggle={onShowTemplates}
                            />
                        </div>
                        <Button onClick={onFetchTemplates}>Fetch</Button>
                    </div>
                </AdminControlPanel>
            </main>
        </>
    );
};
