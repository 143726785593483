import { AppText } from "@/components/Text/AppText";
import { SectionGridItem } from "@/pages/Admin/Templates/types";

type TemplateSectionItemProps = {
    section: SectionGridItem;
};

export const TemplateSectionItem = ({ section }: TemplateSectionItemProps) => {
    return (
        <div className="mt-2 flex h-16 flex-col items-center justify-center rounded bg-primary-100">
            {section.isStaticHeader && (
                <AppText variant="contextheading">HEADER SECTION</AppText>
            )}
            <AppText variant="regular">{section.name}</AppText>
        </div>
    );
};
