import { sortMeasurementsByColumnAndPositionIndex, getNextAvailablePositionIndex, getEmptyPage } from "@/store/pages/utils";
import { Measurement, Page, ResumeSections, SectionDetails } from "@/types";
import { SectionItemDetails } from "@/types/resume";
import { PayloadAction, createSlice, current } from "@reduxjs/toolkit";

interface PagesState {
    measurements: Measurement[];
    defaultMeasurements: Measurement[];
    pages: Page[];
    ready: boolean;
}

const initialState: PagesState = {
    measurements: [],
    defaultMeasurements: [],
    pages: [
        getEmptyPage(1),
    ],
    ready: false,
};

const pagesSlice = createSlice({
    name: "pages",
    initialState,
    reducers: {
        resetPages: () => initialState,
        setPages: (state, { payload }: PayloadAction<Page[]>) => {
            state.pages = payload;
            return state;
        },
        buildInitialPages: (state) => {
            state.ready = true;
            return state;
        },
        addSectionToPage: (state, { payload }: PayloadAction<{ sectionId: string, section: SectionDetails; }>) => {
            const { section, sectionId } = payload;
            const { sectionConfigId } = section;
            const defaultMeasurementsForSection = state.defaultMeasurements.filter(dm => dm.sectionConfigId === sectionConfigId);

            if (defaultMeasurementsForSection.length === 0) return;

            const sectionHeaderMeasurement = defaultMeasurementsForSection.find(m => m.type === "header");
            const bodyItemMeasurement = defaultMeasurementsForSection.find(m => m.type === "item");

            if (!sectionHeaderMeasurement || !bodyItemMeasurement) return;

            // Update the section header measurement with section details
            const newSectionHeaderMeasurement = {
                ...sectionHeaderMeasurement,
                section: sectionId,
                columnIndex: 0,
                positionIndex: getNextAvailablePositionIndex(state.measurements, 0)
            };

            // Update the body item measurement with section details
            const newBodyItemMeasurements = {
                ...bodyItemMeasurement,
                section: sectionId,
                columnIndex: 0,
                itemId: section.body[0].__id,
                itemIndex: 0
            };

            state.measurements.push(newSectionHeaderMeasurement, newBodyItemMeasurements);
        },
        removeSectionFromPage: (state, { payload }: PayloadAction<{ sectionId: string; }>) => {
            const { measurements } = current(state);

            // Remove all items with the same section id
            const measurementsCopy: Measurement[] = JSON.parse(JSON.stringify(measurements));
            const sectionMeasurements = measurementsCopy.filter(m => m.section !== payload.sectionId);

            state.measurements = sectionMeasurements;

            return state;
        },
        setDefaultSectionHeight: (
            state,
            { payload }: PayloadAction<Measurement>,
        ) => {
            const { defaultMeasurements } = current(state);

            const sectionHeaderMeasurements = defaultMeasurements.filter(m => m.type === "header" && m.section === payload.section);
            const sectionItemMeasurements = defaultMeasurements.filter(m => m.type === "item" && m.section === payload.section);

            if (payload.type === "item") {
                // default sections only have 1 item on load
                if (sectionItemMeasurements.length > 0) {
                    // We already have item measurements, ignore
                    return state;
                }

                // Add new item measurement
                const newMeasurements = [...defaultMeasurements].map(m => ({ ...m }));
                newMeasurements.push(payload);
                state.defaultMeasurements = newMeasurements;
            }

            if (payload.type === "header") {
                if (sectionHeaderMeasurements.length > 0) {
                    // We already have header measurements, ignore
                    return state;
                }

                // Add new header measurement
                const newMeasurements = [...defaultMeasurements].map(m => ({ ...m }));
                newMeasurements.push(payload);
                state.defaultMeasurements = newMeasurements;
            }

            return state;
        },
        setSectionHeight: (state, { payload }: PayloadAction<Measurement>) => {
            const { measurements } = current(state);
            const measurementIndex = measurements.findIndex(m =>
                m.section === payload.section &&
                m.type === payload.type &&
                (payload.type === "header"
                    ? m.sectionConfigId === payload.sectionConfigId
                    : m.itemId === payload.itemId)
            );

            if (measurementIndex === -1) {
                // Add new measurement
                state.measurements = sortMeasurementsByColumnAndPositionIndex([...measurements, payload]);
            } else if (measurements[measurementIndex].height !== payload.height) {
                // Update existing measurement if height has changed
                const newMeasurements = [...measurements];
                newMeasurements[measurementIndex] = { ...newMeasurements[measurementIndex], height: payload.height };
                state.measurements = sortMeasurementsByColumnAndPositionIndex(newMeasurements);
            }

            return state;
        },
        updateMeasurements: (
            state,
            { payload }: PayloadAction<{ sectionId: string; columnIndex: number; }[]>,
        ) => {
            const { measurements } = current(state);
            const newMeasurements: Measurement[] = JSON.parse(JSON.stringify(measurements));

            payload.forEach(p => {
                // Only need to update indexes for header measurements
                const sectionMeasurementIndex = newMeasurements.findIndex(m => m.section === p.sectionId && m.type === "header");

                if (sectionMeasurementIndex === -1) {
                    return;
                } else {
                    newMeasurements[sectionMeasurementIndex] = {
                        ...newMeasurements[sectionMeasurementIndex],
                        columnIndex: p.columnIndex,
                    };
                }
            });

            state.measurements = sortMeasurementsByColumnAndPositionIndex(newMeasurements);

            return state;
        },
        updateMeasurementPositions: (
            state,
            { payload }: PayloadAction<{ updatedMeasurements: ResumeSections, removedMeasurements: string[]; }>,
        ) => {
            const { measurements } = current(state);
            const { updatedMeasurements, removedMeasurements } = payload;
            // Given a resumes sections, update the measurements position and column index
            let newMeasurements: Measurement[] = JSON.parse(JSON.stringify(measurements));

            Object.entries(updatedMeasurements).forEach(([sectionId, sectionDetails]) => {
                const sectionMeasurements = newMeasurements.filter(m => m.section === sectionId);

                sectionMeasurements.forEach(measurement => {
                    const measurementIndex = newMeasurements.findIndex(m =>
                        m.section === measurement.section &&
                        m.type === measurement.type &&
                        (measurement.type === 'header'
                            ? m.sectionConfigId === measurement.sectionConfigId
                            : m.itemId === measurement.itemId)
                    );

                    if (measurementIndex !== -1) {
                        newMeasurements[measurementIndex] = {
                            ...newMeasurements[measurementIndex],
                            positionIndex: sectionDetails.positionIndex,
                            columnIndex: sectionDetails.columnIndex,
                        };
                    }
                });
            });

            removedMeasurements.forEach(id => {
                newMeasurements = newMeasurements.filter(m => m.section !== id);
            });

            state.measurements = newMeasurements;
        },
        removeItemMeasurement: (state, { payload }: PayloadAction<{ id: string; }>) => {
            const { measurements } = current(state);
            const measurementBeingRemoved = measurements.find(m => m.itemId === payload.id);

            if (!measurementBeingRemoved) return state;

            const measurementsCopy: Measurement[] = JSON.parse(JSON.stringify(measurements));

            state.measurements = measurementsCopy.filter(m => m.itemId !== payload.id);;
            return state;
        },
        addItemMeasurement: (state, { payload }: PayloadAction<{ sectionId: string; item: SectionItemDetails; sectionConfigId: string; itemIndex: number; columnIndex: number; }>) => {
            const { measurements, defaultMeasurements } = current(state);
            const defaultMeasurement = defaultMeasurements.find(dm => dm.sectionConfigId === payload.sectionConfigId && dm.type === "item");

            if (!defaultMeasurement) return state;

            const newMeasurement = {
                ...defaultMeasurement,
                itemId: payload.item.__id,
                section: payload.sectionId,
                itemIndex: payload.itemIndex,
                columnIndex: payload.columnIndex
            };

            // An item could be inserted at the same index as another item, if so, we need to update the itemIndex of all items with the same index or greater
            const updatedMeasurements = [...measurements, newMeasurement].map(measurement => {
                const isExistingItem = measurement.section === payload.sectionId
                    && measurement.type === "item"
                    && measurement.itemIndex !== null
                    && measurement.itemIndex >= payload.itemIndex
                    && measurement.itemId !== payload.item.__id;

                if (isExistingItem) {
                    return { ...measurement, itemIndex: measurement.itemIndex! + 1 };
                }

                return measurement;
            });

            const sortedMeasurements = sortMeasurementsByColumnAndPositionIndex(updatedMeasurements);

            state.measurements = sortedMeasurements;
            return state;
        },
    },
});

export const { resetPages, setSectionHeight, addSectionToPage, removeSectionFromPage, buildInitialPages, updateMeasurements, setDefaultSectionHeight, updateMeasurementPositions, setPages, removeItemMeasurement, addItemMeasurement } = pagesSlice.actions;
export const { reducer: pagesReducer } = pagesSlice;
