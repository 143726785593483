import { IconButton } from "@/components/Button/IconButton";
import { Checkbox } from "@/components/form/Checkbox";
import { Input } from "@/components/form/Input";
import { InputLabel } from "@/components/form/InputLabel";
import { useAppDispatch, useAppSelector } from "@/hooks/types";
import {
    PreferencesStep,
    PreferenceTitle,
} from "@/pages/Preferences/components";
import { industries } from "@/pages/Preferences/constants";
import { getUserPreferenceIndustries } from "@/store/wizard/selectors";
import { setWizardUserPreference } from "@/store/wizard/slice";
import { PreferenceOptions } from "@/types";
import { Plus } from "lucide-react";
import { useCallback, useState, useEffect } from "react";

export const PreferencesIndustry = () => {
    const dispatch = useAppDispatch();
    const selectedIndustries = useAppSelector(getUserPreferenceIndustries);
    const [otherChecked, setOtherChecked] = useState(false);
    const [otherIndustry, setOtherIndustry] = useState("");
    const [additionalIndustries, setAdditionalIndustries] =
        useState<PreferenceOptions>([]);
    const [localIndustries, setLocalIndustries] = useState<PreferenceOptions>(
        [],
    );

    useEffect(() => {
        const uniqueIndustries = new Map();
        industries.forEach(industry =>
            uniqueIndustries.set(industry.value, industry),
        );
        selectedIndustries.forEach(industry => {
            if (!uniqueIndustries.has(industry)) {
                uniqueIndustries.set(industry, {
                    label: industry,
                    value: industry,
                });
            }
        });
        setLocalIndustries(Array.from(uniqueIndustries.values()));
    }, [selectedIndustries]);

    const onIndustryClick = useCallback(
        (industry: string, selected: boolean) => {
            let newIndustries = [...selectedIndustries];
            if (selected) {
                newIndustries.push(industry);
            } else {
                newIndustries = newIndustries.filter(
                    selectedIndustry => selectedIndustry !== industry,
                );
            }

            dispatch(
                setWizardUserPreference({
                    preference: "industries",
                    value: newIndustries,
                }),
            );
        },
        [dispatch, selectedIndustries],
    );

    const handleOtherChange = useCallback(
        (checked: boolean) => {
            setOtherChecked(checked);
            if (!checked) {
                setOtherIndustry("");
            }
        },
        [setOtherChecked, setOtherIndustry],
    );

    const handleOtherIndustryChange = useCallback(
        (value: string) => {
            setOtherIndustry(value);
        },
        [setOtherIndustry],
    );

    const persistOtherIndustry = useCallback(() => {
        if (otherIndustry) {
            if (
                !additionalIndustries.some(
                    industry => industry.value === otherIndustry,
                )
            ) {
                setAdditionalIndustries([
                    ...additionalIndustries,
                    { label: otherIndustry, value: otherIndustry },
                ]);
                dispatch(
                    setWizardUserPreference({
                        preference: "industries",
                        value: [...selectedIndustries, otherIndustry],
                    }),
                );
            }
        }
    }, [additionalIndustries, dispatch, otherIndustry, selectedIndustries]);

    return (
        <PreferencesStep>
            <PreferenceTitle title="Which industries are you currently working in or most interested in pursuing?" />
            <div className="h-[200px] overflow-y-scroll bg-gradient-to-b from-transparent from-95% to-black/10 px-2 py-2 pb-4 lg:h-[250px]">
                <div className="grid gap-x-6 gap-y-4 md:grid-cols-3 xl:grid-cols-3">
                    {localIndustries.map(({ label, value }) => (
                        <Checkbox
                            key={value}
                            label={label}
                            id={value}
                            checked={selectedIndustries.includes(value)}
                            onChange={e => onIndustryClick(value, e)}
                        />
                    ))}
                    <Checkbox
                        label="Other"
                        id="other"
                        checked={otherChecked}
                        onChange={e => handleOtherChange(e)}
                    />
                </div>
            </div>
            {otherChecked && (
                <div className="mt-4 w-64">
                    <InputLabel label="Enter your industry" />
                    <div className="mt-2 flex items-center gap-2">
                        <Input
                            type="text"
                            value={otherIndustry}
                            onChange={(_, value) =>
                                handleOtherIndustryChange(value)
                            }
                            className="w-full rounded border px-2 py-1"
                            onBlur={persistOtherIndustry}
                        />
                        <IconButton
                            size="6"
                            className="!h-8 !min-h-8 !w-8 !min-w-8"
                            onClick={persistOtherIndustry}
                            disabled={
                                !otherIndustry ||
                                additionalIndustries.some(
                                    industry =>
                                        industry.value === otherIndustry,
                                )
                            }
                        >
                            <Plus size={16} />
                        </IconButton>
                    </div>
                </div>
            )}
        </PreferencesStep>
    );
};
