import { StyleSheet, View } from "@react-pdf/renderer";
import { PdfTextRenderer } from "@/components/PdfDocument/PdfTextRenderer";
import { ResumeStyles } from "@/types/resume";

interface PdfListRendererProps {
    node: any;
    nodeKey: string | number;
    documentStyles: ResumeStyles;
    textAlign?: "left" | "center" | "right";
    variant?: "ul" | "ol";
}

const listStyles = StyleSheet.create({
    list: {
        display: "flex",
        flexDirection: "column",
        paddingTop: 4,
        paddingBottom: 4,
    },
    listItem: {
        display: "flex",
        flexDirection: "row",
        width: "100%",
        flexWrap: "wrap",
        // Emulate the indentation of the list item.
        marginLeft: 10,
        // Below styles ensure list items do not grow beyond the width of the list.
        paddingRight: 10,
        flexShrink: 0,
    },
});

export const PdfListRenderer = ({
    node,
    nodeKey,
    documentStyles,
    textAlign,
    variant,
}: PdfListRendererProps) => {
    return (
        <View
            style={listStyles.list}
            key={nodeKey}
        >
            {node.children.map((child: any, index: number) => {
                return (
                    <View
                        style={listStyles.listItem}
                        key={`${nodeKey}-${index}`}
                    >
                        <PdfTextRenderer
                            documentStyles={documentStyles}
                            textAlign={textAlign}
                            node={child}
                            nodeKey={`${nodeKey}-${index}-text`}
                            variant="p" // only p tags can use lists
                            prefix={variant === "ul" ? "•" : `${index + 1}.`}
                        />
                    </View>
                );
            })}
        </View>
    );
};
