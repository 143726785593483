import { Button, ButtonVariant } from "@/components/Button/Button";
import { useAppDispatch, useAppSelector } from "@/hooks/types";
import { getUserMarketingOptIn } from "@/store/user/selectors";
import userApi from "@/api/user";
import { addNotification } from "@/store/app/slice";
import { NotificationMessageType, NotificationType } from "@/types";
import { AppText } from "@/components/Text/AppText";
import { MarketingChannels } from "@/types/user";
import { useState } from "react";
import { setUserDetails } from "@/store/user/slice";
import { Checkbox } from "@/components/form/Checkbox";

export const ChangePreferences = () => {
    const marketingOptIns = useAppSelector(getUserMarketingOptIn);
    const dispatch = useAppDispatch();
    const [loading, setLoading] = useState(false);
    const [optInEmails, setOptInEmails] = useState(
        (marketingOptIns || []).some(
            mo => mo.channel === MarketingChannels.EMAIL,
        ),
    );

    const updateOptIns = async () => {
        setLoading(true);
        await userApi
            .updateMarketingOptInds({
                channel: MarketingChannels.EMAIL,
                optedIn: optInEmails,
            })
            .then(user => {
                dispatch(setUserDetails(user));

                dispatch(
                    addNotification({
                        title: "Successfully changed!",
                        desc: "Details have been changed successfully!!",
                        messageType:
                            NotificationMessageType.MARKETING_OPT_IN_CHANGED,
                        type: NotificationType.SUCCESS,
                    }),
                );
            })
            .catch(() => {
                dispatch(
                    addNotification({
                        title: "Error",
                        desc: "Unable to update details. Please try again",
                        messageType:
                            NotificationMessageType.MARKETING_OPT_IN_CHANGED,
                        type: NotificationType.ERROR,
                    }),
                );
            })
            .finally(() => {
                setLoading(false);
            });
    };

    return (
        <div>
            <AppText
                variant="subheadings"
                className="mb-4"
            >
                Email Notifications
            </AppText>
            <Checkbox
                checked={optInEmails}
                onChange={setOptInEmails}
                id={"email-opt-in"}
                label="Updates from 1Template"
                borderless
                className="max-w-58 pl-0"
            />
            <Button
                onClick={updateOptIns}
                className="float-end"
                variant={ButtonVariant.SOLID}
                disabled={loading}
                loading={loading}
            >
                Save
            </Button>
        </div>
    );
};
