import { ResumeSections } from "@/types";
import { ColumnLayout, DateLocationLayout, DateLocationPosition, SectionDetails } from "@/types/resume";

/**
 * Get all sections from a resume that are using the provided section config ID.
 */
export const getAllResumeSectionsWithSectionConfigId = (sectionConfigId: string, resumeSections?: ResumeSections) => {
    return Object.entries(resumeSections ?? {}).reduce<Record<string, SectionDetails>>((acc, [sectionId, sectionDetails]) => {
        if (sectionDetails.sectionConfigId === sectionConfigId) {
            acc[sectionId] = sectionDetails;
        }
        return acc;
    }, {});
};

/**
 * The date location layout setting in the global styles should be ignored when
 * 1. User is using a double column layout
 * AND
 * 2. The section is in the second column
 * The reason it should be ignored is because there is not enough space to display this content in a single row.
 */
export const getDateLocationPositionByColumnLayout = (columnLayout: ColumnLayout, sectionColumnIndex: number, layout: DateLocationPosition) => {
    return columnLayout === ColumnLayout.DOUBLE && sectionColumnIndex === 1 ? "column" : layout;
};

export const getSectionDetailsBySectionConfigId = (resumeSections: ResumeSections, sectionConfigId: string) => {
    return Object.values(resumeSections).find(section => section.sectionConfigId === sectionConfigId);
};

export const getSectionDetailsIdBySectionConfigId = (resumeSections: ResumeSections, sectionConfigId: string) => {
    return Object.keys(resumeSections).find(sectionId => resumeSections[sectionId].sectionConfigId === sectionConfigId);
};

export const getDateLocationLayoutAlignment = (dateLocationPosition?: DateLocationPosition, dateLocationLayout?: DateLocationLayout) => {
    const isSplitPosition = dateLocationPosition === "row" || !dateLocationPosition;
    const isVerticalLayout = dateLocationLayout === "column" || !dateLocationLayout;

    if (isSplitPosition && isVerticalLayout) {
        return "flex-end";
    } else
        return "flex-start";
};

// This style is used to prevent overlapping of content on the PDF when in a split layout
export const getDateLocationContentStyle = (dateLocationPosition: DateLocationPosition) => {
    return dateLocationPosition === "row" ? "flex: 1; min-width: 0;" : "";
};