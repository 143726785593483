import { type ReactNode } from "react";

interface StepContainerProps {
    children: ReactNode;
}

export const StepContainer = ({ children }: StepContainerProps) => (
    <div className="mb-4 flex flex-col items-center justify-center">
        {children}
    </div>
);
