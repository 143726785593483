import { AppText } from "@/components/Text/AppText";

interface PresetsMenuProps {
    title: string;
    children: React.ReactNode;
}

export const PresetsMenu = ({ title, children }: PresetsMenuProps) => (
    <div className="flex flex-col overflow-hidden rounded-lg bg-white text-neutral-900">
        <div className="flex items-center justify-start overflow-hidden rounded-t-lg border-2 border-neutral-300 bg-neutral-100 px-3 py-2">
            <AppText variant="labelsbuttons">{title}</AppText>
        </div>
        <div className="rounded-b-lg border-2 border-t-0 border-neutral-300 p-3">
            {children}
        </div>
    </div>
);
