import { Notification } from "./Notification";
import { useAppSelector } from "@/hooks/types";
import { getNotifications } from "@/store/app/selectors";

export const Notifications = () => {
    const notifications = useAppSelector(getNotifications);
    if (!notifications?.length) return null;
    return (
        <div>
            {notifications.map((notification, index) => (
                <div key={index}>
                    <Notification
                        show={true}
                        notification={notification}
                    />
                </div>
            ))}
        </div>
    );
};
