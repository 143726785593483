import { PdfLink } from "@/components/PdfDocument";
import { pdfStyles } from "@/components/PdfDocument/styles/pdfStyles";
import {
    TextAlignType,
    TextElementType,
} from "@/components/PdfDocument/types/pdfTypes";
import { getTextStyle } from "@/components/PdfDocument/utils/getStyles";
import { GLOBAL_STYLE_KEY } from "@/constants/resume";
import { ResumeStyles } from "@/types/resume";
import { Text, View } from "@react-pdf/renderer";
import { useMemo } from "react";

interface PdfTextRendererProps {
    variant: TextElementType;
    documentStyles: ResumeStyles;
    textAlign?: TextAlignType;
    node: Element;
    prefix?: string;
    nodeKey?: string | number;
    isStaticHeader?: boolean;
}

const headingTags = ["h1", "h2", "h3", "h4", "h5", "h6"];

const renderTextElement = (
    childNode: any,
    childKey: string | number,
    variant: TextElementType,
) => {
    if (childNode.type === "tag") {
        const content = childNode.children.map((child: any, index: number) =>
            renderTextElement(child, `${childKey}-${index}`, variant),
        );

        const isInlineElement = ["strong", "u", "em"].includes(childNode.name);
        if (isInlineElement) {
            return (
                <Text
                    key={childKey}
                    style={
                        childNode.name === "strong"
                            ? variant === "h4"
                                ? pdfStyles.semibold
                                : pdfStyles.bold
                            : childNode.name === "u"
                              ? pdfStyles.underline
                              : pdfStyles.italic
                    }
                >
                    {content}
                </Text>
            );
        }

        switch (childNode.name) {
            case "p":
                return <Text key={childKey}>{content}</Text>;
            case "a":
                return (
                    <PdfLink
                        key={childKey}
                        href={childNode.attribs.href}
                    >
                        {content}
                    </PdfLink>
                );
            default:
                return <Text key={childKey}>{content}</Text>;
        }
    }

    return (
        <Text key={childKey}>{childNode.data?.replace(/\s+/g, " ") || ""}</Text>
    );
};

/**
 * The position of the ordered list prefix in the PDF behaves differently to the browser.
 * On a browser, the number moves left, ensuring the list items are aligned.
 * On a PDF, the number is fixed in position, so we need to move it left to ensure alignment.
 * Ignoring edge case where the number is greater than 99 as that is unlikely and would
 * also make the section item huge. Throwing -10 in the default case as it's a safe bet.
 */
const getOrderListPosition = (fontSize: string, number: number) => {
    if (number < 10) {
        return 0; // Don't need to reposition for numbers less than 10
    }

    switch (fontSize) {
        case "small":
        case "medium":
            return -7;
        case "large":
        case "extra-large":
            return -8;
        default:
            return -10;
    }
};

/**
 * https://github.com/diegomura/react-pdf/issues/164#issuecomment-2236663367
 * In order to support text formatting tags like <strong>, <em>, <u>, etc. we need to render them as individual text elements.
 * This function will recursively render the text elements and apply the appropriate styles.
 * Example structure of output must be:
 * <View style={flexDirection: "row"}> // row to keep the items next to each other
 *  <Text>prefix</Text>
 *  <Text>child1</Text> // this can be a text element or a text element with formatting tags
 *  <Text>child2</Text>
 * </View>
 * */
export const PdfTextRenderer = ({
    variant,
    documentStyles,
    textAlign,
    node,
    prefix,
    nodeKey,
    isStaticHeader,
}: PdfTextRendererProps) => {
    const globalStyles = documentStyles[GLOBAL_STYLE_KEY] ?? {};
    const shouldUnderline = useMemo(
        () =>
            variant === "h2" &&
            !isStaticHeader &&
            globalStyles.underlineSectionHeadings,
        [globalStyles.underlineSectionHeadings, isStaticHeader, variant],
    );
    const isHeading = headingTags.includes(variant);
    const fontSize = isHeading
        ? globalStyles.headingFontSize
        : globalStyles.fontSize;
    const textStyle = getTextStyle(
        fontSize ?? "medium",
        variant,
        isHeading,
        textAlign,
    );

    const wrapperStyle = useMemo(
        () => ({
            borderBottom:
                !isStaticHeader && shouldUnderline ? "1px solid #000" : "",
            display: "flex",
            flexDirection: "row",
            justifyContent: "flex-start",
            flexWrap: "wrap",
            width: prefix ? "100%" : undefined,
        }),
        [isStaticHeader, shouldUnderline, prefix],
    );

    if (textAlign === "center") {
        wrapperStyle.justifyContent = "center";
    } else if (textAlign === "right") {
        wrapperStyle.justifyContent = "flex-end";
    }

    const allTextStyles = useMemo(
        () => ({
            ...wrapperStyle,
            ...textStyle,
        }),
        [textStyle, wrapperStyle],
    );

    let position = 0;
    let prefixStyle = {};

    if (prefix) {
        const isOL = prefix.includes(".");
        const indexValue = isOL ? parseInt(prefix.split(".")[0]) : 0;
        position = getOrderListPosition(
            globalStyles.fontSize ?? "medium",
            indexValue,
        );
        prefixStyle = {
            minWidth: 16,
            flexShrink: 0,
            paddingLeft: prefix && prefix.includes(".") ? 0 : 4,
            marginLeft: position,
        };
    }

    const contentWrapperStyle = prefix
        ? {
              flex: 1,
              display: "flex",
              flexDirection: "row",
              flexWrap: "wrap",
              marginLeft: 2,
          }
        : {};

    return (
        <View
            style={allTextStyles}
            key={`${nodeKey}-text`}
        >
            {prefix && <Text style={prefixStyle}>{prefix}</Text>}
            {prefix ? (
                <View style={contentWrapperStyle}>
                    {node.children.map((child: any, index: number) => {
                        const textContent = renderTextElement(
                            child,
                            `text-${index}`,
                            variant,
                        );
                        return (
                            <Text key={`text-text-${index}`}>
                                {textContent}
                            </Text>
                        );
                    })}
                </View>
            ) : (
                <Text>
                    {node.children.map((child: any, index: number) =>
                        renderTextElement(child, `text-${index}`, variant),
                    )}
                </Text>
            )}
        </View>
    );
};
