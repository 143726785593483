export const isValidHtml = (html: string, allowTextOnly: boolean): { [key: string]: any; } => {
    const mimeType: DOMParserSupportedType = 'application/xml';
    const domParser: DOMParser = new DOMParser();
    const doc: Document = domParser.parseFromString(html, mimeType);
    const parseError: Element | null = doc.documentElement.querySelector('parsererror');
    const result: { [key: string]: any; } = {
        isParseErrorAvailable: parseError !== null,
        isStringValidHtml: false,
        error: ''
    };

    if (allowTextOnly) {
        // Check if the input is just plain text (no HTML tags)
        const isPlainText = !/<[a-z][\s\S]*>/i.test(html);

        if (isPlainText) {
            result.isStringValidHtml = true;
            result.error = html; // No error, just return the text itself
            return result;
        }
    }

    if (parseError !== null && parseError.nodeType === Node.ELEMENT_NODE) {
        result.error = parseError.querySelector('div')?.textContent || '';
    } else {
        result.isStringValidHtml = true;
        result.error = typeof doc.documentElement.textContent === 'string' ? doc.documentElement.textContent : '';
    }

    return result;
};