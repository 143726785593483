import { PageColumn } from "@/components/Grid/PageColumn";
import { getPageHorizontalMarginStyle } from "@/components/PdfDocument/utils/getStyles";
import { useAppSelector } from "@/hooks/types";
import {
    getActiveResumeGlobalStyles,
    getIsTwoColumnLayout,
} from "@/store/resume/selectors";
import { Page } from "@/types";

interface PageGridProps {
    page: Page;
}

export const PageGrid = ({ page }: PageGridProps) => {
    const isTwoColumnLayout = useAppSelector(getIsTwoColumnLayout);
    const activeResumeStyle = useAppSelector(getActiveResumeGlobalStyles);
    const pageHorizontalMargins = getPageHorizontalMarginStyle(
        activeResumeStyle.pageMarginSize,
    );
    return (
        <div
            className="grid h-full grid-cols-2"
            style={{
                ...pageHorizontalMargins,
                gridTemplateColumns: !isTwoColumnLayout ? "1fr" : "1fr 40%",
            }}
        >
            <PageColumn
                pageNumber={page.pageNumber}
                numberOfColumns={isTwoColumnLayout ? 2 : 1}
                columnIndex={0}
                className={isTwoColumnLayout ? "col-span-1" : "col-span-1"}
            />
            {isTwoColumnLayout && (
                <PageColumn
                    pageNumber={page.pageNumber}
                    numberOfColumns={2}
                    columnIndex={1}
                    className="col-span-1"
                />
            )}
        </div>
    );
};
