import { ApiPaginationResponse, Section } from "@/types";
import { apiRequest } from "./request";

const getSection = (id: string) => apiRequest<Section>(`/section/${id}`, "GET");

const getAllSections = () => apiRequest<ApiPaginationResponse<Section[]>>(`/section/`, "GET");

const saveSection = (data: Section, id?: string) =>
    apiRequest<Section>(
        id ? `/section/${id}` : "/section",
        id ? "PATCH" : "POST",
        data,
    );

const deleteSection = (id: string) => apiRequest(`/section/${id}`, "DELETE");

export default {
    saveSection,
    getSection,
    getAllSections,
    deleteSection,
};
