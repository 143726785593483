import clsx from "clsx";

export const AdminPip = ({
    children,
    colour,
    blank,
}: {
    children: React.ReactNode;
    colour: string;
    blank?: boolean;
}) => (
    <span
        className={clsx(
            " text-neutral-900",
            colour,
            blank ? "!bg-transparent" : "rounded-full px-2 py-1",
        )}
    >
        {children}
    </span>
);
