import documentTypeApi from "@/api/documentType";
import { useEffect, useState } from "react";
import { NavLink } from "../../../components/NavLink";
import { DocumentType } from "@/types";
import { AdminListHeader } from "@/pages/Admin/AdminListHeader";

export const DocumentTypes = () => {
    const [docTypes, setDocTypes] = useState<DocumentType[]>([]);

    useEffect(() => {
        const getAllDocumentTypes = async () => {
            const response = await documentTypeApi.getAllDocumentTypes();
            setDocTypes(response.results);
        };
        getAllDocumentTypes();
    }, []);

    return (
        <div className="px-4 sm:px-6 lg:px-8">
            <AdminListHeader
                title="Document Types"
                description="All document types that can be created by the user. A document type can have templates and sections linked to them."
                buttonText="Add Document Type"
                to="new"
            />
            <div className="mt-8 flow-root">
                <div className="-my-2 sm:-mx-6 lg:-mx-8">
                    <div className="inline-block py-2 align-middle">
                        <table className="min-w-full border-separate border-spacing-0">
                            <thead>
                                <tr>
                                    <th
                                        scope="col"
                                        className="sticky top-0 z-10 border-b border-gray-300 bg-white bg-opacity-75 py-3.5 pl-4 pr-3 text-left text-sm font-semibold text-gray-900 backdrop-blur backdrop-filter sm:pl-6 lg:pl-8"
                                    >
                                        id
                                    </th>
                                    <th
                                        scope="col"
                                        className="sticky top-0 z-10 border-b border-gray-300 bg-white bg-opacity-75 py-3.5 pl-4 pr-3 text-left text-sm font-semibold text-gray-900 backdrop-blur backdrop-filter sm:pl-6"
                                    >
                                        Name
                                    </th>
                                </tr>
                            </thead>
                            <tbody>
                                {docTypes &&
                                    docTypes.map(docType => (
                                        <tr key={docType.name}>
                                            <td className="whitespace-nowrap py-4 pl-4 pr-3 text-sm font-medium text-gray-900 ">
                                                <NavLink
                                                    href={`${docType.id}`}
                                                    className="text-primary-600 hover:text-primary-900"
                                                >
                                                    {docType.id}
                                                </NavLink>
                                            </td>
                                            <td className="whitespace-nowrap py-4 pl-4 pr-3 text-sm font-medium text-gray-900">
                                                <NavLink
                                                    href={`${docType.id}`}
                                                    className="text-primary-600 hover:text-primary-900"
                                                >
                                                    {docType.name}
                                                </NavLink>
                                            </td>
                                        </tr>
                                    ))}
                            </tbody>
                        </table>
                    </div>
                </div>
            </div>
        </div>
    );
};
