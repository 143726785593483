import { LoadingPage } from "@/components/LoadingPage/LoadingPage";
import { useAppDispatch } from "@/hooks/types";
import { setUserDetails, resetTokens } from "@/store/user/slice";
import { setWizardUserPreferences } from "@/store/wizard/slice";
import { AppRoutes } from "@/types/routes";
import { useEffect } from "react";
import { useNavigate } from "react-router-dom";

export const LogoutPage = () => {
    const dispatch = useAppDispatch();
    const navigate = useNavigate();

    useEffect(() => {
        dispatch(setUserDetails(null));
        dispatch(setWizardUserPreferences(null));
        dispatch(resetTokens());
        localStorage.removeItem("accessToken");
        localStorage.removeItem("refreshToken");
        navigate(`/${AppRoutes.Login}`, { replace: true });
    }, [dispatch, navigate]);

    return <LoadingPage />;
};
