import {
    AddNewSectionModal,
    HelpGuideModal,
    PlanLimitModal,
    PreviewResumeModal,
    RearrangeModal,
    RenameResumeModal,
    ResumeAdminModal,
    JobRoleModal,
    PreferencesModal,
    DeleteResumeModal,
} from "@/components/Modals";

const ModalManager = () => {
    return (
        <>
            <RearrangeModal />
            <HelpGuideModal />
            <RenameResumeModal />
            <AddNewSectionModal />
            <PlanLimitModal />
            <ResumeAdminModal />
            <PreviewResumeModal />
            <JobRoleModal />
            <PreferencesModal />
            <DeleteResumeModal />
        </>
    );
};

export default ModalManager;
