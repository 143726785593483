interface AdminTableProps {
    children: React.ReactNode;
}

export const AdminTable = ({ children }: AdminTableProps) => {
    return (
        <div className="max-h-[calc(100vh-340px)] overflow-y-auto border border-neutral-300">
            <table className="min-w-full divide-y divide-gray-200 ">
                {children}
            </table>
        </div>
    );
};
