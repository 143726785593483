import { Button } from "@/components/Button/Button";

interface AdminListHeaderProps {
    title: string;
    description: string;
    buttonText: string;
    to: string;
}

export const AdminListHeader = ({
    title,
    description,
    buttonText,
    to,
}: AdminListHeaderProps) => (
    <div className="flex items-center">
        <div className="flex-auto">
            <h1 className="text-base font-semibold leading-6 text-gray-900">
                {title}
            </h1>
            <p className="mt-2 text-sm text-gray-700">{description}</p>
        </div>
        <Button to={to}>{buttonText}</Button>
    </div>
);
