import { RootState } from "@/store/store";
import { createSelector } from "@reduxjs/toolkit";

export const getWizardState = (state: RootState) => state.wizard;

export const getUserPreferenceWizard = createSelector(
    getWizardState,
    wizard => wizard.userPreferences,
);

export const getUserPreferenceIndustries = createSelector(
    getUserPreferenceWizard,
    userPreferences => userPreferences.industries,
);

export const getUserPreferenceJobRoles = createSelector(
    getUserPreferenceWizard,
    userPreferences => userPreferences.jobRoles,
);

export const getUserPreferenceJobTypes = createSelector(
    getUserPreferenceWizard,
    userPreferences => userPreferences.jobTypes,
);

export const getUserPreferenceExperienceLevel = createSelector(
    getUserPreferenceWizard,
    userPreferences => userPreferences.experienceLevel,
);

export const getUserHasSetPreferences = createSelector(
    getUserPreferenceJobTypes,
    getUserPreferenceIndustries,
    getUserPreferenceExperienceLevel,
    getUserPreferenceJobRoles,
    (jobTypes, industries, experienceLevel, jobRoles) =>
        jobTypes.length > 0 ||
        industries.length > 0 ||
        !!experienceLevel ||
        jobRoles?.length > 0,
);