import { Radio } from "@/components/form/Radio";
import { useAppSelector, useAppDispatch } from "@/hooks/types";
import {
    PreferencesStep,
    PreferenceTitle,
} from "@/pages/Preferences/components";
import { experienceLevels } from "@/pages/Preferences/constants";
import { getUserPreferenceExperienceLevel } from "@/store/wizard/selectors";
import { setWizardUserPreference } from "@/store/wizard/slice";
import { useCallback } from "react";

export const PreferencesExperience = () => {
    const selectedExperience = useAppSelector(getUserPreferenceExperienceLevel);
    const dispatch = useAppDispatch();
    const onExperienceClick = useCallback(
        (experience: string, selected: boolean) => {
            dispatch(
                setWizardUserPreference({
                    preference: "experienceLevel",
                    value: selected ? experience : "",
                }),
            );
        },
        [dispatch],
    );

    return (
        <PreferencesStep>
            <PreferenceTitle title="What level of role are you looking for?" />
            <div className="h-[200px] overflow-y-scroll px-2 py-2 pb-4 lg:h-[250px]">
                <div className="grid grid-cols-[225px] gap-x-6 gap-y-4 lg:grid-cols-[300px_300px]">
                    {experienceLevels.map(({ label, value }) => (
                        <Radio
                            key={value}
                            label={label}
                            id={value}
                            checked={selectedExperience === value}
                            onChange={e => onExperienceClick(value, e)}
                        />
                    ))}
                </div>
            </div>
        </PreferencesStep>
    );
};
