import { DocumentType } from "@/types";
import { PayloadAction, createSlice } from "@reduxjs/toolkit";

interface DocumentTypesState {
    documentTypes: DocumentType[];
}

const initialState: DocumentTypesState = {
    documentTypes: [],
};

const documentTypeSlice = createSlice({
    name: "documentType",
    initialState,
    reducers: {
        setDocumentTypes: (state, { payload }: PayloadAction<DocumentType[]>) => {
            return {
                ...state,
                documentTypes: payload,
            };
        }
    },
});

export const {
    setDocumentTypes
} = documentTypeSlice.actions;
export const { reducer: documentTypeReducer } = documentTypeSlice;
