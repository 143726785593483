import { PreferenceOptions } from "@/types";

export const industries: PreferenceOptions = [
    { label: "Agriculture", value: "Agriculture" },
    { label: "Arts and Design", value: "Arts and Design" },
    { label: "Construction", value: "Construction" },
    { label: "Education", value: "Education" },
    { label: "Energy and Utilities", value: "Energy and Utilities" },
    { label: "Finance", value: "Finance" },
    { label: "Government", value: "Government" },
    { label: "Healthcare", value: "Healthcare" },
    { label: "Hospitality and Tourism", value: "Hospitality and Tourism" },
    { label: "IT and Software", value: "IT and Software" },
    { label: "Manufacturing", value: "Manufacturing" },
    { label: "Media and Entertainment", value: "Media and Entertainment" },
    { label: "Non-Profit and Social Services", value: "Non-Profit and Social Services" },
    { label: "Pharmaceutical and Medical", value: "Pharmaceutical and Medical" },
    { label: "Professional Services", value: "Professional Services" },
    { label: "Retail", value: "Retail" },
    { label: "Transportation and Logistics", value: "Transportation and Logistics" },
];

export const jobTypes: PreferenceOptions = [
    { label: "Full Time (Permanent)", value: "Full-time (Permanent)" },
    { label: "Part Time (Permanent)", value: "Part-time (Permanent)" },
    { label: "Contract", value: "Contract" },
    { label: "Internship", value: "Internship" },
    { label: "Freelance", value: "Freelance" },
    { label: "Temporary", value: "Temporary" },
    { label: "Summer Job", value: "Summer Job" },
];

export const experienceLevels: PreferenceOptions = [
    { label: "Entry-level", value: "Entry-level" },
    { label: "Junior", value: "Junior" },
    { label: "Mid-level", value: "Mid-level" },
    { label: "Senior", value: "Senior" },
    { label: "Director", value: "Director" },
    { label: "Executive", value: "Executive" },
];

export const jobRoles: PreferenceOptions = [
    { label: "Banking and Investment", value: "Banking and Investment" },
    { label: "Business Operations & Strategy", value: "Business Operations & Strategy" },
    { label: "Communications & Media", value: "Communications & Media" },
    { label: "Consulting", value: "Consulting" },
    { label: "Customer Support", value: "Customer Support" },
    { label: "Data & Analytics", value: "Data & Analytics" },
    { label: "Design and Product Development", value: "Design and Product Development" },
    { label: "Finance and Accounting", value: "Finance and Accounting" },
    { label: "Marketing", value: "Marketing" },
    { label: "Office Administration", value: "Office Administration" },
    { label: "People & Culture", value: "People & Culture" },
    { label: "Sales & Customer Success", value: "Sales & Customer Success" },
    { label: "Software Development", value: "Software Development" },
];
