import {
    ArialBold,
    ArialBoldItalic,
    ArialItalic,
    ArialRegular,
    CambriaBold,
    CambriaBoldItalic,
    CambriaItalic,
    CambriaRegular,
    GeorgiaBold,
    GeorgiaBoldItalic,
    GeorgiaItalic,
    GeorgiaRegular,
    MontserratBold,
    MontserratBoldItalic,
    MontserratItalic,
    MontserratRegular,
    PoppinsBold,
    PoppinsBoldItalic,
    PoppinsItalic,
    PoppinsRegular,
    RobotoBold,
    RobotoBoldItalic,
    RobotoItalic,
    RobotoRegular,
    TahomaBold,
    TahomaBoldItalic,
    TahomaItalic,
    TahomaRegular,
    TimesNewRomanBold,
    TimesNewRomanBoldItalic,
    TimesNewRomanItalic,
    TimesNewRomanRegular,
} from "@/assets/fonts";
import { Font } from "@react-pdf/renderer";

const fontMap = {
    "Arial, sans-serif": {
        family: "Arial, sans-serif",
        fontStyle: "normal",
        fontWeight: "normal",
        fonts: [
            { src: ArialRegular, fontWeight: 'normal', fontStyle: "normal" },
            { src: ArialBold, fontWeight: 'bold', fontStyle: "normal" },
            { src: ArialItalic, fontStyle: "italic", fontWeight: 'normal' },
            { src: ArialBoldItalic, fontWeight: 'bold', fontStyle: "italic" }
        ]
    },
    "Cambria, serif": {
        family: "Cambria, serif",
        fonts: [
            { src: CambriaRegular },
            { src: CambriaBold, fontWeight: 'bold' },
            { src: CambriaItalic, fontStyle: "italic", fontWeight: 'normal' },
            { src: CambriaBoldItalic, fontWeight: 'bold', fontStyle: "italic" }
        ]
    },
    "Georgia, serif": {
        family: "Georgia, serif",
        fonts: [
            { src: GeorgiaRegular },
            { src: GeorgiaBold, fontWeight: 'bold' },
            { src: GeorgiaItalic, fontStyle: "italic", fontWeight: 'normal' },
            { src: GeorgiaBoldItalic, fontWeight: 'bold', fontStyle: "italic" }
        ]
    },
    "Montserrat, sans-serif": {
        family: "Montserrat, sans-serif",
        fonts: [
            { src: MontserratRegular },
            { src: MontserratBold, fontWeight: 'bold' },
            { src: MontserratItalic, fontStyle: "italic", fontWeight: 'normal' },
            { src: MontserratBoldItalic, fontWeight: 'bold', fontStyle: "italic" }
        ]
    },
    "Poppins, sans-serif": {
        family: "Poppins, sans-serif",
        fonts: [
            { src: PoppinsRegular },
            { src: PoppinsBold, fontWeight: 'bold' },
            { src: PoppinsItalic, fontStyle: "italic", fontWeight: 'normal' },
            { src: PoppinsBoldItalic, fontWeight: 'bold', fontStyle: "italic" }
        ]
    },
    "Roboto, sans-serif": {
        family: "Roboto, sans-serif",
        fonts: [
            { src: RobotoRegular },
            { src: RobotoBold, fontWeight: 'bold' },
            { src: RobotoItalic, fontStyle: "italic", fontWeight: 'normal' },
            { src: RobotoBoldItalic, fontWeight: 'bold', fontStyle: "italic" }
        ]
    },
    "Tahoma, sans-serif": {
        family: "Tahoma, sans-serif",
        fonts: [
            { src: TahomaRegular },
            { src: TahomaBold, fontWeight: 'bold' },
            { src: TahomaItalic, fontStyle: "italic", fontWeight: 'normal' },
            { src: TahomaBoldItalic, fontWeight: 'bold', fontStyle: "italic" }
        ]
    },
    "Times New Roman, serif": {
        family: "Times New Roman, serif",
        fonts: [
            { src: TimesNewRomanRegular },
            { src: TimesNewRomanBold, fontWeight: 'bold' },
            { src: TimesNewRomanItalic, fontStyle: "italic", fontWeight: 'normal' },
            { src: TimesNewRomanBoldItalic, fontWeight: 'bold', fontStyle: "italic" }
        ]
    }
};

export const loadFonts = (fontFamily: string) => {
    const fontValue = fontMap[fontFamily];
    Font.register(fontValue);
    Font.registerHyphenationCallback(word => [word]);
};