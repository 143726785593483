import { ApiPaginationResponse, DocumentType } from "@/types";
import { apiRequest } from "./request";

const getDocumentType = (id: string) => apiRequest<DocumentType>(`/document-type/${id}`, "GET");

const getAllDocumentTypes = () => apiRequest<ApiPaginationResponse<DocumentType[]>>(`/document-type/`, "GET");

const saveDocumentType = (data: Partial<DocumentType>, id?: string) =>
    apiRequest<DocumentType>(
        id ? `/document-type/${id}` : "/document-type",
        id ? "PATCH" : "POST",
        data,
    );

const deleteDocumentType = (id: string) => apiRequest(`/document-type/${id}`, "DELETE");

export default {
    saveDocumentType,
    getDocumentType,
    getAllDocumentTypes,
    deleteDocumentType,
};
