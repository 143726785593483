import {
    Button,
    ButtonColourStyles,
    ButtonVariant,
} from "@/components/Button/Button";
import { Logo } from "@/components/Icons";
import { PageHelmet } from "@/components/PageHelmet/PageHelmet";
import { AppText } from "@/components/Text/AppText";
import { ErrorTypes } from "@/types";
import { AppRoutes } from "@/types/routes";
import { Transition, Dialog, DialogPanel } from "@headlessui/react";
import { ArrowLeft, CircleX, HomeIcon, RefreshCcw } from "lucide-react";
import { useCallback, useState } from "react";
import { Formik, Form, Field } from "formik";
import * as Yup from "yup";
import { sendErrorReport } from "@/email/send";
import { useAppSelector } from "@/hooks/types";
import { getUserDetails } from "@/store/user/selectors";

interface ErrorDetails {
    title: string;
    description: string;
}

const errorDetails: Record<ErrorTypes, ErrorDetails> = {
    notFound: {
        title: "The page you’re looking for doesn’t exist",
        description: "Error Code: 404",
    },
    unauthorized: {
        title: "You are not authorized to view this page",
        description: "Error Code: 401",
    },
    forbidden: {
        title: "You are forbidden from viewing this page",
        description: "Error Code: 403",
    },
    internalServerError: {
        title: "Something went wrong on our end. Please try again.",
        description: "Error Code: 500",
    },
};

interface ErrorPageProps {
    error: ErrorTypes;
    additionalInfo?: {
        message: unknown;
        info: unknown;
    };
}

interface FeedbackForm {
    feedback: string;
}

export const ErrorPage = ({ error, additionalInfo }: ErrorPageProps) => {
    const errorMessage = errorDetails[error] || errorDetails.notFound;
    const isDevelopmentMode = import.meta.env.VITE_NODE_ENV !== "production";
    const [showAdditionalInfo, setShowAdditionalInfo] = useState(false);
    const close = () => setShowAdditionalInfo(false);
    const userDetails = useAppSelector(getUserDetails);
    const [hasSubmitted, setHasSubmitted] = useState(false);

    const validationSchema = Yup.object({
        feedback: Yup.string(),
    });

    const onSubmitFeedback = useCallback(
        (values: FeedbackForm) => {
            sendErrorReport(
                error,
                additionalInfo,
                values.feedback,
                window.location.href,
                userDetails,
            );
            setHasSubmitted(true);
        },
        [additionalInfo, error, userDetails],
    );

    return (
        <div className="relative h-full bg-neutral-100">
            <PageHelmet
                title="Error"
                robotsOverride="noindex, nofollow"
            />

            <div className="relative flex h-full flex-col justify-between p-6">
                <span
                    onClick={() => {
                        window.location.href = AppRoutes.Dashboard;
                    }}
                >
                    <Logo className="h-8 w-44 cursor-pointer text-left" />
                </span>
                <div className="mx-auto flex flex-col items-center justify-center lg:w-1/3">
                    <div className="mb-12 text-center">
                        <AppText
                            variant="headings"
                            className="mb-4 text-neutral-900"
                        >
                            {errorMessage.title}
                        </AppText>
                        <AppText
                            variant="labelsbuttons"
                            className="text-neutral-500"
                        >
                            {errorMessage.description}
                        </AppText>

                        {isDevelopmentMode && (
                            <div className="mt-4">
                                {error !== ErrorTypes.notFound && (
                                    <div className="flex flex-row justify-center gap-3">
                                        <Button
                                            onClick={() =>
                                                setShowAdditionalInfo(true)
                                            }
                                            color={ButtonColourStyles.SOLID_RED}
                                            variant={ButtonVariant.SOLID}
                                        >
                                            Additional Info
                                        </Button>
                                        <Button
                                            onClick={() =>
                                                window.location.reload()
                                            }
                                            leftIcon={<RefreshCcw size={20} />}
                                        >
                                            Refresh
                                        </Button>
                                    </div>
                                )}
                                <Transition
                                    appear
                                    show={showAdditionalInfo}
                                >
                                    <Dialog
                                        as="div"
                                        className="relative z-10 focus:outline-none"
                                        onClose={close}
                                    >
                                        <div className="fixed inset-0 z-10 w-screen overflow-y-auto">
                                            <div className="flex min-h-full items-center justify-center p-4">
                                                <Transition
                                                    enter="ease-out duration-300"
                                                    enterFrom="opacity-0 transform-[scale(95%)]"
                                                    enterTo="opacity-100 transform-[scale(100%)]"
                                                    leave="ease-in duration-200"
                                                    leaveFrom="opacity-100 transform-[scale(100%)]"
                                                    leaveTo="opacity-0 transform-[scale(95%)]"
                                                >
                                                    <DialogPanel className="relative w-[1000px] rounded-xl bg-error-200 p-6">
                                                        <CircleX
                                                            onClick={() =>
                                                                setShowAdditionalInfo(
                                                                    false,
                                                                )
                                                            }
                                                            className="absolute right-4 top-4 h-6 w-6 cursor-pointer"
                                                        />
                                                        <pre className="text-balance text-left text-app-regular-5-mobile md:text-app-regular-5-desktop">
                                                            {typeof additionalInfo?.message ===
                                                                "string" &&
                                                                additionalInfo.message}
                                                            {typeof additionalInfo?.message !==
                                                                "string" && (
                                                                <>
                                                                    <p>
                                                                        {
                                                                            additionalInfo
                                                                                ?.message
                                                                                ?.message
                                                                        }
                                                                    </p>
                                                                    <p>
                                                                        {
                                                                            additionalInfo
                                                                                ?.message
                                                                                ?.stack
                                                                        }
                                                                    </p>
                                                                </>
                                                            )}
                                                        </pre>
                                                        <pre className="flex flex-col gap-3 whitespace-normal text-balance text-left text-app-regular-5-mobile md:text-app-regular-5-desktop">
                                                            {JSON.stringify(
                                                                additionalInfo?.info,
                                                                null,
                                                                2,
                                                            )}
                                                        </pre>
                                                    </DialogPanel>
                                                </Transition>
                                            </div>
                                        </div>
                                    </Dialog>
                                </Transition>
                            </div>
                        )}
                    </div>
                    {error !== ErrorTypes.notFound && (
                        <div className="mb-8 w-full max-w-md">
                            <Formik
                                initialValues={{ feedback: "" }}
                                validationSchema={validationSchema}
                                onSubmit={(values, { resetForm }) => {
                                    onSubmitFeedback(values);
                                    resetForm();
                                }}
                            >
                                {() => (
                                    <Form>
                                        {hasSubmitted ? (
                                            <div className="mb-4 text-center">
                                                <AppText variant="contextheading">
                                                    Thank you!
                                                </AppText>
                                            </div>
                                        ) : (
                                            <>
                                                <div className="mb-4">
                                                    <label
                                                        htmlFor="feedback"
                                                        className="mb-2 block text-neutral-700"
                                                    >
                                                        <AppText variant="labelsbuttons">
                                                            Help us improve by
                                                            sharing what went
                                                            wrong
                                                        </AppText>
                                                    </label>
                                                    <Field
                                                        as="textarea"
                                                        id="feedback"
                                                        name="feedback"
                                                        className="w-full rounded-md border border-neutral-300 p-2"
                                                        rows={4}
                                                    />
                                                </div>
                                                <Button
                                                    type="submit"
                                                    className="w-full"
                                                >
                                                    Submit Feedback
                                                </Button>
                                            </>
                                        )}
                                    </Form>
                                )}
                            </Formik>
                        </div>
                    )}
                    <div className="flex flex-col gap-3 lg:flex-row">
                        <Button
                            leftIcon={<ArrowLeft size={20} />}
                            onClick={() => window.history.go(-1)}
                            className="w-64"
                        >
                            Go back
                        </Button>
                        <Button
                            leftIcon={<HomeIcon size={20} />}
                            onClick={() =>
                                (window.location.href = AppRoutes.Dashboard)
                            }
                            className="w-64"
                            variant={ButtonVariant.OUTLINE}
                            color={ButtonColourStyles.OUTLINE_PRIMARY}
                        >
                            Go to Dashboard
                        </Button>
                    </div>
                </div>
                <div className="text-center lg:text-right">
                    {/* <Button
                        variant={ButtonVariant.TEXT}
                        color={ButtonColourStyles.OUTLINE_GREY}
                        onClick={() => (window.location.href = "/about")}
                    >
                        About 1Template
                    </Button>
                    <Button
                        variant={ButtonVariant.TEXT}
                        color={ButtonColourStyles.OUTLINE_GREY}
                        onClick={() => (window.location.href = "/pricing")}
                    >
                        Pricing
                    </Button>
                    <Button
                        variant={ButtonVariant.TEXT}
                        color={ButtonColourStyles.OUTLINE_GREY}
                        onClick={() =>
                            (window.location.href = AppRoutes.Dashboard)
                        }
                    >
                        Resume examples
                    </Button> */}
                </div>
            </div>
        </div>
    );
};
