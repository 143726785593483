import { ReactNode } from "react";
import Skeleton, { SkeletonTheme } from "react-loading-skeleton";
import "react-loading-skeleton/dist/skeleton.css";

const SectionBlock = ({ children }: { children: ReactNode }) => (
    <div className="mb-6 flex flex-col gap-2">{children}</div>
);

export const LoadingDocumentPage = () => {
    return (
        <div className="px-16 pt-12">
            <SkeletonTheme
                baseColor="#EFEFEF"
                highlightColor="#FBFBFB"
            >
                <SectionBlock>
                    <Skeleton
                        height={72}
                        width={"75%"}
                    />
                    <Skeleton
                        height={100}
                        width={"100%"}
                    />
                </SectionBlock>
                <SectionBlock>
                    <Skeleton
                        height={72}
                        width={"60%"}
                    />
                    <Skeleton
                        height={72}
                        width={"100%"}
                    />
                    <Skeleton
                        height={130}
                        width={"80%"}
                    />
                </SectionBlock>
                <SectionBlock>
                    <Skeleton
                        height={72}
                        width={"40%"}
                    />
                    <Skeleton
                        height={130}
                        width={"100%"}
                    />
                </SectionBlock>
                <SectionBlock>
                    <Skeleton
                        height={72}
                        width={"80%"}
                    />
                    <Skeleton
                        height={72}
                        width={"100%"}
                    />
                </SectionBlock>
            </SkeletonTheme>
        </div>
    );
};
