import { Logo } from "@/components/Icons";
import { AppRoutes } from "@/types/routes";
import {
    getIsNewResumePage,
    getIsPreferencesPage,
    getIsSignUpPage,
} from "@/utils/routes";
import clsx from "clsx";
import { Link, useSearchParams } from "react-router-dom";

interface NoNavPageContainerProps {
    children: React.ReactNode;
    footer?: React.ReactNode;
}

export const NoNavPageContainer = ({
    children,
    footer,
}: NoNavPageContainerProps) => {
    const isPreferences = getIsPreferencesPage();
    const isNewResume = getIsNewResumePage();
    const isSignUp = getIsSignUpPage();
    const [searchParams] = useSearchParams();
    const hasRedirect = !!searchParams.get("redirect");

    return (
        <div className="relative flex min-h-screen flex-col bg-neutral-100 px-4 lg:px-6">
            <div className="py-4">
                {isPreferences && !hasRedirect ? (
                    <span
                        aria-label="Preferences"
                        className="cursor-default"
                    >
                        <Logo className="m-auto h-auto w-[150px]" />
                    </span>
                ) : (
                    <Link
                        to={AppRoutes.Dashboard}
                        aria-label="Home"
                    >
                        <Logo className="m-auto h-auto w-[150px]" />
                    </Link>
                )}
            </div>
            <div
                className={clsx(
                    "mx-auto mb-4 flex flex-col rounded-lg bg-white",
                    isSignUp || isPreferences || isNewResume
                        ? "mt-4"
                        : "mt-4 lg:mt-16",
                    isPreferences
                        ? "relative max-w-[1000px] overflow-y-scroll p-6 md:w-full"
                        : isNewResume
                          ? "max-w-[1072px] p-6"
                          : "max-w-[800px] p-8 lg:min-w-[650px]",
                )}
            >
                {children}
            </div>
            {footer}
        </div>
    );
};
