import {
    AtSignPdfIcon,
    CalendarPdfIcon,
    LinkPdfIcon,
    MapPinPdfIcon,
    PhonePdfIcon,
    TagPdfIcon,
} from "@/components/PdfDocument/PdfIcons";

interface PdfIconProps {
    name: string;
    size: number;
    backgroundColor?: string;
}

export const PdfIcon = ({ name, size }: PdfIconProps) => {
    if (!name) return null;
    switch (name.toLowerCase()) {
        case "atsign":
            return <AtSignPdfIcon size={size} />;
        case "calendar":
            return <CalendarPdfIcon size={size} />;
        case "mappin":
            return <MapPinPdfIcon size={size} />;
        case "phone":
            return <PhonePdfIcon size={size} />;
        case "link":
            return <LinkPdfIcon size={size} />;
        case "tag":
            return <TagPdfIcon size={size} />;
        default:
            null;
    }
};
