import { MemoEditableMapper } from "@/components/EditorComponents/EditableMapper";
import { useAppDispatch, useAppSelector } from "@/hooks/types";
import { decodeHtml } from "@/pages/ResumeBuilder/utils";
import {
    getActiveResumeSectionById,
    getActiveResumeStyles,
    getResumeById,
} from "@/store/resume/selectors";
import {
    ComponentPropsObject,
    ResumeStyles,
    SectionItemDetails,
} from "@/types/resume";
import {
    getResumeStylesConfig,
    getSectionFieldsConfig,
    getVisibleProps,
    transformFieldsToProps,
} from "@/utils/section";
import clsx from "clsx";
import { useCallback, useMemo } from "react";
import { setActiveEntities } from "@/store/template/slice";
import { getSectionConfigById } from "@/store/sections/selectors";
import { SECTION_ITEM_CLASS } from "@/constants/resume";
import { getActiveItemId } from "@/store/template/selectors";
import { MemoReadOnlyMapper } from "@/components/EditorComponents/ReadOnlyMapper";

interface EditableSectionItemProps {
    sectionId: string;
    item: SectionItemDetails;
    resumeId?: string;
    isEditable: boolean;
    overrideResumeStyles?: ResumeStyles;
    layoutProps?: ComponentPropsObject;
    sectionConfigId: string;
}

export const SectionBodyItem = ({
    sectionId,
    item,
    resumeId,
    isEditable,
    overrideResumeStyles,
    layoutProps,
    sectionConfigId,
}: EditableSectionItemProps) => {
    const sectionConfig = useAppSelector(state =>
        getSectionConfigById(state, sectionConfigId),
    );
    const resume = useAppSelector(state => getResumeById(state, resumeId));
    const resumeSection = resume?.sections?.[sectionId];
    const activeResumeSection = useAppSelector(state =>
        getActiveResumeSectionById(state, sectionId),
    );
    const activeItemId = useAppSelector(getActiveItemId);
    const bodyComp = useMemo(
        () =>
            sectionConfig?.component
                ? decodeHtml(sectionConfig?.component)
                : "",
        [sectionConfig?.component],
    );
    const dispatch = useAppDispatch();

    const resumeSectionDetails = resumeSection || activeResumeSection;
    const itemProps = useMemo(
        () =>
            transformFieldsToProps(
                false,
                sectionConfig?.bodyFields ?? [],
                item,
            ),
        [item, sectionConfig?.bodyFields],
    );

    const visibleProps = useMemo(
        () => getVisibleProps(itemProps ?? {}, item?.hiddenFields ?? []),
        [item?.hiddenFields, itemProps],
    );

    const isEmptyItem = useMemo(() => {
        return Object.keys(visibleProps).length === 0;
    }, [visibleProps]);

    const fieldsConfig = useMemo(
        () => getSectionFieldsConfig(sectionConfig?.bodyFields, visibleProps),
        [sectionConfig?.bodyFields, visibleProps],
    );
    const activeResumeStyles = useAppSelector(getActiveResumeStyles);

    const stylesConfig = useMemo(
        () =>
            getResumeStylesConfig({
                overrideResumeStyles,
                resumeStyles: resume?.styles,
                activeResumeStyles,
                columnIndex: resumeSectionDetails?.columnIndex ?? 0,
            }),
        [
            activeResumeStyles,
            overrideResumeStyles,
            resume?.styles,
            resumeSectionDetails?.columnIndex,
        ],
    );

    const onContainerClick = useCallback(() => {
        if (isEditable && sectionId)
            dispatch(
                setActiveEntities({
                    activeSection: sectionId,
                    activeItemId: item.__id,
                }),
            );
    }, [dispatch, isEditable, item.__id, sectionId]);

    return (
        <div
            onClick={onContainerClick}
            className={clsx(
                SECTION_ITEM_CLASS,
                isEmptyItem && "min-h-4 rounded-md",
                isEmptyItem &&
                    activeItemId !== item.__id &&
                    "hover:ring-2 hover:ring-neutral-300",
            )}
        >
            {isEditable ? (
                <MemoEditableMapper
                    html={bodyComp}
                    sectionId={sectionId}
                    visibleProps={visibleProps}
                    propType="body"
                    itemId={item.__id}
                    fieldsConfig={fieldsConfig}
                    stylesConfig={stylesConfig}
                    layoutProps={layoutProps}
                    onClick={onContainerClick}
                />
            ) : (
                <MemoReadOnlyMapper
                    html={bodyComp}
                    sectionId={sectionId}
                    visibleProps={visibleProps}
                    propType="body"
                    itemId={item.__id}
                    fieldsConfig={fieldsConfig}
                    stylesConfig={stylesConfig}
                    layoutProps={layoutProps}
                />
            )}
        </div>
    );
};
