import userApi from "@/api/user";
import assistantApi from "@/api/assistant";
import { Modal } from "@/components/Modal/Modal";
import { ModalFooter } from "@/components/Modal/ModalFooter";
import { AppText } from "@/components/Text/AppText";
import { useAppDispatch, useAppSelector } from "@/hooks/types";
import { JobRoleList } from "@/pages/Preferences/components/JobRoleList";
import { addNotification } from "@/store/app/slice";
import { getIsModalOpen, getModalProps } from "@/store/modal/selectors";
import { closeModal } from "@/store/modal/slice";
import { getUserDetails, getUserPreferences } from "@/store/user/selectors";
import { setUserDetails } from "@/store/user/slice";
import { setWizardUserPreference } from "@/store/wizard/slice";
import { NotificationMessageType, NotificationType } from "@/types/app";
import { ModalTypes } from "@/types/modal";
import { UserPreferences } from "@/types/user";
import { useCallback, useEffect, useState } from "react";
import { setAssistantItems } from "@/store/assistant/slice";

const PreferencesModal = () => {
    const dispatch = useAppDispatch();
    const user = useAppSelector(getUserDetails);
    const isOpen = useAppSelector(
        getIsModalOpen(ModalTypes.ASSISTANT_PREFERENCES),
    );
    const modalProps = useAppSelector(
        getModalProps(ModalTypes.ASSISTANT_PREFERENCES),
    );
    const userPreferences = useAppSelector(getUserPreferences);

    const [selectedJobRoles, setSelectedJobRoles] = useState<string[]>(
        userPreferences?.jobRoles ?? [],
    );
    const [localPreference, setLocalPreference] = useState<
        string | undefined
    >();
    const [localTitle, setLocalTitle] = useState<string | undefined>();
    const [localDescription, setLocalDescription] = useState<
        string | undefined
    >();
    const [isClosing, setIsClosing] = useState(false);
    const [isSaving, setIsSaving] = useState(false);

    useEffect(() => {
        if (modalProps && !isClosing) {
            setLocalPreference(modalProps.preference);
            setLocalTitle(modalProps.title);
            setLocalDescription(modalProps.description);
        }
    }, [modalProps, isClosing]);

    const onClose = useCallback(() => {
        setIsClosing(true);
        dispatch(closeModal(ModalTypes.ASSISTANT_PREFERENCES));
    }, [dispatch]);

    const onSavePreferences = useCallback(async () => {
        setIsSaving(true);
        const newPreferences: UserPreferences = {
            ...(userPreferences ?? {}),
            jobRoles: selectedJobRoles,
            experienceLevel: userPreferences?.experienceLevel ?? "",
            industries: userPreferences?.industries ?? [],
            jobTypes: userPreferences?.jobTypes ?? [],
        };
        try {
            const newUser = await userApi.saveUserApi(
                {
                    preferences: newPreferences,
                },
                user?.id,
            );
            dispatch(setUserDetails(newUser));
            dispatch(
                setWizardUserPreference({
                    preference: "jobRoles",
                    value: selectedJobRoles,
                }),
            );

            assistantApi.getUserAssistantItems().then(assistantItems => {
                dispatch(setAssistantItems(assistantItems.results));
            });
        } catch (error) {
            dispatch(
                addNotification({
                    title: "Unable to save preferences",
                    desc: "Please try again.",
                    type: NotificationType.ERROR,
                    messageType: NotificationMessageType.PREFERENCES_SAVED,
                }),
            );
        } finally {
            onClose();
            setIsSaving(false);
        }
    }, [dispatch, selectedJobRoles, onClose, userPreferences, user]);

    const onJobRoleClick = useCallback((role: string, checked: boolean) => {
        setSelectedJobRoles(prev =>
            checked
                ? [...new Set([...prev, role])]
                : prev.filter(r => r !== role),
        );
    }, []);

    return (
        <Modal
            modalType={ModalTypes.ASSISTANT_PREFERENCES}
            open={isOpen}
            title={localTitle ?? ""}
            width="4xl"
            footer={
                <ModalFooter
                    primaryActionText="Update Preferences"
                    primaryActionOnClick={onSavePreferences}
                    secondaryActionText="Cancel"
                    secondaryActionOnClick={onClose}
                    primaryActionLoading={isSaving}
                />
            }
        >
            <div>
                <AppText
                    variant="regular"
                    className="mb-4 text-neutral-900"
                >
                    {localDescription}
                </AppText>
                <div className="mt-4 grid grid-cols-2 gap-x-4 gap-y-3 overflow-y-auto px-20 py-2">
                    {localPreference === "jobRoles" && (
                        <JobRoleList
                            onJobRoleClick={onJobRoleClick}
                            selectedJobRoles={selectedJobRoles}
                        />
                    )}
                </div>
            </div>
        </Modal>
    );
};

export default PreferencesModal;
