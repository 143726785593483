import { Button } from "@/components/Button/Button";
import { GridHandle } from "@/components/Grid/GridHandle";
import { LoadingSpinner } from "@/components/LoadingSpinner/LoadingSpinner";
import { Modal } from "@/components/Modal/Modal";
import { ModalFooter } from "@/components/Modal/ModalFooter";
import { TabButton } from "@/components/Tabs/TabButton";
import { AppText } from "@/components/Text/AppText";
import { useAppSelector, useAppDispatch } from "@/hooks/types";
import { useUpdateTemplate } from "@/hooks/useUpdateTemplate";
import { getTemplateLoadingState } from "@/store/app/selectors";
import { getIsModalOpen } from "@/store/modal/selectors";
import { closeModal, openModal } from "@/store/modal/slice";
import {
    A4_PAGE_HEIGHT_PIXELS,
    A4_PAGE_WIDTH_PIXELS,
    HEADER_SECTION_MARGIN_BOTTOM,
    SECTION_MARGIN_BOTTOM,
} from "@/store/pages/constants";
import { getMeasurements } from "@/store/pages/selectors";
import { updateMeasurementPositions } from "@/store/pages/slice";
import {
    getActiveResume,
    getActiveResumeColumnLayout,
    getActiveResumeGlobalStyles,
    getActiveResumeSections,
    getSectionsByActiveDocumentType,
} from "@/store/resume/selectors";
import { determineColumnIndex } from "@/store/resume/utils";
import { TemplateLoadingState } from "@/types/app";
import { ModalTypes } from "@/types/modal";
import { ColumnLayout, ResumeSections } from "@/types/resume";
import {
    DndContext,
    DragOverlay,
    DragEndEvent,
    DragStartEvent,
    DragOverEvent,
    useDroppable,
    pointerWithin,
} from "@dnd-kit/core";
import {
    restrictToFirstScrollableAncestor,
    restrictToWindowEdges,
} from "@dnd-kit/modifiers";
import {
    SortableContext,
    useSortable,
    verticalListSortingStrategy,
} from "@dnd-kit/sortable";
import { CSS } from "@dnd-kit/utilities";
import { Tab, TabGroup, TabList, TabPanel, TabPanels } from "@headlessui/react";
import clsx from "clsx";
import { PlusSquare, X } from "lucide-react";
import { useCallback, useEffect, useRef, useState } from "react";
import { createPortal } from "react-dom";

const SCALE_WIDTH_FACTOR = 0.5;
const SCALE_HEIGHT_FACTOR = 0.35;
const PAGE_WIDTH_PX = A4_PAGE_WIDTH_PIXELS * SCALE_WIDTH_FACTOR;
const PAGE_HEIGHT_PX = A4_PAGE_HEIGHT_PIXELS * SCALE_HEIGHT_FACTOR;

interface SectionData {
    id: string;
    name: string;
    columnIndex: number;
    positionIndex: number;
    height: number;
}

const RearrangeModal = () => {
    const isOpen = useAppSelector(getIsModalOpen(ModalTypes.REARRANGE));
    const activeResume = useAppSelector(getActiveResume);
    const sectionConfigs = useAppSelector(getSectionsByActiveDocumentType);
    const activeResumeSections = useAppSelector(getActiveResumeSections);
    const templateLoadingState = useAppSelector(getTemplateLoadingState);
    const activeResumeColumnLayout = useAppSelector(
        getActiveResumeColumnLayout,
    );
    const { saveAndUpdateTemplate } = useUpdateTemplate();
    const backgroundColor = useAppSelector(
        getActiveResumeGlobalStyles,
    )?.backgroundColor;
    const [isSaving, setIsSaving] = useState(false);
    const [loading, setLoading] = useState(true);
    const measurements = useAppSelector(getMeasurements);
    const dispatch = useAppDispatch();
    const hasInitialised = useRef(false);
    const [tabIndex, setTabIndex] = useState(
        activeResumeColumnLayout === ColumnLayout.SINGLE ? 0 : 1,
    );
    const [removedSections, setRemovedSections] = useState<SectionData[]>([]);

    useEffect(() => {
        setTabIndex(activeResumeColumnLayout === ColumnLayout.SINGLE ? 0 : 1);
    }, [activeResumeColumnLayout]);

    const handleCloseModal = useCallback(
        (close?: boolean) => {
            setIsSaving(true);
            if (close) {
                dispatch(closeModal(ModalTypes.REARRANGE));
            }
            setTabIndex(
                activeResumeColumnLayout === ColumnLayout.SINGLE ? 0 : 1,
            );
        },
        [dispatch, activeResumeColumnLayout],
    );

    const resetItems = useCallback(() => {
        hasInitialised.current = false;
        setLeftItems([]);
        setRightItems([]);
        setItems([]);
        setIsSaving(false);
    }, []);

    const [leftItems, setLeftItems] = useState<SectionData[]>([]);
    const [rightItems, setRightItems] = useState<SectionData[]>([]);
    const [items, setItems] = useState<SectionData[]>([]);

    const initialiseItems = useCallback(
        (newLayout?: ColumnLayout) => {
            const columnSectionsCount: Record<number, number> = {
                0: 0,
                1: 0,
            };
            const activeResumeSectionsArray = Object.entries(
                activeResumeSections ?? {},
            ).reduce((acc, sectionData, index) => {
                const [id, section] = sectionData;
                const sectionConfig = sectionConfigs.find(
                    config => config.id === section.sectionConfigId,
                );
                if (!sectionConfig?.isStaticHeader) {
                    const height = measurements.reduce(
                        (total, measurement) =>
                            measurement.section === id
                                ? total + measurement.height
                                : total,
                        0,
                    );

                    let columnIndex = section.columnIndex ?? 0;
                    let positionIndex = section.positionIndex ?? 0;
                    if (newLayout) {
                        columnIndex = determineColumnIndex(
                            sectionConfig,
                            newLayout,
                            measurements.find(m => m.section === id),
                            columnSectionsCount,
                        );
                        positionIndex = columnSectionsCount[columnIndex];
                        columnSectionsCount[columnIndex]++;
                    }

                    acc.push({
                        id,
                        name: sectionConfig?.name ?? "Section",
                        columnIndex,
                        positionIndex,
                        height,
                    });
                }
                return acc;
            }, [] as SectionData[]);

            const sortedItems = activeResumeSectionsArray.sort(
                (a, b) => a.positionIndex - b.positionIndex,
            );

            setItems(sortedItems);
            setLeftItems(
                sortedItems.filter(section => section.columnIndex === 0),
            );
            setRightItems(
                sortedItems.filter(section => section.columnIndex === 1),
            );
            setLoading(false);
            hasInitialised.current = true;
        },
        [activeResumeSections, measurements, sectionConfigs],
    );

    useEffect(() => {
        if (
            isOpen &&
            !hasInitialised.current &&
            sectionConfigs.length &&
            activeResumeSections &&
            measurements.length
        ) {
            initialiseItems();
        } else {
            setLoading(true);
        }
    }, [
        activeResumeSections,
        hasInitialised,
        initialiseItems,
        isOpen,
        measurements,
        sectionConfigs,
    ]);

    const [activeId, setActiveId] = useState<string | null>(null);

    const handleRemoveItem = (id: string) => {
        setRemovedSections(items.filter(item => item.id === id));
        if (tabIndex === 0) {
            setItems(items.filter(item => item.id !== id));
        } else {
            setLeftItems(leftItems.filter(item => item.id !== id));
            setRightItems(rightItems.filter(item => item.id !== id));
        }
    };

    const findContainer = (id: string) => {
        if (tabIndex === 0) {
            return "single";
        }
        if (id === "left" || id === "right") {
            return id;
        }
        if (leftItems.find(item => item.id === id)) return "left";
        if (rightItems.find(item => item.id === id)) return "right";
        return null;
    };

    const handleDragStart = (event: DragStartEvent) => {
        const { active } = event;
        setActiveId(active.id as string);
    };

    const handleDragOver = (event: DragOverEvent) => {
        // For single column layout, we don't need to handle column changes
        if (tabIndex === 0) return;

        const { active, over } = event;

        if (!over) return;
        const activeId = active.id as string;
        const overId = over.id as string;
        const activeContainer = findContainer(activeId);
        const overContainer = findContainer(overId);

        if (
            !activeContainer ||
            !overContainer ||
            activeContainer === overContainer
        ) {
            return;
        }

        const activeItems = activeContainer === "left" ? leftItems : rightItems;
        const overItems = overContainer === "left" ? leftItems : rightItems;

        const activeIndex = activeItems.findIndex(item => item.id === activeId);
        const overIndex =
            overId === "left" || overId === "right"
                ? overItems.length
                : overItems.findIndex(item => item.id === overId);

        const newItem = {
            ...activeItems[activeIndex],
            columnIndex: overContainer === "left" ? 0 : 1,
        };

        setLeftItems(items => {
            return items.filter(item => item.id !== active.id);
        });
        setRightItems(items => {
            return items.filter(item => item.id !== active.id);
        });

        setLeftItems(items => {
            if (activeContainer === "left") {
                return items.filter(item => item.id !== activeId);
            } else if (overContainer === "left") {
                return [
                    ...items.slice(0, overIndex),
                    newItem,
                    ...items.slice(overIndex),
                ];
            }
            return items;
        });

        setRightItems(items => {
            if (activeContainer === "right") {
                return items.filter(item => item.id !== activeId);
            } else if (overContainer === "right") {
                return [
                    ...items.slice(0, overIndex),
                    newItem,
                    ...items.slice(overIndex),
                ];
            }
            return items;
        });
    };

    const handleDragEnd = (event: DragEndEvent) => {
        const { active, over } = event;
        if (!over) return;

        const activeId = active.id as string;
        const overId = over.id as string;

        const activeContainer = findContainer(activeId);
        const overContainer = findContainer(overId);

        if (!activeContainer || !overContainer) {
            return;
        }

        if (tabIndex === 0) {
            // Handle reordering for single column
            const oldIndex = items.findIndex(item => item.id === active.id);
            const newIndex = items.findIndex(item => item.id === over.id);

            if (oldIndex !== newIndex) {
                const newItems = [...items];
                const [reorderedItem] = newItems.splice(oldIndex, 1);
                newItems.splice(newIndex, 0, reorderedItem);

                const newItemsWithUpdatedIndexes = newItems.map(
                    (item, index) => ({
                        ...item,
                        positionIndex: index,
                    }),
                );

                setItems(newItemsWithUpdatedIndexes);
            }
        } else {
            const activeItems =
                activeContainer === "left" ? leftItems : rightItems;
            const overItems = overContainer === "left" ? leftItems : rightItems;

            const activeIndex = activeItems.findIndex(
                item => item.id === activeId,
            );
            const overIndex =
                overId === "left" || overId === "right"
                    ? overItems.length
                    : overItems.findIndex(item => item.id === overId);

            if (activeContainer !== overContainer) {
                const newItem = {
                    ...activeItems[activeIndex],
                    columnIndex: overContainer === "left" ? 0 : 1,
                };

                setLeftItems(items => {
                    if (activeContainer === "left") {
                        return items.filter(item => item.id !== activeId);
                    } else if (overContainer === "left") {
                        return [
                            ...items.slice(0, overIndex),
                            newItem,
                            ...items.slice(overIndex),
                        ];
                    }
                    const itemsWithUpdatedIndexes = items.map(
                        (item, index) => ({
                            ...item,
                            positionIndex: index,
                        }),
                    );
                    return itemsWithUpdatedIndexes;
                });

                setRightItems(items => {
                    if (activeContainer === "right") {
                        return items.filter(item => item.id !== activeId);
                    } else if (overContainer === "right") {
                        return [
                            ...items.slice(0, overIndex),
                            newItem,
                            ...items.slice(overIndex),
                        ];
                    }

                    const itemsWithUpdatedIndexes = items.map(
                        (item, index) => ({
                            ...item,
                            positionIndex: index,
                        }),
                    );
                    return itemsWithUpdatedIndexes;
                });
            } else if (activeIndex !== overIndex) {
                const newItems = [...overItems];
                const [reorderedItem] = newItems.splice(activeIndex, 1);
                newItems.splice(overIndex, 0, reorderedItem);

                const newItemsWithUpdatedIndexes = newItems.map(
                    (item, index) => ({
                        ...item,
                        positionIndex: index,
                    }),
                );

                if (overContainer === "left") {
                    setLeftItems(newItemsWithUpdatedIndexes);
                } else {
                    setRightItems(newItemsWithUpdatedIndexes);
                }
            }
        }
        setActiveId(null);
    };

    const onTabChange = (index: number) => {
        setTabIndex(index);
        initialiseItems(
            index === 0 ? ColumnLayout.SINGLE : ColumnLayout.DOUBLE,
        );
    };

    const saveLayout = () => {
        setIsSaving(true);
        const isTwoColumn = tabIndex === 1;
        let allItems = [];
        if (isTwoColumn) {
            allItems = [...leftItems, ...rightItems];
        } else {
            allItems = items;
        }
        const copiedResumeSections: ResumeSections = JSON.parse(
            JSON.stringify(activeResume?.sections ?? {}),
        );

        removedSections.forEach(item => {
            delete copiedResumeSections[item.id];
        });

        allItems.forEach(item => {
            copiedResumeSections[item.id].columnIndex = item.columnIndex;
            copiedResumeSections[item.id].positionIndex = item.positionIndex;
        });

        saveAndUpdateTemplate({
            newTemplateSections: copiedResumeSections,
            newLayout: isTwoColumn ? ColumnLayout.DOUBLE : ColumnLayout.SINGLE,
            cb: success => {
                if (success) {
                    dispatch(
                        updateMeasurementPositions({
                            updatedMeasurements: copiedResumeSections,
                            removedMeasurements: removedSections.map(
                                item => item.id,
                            ),
                        }),
                    );
                    handleCloseModal(true);
                } else {
                    setIsSaving(false);
                    setLoading(false);
                }
            },
        });
    };

    const SortableItem = ({ id, name }: SectionData) => {
        const {
            attributes,
            listeners,
            setNodeRef,
            transform,
            transition,
            isDragging,
        } = useSortable({ id });

        const style = {
            transform: CSS.Transform.toString(transform),
            transition,
            opacity: isDragging ? 0.5 : 1,
            height: 50,
            marginBottom: `${SECTION_MARGIN_BOTTOM * SCALE_HEIGHT_FACTOR}px`,
        };

        return (
            <div
                ref={setNodeRef}
                style={style}
                className={clsx(
                    "group relative flex rounded bg-gray-100 px-1 py-1",
                    isDragging
                        ? "z-10 opacity-50"
                        : "opacity-100 hover:bg-neutral-200",
                )}
            >
                <div
                    className="flex w-full cursor-grab items-center"
                    {...attributes}
                    {...listeners}
                >
                    <div className="flex items-center">
                        <div className="flex-shrink-0 pr-1 text-neutral-900">
                            <GridHandle />
                        </div>
                    </div>
                    <div className="min-w-0 flex-grow truncate">
                        <AppText
                            variant="labelsbuttons"
                            className="select-none truncate text-neutral-900"
                        >
                            {name}
                        </AppText>
                    </div>
                </div>
                <button
                    onClick={e => {
                        e.preventDefault();
                        e.stopPropagation();
                        handleRemoveItem(id);
                    }}
                    className="absolute right-0 top-3 hidden -translate-y-1/2 transform p-1 text-neutral-700 hover:text-neutral-900 group-hover:block"
                >
                    <X className="h-4 w-4" />
                </button>
            </div>
        );
    };

    const DroppableContainer = ({
        id,
        items,
    }: {
        id: "single" | "left" | "right";
        items: SectionData[];
    }) => {
        const { setNodeRef } = useDroppable({
            id,
        });
        const isEmptyColumn = items.length === 0;
        return (
            <SortableContext
                id={id}
                items={items}
                strategy={verticalListSortingStrategy}
            >
                <div
                    ref={setNodeRef}
                    className={clsx(
                        id === "single"
                            ? "w-full"
                            : id === "left"
                              ? "w-3/5"
                              : "w-2/5",
                        "px-3",
                        isEmptyColumn && "pb-2",
                    )}
                    style={{
                        paddingTop: `${HEADER_SECTION_MARGIN_BOTTOM * SCALE_HEIGHT_FACTOR}px`,
                        minHeight: `${PAGE_HEIGHT_PX}px`,
                    }}
                >
                    {items.map(item => (
                        <SortableItem
                            key={item.id}
                            {...item}
                        />
                    ))}
                    {!items.length && (
                        <div className="flex h-full w-full items-center justify-center rounded-lg border border-dashed border-neutral-300 px-1 text-center text-neutral-500">
                            <AppText
                                variant="labelsbuttons"
                                className="!text-xs"
                            >
                                Drag and drop sections here
                            </AppText>
                        </div>
                    )}
                </div>
            </SortableContext>
        );
    };

    return (
        <Modal
            modalType={ModalTypes.REARRANGE}
            open={isOpen}
            onClose={handleCloseModal}
            title="Rearrange Resume"
            width="2xl"
            closeOnOverlayClick={false}
            forceFullHeight
            afterClose={resetItems}
            footer={
                <ModalFooter
                    primaryActionText="Save"
                    primaryActionOnClick={saveLayout}
                    secondaryActionText="Cancel"
                    secondaryActionOnClick={() => handleCloseModal(true)}
                    primaryActionLoading={isSaving}
                />
            }
        >
            {isSaving ||
            loading ||
            templateLoadingState === TemplateLoadingState.LOADING ? (
                <div className="flex h-96 w-full items-center justify-center">
                    <LoadingSpinner />
                </div>
            ) : items.length === 0 ? (
                <div className="flex h-full flex-col items-center justify-center text-neutral-900">
                    <AppText
                        variant="subheadings"
                        className="mb-2"
                    >
                        No Moveable Sections
                    </AppText>
                    <AppText variant="regular">
                        You don&apos;t currently have any moveable sections
                        added to your resume.
                    </AppText>
                    <AppText
                        variant="regular"
                        className="mb-4"
                    >
                        Add sections to your resume to start rearranging.
                    </AppText>
                    <Button
                        onClick={() => {
                            dispatch(
                                openModal({
                                    modalType: ModalTypes.ADD_NEW_SECTION,
                                }),
                            );
                            handleCloseModal(true);
                        }}
                        leftIcon={<PlusSquare className="h-4 w-4" />}
                    >
                        Add Section
                    </Button>
                </div>
            ) : (
                <div className="mb-4 flex flex-col">
                    <div className="mb-6 flex flex-row justify-between gap-2">
                        <AppText
                            variant="regular"
                            className="text-neutral-700"
                        >
                            Reorder sections by dragging and dropping them into
                            your preferred arrangement.
                        </AppText>
                    </div>
                    <div className="relative mb-4 flex-grow">
                        <DndContext
                            collisionDetection={pointerWithin}
                            onDragStart={handleDragStart}
                            onDragOver={handleDragOver}
                            onDragEnd={handleDragEnd}
                        >
                            <TabGroup
                                className="overflow-hidden"
                                defaultIndex={0}
                                selectedIndex={tabIndex}
                                onChange={onTabChange}
                            >
                                <TabList className="mx-auto mb-4 flex w-1/2 gap-3 rounded-lg border-[1px]  border-neutral-300 p-1">
                                    <TabButton>Full Page</TabButton>
                                    <TabButton>Split Page</TabButton>
                                </TabList>
                                <TabPanels className="mt-2 overflow-x-hidden overflow-y-scroll px-4">
                                    <TabPanel>
                                        <div
                                            className="relative mx-auto"
                                            style={{
                                                width: `${PAGE_WIDTH_PX}px`,
                                            }}
                                        >
                                            <div
                                                className="flex w-full items-center justify-center rounded-t-lg border border-neutral-300"
                                                style={{
                                                    height: "50px",
                                                    backgroundColor:
                                                        backgroundColor,
                                                    minHeight: "30px",
                                                }}
                                            >
                                                <AppText
                                                    variant="contextheading"
                                                    className="text-neutral-900"
                                                >
                                                    Header
                                                </AppText>
                                            </div>
                                            <div className="relative flex justify-between divide-x divide-neutral-300 rounded-b-lg border border-t-0  border-neutral-300">
                                                <DroppableContainer
                                                    id="single"
                                                    items={items}
                                                />
                                            </div>
                                        </div>
                                    </TabPanel>
                                    <TabPanel>
                                        <div
                                            className="relative mx-auto"
                                            style={{
                                                width: `${PAGE_WIDTH_PX}px`,
                                            }}
                                        >
                                            <div
                                                className="flex w-full items-center justify-center rounded-t-lg border border-neutral-300"
                                                style={{
                                                    height: "50px",
                                                    backgroundColor:
                                                        backgroundColor,
                                                }}
                                            >
                                                <AppText
                                                    variant="contextheading"
                                                    className="text-neutral-900"
                                                >
                                                    Header
                                                </AppText>
                                            </div>
                                            <div className="relative flex justify-between divide-x divide-neutral-300 rounded-b-lg border border-t-0  border-neutral-300">
                                                <>
                                                    <DroppableContainer
                                                        id="left"
                                                        items={leftItems}
                                                    />
                                                    <DroppableContainer
                                                        id="right"
                                                        items={rightItems}
                                                    />
                                                </>
                                            </div>
                                        </div>
                                    </TabPanel>
                                </TabPanels>
                            </TabGroup>
                            {createPortal(
                                <DragOverlay
                                    modifiers={[
                                        restrictToFirstScrollableAncestor,
                                        restrictToWindowEdges,
                                    ]}
                                    adjustScale={false}
                                    dropAnimation={null}
                                >
                                    {activeId ? (
                                        <div className="rounded-lg bg-white shadow-lg">
                                            <SortableItem
                                                {...(leftItems.find(
                                                    item =>
                                                        item.id === activeId,
                                                ) ||
                                                    rightItems.find(
                                                        item =>
                                                            item.id ===
                                                            activeId,
                                                    ))!}
                                            />
                                        </div>
                                    ) : null}
                                </DragOverlay>,
                                document.getElementById(
                                    "drag-and-drop-portal-root",
                                ),
                            )}
                        </DndContext>
                    </div>
                </div>
            )}
        </Modal>
    );
};

export default RearrangeModal;
