import { resetResumeBuilderState } from "@/routes/utils/resetResumeBuilderState";
import { setTemplateLoadingState } from "@/store/app/slice";
import { setDocumentTypes } from "@/store/documentType/slice";
import { resetPages } from "@/store/pages/slice";
import { setActiveResume, setResumes } from "@/store/resume/slice";
import { setSections } from "@/store/sections/slice";
import { AppDispatch } from "@/store/store";
import { setTemplates } from "@/store/template/slice";
import { setUserDetails, resetTokens, setUserPlan } from "@/store/user/slice";
import { resetWizardUserPreferences, setWizardUserPreferences } from "@/store/wizard/slice";
import { TemplateLoadingState } from "@/types/app";

export const resetAppState = (dispatch: AppDispatch) => {
    resetResumeBuilderState(dispatch);
    dispatch(setUserDetails(null));
    dispatch(resetTokens());
    dispatch(setResumes([]));
    dispatch(setActiveResume(undefined));
    dispatch(resetWizardUserPreferences());
    dispatch(setDocumentTypes([]));
    dispatch(setSections([]));
    dispatch(setTemplates([]));
    dispatch(resetPages());
    dispatch(setWizardUserPreferences(null));
    dispatch(setTemplateLoadingState(TemplateLoadingState.INITIAL));
    dispatch(setUserPlan({ planStatus: null, details: null, isSubscribed: false, hasUsedTrial: false }));
};