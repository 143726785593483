import userApi from "@/api/user";
import templateApi from "@/api/template";
import documentTypeApi from "@/api/documentType";
import sectionApi from "@/api/section";
import resumeApi from "@/api/resume";
import billingApi from "@/api/billing";
import assistantApi from "@/api/assistant";
import { AuthLayout, AppLayout } from "@/layouts";
import { Dashboard } from "@/pages/Dashboard/Dashboard";
import { LoginPage } from "@/pages/Login/LoginPage";
import { ResumeBuilder } from "@/pages/ResumeBuilder/ResumeBuilder";
import { SignUpPage } from "@/pages/Signup/SignUpPage";
import {
    Route,
    createBrowserRouter,
    createRoutesFromElements,
    defer,
} from "react-router-dom";
import { SectionForm } from "../pages/Admin/Sections/SectionForm";
import { AdminProtectedRoute } from "./AdminProtectedRoute";
import { ProtectedRoute } from "./ProtectedRoute";
import { Sections } from "@/pages/Admin/Sections/Sections";
import { StylePage } from "@/pages/Admin/StylePage/StylePage";
import { Users } from "@/pages/Admin/Users/Users";
import { UserForm } from "@/pages/Admin/Users/UserForm";
import { Templates } from "@/pages/Admin/Templates/Templates";
import { TemplateForm } from "@/pages/Admin/Templates/TemplateForm";
import { AssistantItems } from "@/pages/Admin/Assistant/AssistantItems";
import { AssistantItemForm } from "@/pages/Admin/Assistant/AssistantItemForm";
import { store } from "@/store/store";
import { setUserDetails, setUserPlan } from "@/store/user/slice";
import { setActiveResume, setExamples, setResumes } from "@/store/resume/slice";
import { setActiveTemplate, setTemplates } from "@/store/template/slice";
import { setSections } from "@/store/sections/slice";
import {
    getAdminAssistantItem,
    getAdminDocumentType,
    getAdminSection,
    getAdminTemplate,
    getAdminUser,
} from "./loaders";
import { AdminDashboardLayout } from "@/layouts/AdminDashboardLayout";
import { DocumentTypes } from "@/pages/Admin/DocumentTypes/DocumentTypes";
import { DocumentTypeForm } from "@/pages/Admin/DocumentTypes/DocumentTypeForm";
import { setDocumentTypes } from "@/store/documentType/slice";
import { ErrorPage } from "@/pages/Error/ErrorPage";
import { ErrorTypes } from "@/types";
import { ProfilePage } from "@/pages/Profile/ProfilePage";
import { resetResumeBuilderState } from "@/routes/utils/resetResumeBuilderState";
import { ForgotPasswordPage } from "@/pages/ForgotPassword/ForgotPasswordPage";
import { ResetPasswordPage } from "@/pages/ResetPassword/ResetPasswordPage";
import { VerifyEmailPage } from "@/pages/VerifyEmail/VerifyEmailPage";
import { PreferencesPage } from "@/pages/Preferences/PreferencesPage";
import { LogoutPage } from "@/pages/Logout/LogoutPage";
import { setWizardUserPreferences } from "@/store/wizard/slice";
import { CreateResumePage } from "@/pages/CreateResume/CreateResumePage";
import { AppRoutes } from "@/types/routes";
import { PreviewPage } from "@/pages/Preview/PreviewPage";
import { ExamplesPage } from "@/pages/Examples/ExamplesPage";
import ModalManager from "@/components/Modals/ModalManager";
import { setAssistantItems } from "@/store/assistant/slice";
import { AuthWrapper } from "@/layouts/AuthWrapper";

const getUserData = () => {
    try {
        return new Promise(resolve => {
            const accessToken =
                window.localStorage.getItem("accessToken") || null;
            const parsedAccessToken = accessToken
                ? JSON.parse(accessToken)
                : {};

            if (
                parsedAccessToken?.token &&
                parsedAccessToken?.token !== "null"
            ) {
                return userApi
                    .getMeApi()
                    .then(response => {
                        resolve(response);
                        store.dispatch(setUserDetails(response));
                        store.dispatch(
                            setWizardUserPreferences(
                                response.preferences || null,
                            ),
                        );
                        billingApi
                            .getUserPlan(response.stripeCustomerId)
                            .then(userPlan => {
                                store.dispatch(setUserPlan(userPlan));
                            });
                    })
                    .catch(err => {
                        window.localStorage.removeItem("accessToken");
                        resolve(err);
                    });
            } else {
                resolve(null);
            }
        });
    } catch (e) {
        console.error("Failed to get user data on loader", e);
    }
};

const getAppData = (resetResumeState?: boolean) => {
    try {
        if (resetResumeState) {
            resetResumeBuilderState(store.dispatch);
        }

        // get all resumes
        return Promise.all([
            resumeApi.getAllResume(false),
            sectionApi.getAllSections(),
            templateApi.getAllTemplates(),
            documentTypeApi.getAllDocumentTypes(),
            resumeApi.getExamples(),
        ]).then(values => {
            const [resumes, sections, templates, documentTypes, examples] =
                values;
            store.dispatch(setResumes(resumes.results));
            store.dispatch(setSections(sections.results));
            store.dispatch(setTemplates(templates.results));
            store.dispatch(setDocumentTypes(documentTypes.results));
            store.dispatch(setExamples(examples.results));
        });
    } catch (e) {
        console.error("Problem initialising app", e);
    }
};

const getResumeData = (id: string) => {
    try {
        return resumeApi.getResume(id).then(resume => {
            templateApi.getTemplate(resume.template).then(template => {
                store.dispatch(setActiveResume(resume));
                store.dispatch(setActiveTemplate(template));
            });
        });
    } catch (e) {
        console.error("Problem initialising app with resume data", e);
    }
};

const getAssistantItems = () => {
    try {
        return assistantApi.getUserAssistantItems().then(assistantItems => {
            store.dispatch(setAssistantItems(assistantItems.results));
        });
    } catch (e) {
        console.error("Problem initialising app with assistant items", e);
    }
};

export const router = createBrowserRouter(
    createRoutesFromElements(
        <Route
            element={<AuthLayout />}
            loader={() => defer({ userPromise: getUserData() })}
        >
            <Route element={<AuthWrapper />}>
                <Route
                    path={AppRoutes.Login}
                    element={<LoginPage />}
                />
                <Route
                    path={AppRoutes.Signup}
                    element={<SignUpPage />}
                />
            </Route>
            <Route
                path={AppRoutes.Logout}
                element={<LogoutPage />}
            />
            <Route
                path={AppRoutes.Verify}
                element={<VerifyEmailPage />}
            />
            <Route
                path={AppRoutes.ForgotPassword}
                element={<ForgotPasswordPage />}
            />
            <Route
                path={AppRoutes.ResetPassword}
                element={<ResetPasswordPage />}
            />
            <Route
                element={
                    <ProtectedRoute>
                        <AppLayout />
                        <ModalManager />
                    </ProtectedRoute>
                }
            >
                <Route
                    path={AppRoutes.Dashboard}
                    element={<Dashboard />}
                    loader={() => defer({ initialiseApp: getAppData(true) })}
                ></Route>
                <Route
                    path={AppRoutes.Preferences}
                    element={<PreferencesPage />}
                ></Route>
                <Route
                    path={AppRoutes.Examples}
                    element={<ExamplesPage />}
                    loader={() => defer({ initialiseApp: getAppData(true) })}
                ></Route>
                <Route
                    path={AppRoutes.NewResume}
                    element={<CreateResumePage />}
                    loader={() => defer({ initialiseApp: getAppData(true) })}
                ></Route>
                <Route
                    path={AppRoutes.Preview}
                    element={<PreviewPage />}
                    loader={({ params }) =>
                        defer({
                            initialiseApp: getAppData(true),
                            resumePromise: getResumeData(params.id!),
                        })
                    }
                ></Route>
                <Route
                    path={AppRoutes.Resume}
                    element={<ResumeBuilder />}
                    loader={({ params }) =>
                        defer({
                            initialiseApp: getAppData(),
                            resumePromise: getResumeData(params.id!),
                            assistantPromise: getAssistantItems(),
                        })
                    }
                ></Route>
                <Route
                    path={AppRoutes.Profile}
                    element={<ProfilePage />}
                ></Route>
            </Route>

            <Route
                path={AppRoutes.Admin}
                element={
                    <AdminProtectedRoute>
                        <AdminDashboardLayout />
                    </AdminProtectedRoute>
                }
            >
                <Route
                    path={AppRoutes.Users}
                    element={<Users />}
                ></Route>
                <Route
                    path={AppRoutes.User}
                    element={<UserForm />}
                    loader={({ params }) =>
                        defer({
                            getAdminUser: getAdminUser(params.userId),
                        })
                    }
                ></Route>
                <Route
                    path={AppRoutes.Sections}
                    element={<Sections />}
                ></Route>
                <Route
                    path={AppRoutes.Section}
                    element={<SectionForm />}
                    loader={({ params }) =>
                        defer({
                            getAdminSection: getAdminSection(params.sectionId),
                        })
                    }
                ></Route>
                <Route
                    path={AppRoutes.Styles}
                    element={<StylePage />}
                />
                <Route
                    path={AppRoutes.Templates}
                    element={<Templates />}
                ></Route>
                <Route
                    path={AppRoutes.Template}
                    element={<TemplateForm />}
                    loader={({ params }) =>
                        defer({
                            getAdminTemplate: getAdminTemplate(
                                params.templateId,
                            ),
                        })
                    }
                ></Route>
                <Route
                    path={AppRoutes.Assistant}
                    element={<AssistantItems />}
                ></Route>
                <Route
                    path={AppRoutes.AssistantItem}
                    element={<AssistantItemForm />}
                    loader={({ params }) =>
                        defer({
                            getAdminAssistantItem: getAdminAssistantItem(
                                params.assistantItemId,
                            ),
                        })
                    }
                ></Route>
                <Route
                    path={AppRoutes.DocumentTypes}
                    element={<DocumentTypes />}
                ></Route>
                <Route
                    path={AppRoutes.DocumentType}
                    element={<DocumentTypeForm />}
                    loader={({ params }) =>
                        defer({
                            getAdminDocumentType: getAdminDocumentType(
                                params.documentTypeId,
                            ),
                        })
                    }
                ></Route>
            </Route>
            <Route
                path="*"
                element={<ErrorPage error={ErrorTypes.notFound} />}
            ></Route>
        </Route>,
    ),
);
