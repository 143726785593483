import { ReactNode } from "react";

interface SideBarFieldContainerProps {
    children: ReactNode;
}

export const SideBarFieldContainer = ({
    children,
}: SideBarFieldContainerProps) => {
    return <div className="grid grid-cols-1 gap-4">{children}</div>;
};
