import clsx from "clsx";

interface AdminTableBodyCellProps {
    children: React.ReactNode;
    className?: string;
    onClick?: (e: React.MouseEvent<HTMLTableCellElement>) => void;
    width?: number;
}

export const AdminTableBodyCell = ({
    children,
    className,
    onClick,
    width,
}: AdminTableBodyCellProps) => {
    return (
        <td
            className={clsx(
                "overflow-hidden text-ellipsis whitespace-nowrap px-4 py-2 text-sm font-medium text-neutral-900",
                className,
            )}
            style={{
                maxWidth: width,
                width: width,
                overflow: "hidden",
                textOverflow: "ellipsis",
            }}
            onClick={onClick}
        >
            {children}
        </td>
    );
};
