import { EmailButton } from "@/email/components/EmailButton";
import { EmailHeading } from "@/email/components/EmailHeading";
import { EmailLink } from "@/email/components/EmailLink";
import { EmailText } from "@/email/components/EmailText";
import { EmailWrapper } from "@/email/components/EmailWrapper";
import { Section } from "@react-email/components";

const PasswordChangedEmail = ({
    appDomain = "https://app.1template.io",
    name = "User",
}: {
    appDomain: string;
    name: string;
}) => {
    return (
        <EmailWrapper preview="1Template password reset confirmation">
            <Section>
                <EmailHeading>
                    Your 1Template password has been reset.
                </EmailHeading>
                <Section>
                    <EmailText>Hi {name},</EmailText>
                    <EmailText>
                        Your password has successfully been updated.
                    </EmailText>
                    <EmailText noMargin>
                        You can now login using your new password.
                    </EmailText>
                    <EmailButton href={`${appDomain}/login`}>
                        Login to your account
                    </EmailButton>
                    <EmailText>
                        If this wasn’t you, please contact us at{" "}
                        <EmailLink href="mailto:hello@1template.io">
                            hello@1template.io
                        </EmailLink>
                        .
                    </EmailText>
                </Section>
            </Section>
        </EmailWrapper>
    );
};

export default PasswordChangedEmail;
