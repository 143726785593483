import { AppText } from "@/components/Text/AppText";
import { SectionField } from "@/types/section";
import { FormikErrors } from "formik";

interface InputErrorProps {
    error?: string | FormikErrors<SectionField>[];
}

export const InputError = ({ error }: InputErrorProps) => {
    if (!error) return null;

    let stringError = "";

    if (typeof error === "string") {
        stringError = error;
    } else {
        const errors = error.reduce<string[]>((acc, curr) => {
            const keys = Object.keys(curr);

            keys.forEach(key => {
                acc.push(curr[key]);
            });

            return acc;
        }, []);

        stringError = errors.join(", ");
    }

    return (
        <AppText
            variant="labelsbuttons"
            className="mt-1 block text-error-500"
        >
            {stringError}
        </AppText>
    );
};
