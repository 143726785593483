import { RootState } from "@/store/store";
import { createSelector } from "@reduxjs/toolkit";

export const getBillingState = (state: RootState) => state.billing;

export const getProducts = createSelector(
    getBillingState,
    billing => billing.products,
);

export const getProductsLoading = createSelector(
    getBillingState,
    billing => billing.isLoading,
);

export const getProductsError = createSelector(
    getBillingState,
    billing => billing.error,
);