import React from "react";
import { createRoot } from "react-dom/client";
import { RouterProvider } from "react-router-dom";
import { Provider } from "react-redux";
import { router } from "@/routes";
import { store } from "./store/store";
import "./index.css";
import { Notifications } from "./components/Notifications/Notifications";
import ErrorBoundary from "@/components/ErrorBoundary/ErrorBoundary";
import { DeviceProvider } from "@/context/DeviceProvider";
import { detectUserCountry } from "@/i18n";
import CookieConsent from "@/components/CookieConsent/CookieConsent";
import { TrackingManager } from "@/components/Tracking/TrackingManager";

const rootElement = document.getElementById("root");

if (rootElement === null)
    throw new Error("Root container missing in index.html");

const root = createRoot(rootElement);

detectUserCountry();

root.render(
    <React.StrictMode>
        <Provider store={store}>
            <DeviceProvider>
                <ErrorBoundary>
                    <RouterProvider router={router} />
                    <Notifications />
                </ErrorBoundary>
                <CookieConsent />
                <TrackingManager />
            </DeviceProvider>
        </Provider>
    </React.StrictMode>,
);
