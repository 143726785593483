import { PageHelmet } from "@/components/PageHelmet/PageHelmet";
import { AdminNavigationItem } from "@/types/admin";
import clsx from "clsx";

interface AdminFormContainer {
    navigationItems: AdminNavigationItem[];
    children: React.ReactNode;
    onNavigationItemClick: (id: string) => void;
    activeItemId: string;
    pageTitle: string;
}

export const AdminFormContainer = ({
    navigationItems,
    children,
    onNavigationItemClick,
    activeItemId,
    pageTitle,
}: AdminFormContainer) => {
    return (
        <>
            <PageHelmet
                title="Admin"
                prefix={pageTitle}
                robotsOverride="noindex, nofollow"
            />
            <div className="fixed flex w-52 flex-col pt-2">
                <div className="flex grow flex-col gap-y-5 overflow-y-auto border-r border-gray-200 bg-white px-6">
                    <nav className="flex flex-1 flex-col">
                        <ul
                            role="list"
                            className="flex flex-1 flex-col gap-y-7"
                        >
                            <li>
                                <ul
                                    role="list"
                                    className="-mx-2 space-y-1"
                                >
                                    {navigationItems.map(item => (
                                        <li key={item.label}>
                                            <div
                                                onClick={() =>
                                                    onNavigationItemClick(
                                                        item.id,
                                                    )
                                                }
                                                className={clsx(
                                                    activeItemId === item.id
                                                        ? "bg-gray-50 text-indigo-600"
                                                        : "text-gray-700 hover:bg-gray-50 hover:text-indigo-600",
                                                    "group flex cursor-pointer gap-x-3 rounded-md p-2 text-sm font-semibold leading-6",
                                                )}
                                            >
                                                {item.label}
                                            </div>
                                        </li>
                                    ))}
                                </ul>
                            </li>
                        </ul>
                    </nav>
                </div>
            </div>
            <main className="overflow-x-scroll pl-52 pt-2">{children}</main>
        </>
    );
};
