import React from "react";
import { SortAsc, SortDesc } from "lucide-react";

interface AdminTableHeaderCellProps {
    label: string;
    sortKey: string;
    currentSortColumn: string;
    currentSortDirection: "asc" | "desc";
    onSort: (key: string) => void;
    isSortable?: boolean;
    className?: string;
    width?: number;
}

export const AdminTableHeaderCell: React.FC<AdminTableHeaderCellProps> = ({
    label,
    sortKey,
    currentSortColumn,
    currentSortDirection,
    onSort,
    isSortable = true,
    className,
    width,
}) => {
    return (
        <th
            onClick={() => isSortable && onSort(sortKey)}
            className={
                className ||
                `px-4 py-3 text-left text-xs font-medium uppercase tracking-wider text-gray-500 transition duration-150 ease-in-out ${
                    isSortable ? "cursor-pointer hover:bg-gray-100" : ""
                }`
            }
            style={{ maxWidth: width, width: width }}
        >
            <div className="flex items-center">
                <span>{label}</span>
                {isSortable &&
                    currentSortColumn === sortKey &&
                    (currentSortDirection === "asc" ? (
                        <SortAsc className="ml-1 h-4 w-4" />
                    ) : (
                        <SortDesc className="ml-1 h-4 w-4" />
                    ))}
            </div>
        </th>
    );
};
