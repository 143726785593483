import { CSSProperties } from "react";
import { v4 as uuidv4 } from "uuid";

export const formatCapitalisedTitle = (title: string, prefix?: string) => {
  const words = title.replace(/([A-Z])/g, "$1").trim();

  const capitalizedTitle = words.charAt(0).toUpperCase() + words.slice(1).toLowerCase();
  return prefix ? `${prefix} ${capitalizedTitle}` : capitalizedTitle;
};

export const generateUniqueId = () => {
  return uuidv4();
};

export const kebabToCamelCase = (str: string) => {
  return str.replace(/-./g, match => match.charAt(1).toUpperCase());
};

export const objectToCssString = (styles: CSSProperties) => {
  return Object.entries(styles)
    .map(([key, value]) => {
      // Convert camelCase to kebab-case for CSS properties
      const kebabKey = key.replace(/([a-z])([A-Z])/g, '$1-$2').toLowerCase();
      return `${kebabKey}: ${value};`;
    })
    .join(' ');
};

// Insert a space wherever there are empty tags to ensure a new line is shown
export const insertBlankSpaceToEmptyTags = (html: string) => {
  return html.replace(/<(\w+)(\s[^>]*)?><\/\1>/g, '<$1$2>&nbsp;</$1>');
};

export const pluralise = (count: number, singular: string, plural: string) => {
  return count === 1 ? singular : plural;
};

export const formatCasing = (word: string, casing: "lower" | "title" | "upper" = "lower") => {
  switch (casing) {
    case "lower":
      return word.toLowerCase();
    case "title":
      return formatCapitalisedTitle(word);
    case "upper":
      return word.toUpperCase();
    default:
      return word;
  }
};

export const decodeHtmlEntities = (str: string): string => {
  const textArea = document.createElement("textarea");
  textArea.innerHTML = str;
  return textArea.value;
};