export type DeviceType = "mobile" | "tablet" | "desktop";

export interface DeviceContextType {
    deviceType: DeviceType;
    isMobile: boolean;
    isDesktop: boolean;
    isTablet: boolean;
}

export const DEVICE_TYPE = {
    MOBILE: "mobile",
    TABLET: "tablet",
    DESKTOP: "desktop",
} as const;
