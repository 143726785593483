import { ModalTypes } from '@/types/modal';
import { createSlice, PayloadAction } from '@reduxjs/toolkit';

interface ModalState {
  openModals: Record<string, boolean>;
  modalProps: Record<string, any>;
  modalStack: string[];
}

const initialState: ModalState = {
  openModals: {},
  modalProps: {},
  modalStack: [],
};

const modalSlice = createSlice({
  name: 'modal',
  initialState,
  reducers: {
    openModal: (state, action: PayloadAction<{ modalType: string; props?: Record<string, any>; }>) => {
      const { modalType, props } = action.payload;
      if (!state.openModals[modalType]) {
        state.openModals[modalType] = true;
        state.modalProps[modalType] = props || {};
        state.modalStack.push(modalType); // Add modal to the top of the stack
      }
    },
    closeModal: (state, action: PayloadAction<ModalTypes>) => {
      const modalType = action.payload;
      delete state.openModals[modalType];
      delete state.modalProps[modalType];
      state.modalStack = state.modalStack.filter(type => type !== modalType);
    },
    closeTopModal: (state) => {
      if (state.modalStack.length > 0) {
        const topModal = state.modalStack.pop();
        if (topModal) {
          delete state.openModals[topModal];
          delete state.modalProps[topModal];
        }
      }
    },
    closeAllModals: (state) => {
      state.openModals = {};
      state.modalProps = {};
      state.modalStack = [];
    },
  },
});

export const { openModal, closeModal, closeTopModal, closeAllModals } = modalSlice.actions;
export const { reducer: modalReducer } = modalSlice;
