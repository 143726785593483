// Filename structure:
// [userId]/resume/[resumeId]/[date]_[file.fieldname]_[file.originalname];

export const getDetailsFromImageSrc = (src: string) => {
    const path = src.split("1template.com/")[1];
    const parts = path.split("/");
    const userId = parts[0];
    const resumeId = parts[2];

    return {
        userId,
        resumeId,
    };
};