import { Tooltip } from "@/components/Tooltip/Tooltip";
import clsx from "clsx";

interface EditorToolbarButtonProps {
    onClick: () => void;
    isActive: boolean;
    disabled?: boolean;
    children: React.ReactNode;
    className?: string;
    tooltip?: string;
    "aria-label": string;
}

export const EditorToolbarButton = ({
    onClick,
    isActive,
    disabled,
    children,
    className,
    tooltip,
    "aria-label": ariaLabel,
}: EditorToolbarButtonProps) => {
    return (
        <button
            onClick={onClick}
            disabled={disabled}
            aria-label={ariaLabel}
            className={clsx(
                className,
                isActive && !disabled && "bg-neutral-200",
                isActive && disabled && "bg-neutral-200/25",
                "group relative flex h-9 w-9 items-center justify-center rounded-lg text-neutral-900 hover:bg-neutral-100 disabled:text-neutral-900/10 disabled:hover:bg-white",
            )}
        >
            <span aria-hidden="true">{children}</span>
            {tooltip && (
                <Tooltip
                    text={tooltip}
                    size="small"
                    className="min-w-28"
                    positionClassName={
                        tooltip.length > 12
                            ? "-translate-x-1/2 translate-y-12"
                            : "-translate-x-1/2 translate-y-10"
                    }
                />
            )}
        </button>
    );
};
