import {
    getDebugPageMargins,
    getTemplateLoadingState,
} from "@/store/app/selectors";
import { useAppSelector } from "@/hooks/types";
import clsx from "clsx";
import { TemplateLoadingState } from "@/types/app";
import { ReactNode, useMemo } from "react";
import { getActiveResumeGlobalStyles } from "@/store/resume/selectors";
import "../../pages/ResumeBuilder/resumeStyles.css";
import { getIsAResumePreviewPage } from "@/utils/routes";
import { ResumeStyles } from "@/types/resume";
import { PageMarginsDebugger } from "@/components/Page/PageMarginsDebugger";
import { GLOBAL_STYLE_KEY } from "@/constants/resume";

interface PageProps {
    children: ReactNode;
    pageNumber: number;
    overrideResumeStyles?: ResumeStyles;
}

export const Page = ({
    children,
    pageNumber,
    overrideResumeStyles,
}: PageProps) => {
    const isPreview = useMemo(() => getIsAResumePreviewPage(), []);
    const globalStyles = useAppSelector(getActiveResumeGlobalStyles);
    const loadingState = useAppSelector(getTemplateLoadingState);
    const styles = overrideResumeStyles?.[GLOBAL_STYLE_KEY] || globalStyles;
    const debugPageMargins = useAppSelector(getDebugPageMargins);

    return (
        <div
            id={`document-page-${pageNumber}`}
            className={clsx(
                isPreview || loadingState === TemplateLoadingState.READY
                    ? "opacity-100"
                    : "opacity-0",
                "document-page",
                isPreview &&
                    "pointer-events-none !translate-x-0 overflow-hidden",
                debugPageMargins && "relative",
            )}
            style={{
                fontFamily: styles?.fontFamily,
            }}
        >
            {children}
            {debugPageMargins && (
                <PageMarginsDebugger pageNumber={pageNumber} />
            )}
        </div>
    );
};
