import { Button } from "@/components/Button/Button";
import { Input } from "@/components/form/Input";
import { useAppDispatch, useAppSelector } from "@/hooks/types";
import { changeEmailSchema } from "@/pages/Profile/schema/changeEmail";
import { getUserDetails } from "@/store/user/selectors";
import { Formik } from "formik";
import { useCallback, useMemo, useState } from "react";
import userApi from "@/api/user";
import { addNotification } from "@/store/app/slice";
import { NotificationMessageType, NotificationType } from "@/types";
import { setUserEmail } from "@/store/user/slice";
import { sendVerifyEmail } from "@/email/send";
import { Modal } from "@/components/Modal/Modal";
import { VerifyCode } from "@/components/VerifyCode/VerifyCode";
import { AppText } from "@/components/Text/AppText";
import { ModalTypes } from "@/types/modal";
import { openModal } from "@/store/modal/slice";

interface ChangeEmailFormFields {
    currentEmail: string;
    password: string;
    newEmail: string;
}

export const ChangeEmail = () => {
    const userEmail = useAppSelector(getUserDetails)?.email;
    const dispatch = useAppDispatch();
    const initialValues: ChangeEmailFormFields = useMemo(
        () => ({
            currentEmail: userEmail ?? "",
            newEmail: "",
            password: "",
        }),
        [userEmail],
    );
    const [incorrectPassword, setIncorrectPassword] = useState(false);
    const [emailTaken, setEmailTaken] = useState(false);
    const [verifyingEmail, setVerifyingEmail] = useState<null | string>(null);
    const [loading, setLoading] = useState(false);

    const openVerifyEmailModal = (email: string) => {
        setVerifyingEmail(email);
        dispatch(openModal({ modalType: ModalTypes.VERIFY_EMAIL }));
    };

    const onSuccessVerify = useCallback(() => {
        dispatch(setUserEmail(verifyingEmail));
        setVerifyingEmail(null);
        dispatch(
            addNotification({
                title: "Successfully changed!",
                desc: "Email has been changed successfully!!",
                messageType: NotificationMessageType.EMAIL_CHANGED,
                type: NotificationType.SUCCESS,
            }),
        );
    }, [dispatch, verifyingEmail]);

    const onSubmit = async (
        values: ChangeEmailFormFields,
        resetForm: () => void,
    ) => {
        setLoading(true);
        await userApi
            .verifyChangeEmailApi({
                currentEmail: values.currentEmail,
                newEmail: values.newEmail,
                password: values.password,
            })
            .then(res => {
                if (res.code) {
                    sendVerifyEmail(values.newEmail, res.code.toString());
                    openVerifyEmailModal(values.newEmail);
                }
                resetForm();
            })
            .catch(error => {
                if (error.message === "Incorrect password") {
                    setIncorrectPassword(true);
                } else if (error.message === "Email already taken") {
                    setEmailTaken(true);
                } else {
                    dispatch(
                        addNotification({
                            title: "Error",
                            desc: "Unable to update email. Please try again",
                            messageType: NotificationMessageType.EMAIL_CHANGED,
                            type: NotificationType.ERROR,
                        }),
                    );
                }
            })
            .finally(() => {
                setLoading(false);
            });
    };

    return (
        <>
            <Formik
                initialValues={initialValues}
                validationSchema={changeEmailSchema}
                onSubmit={async (
                    values,
                    { setFieldError, resetForm, setFieldValue },
                ) => {
                    setIncorrectPassword(false);
                    if (values.newEmail === userEmail) {
                        setFieldError("newEmail", "Email must be different");
                    } else {
                        const resetFields = () => {
                            setFieldValue("password", "");
                            setFieldValue("newEmail", "");
                            resetForm({
                                values: initialValues,
                                errors: {},
                            });
                            setEmailTaken(false);
                            setIncorrectPassword(false);
                        };
                        await onSubmit(values, resetFields);
                    }
                }}
                validateOnBlur={false}
                validateOnChange={false}
                validateOnMount={false}
            >
                {({ values, errors, handleChange, handleSubmit }) => (
                    <form onSubmit={handleSubmit}>
                        <div className="mb-4 ">
                            <Input
                                label="New email"
                                autoComplete="username"
                                name="newEmail"
                                type="text"
                                required
                                formikChange={handleChange}
                                value={values.newEmail}
                                error={
                                    emailTaken
                                        ? "An account with this email already exists"
                                        : errors.newEmail
                                }
                            />
                        </div>
                        <div className="mb-4">
                            <Input
                                label="Current password"
                                autoComplete="current-password"
                                name="password"
                                type="password"
                                required
                                formikChange={handleChange}
                                value={values.password}
                                error={
                                    errors.password || incorrectPassword
                                        ? "Incorrect password"
                                        : undefined
                                }
                            />
                        </div>
                        <div className="text-right">
                            <Button
                                type="submit"
                                disabled={loading}
                                loading={loading}
                            >
                                Verify email
                            </Button>
                        </div>
                    </form>
                )}
            </Formik>
            <Modal
                open={!!verifyingEmail}
                title="Verify new email"
                onClose={() => setVerifyingEmail(null)}
                width="lg"
                mobileFullScreen
                modalType={ModalTypes.VERIFY_EMAIL}
            >
                <>
                    <AppText
                        variant="regular"
                        className="text-neutral-700"
                    >
                        We’ve sent a verification code to{" "}
                        <strong>{verifyingEmail}</strong>. Enter the code below
                        to verify and update your email.
                    </AppText>
                    <VerifyCode
                        onSuccess={onSuccessVerify}
                        email={verifyingEmail!}
                    />
                </>
            </Modal>
        </>
    );
};
