import React from "react";
import { AppText } from "@/components/Text/AppText";
import clsx from "clsx";

interface MenuGuideItem {
    icon: React.ReactNode;
    name: string;
    description: string;
}

interface MenuGuideTableProps {
    items: MenuGuideItem[];
}

const MenuGuideTableRow: React.FC<MenuGuideItem> = ({
    icon,
    name,
    description,
}) => (
    <tr className="hover:bg-neutral-100">
        <td className="whitespace-nowrap py-2 pl-1 text-neutral-900">
            <div className="flex h-8 w-8 items-center justify-center">
                {icon}
            </div>
        </td>
        <td className="whitespace-nowrap py-2 pl-2 pr-4 text-neutral-700">
            <AppText variant="regular">{name}</AppText>
        </td>
        <td className="py-2 pl-2 pr-4 text-neutral-700">
            <AppText variant="regular">{description}</AppText>
        </td>
    </tr>
);

const MenuGuideTable: React.FC<MenuGuideTableProps> = ({ items }) => {
    return (
        <div className="overflow-x-auto">
            <table className="min-w-full border-separate border-spacing-0">
                <thead className="sticky top-0 z-[1]">
                    <tr>
                        <th
                            scope="col"
                            className={clsx(
                                "z-10 border-b border-gray-300 bg-white bg-opacity-75 py-3.5 pr-2",
                                "w-16 text-neutral-900 backdrop-blur backdrop-filter",
                            )}
                        >
                            <AppText variant="labelsbuttons">Icon</AppText>
                        </th>
                        <th
                            scope="col"
                            className={clsx(
                                "z-10 border-b border-gray-300 bg-white bg-opacity-75 py-3.5 pl-2 pr-4",
                                "w-32 text-neutral-900 backdrop-blur backdrop-filter",
                            )}
                        >
                            <AppText variant="labelsbuttons">Name</AppText>
                        </th>
                        <th
                            scope="col"
                            className={clsx(
                                "z-10 border-b border-gray-300 bg-white bg-opacity-75 py-3.5 pl-2 pr-4",
                                "text-neutral-900 backdrop-blur backdrop-filter",
                            )}
                        >
                            <AppText variant="labelsbuttons">
                                Description
                            </AppText>
                        </th>
                    </tr>
                </thead>
                <tbody>
                    {items.map((item, index) => (
                        <MenuGuideTableRow
                            key={index}
                            {...item}
                        />
                    ))}
                </tbody>
            </table>
        </div>
    );
};

export default MenuGuideTable;
