import { useCallback, useEffect, useState } from "react";
import {
    DateFormat,
    PageMarginSize,
    ResumeStyles,
    SectionSpacingSize,
} from "@/types/resume";
import { SideBarHeader } from "../SideBarHeader";
import { useAppSelector, useAppDispatch } from "@/hooks/types";
import {
    getActiveResume,
    getActiveResumeGlobalStyles,
} from "@/store/resume/selectors";
import { setActiveResume } from "@/store/resume/slice";
import resumeApi from "@/api/resume";
import {
    GLOBAL_STYLE_KEY,
    resumeFontSizes,
    resumeFonts,
    resumeDateLocationPositions,
    resumeDateLocationLayouts,
    resumeHeadingFontSizes,
} from "@/constants/resume";
import { ResumeStyleOptions } from "@/components/Sidebar/Styles/ResumeStyleOptions";

export const SidebarStyles = () => {
    const activeResume = useAppSelector(getActiveResume);
    const globalStyles = useAppSelector(getActiveResumeGlobalStyles);
    const dispatch = useAppDispatch();
    const [font, setFont] = useState(
        globalStyles?.fontFamily ?? resumeFonts[0].value,
    );
    const [showIcons, setShowIcons] = useState(!!globalStyles.showIcons);
    const [underlineHeadings, setUnderlineHeadings] = useState(
        !!globalStyles.underlineSectionHeadings,
    );
    const [dateLocationPosition, setDateLocationPosition] = useState(
        globalStyles.dateLocationPosition ??
            (resumeDateLocationPositions[0].value as "column"),
    );
    const [dateLocationLayout, setDateLocationLayout] = useState(
        globalStyles.dateLocationLayout ??
            (resumeDateLocationLayouts[0].value as "column"),
    );
    const [fontSize, setFontSize] = useState(
        globalStyles?.fontSize ?? resumeFontSizes[0].value,
    );
    const [accentColour, setAccentColour] = useState<string>(
        globalStyles?.backgroundColor ?? "#FFFFFF",
    );
    const [dateFormat, setDateFormat] = useState(
        globalStyles?.dateFormat ?? DateFormat.LRG,
    );
    const [headingFontSize, setHeadingFontSize] = useState(
        globalStyles?.headingFontSize ?? resumeHeadingFontSizes[0].value,
    );
    const [pageMarginSize, setPageMarginSize] = useState(
        globalStyles?.pageMarginSize ?? PageMarginSize.MD,
    );
    const [sectionSpacingSize, setSectionSpacingSize] = useState(
        globalStyles?.sectionSpacingSize ?? SectionSpacingSize.MD,
    );

    useEffect(() => {
        setFont(globalStyles?.fontFamily ?? resumeFonts[0].value);
        setShowIcons(!!globalStyles.showIcons);
        setUnderlineHeadings(!!globalStyles.underlineSectionHeadings);
        setDateLocationPosition(
            globalStyles.dateLocationPosition ??
                (resumeDateLocationPositions[0].value as "column"),
        );
        setDateLocationLayout(
            globalStyles.dateLocationLayout ??
                (resumeDateLocationLayouts[0].value as "column"),
        );
        setFontSize(globalStyles?.fontSize ?? resumeFontSizes[0].value);
        setHeadingFontSize(
            globalStyles?.headingFontSize ?? resumeHeadingFontSizes[0].value,
        );
        setAccentColour(globalStyles?.backgroundColor ?? "#FFFFFF");
        setPageMarginSize(globalStyles?.pageMarginSize ?? PageMarginSize.MD);
        setSectionSpacingSize(
            globalStyles?.sectionSpacingSize ?? SectionSpacingSize.MD,
        );
    }, [globalStyles]);

    const updateResumeStyle = useCallback(
        (styles: ResumeStyles) => {
            if (!activeResume?.id) return;
            const newGlobalStyles = {
                ...activeResume.styles[GLOBAL_STYLE_KEY],
                ...styles[GLOBAL_STYLE_KEY],
            };

            const allStyles = {
                ...activeResume.styles,
                [GLOBAL_STYLE_KEY]: newGlobalStyles,
            };

            dispatch(
                setActiveResume({
                    ...activeResume,
                    styles: allStyles,
                }),
            );

            resumeApi.saveResume(activeResume.id, {
                name: activeResume.name,
                sections: activeResume.sections,
                styles: allStyles,
            });
        },
        [dispatch, activeResume],
    );

    const onFontSizeChange = useCallback(
        (value: string) => {
            setFontSize(value);
            updateResumeStyle({ [GLOBAL_STYLE_KEY]: { fontSize: value } });
        },
        [updateResumeStyle],
    );

    const onFontChange = useCallback(
        (font: string) => {
            setFont(font);
            updateResumeStyle({ [GLOBAL_STYLE_KEY]: { fontFamily: font } });
        },
        [updateResumeStyle],
    );

    const onHeadingFontSizeChange = useCallback(
        (value: string) => {
            setHeadingFontSize(value);
            updateResumeStyle({
                [GLOBAL_STYLE_KEY]: { headingFontSize: value },
            });
        },
        [updateResumeStyle],
    );

    const onAccentColourChange = useCallback(
        (hexColour: string) => {
            setAccentColour(hexColour);
            updateResumeStyle({
                [GLOBAL_STYLE_KEY]: { backgroundColor: hexColour },
            });
        },
        [updateResumeStyle],
    );

    const onIconsToggle = useCallback(
        (isEnabled: boolean) => {
            setShowIcons(isEnabled);
            updateResumeStyle({ [GLOBAL_STYLE_KEY]: { showIcons: isEnabled } });
        },
        [updateResumeStyle],
    );

    const onUnderlineToggle = useCallback(
        (isEnabled: boolean) => {
            setUnderlineHeadings(isEnabled);
            updateResumeStyle({
                [GLOBAL_STYLE_KEY]: { underlineSectionHeadings: isEnabled },
            });
        },
        [updateResumeStyle],
    );

    const onDateLocationPositionChange = useCallback(
        (layoutOption: "row" | "column") => {
            setDateLocationPosition(layoutOption);
            updateResumeStyle({
                [GLOBAL_STYLE_KEY]: { dateLocationPosition: layoutOption },
            });
        },
        [updateResumeStyle],
    );

    const onDateLocationLayoutChange = useCallback(
        (layoutOption: "row" | "column") => {
            setDateLocationPosition(layoutOption);
            updateResumeStyle({
                [GLOBAL_STYLE_KEY]: { dateLocationLayout: layoutOption },
            });
        },
        [updateResumeStyle],
    );

    const onDateFormatChange = useCallback(
        (format: DateFormat) => {
            setDateFormat(format);
            updateResumeStyle({ [GLOBAL_STYLE_KEY]: { dateFormat: format } });
        },
        [updateResumeStyle],
    );

    const onPageMarginSizeChange = useCallback(
        (size: PageMarginSize) => {
            setPageMarginSize(size);
            updateResumeStyle({ [GLOBAL_STYLE_KEY]: { pageMarginSize: size } });
        },
        [updateResumeStyle],
    );

    const onSectionSpacingSizeChange = useCallback(
        (size: SectionSpacingSize) => {
            setSectionSpacingSize(size);
            updateResumeStyle({
                [GLOBAL_STYLE_KEY]: { sectionSpacingSize: size },
            });
        },
        [updateResumeStyle],
    );

    return (
        <>
            <SideBarHeader title="Style Resume" />
            <div className="no-scrollbar mx-4 h-full overflow-y-auto overflow-x-hidden">
                <ResumeStyleOptions
                    font={font}
                    onFontChange={onFontChange}
                    fontSize={fontSize}
                    onFontSizeChange={onFontSizeChange}
                    accentColour={accentColour}
                    onAccentColourChange={onAccentColourChange}
                    showIcons={showIcons}
                    onIconsToggle={onIconsToggle}
                    underlineHeadings={underlineHeadings}
                    onUnderlineToggle={onUnderlineToggle}
                    dateLocationPosition={dateLocationPosition}
                    onDateLocationPositionChange={onDateLocationPositionChange}
                    dateLocationLayout={dateLocationLayout}
                    onDateLocationLayoutChange={onDateLocationLayoutChange}
                    dateFormat={dateFormat}
                    onDateFormatChange={onDateFormatChange}
                    headingFontSize={headingFontSize}
                    onHeadingFontSizeChange={onHeadingFontSizeChange}
                    pageMarginSize={pageMarginSize}
                    onPageMarginSizeChange={onPageMarginSizeChange}
                    sectionSpacingSize={sectionSpacingSize}
                    onSectionSpacingSizeChange={onSectionSpacingSizeChange}
                />
            </div>
        </>
    );
};
