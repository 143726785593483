import { object, string, array, bool } from "yup";

export const documentTypeSchema = object({
    name: string().required(),
    assistantSections: array().of(object({
        title: string().required(),
        content: string().required(),
        isExpandable: bool().required(),
    })),
});
