import sectionApi from "@/api/section";
import templateApi from "@/api/template";
import documentTypeApi from "@/api/documentType";
import userApi from "@/api/user";
import assistantApi from "@/api/assistant";

export const getAdminSection = (id?: string) => {
    try {
        return new Promise(resolve => {
            const sectionId = id !== "new" ? id : undefined;
            if (!sectionId) return resolve(null);
            return sectionApi
                .getSection(sectionId)
                .then(section => {
                    resolve(section);
                })
                .catch(err => {
                    resolve(err);
                });
        });
    } catch (e) {
        console.error("Failed to get section", e);
    }
};

export const getAdminTemplate = (id?: string) => {
    try {
        return new Promise(resolve => {
            const templateId = id !== "new" ? id : undefined;
            if (!templateId) return resolve(null);
            return templateApi
                .getTemplate(templateId)
                .then(template => {
                    resolve(template);
                })
                .catch(err => {
                    resolve(err);
                });
        });
    } catch (e) {
        console.error("Failed to get template", e);
    }
};

export const getAdminDocumentType = (id?: string) => {
    try {
        return new Promise(resolve => {
            const documentTypeId = id !== "new" ? id : undefined;
            if (!documentTypeId) return resolve(null);
            return documentTypeApi
                .getDocumentType(documentTypeId)
                .then(docType => {
                    resolve(docType);
                })
                .catch(err => {
                    resolve(err);
                });
        });
    } catch (e) {
        console.error("Failed to get documentType", e);
    }
};

export const getAdminUser = (id?: string) => {
    try {
        return new Promise(resolve => {
            const userId = id !== "new" ? id : undefined;
            if (!userId) return resolve(null);
            return userApi
                .getUserApi(userId)
                .then(user => {
                    resolve(user);
                })
                .catch(err => {
                    resolve(err);
                });
        });
    } catch (e) {
        console.error("Failed to get user", e);
    }
};

export const getAdminAssistantItem = (id?: string) => {
    try {
        return new Promise(resolve => {
            const assistantItemId = id !== "new" ? id : undefined;
            if (!assistantItemId) return resolve(null);
            return assistantApi
                .getAssistantItem(assistantItemId)
                .then(assistantItem => {
                    resolve(assistantItem);
                })
                .catch(err => {
                    resolve(err);
                });
        });
    } catch (e) {
        console.error("Failed to get assistant item", e);
    }
};