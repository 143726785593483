interface AdminTableHeaderProps {
    children: React.ReactNode;
}

export const AdminTableHeader = ({ children }: AdminTableHeaderProps) => {
    return (
        <thead className="sticky top-0 z-10 bg-gray-50">
            <tr>{children}</tr>
        </thead>
    );
};
