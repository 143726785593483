export enum AppRoutes {
    Admin = "admin",
    Assistant = "assistant",
    AssistantItem = "assistant/:assistantItemId",
    Dashboard = "/",
    DocumentType = "document-types/:documentTypeId",
    DocumentTypes = "document-types",
    Examples = "examples",
    ForgotPassword = "forgot-password",
    Login = "login",
    Logout = "logout",
    NewResume = "new-resume",
    Preferences = "preferences",
    Preview = "preview/:id",
    Profile = "profile",
    ResetPassword = "reset-password",
    Resume = "resume/:id",
    Section = "sections/:sectionId",
    Sections = "sections",
    Signup = "signup",
    Styles = "styles",
    Template = "templates/:templateId",
    Templates = "templates",
    User = "users/:userId",
    Users = "users",
    Verify = "verify"
}

export enum SiteRoutes {
    PrivacyPolicy = "https://1template.io/privacy-policy",
    Terms = "https://1template.io/terms-of-service",
    CookiePolicy = "https://1template.io/privacy-policy#using-our-platform-and-cookies"
}
