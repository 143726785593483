import { ScrollRestoration, useOutlet } from "react-router-dom";
import { Navigation } from "@/components/Navigation/Navigation";
import clsx from "clsx";
import {
    getIsAResumePreviewPage,
    getIsNewResumePage,
    getIsPreferencesPage,
    getIsPreviewPage,
    getIsProfilePage,
    getIsResumePage,
} from "@/utils/routes";
import { PlansModal } from "@/components/Modals/PlansModal/PlansModal";

export const AppLayout = () => {
    const outlet = useOutlet();
    const isResumePage = getIsResumePage();
    const isNewResumePage = getIsNewResumePage();
    const isAResumePreviewPage = getIsAResumePreviewPage();
    const isPreferencesPages = getIsPreferencesPage();
    const isPreviewPage = getIsPreviewPage();
    const isProfilePage = getIsProfilePage();

    return (
        <>
            <div
                className={clsx(
                    "relative bg-neutral-100",
                    (isAResumePreviewPage || isPreviewPage || isProfilePage) &&
                        "min-h-full",
                )}
            >
                {!isNewResumePage && !isPreferencesPages && <Navigation />}
                <div
                    className={clsx(
                        isResumePage ? "bg-neutral-100" : "",
                        "relative",
                    )}
                >
                    <ScrollRestoration />
                    {outlet}
                </div>
            </div>
            <PlansModal />
        </>
    );
};
