import axios from "axios";
import { baseURL } from "./constants";
import { registerInterceptor } from "./interceptor";

export const client = axios.create({
    baseURL,
    headers: {
        "Content-Type": "application/json",
    },
});

registerInterceptor(client);
