export interface AssistantItemDocumentType {
    title: string;
    content: string;
    isExpandable: boolean;
}

export enum AssistantItemSectionContentType {
    DEFAULT = "default",
    EXAMPLE = "example",
}

export interface AssistantItemSectionType {
    content: string;
    contentType: AssistantItemSectionContentType;
    title?: string;
    isExpandable?: boolean;
}

export enum AssistantItemTypes {
    DOCUMENT = "document",
    SECTION = "section",
}

export enum AssistantItemStatus {
    DRAFT = "draft",
    PUBLISHED = "published",
}

export interface AssistantItemBase {
    id: string;
    name: string;
    type: AssistantItemTypes;
    documentType: string;
    section: string;
    jobRoleType: string;
    createdAt: Date;
    updatedAt: Date;
    status: AssistantItemStatus;
}

export interface AssistantDocumentItem extends AssistantItemBase {
    type: AssistantItemTypes.DOCUMENT;
    properties: AssistantItemDocumentType;
}

export interface AssistantSectionItem extends AssistantItemBase {
    type: AssistantItemTypes.SECTION;
    properties: AssistantItemSectionType;
}

export type AssistantItem = AssistantDocumentItem | AssistantSectionItem;

export enum AssistantTabState {
    DOCUMENT = 0,
    SECTION = 1,
}