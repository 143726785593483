import React, { useMemo } from "react";
import { getTextStyle } from "@/components/PdfDocument/utils/getStyles";
import { useAppSelector } from "@/hooks/types";
import { getSummarySectionPreviewText } from "@/pages/CreateResume/getSummarySectionPreviewText";
import { getUserPreferenceJobRoles } from "@/store/wizard/selectors";
import { ColumnLayout } from "@/types/resume";
import clsx from "clsx";
import { motion, AnimatePresence } from "framer-motion";
import { AtSignIcon } from "lucide-react";

interface CreateResumePreviewProps {
    showUnderline: boolean;
    showImage: boolean;
    showIcons: boolean;
    fontFamily: string;
    fontSize: string;
    headingFontSize: string;
    backgroundColor: string;
    columnLayout: ColumnLayout;
    userFullName: string;
    userEmail: string;
}

const SUMMARY_TITLE = "Summary";

const TextWrapper = ({
    fontSize,
    headingFontSize,
    variant,
    children,
    fontFamily,
}: {
    fontSize: string;
    headingFontSize: string;
    variant: "h1" | "h2" | "p";
    children: React.ReactNode;
    fontFamily: string;
}) => {
    const isHeading = variant !== "p";
    const textStyle = getTextStyle(
        isHeading ? headingFontSize : fontSize,
        variant,
        isHeading,
    );
    switch (variant) {
        case "h1":
            return (
                <h1
                    className="text-resume-large-1"
                    style={{ ...textStyle, fontFamily }}
                >
                    {children}
                </h1>
            );
        case "h2":
            return (
                <h3
                    className="text-resume-large-3"
                    style={{ ...textStyle, fontFamily }}
                >
                    {children}
                </h3>
            );
        default:
            return (
                <p
                    className="text-resume-regular-5"
                    style={{ ...textStyle, fontFamily }}
                >
                    {children}
                </p>
            );
    }
};

export const CreateResumePreview = ({
    showUnderline,
    showImage,
    showIcons,
    fontFamily,
    fontSize,
    headingFontSize,
    backgroundColor,
    columnLayout,
    userFullName,
    userEmail,
}: CreateResumePreviewProps) => {
    const userJobRoles = useAppSelector(getUserPreferenceJobRoles);
    const summarySectionText = useMemo(
        () => getSummarySectionPreviewText(userJobRoles),
        [userJobRoles],
    );
    return (
        <div className="flex h-[390px] w-[640px] flex-col overflow-hidden rounded-lg ring-1 ring-neutral-300">
            <div className="flex flex-col rounded-lg ">
                <motion.div
                    className="mb-2 flex h-32 items-center rounded-t-lg px-6 transition-colors"
                    style={{ backgroundColor }}
                >
                    <div className="w-full transition-all">
                        <TextWrapper
                            variant="h1"
                            fontSize={fontSize}
                            headingFontSize={headingFontSize}
                            fontFamily={fontFamily}
                        >
                            {userFullName}
                        </TextWrapper>
                        <div>
                            <motion.div
                                className="flex flex-row items-center gap-2"
                                animate={showIcons ? "show" : "hide"}
                                variants={{
                                    show: {
                                        transform: "translateX(0px)",
                                    },
                                    hide: {
                                        transform: "translateX(-24px)",
                                    },
                                }}
                            >
                                <motion.div
                                    animate={showIcons ? "show" : "hide"}
                                    variants={{
                                        show: {
                                            transform: `scale(1) translateX(0)`,
                                        },
                                        hide: {
                                            transform: "scale(0)",
                                        },
                                    }}
                                >
                                    <AtSignIcon size={18} />
                                </motion.div>
                                <TextWrapper
                                    variant="p"
                                    fontSize={fontSize}
                                    headingFontSize={headingFontSize}
                                    fontFamily={fontFamily}
                                >
                                    {userEmail}
                                </TextWrapper>
                            </motion.div>
                        </div>
                    </div>
                    <motion.div
                        animate={showImage ? "show" : "hide"}
                        variants={{
                            show: {
                                transform: "scale(1)",
                            },
                            hide: {
                                transform: "scale(0)",
                            },
                        }}
                        className="h-24 w-24 flex-shrink-0 rounded-full bg-neutral-100"
                    />
                </motion.div>
                <div
                    className={clsx(
                        "grid h-[400px] auto-rows-max grid-cols-3 gap-4 px-4",
                    )}
                >
                    <AnimatePresence mode="wait">
                        {columnLayout === ColumnLayout.SINGLE ? (
                            <motion.div
                                key="single"
                                initial={{ opacity: 0 }}
                                animate={{ opacity: 1 }}
                                exit={{ opacity: 0 }}
                                transition={{ duration: 0.3 }}
                                className="col-span-3 grid grid-cols-3 gap-4"
                            >
                                <motion.div
                                    layout
                                    className="col-span-3 rounded-lg px-2"
                                    style={{ maxWidth: "100%" }}
                                >
                                    <TextWrapper
                                        variant="h2"
                                        fontSize={fontSize}
                                        headingFontSize={headingFontSize}
                                        fontFamily={fontFamily}
                                    >
                                        {SUMMARY_TITLE}
                                    </TextWrapper>
                                    <motion.div
                                        animate={
                                            showUnderline ? "show" : "hide"
                                        }
                                        variants={{
                                            show: {
                                                transform: "scale(1)",
                                            },
                                            hide: {
                                                transform: "scale(0)",
                                            },
                                        }}
                                        className="mb-1 h-1 w-[98%] border-b-2 border-neutral-900"
                                    />
                                    <div style={{ maxWidth: "100%" }}>
                                        <TextWrapper
                                            variant="p"
                                            fontSize={fontSize}
                                            headingFontSize={headingFontSize}
                                            fontFamily={fontFamily}
                                        >
                                            {summarySectionText}
                                        </TextWrapper>
                                    </div>
                                </motion.div>
                                <motion.div
                                    layoutId="2"
                                    className="col-span-3 h-20 rounded-lg bg-neutral-100"
                                />
                                <motion.div
                                    layoutId="3"
                                    className="col-span-3 h-20 rounded-lg bg-neutral-100"
                                />
                                <motion.div
                                    layoutId="4"
                                    className="col-span-3 h-20 rounded-lg bg-neutral-100"
                                />
                            </motion.div>
                        ) : (
                            <motion.div
                                key="double"
                                initial={{ opacity: 0 }}
                                animate={{ opacity: 1 }}
                                exit={{ opacity: 0 }}
                                transition={{ duration: 0.3 }}
                                className="col-span-3 grid grid-cols-3 gap-4"
                            >
                                <motion.div
                                    layout
                                    className="col-span-2 rounded-lg px-2"
                                    style={{ maxWidth: "100%" }}
                                >
                                    <TextWrapper
                                        variant="h2"
                                        fontSize={fontSize}
                                        headingFontSize={headingFontSize}
                                        fontFamily={fontFamily}
                                    >
                                        {SUMMARY_TITLE}
                                    </TextWrapper>
                                    <motion.div
                                        animate={
                                            showUnderline ? "show" : "hide"
                                        }
                                        variants={{
                                            show: {
                                                transform: "scale(1)",
                                            },
                                            hide: {
                                                transform: "scale(0)",
                                            },
                                        }}
                                        className="mb-1 h-1 w-[100%] border-b-2 border-neutral-900"
                                    />
                                    <div style={{ maxWidth: "100%" }}>
                                        <TextWrapper
                                            variant="p"
                                            fontSize={fontSize}
                                            headingFontSize={headingFontSize}
                                            fontFamily={fontFamily}
                                        >
                                            {summarySectionText}
                                        </TextWrapper>
                                    </div>
                                </motion.div>
                                <motion.div
                                    layoutId="2"
                                    className="col-span-1 h-32 rounded-lg bg-neutral-100"
                                />

                                <motion.div
                                    layoutId="3"
                                    className="col-span-2 h-32 rounded-lg bg-neutral-100"
                                />
                                <motion.div
                                    layoutId="4"
                                    className="col-span-1 h-32 rounded-lg bg-neutral-100"
                                />
                            </motion.div>
                        )}
                    </AnimatePresence>
                </div>
            </div>
        </div>
    );
};
