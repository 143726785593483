import { FieldIcon } from "@/components/FieldIcon/FieldIcon";
import { getTextStyle } from "@/components/PdfDocument/utils/getStyles";
import { GLOBAL_STYLE_KEY } from "@/constants/resume";
import { decodeHtml } from "@/pages/ResumeBuilder/utils";
import { ResumeStyles } from "@/types/resume";
import { insertBlankSpaceToEmptyTags } from "@/utils/string";

interface ReadOnlyBodyTextProps {
    value: string;
    icon?: string;
    stylesConfig: ResumeStyles;
}

export const ReadOnlyBodyText = ({
    value,
    icon,
    stylesConfig,
}: ReadOnlyBodyTextProps) => {
    const decodedValue = decodeHtml(value);
    const textStyle = getTextStyle(
        stylesConfig?.[GLOBAL_STYLE_KEY]?.fontSize ?? "",
        "p",
        false,
    );
    const valueWithSpaces = insertBlankSpaceToEmptyTags(decodedValue);
    return (
        <div className="ProseMirror flex flex-row items-center">
            {icon && (
                <FieldIcon
                    name={icon}
                    stylesConfig={stylesConfig}
                />
            )}
            {decodedValue.startsWith("<p") ? (
                <div
                    style={{
                        ...textStyle,
                        wordBreak: "break-word",
                    }}
                    dangerouslySetInnerHTML={{ __html: valueWithSpaces }}
                />
            ) : (
                <p
                    style={{
                        ...textStyle,
                        wordBreak: "break-word",
                    }}
                    dangerouslySetInnerHTML={{
                        __html: valueWithSpaces ?? "",
                    }}
                />
            )}
        </div>
    );
};
