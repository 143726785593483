import clsx from "clsx";

interface ChipProps {
    children: React.ReactNode;
    onClick?: () => void;
    type: "primary" | "secondary";
    className?: string;
}

const Chip = ({ children, onClick, type, className }: ChipProps) => {
    return (
        <div
            className={clsx(
                "align-items flex gap-2 rounded-lg px-2 py-1",
                type === "primary"
                    ? "bg-primary-600 text-white"
                    : "bg-primary-200 text-neutral-900",
                className,
            )}
            onClick={onClick}
        >
            {children}
        </div>
    );
};

export default Chip;
