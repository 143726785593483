import { AppText } from "@/components/Text/AppText";
import {
    Menu,
    MenuButton,
    MenuItem,
    MenuItems,
    Transition,
} from "@headlessui/react";
import { ChevronDownIcon } from "@heroicons/react/20/solid";
import clsx from "clsx";
import { CircleCheckIcon } from "lucide-react";
import { Fragment, ReactNode } from "react";

interface DropdownItemProps {
    title: string;
    id: string;
    icon?: ReactNode;
}

interface DropdownProps {
    items: DropdownItemProps[];
    activeId: string;
    handleChange: (id: string) => void;
    title?: string | ReactNode;
    className?: string;
    leftIcon?: ReactNode;
    disabled?: boolean;
}

export const Dropdown = ({
    items,
    activeId,
    handleChange,
    title,
    className,
    leftIcon,
    disabled,
}: DropdownProps) => {
    return (
        <Menu
            as="div"
            className={clsx(
                "relative inline-block min-w-[144px] max-w-[328px] text-left",
                className,
            )}
        >
            {({ open }) => (
                <>
                    <MenuButton
                        className="relative flex h-9 w-full items-center justify-between gap-x-1.5 rounded-lg bg-neutral-50 px-3 py-2 text-neutral-900 ring-2 ring-inset ring-neutral-300 hover:bg-neutral-100 disabled:opacity-40 disabled:hover:bg-neutral-50"
                        disabled={disabled}
                    >
                        <div className="flex flex-row gap-2 pr-6">
                            {leftIcon}
                            <AppText
                                variant="labelsbuttons"
                                className="text-neutral-900"
                            >
                                {title}
                            </AppText>
                        </div>
                        <ChevronDownIcon
                            className={clsx(
                                open && "rotate-180",
                                "absolute right-3 -mr-1 h-5 w-5 text-neutral-900",
                            )}
                            aria-hidden="true"
                        />
                    </MenuButton>

                    <Transition
                        as={Fragment}
                        enter="transition ease-out duration-100"
                        enterFrom="transform opacity-0 scale-95"
                        enterTo="transform opacity-100 scale-100"
                        leave="transition ease-in duration-75"
                        leaveFrom="transform opacity-100 scale-100"
                        leaveTo="transform opacity-0 scale-95"
                    >
                        <MenuItems
                            className="absolute z-10 my-2 max-h-56 w-full origin-top-right overflow-x-hidden rounded-lg bg-neutral-50 shadow-lg ring-2 ring-inset ring-neutral-300 focus:outline-none"
                            modal={false}
                        >
                            {!!items.length &&
                                items.map(({ id, title, icon }, index) => (
                                    <MenuItem key={id}>
                                        <a
                                            className={clsx(
                                                "flex h-10 cursor-pointer items-center justify-between border-neutral-300 bg-clip-padding px-3 hover:bg-neutral-100",
                                                index === items.length - 1
                                                    ? "rounded-b-lg border-2"
                                                    : "border-l-2 border-r-2 border-t-2",
                                                index === 0 && "rounded-t-lg",
                                            )}
                                            onClick={() => handleChange(id)}
                                        >
                                            <div className="flex items-center">
                                                {icon && (
                                                    <span className="mr-2">
                                                        {icon}
                                                    </span>
                                                )}
                                                <AppText
                                                    variant="labelsbuttons"
                                                    className="text-neutral-900"
                                                >
                                                    {title}
                                                </AppText>
                                            </div>
                                            {activeId === id && (
                                                <CircleCheckIcon />
                                            )}
                                        </a>
                                    </MenuItem>
                                ))}
                        </MenuItems>
                    </Transition>
                </>
            )}
        </Menu>
    );
};
