import { useAppDispatch, useAppSelector } from "@/hooks/types";
import {
    getSectionsByActiveDocumentType,
    getActiveResume,
} from "@/store/resume/selectors";
import { setActiveResume } from "@/store/resume/slice";
import {
    getSectionIdsWithNewColumnIndexes,
    setResumeSectionsIndexes,
} from "@/store/resume/utils";
import { ResumeSections } from "@/types";
import { ColumnLayout } from "@/types/resume";
import { useEffect, useState } from "react";
import resumeApi from "@/api/resume";
import { resumeLayouts } from "@/constants/resume";
import clsx from "clsx";
import { Tooltip } from "@/components/Tooltip/Tooltip";
import { updateMeasurements } from "@/store/pages/slice";
import { getMeasurements } from "@/store/pages/selectors";

interface LayoutTogglesProps {
    onLayoutChange?: (layout: ColumnLayout) => void;
    overrideActiveLayout?: ColumnLayout;
}

export const LayoutToggles = ({
    onLayoutChange,
    overrideActiveLayout,
}: LayoutTogglesProps) => {
    const sections = useAppSelector(getSectionsByActiveDocumentType);
    const measurements = useAppSelector(getMeasurements);
    const activeResume = useAppSelector(getActiveResume);
    const [activeLayout, setActiveLayout] = useState<ColumnLayout>(
        overrideActiveLayout ||
            activeResume?.columnLayout ||
            ColumnLayout.SINGLE,
    );
    const dispatch = useAppDispatch();

    useEffect(() => {
        setActiveLayout(
            overrideActiveLayout ||
                activeResume?.columnLayout ||
                ColumnLayout.SINGLE,
        );
    }, [overrideActiveLayout, activeResume]);

    const setColumnLayout = (layout: ColumnLayout) => {
        if (!activeResume?.id) {
            onLayoutChange?.(layout);
            setActiveLayout(layout);
            return;
        }
        const resumeSections: ResumeSections = JSON.parse(
            JSON.stringify(activeResume.sections),
        );

        const resumeSectionsWithIndex = setResumeSectionsIndexes(
            resumeSections,
            layout,
            sections,
            measurements,
        );

        // Update measurements to ensure they have the correct column index set
        const sectionsWithNewColumnIndexes = getSectionIdsWithNewColumnIndexes(
            measurements,
            resumeSectionsWithIndex,
            sections,
        );

        resumeApi
            .saveResume(activeResume.id, {
                columnLayout: layout,
                sections: resumeSectionsWithIndex,
            })
            .then(resume => {
                dispatch(setActiveResume({ ...resume }));
                dispatch(updateMeasurements(sectionsWithNewColumnIndexes));
                setActiveLayout(layout);
            });
    };

    return (
        <div className="grid h-10 grid-cols-2 gap-1 rounded bg-neutral-100 p-1">
            {resumeLayouts.map(layout => (
                <button
                    key={layout.id}
                    onClick={() => setColumnLayout(layout.id)}
                    className={clsx(
                        "group relative flex flex-col items-center justify-center gap-1 rounded-lg p-1",
                        activeLayout === layout.id
                            ? "bg-primary-900 text-white hover:bg-primary-800"
                            : "bg-transparent text-neutral-700 hover:bg-primary-200 hover:text-neutral-900",
                    )}
                >
                    <layout.icon
                        size={24}
                        className={
                            layout.id === ColumnLayout.DOUBLE
                                ? "rotate-180"
                                : ""
                        }
                    />
                    <Tooltip
                        text={layout.name}
                        size="small"
                        className="w-12"
                        yPosition={80}
                    />
                </button>
            ))}
        </div>
    );
};
