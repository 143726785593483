import { AppText } from "@/components/Text/AppText";
import { ReactNode } from "react";

interface SideBarFieldProps {
    children: ReactNode;
    title: string;
    vertical?: boolean;
}

export const SideBarField = ({
    children,
    title,
    vertical = false,
}: SideBarFieldProps) => {
    return (
        <div
            className={` ${
                vertical ? "flex flex-col" : "grid grid-cols-2 items-center"
            }`}
        >
            <AppText
                variant="labelsbuttons"
                className="text-neutral-700"
            >
                {title}
            </AppText>
            {children}
        </div>
    );
};
