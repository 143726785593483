import { kebabToCamelCase } from "@/utils/string";

export const parseStyles = (styleString: string) => {
    const styleObject: { [key: string]: string; } = {};
    const styles = styleString.split(";").filter(style => style.trim());
    styles.forEach(style => {
        const [key, value] = style.split(":").map(item => item.trim());
        if (key && value) {
            const camelCaseKey = kebabToCamelCase(key);
            styleObject[camelCaseKey] = value;
        }
    });
    return styleObject;
};