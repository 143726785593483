import { useDroppable } from "@dnd-kit/core";
import {
    SortableContext,
    verticalListSortingStrategy,
} from "@dnd-kit/sortable";
import { TemplateSectionItem } from "./TemplateSectionItem";
import { SortableTemplateSectionItem } from "./SortableTemplateSectionItem";
import { AppText } from "@/components/Text/AppText";
import { SectionGridItem } from "@/pages/Admin/Templates/types";

type TemplateSectionColumnProps = {
    id: string;
    title: string;
    sections: SectionGridItem[];
};

export const TemplateSectionColumn = ({
    id,
    title,
    sections,
}: TemplateSectionColumnProps) => {
    const { setNodeRef } = useDroppable({
        id,
    });

    return (
        <div className="rounded bg-neutral-100 p-4">
            <AppText
                variant="contextheading"
                className="mb-2 border-b-2 border-neutral-700 pb-2 text-center text-primary-700"
            >
                {title === "0" ? "Unused Sections" : `Column ${title}`}
            </AppText>
            <SortableContext
                id={id}
                items={sections}
                strategy={verticalListSortingStrategy}
            >
                <div ref={setNodeRef}>
                    {sections.map(section => (
                        <SortableTemplateSectionItem
                            key={section.id}
                            id={section.id}
                        >
                            <TemplateSectionItem section={section} />
                        </SortableTemplateSectionItem>
                    ))}
                </div>
            </SortableContext>
        </div>
    );
};
