import { SectionWidth } from "./section";

export type ComponentPropsObject = Record<string, string>;

export type SectionItemDetails = ComponentPropsObject & {
    __id: string;
    hiddenFields: string[];
};

export interface SectionDetails {
    header: ComponentPropsObject;
    body: SectionItemDetails[];
    hiddenFields: string[];
    activeWidth: SectionWidth;
    columnIndex: number;
    positionIndex: number;
    sectionName: string;
    layout: ComponentPropsObject;
    sectionConfigId: string; // Used to define the section config
    __isDefaultContent?: boolean;
}

export interface ResumeSections {
    [key: string]: SectionDetails;
}

export type DateLocationPosition = "row" | "column" | null;
export type DateLocationLayout = "row" | "column" | null;

export enum DateFormat {
    "LRG" = "MMMM YYYY",
    "MED" = "MMM YYYY",
    "SM" = "MM/YYYY",
    "XS" = "MM/YY",
}

export enum PageMarginSize {
    XS = 1,
    SM = 2,
    MD = 3,
    LG = 4,
    XL = 5,
}

export enum SectionSpacingSize {
    XS = 1,
    SM = 2,
    MD = 3,
    LG = 4,
    XL = 5,
}

export interface StyleConfig {
    className?: string;
    fontSize?: string;
    headingFontSize?: string;
    fontFamily?: string;
    backgroundColor?: string;
    showIcons?: boolean;
    underlineSectionHeadings?: boolean;
    dateLocationPosition?: DateLocationPosition;
    dateLocationLayout?: DateLocationLayout;
    dateFormat?: DateFormat;
    pageMarginSize?: PageMarginSize;
    sectionSpacingSize?: SectionSpacingSize;
    borderRadius?: string;
    borderColor?: string;
    borderWidth?: string;
    borderStyle?: string;
}

export interface ResumeStyles {
    [key: string]: StyleConfig;
}

export enum ColumnLayout {
    SINGLE = 1,
    DOUBLE = 2,
}

export enum DocumentVisibility {
    PUBLIC = "public",
    PRIVATE = "private",
    UNLISTED = "unlisted",
}

export interface Resume {
    user: string; // Object ID
    styles: ResumeStyles;
    name: string;
    id: string; // Object ID
    createdAt: Date;
    updatedAt: Date;
    sections: ResumeSections;
    template: string; // Object ID
    columnLayout: ColumnLayout;
    documentType: string; // Object ID
    visibility: DocumentVisibility;
    isTemplate: boolean;
    templateUsageCount: number | null;
}

export interface DocumentContextType {
    activeDocument: Resume | null;
    setDocumentById: (documentId: string) => void;
    useActiveDocument: () => void;
}