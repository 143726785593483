import { A4_PAGE_HEIGHT_PIXELS, A4_PAGE_WIDTH_PIXELS } from "@/store/pages/constants";
import { StyleSheet } from "@react-pdf/renderer";

export const pdfStyles = StyleSheet.create({
    // Page Styles
    pageLayout: {
        width: A4_PAGE_WIDTH_PIXELS,
        height: A4_PAGE_HEIGHT_PIXELS,
    },
    previewPageLayout: {
        minWidth: A4_PAGE_WIDTH_PIXELS,
        minHeight: A4_PAGE_HEIGHT_PIXELS,
    },
    // Vertical Spacing
    pageVerticalSpacingExtraSmall: {
        paddingTop: 48,
        paddingBottom: 48,
    },
    pageVerticalSpacingSmall: {
        paddingTop: 56,
        paddingBottom: 56,
    },
    pageVerticalSpacingMedium: {
        paddingTop: 64,
        paddingBottom: 64,
    },
    pageVerticalSpacingLarge: {
        paddingTop: 72,
        paddingBottom: 72,
    },
    pageVerticalSpacingExtraLarge: {
        paddingTop: 80,
        paddingBottom: 80,
    },

    // First Page Vertical Spacing
    firstPageVerticalSpacingExtraSmall: {
        paddingBottom: 48,
    },
    firstPageVerticalSpacingSmall: {
        paddingBottom: 56,
    },
    firstPageVerticalSpacingMedium: {
        paddingBottom: 64,
    },
    firstPageVerticalSpacingLarge: {
        paddingBottom: 72,
    },
    firstPageVerticalSpacingExtraLarge: {
        paddingBottom: 80,
    },
    // Page Gap
    pageGap: {
        marginBottom: 32,
    },
    // Horizontal Margins
    pageHorizontalMarginsExtraSmall: {
        marginLeft: 36,
        marginRight: 36,
    },
    pageHorizontalMarginsSmall: {
        marginLeft: 44,
        marginRight: 44,
    },
    pageHorizontalMarginsMedium: {
        marginLeft: 52,
        marginRight: 52,
    },
    pageHorizontalMarginsLarge: {
        marginLeft: 60,
        marginRight: 60,
    },
    pageHorizontalMarginsExtraLarge: {
        marginLeft: 68,
        marginRight: 68,
    },

    // Column Styles
    fullPage: {
        width: "100%",
    },
    columnGapExtraSmall: {
        marginRight: 4,
        marginLeft: 4,
    },
    columnGapSmall: {
        marginRight: 8,
        marginLeft: 8,
    },
    columnGapMedium: {
        marginRight: 8,
        marginLeft: 8,
    },
    columnGapLarge: {
        marginRight: 12,
        marginLeft: 12,
    },
    columnGapExtraLarge: {
        marginRight: 16,
        marginLeft: 16,
    },
    oneColumn: {
        flexDirection: "column",
    },
    twoColumn: {
        flexDirection: "row",
    },
    // Section Padding and Margin
    sectionHorizontalPaddingExtraSmall: {
        paddingLeft: 8,
        paddingRight: 8,
    },
    sectionHorizontalPaddingSmall: {
        paddingLeft: 10,
        paddingRight: 10,
    },
    sectionHorizontalPaddingMedium: {
        paddingLeft: 12,
        paddingRight: 12,
    },
    sectionHorizontalPaddingLarge: {
        paddingLeft: 14,
        paddingRight: 14,
    },
    sectionHorizontalPaddingExtraLarge: {
        paddingLeft: 16,
        paddingRight: 16,
    },
    sectionMarginYExtraSmall: {
        marginBottom: 12,
    },
    sectionMarginYSmall: {
        marginBottom: 16,
    },
    sectionMarginYMedium: {
        marginBottom: 20,
    },
    sectionMarginYLarge: {
        marginBottom: 24,
    },
    sectionMarginYExtraLarge: {
        marginBottom: 28,
    },
    // Header Section Spacing
    headerSectionSpacingYExtraSmall: {
        paddingTop: 42,
        paddingBottom: 12,
        marginBottom: 6
    },
    headerSectionSpacingYSmall: {
        paddingTop: 46,
        paddingBottom: 16,
        marginBottom: 8
    },
    headerSectionSpacingYMedium: {
        paddingTop: 50,
        paddingBottom: 20,
        marginBottom: 12
    },
    headerSectionSpacingYLarge: {
        paddingTop: 54,
        paddingBottom: 24,
        marginBottom: 16
    },
    headerSectionSpacingYExtraLarge: {
        paddingTop: 58,
        paddingBottom: 28,
        marginBottom: 20
    },
    // Section heading margin
    sectionHeadingMarginYExtraSmall: {
        marginBottom: 2,
    },
    sectionHeadingMarginYSmall: {
        marginBottom: 4,
    },
    sectionHeadingMarginYMedium: {
        marginBottom: 6,
    },
    sectionHeadingMarginYLarge: {
        marginBottom: 8,
    },
    sectionHeadingMarginYExtraLarge: {
        marginBottom: 10,
    },
    // Section Item Margin
    sectionItemMarginYExtraSmall: {
        marginBottom: 4,
    },
    sectionItemMarginYSmall: {
        marginBottom: 6,
    },
    sectionItemMarginYMedium: {
        marginBottom: 8,
    },
    sectionItemMarginYLarge: {
        marginBottom: 12,
    },
    sectionItemMarginYExtraLarge: {
        marginBottom: 16,
    },
    // Image Styles
    image: {
        width: 112,
        height: 112,
    },
    // Text Styles
    bold: {
        fontWeight: 700,
    },
    semibold: {
        fontWeight: 600,
    },
    underline: {
        textDecoration: "underline",
    },
    italic: {
        fontStyle: "italic",
    },
    link: {
        textDecoration: "underline",
        color: "#000"
    }
});
