import { AppText } from "@/components/Text/AppText";

interface AssistantItemsContainerProps {
    children: React.ReactNode;
    icon: React.ReactNode;
    title: string;
}

export const AssistantItemsContainer = ({
    children,
    icon,
    title,
}: AssistantItemsContainerProps) => (
    <div className="rounded-lg bg-neutral-100 p-3">
        <div className="mb-3 flex items-center gap-2">
            {icon}
            <AppText variant="labelsbuttons">{title}</AppText>
        </div>
        {children}
    </div>
);
