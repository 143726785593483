import { Checkbox } from "@/components/form/Checkbox";
import { useAppDispatch, useAppSelector } from "@/hooks/types";
import {
    PreferencesStep,
    PreferenceTitle,
} from "@/pages/Preferences/components";
import { jobTypes } from "@/pages/Preferences/constants";
import { getUserPreferenceJobTypes } from "@/store/wizard/selectors";
import { setWizardUserPreference } from "@/store/wizard/slice";
import { useCallback } from "react";

export const PreferencesJobType = () => {
    const dispatch = useAppDispatch();
    const selectedJobTypes = useAppSelector(getUserPreferenceJobTypes);
    const onJobTypeClick = useCallback(
        (industry: string, selected: boolean) => {
            let newIndustries = [...selectedJobTypes];
            if (selected) {
                newIndustries.push(industry);
            } else {
                newIndustries = newIndustries.filter(
                    selectedIndustry => selectedIndustry !== industry,
                );
            }

            dispatch(
                setWizardUserPreference({
                    preference: "jobTypes",
                    value: newIndustries,
                }),
            );
        },
        [dispatch, selectedJobTypes],
    );
    return (
        <PreferencesStep>
            <PreferenceTitle title="What are your desired job types?" />
            <div className="h-[200px] overflow-y-scroll px-2 py-2 pb-4 lg:h-[250px]">
                <div className="grid grid-cols-[225px] gap-x-6 gap-y-4 lg:grid-cols-[300px_300px]">
                    {jobTypes.map(({ label, value }) => (
                        <Checkbox
                            key={value}
                            label={label}
                            id={value}
                            checked={selectedJobTypes.includes(value)}
                            onChange={e => onJobTypeClick(value, e)}
                        />
                    ))}
                </div>
            </div>
        </PreferencesStep>
    );
};
