import { RootState } from "../store";
import { createSelector } from "@reduxjs/toolkit";

export const getTemplateState = (state: RootState) => state.template;

export const getActiveSection = createSelector(
    getTemplateState,
    template => template.activeSection,
);

export const getActiveItemId = createSelector(
    getTemplateState,
    template => template.activeItemId,
);

export const getTemplates = createSelector(
    getTemplateState,
    template => template.templates,
);
