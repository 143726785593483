import { store } from "@/store/store";
import { getAccessToken, getRefreshToken } from "@/store/user/selectors";
import { Token } from "@/types";

export const getTokenHelper = (
    tokenName: "accessToken" | "refreshToken",
): Token | null => {
    const state = store.getState();

    switch (tokenName) {
        case "accessToken": {
            const stateValue = getAccessToken(state);
            const localValue = localStorage.getItem("accessToken");
            const localToken = localValue ? JSON.parse(localValue) : null;

            return stateValue || localToken;
        }
        case "refreshToken": {
            const stateValue = getRefreshToken(state);
            const localValue = localStorage.getItem("refreshToken");
            const localToken = localValue ? JSON.parse(localValue) : null;

            return stateValue || localToken;
        }
        default:
            return null;
    }
};
