import { DeviceContext } from "@/context/DeviceContext";
import { DEVICE_TYPE, DeviceContextType } from "@/types/device";
import React, { useCallback, useEffect, useMemo, useState } from "react";
import debounce from "lodash.debounce";

interface DeviceProviderProps {
    children: React.ReactNode;
}

const MAX_MOBILE_THRESHOLD = 768; // iPad mini = 768px
const MAX_TABLET_THRESHOLD = 1024; // Macbook Air = 1024px

export const DeviceProvider = ({ children }: DeviceProviderProps) => {
    const [deviceState, setDeviceState] = useState<DeviceContextType>({
        deviceType: DEVICE_TYPE.DESKTOP,
        isMobile: false,
        isTablet: false,
        isDesktop: true,
    });

    const handleResize = useCallback(() => {
        const width = window.innerWidth;
        const isMobile = width < MAX_MOBILE_THRESHOLD;
        const isTablet =
            width >= MAX_MOBILE_THRESHOLD && width < MAX_TABLET_THRESHOLD;
        const isDesktop = width >= MAX_TABLET_THRESHOLD;
        const deviceType = isMobile
            ? DEVICE_TYPE.MOBILE
            : isTablet
              ? DEVICE_TYPE.TABLET
              : DEVICE_TYPE.DESKTOP;

        setDeviceState({ deviceType, isMobile, isTablet, isDesktop });
    }, []);

    const debouncedHandleResize = useMemo(
        () => debounce(handleResize, 250),
        [handleResize],
    );

    useEffect(() => {
        handleResize();
        window.addEventListener("resize", debouncedHandleResize);
        return () => {
            window.removeEventListener("resize", debouncedHandleResize);
            debouncedHandleResize.cancel();
        };
    }, [debouncedHandleResize, handleResize]);

    const contextValue = useMemo(() => deviceState, [deviceState]);

    return (
        <DeviceContext.Provider value={contextValue}>
            {children}
        </DeviceContext.Provider>
    );
};
