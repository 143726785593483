import { useCallback, useEffect } from "react";
import clsx from "clsx";
import { NavItem } from "@/components/Sidebar/NavItem/NavItem";
import {
    Bot,
    Paintbrush,
    PanelRightClose,
    PanelRightOpen,
    Settings2,
} from "lucide-react";
import { Sidebar } from "@/components/Sidebar/Sidebar";
import { SideBarState } from "@/types/app";
import SecondaryNavigation from "@/components/Navigation/SecondaryNavigation";
import { useAppDispatch, useAppSelector } from "@/hooks/types";
import { getShowSideBar, getSideBarState } from "@/store/app/selectors";
import { setShowSideBar, setSideBarState } from "@/store/app/slice";
import { HelpButton } from "@/components/Modals/HelpGuideModal/HelpButton";
import { getActiveResumeName } from "@/store/resume/selectors";
import { PageHelmet } from "@/components/PageHelmet/PageHelmet";
import { DOCUMENT_CONTAINER_CLASS } from "@/constants/resume";
import { A4_PAGE_WIDTH_PIXELS } from "@/store/pages/constants";
import { AppText } from "@/components/Text/AppText";
import { AdminControlPanel } from "@/components/Admin/AdminControlPanel";
import { AdminBuilderSettings } from "@/components/Admin/AdminBuilderSettings";
import { BuilderTourStep } from "@/components/Onboarding/builder";

const navigation = [
    {
        name: "Styles",
        id: SideBarState.STYLES,
        icon: Paintbrush,
        tourStep: BuilderTourStep.STYLES_BUTTON,
        ariaLabel: "Styles",
    },
    {
        name: "Assistant",
        id: SideBarState.ASSISTANT,
        icon: Bot,
        tourStep: BuilderTourStep.ASSISTANT_BUTTON,
        ariaLabel: "Assistant",
    },
    {
        name: "Section Settings",
        id: SideBarState.EDIT,
        icon: Settings2,
        tourStep: BuilderTourStep.SECTION_BUTTON,
        ariaLabel: "Section settings",
    },
];

export const ResumeBuilderContainer = ({
    children,
}: {
    children: React.ReactNode;
}) => {
    const sideBarOpen = useAppSelector(getShowSideBar);
    const sideBarState = useAppSelector(getSideBarState);
    const dispatch = useAppDispatch();
    const activeResumeName = useAppSelector(getActiveResumeName);

    const setTab = useCallback(
        (tab: SideBarState) => {
            dispatch(setSideBarState(tab));
        },
        [dispatch],
    );

    const setSideBarOpen = useCallback(() => {
        dispatch(setShowSideBar(!sideBarOpen));
    }, [dispatch, sideBarOpen]);

    useEffect(() => {
        if (sideBarOpen && sideBarState === SideBarState.CLOSED) {
            setTab(SideBarState.STYLES);
        }
    }, [setTab, sideBarState, sideBarOpen]);

    return (
        <>
            <PageHelmet
                title={activeResumeName ? `${activeResumeName} | Resume Editor` : "Resume Editor"}
                description="Create and edit your resume"
            />
            <div
                className={clsx(
                    DOCUMENT_CONTAINER_CLASS,
                    "fixed inset-y-0 left-0 top-navigationHeight z-50 flex w-16 flex-col border-r-[1px] border-t-[1px] border-neutral-300 bg-primary-900 pb-4",
                )}
            >
                <nav className={clsx("relative mt-4")}>
                    <ul
                        role="list"
                        className="flex flex-col items-center px-1"
                    >
                        <li>
                            <NavItem
                                title="Toggle sidebar"
                                onClick={setSideBarOpen}
                            >
                                <div className="flex flex-col items-center justify-center gap-1">
                                    <div
                                        className={clsx(
                                            "transform rounded-lg p-2 transition-all duration-300",

                                            "text-neutral-50 group-hover:bg-neutral-50 group-hover:text-neutral-900",
                                        )}
                                    >
                                        {sideBarOpen ? (
                                            <PanelRightOpen
                                                className="h-5 w-5 shrink-0 transform text-neutral-50 transition-all duration-300 group-hover:scale-110  group-hover:text-neutral-900"
                                                aria-hidden="true"
                                            />
                                        ) : (
                                            <PanelRightClose
                                                className="h-5 w-5 shrink-0 transform text-neutral-50 transition-all duration-300 group-hover:scale-110  group-hover:text-neutral-900"
                                                aria-hidden="true"
                                            />
                                        )}
                                    </div>
                                </div>
                            </NavItem>
                            <div
                                className="mb-3 h-px w-full bg-neutral-100"
                                aria-hidden="true"
                            />
                        </li>
                        {navigation.map(item => {
                            const isActive =
                                sideBarState === item.id && sideBarOpen;
                            return (
                                <li
                                    key={item.name}
                                    className="w-full"
                                >
                                    <NavItem
                                        title={item.name}
                                        onClick={() => {
                                            setTab(item.id);
                                            if (!sideBarOpen) setSideBarOpen();
                                        }}
                                        ariaLabel={item.ariaLabel}
                                    >
                                        <div
                                            className={`flex flex-col items-center justify-center gap-1 ${item.tourStep}`}
                                        >
                                            <div
                                                className={clsx(
                                                    "transform rounded-lg p-2 transition-all duration-300",
                                                    isActive
                                                        ? "bg-neutral-50 text-neutral-900"
                                                        : "text-neutral-50 group-hover:bg-neutral-50 group-hover:text-neutral-900",
                                                )}
                                            >
                                                <item.icon
                                                    className={clsx(
                                                        "transform transition-all duration-300",
                                                        isActive
                                                            ? "scale-110 text-neutral-900"
                                                            : "text-neutral-50 group-hover:scale-110 group-hover:text-neutral-900",
                                                        "h-5 w-5 shrink-0",
                                                    )}
                                                    aria-hidden="true"
                                                />
                                            </div>
                                            <AppText
                                                variant="regular"
                                                className={clsx(
                                                    isActive
                                                        ? "text-neutral-50"
                                                        : "text-neutral-50",
                                                    "!text-[10px] !leading-3",
                                                )}
                                            >
                                                {item.name}
                                            </AppText>
                                        </div>
                                    </NavItem>
                                </li>
                            );
                        })}
                    </ul>
                </nav>
            </div>
            <div
                className={clsx(
                    "mt-navigationHeight flex flex-row overflow-x-hidden pl-16",
                    DOCUMENT_CONTAINER_CLASS,
                )}
            >
                <Sidebar />
                <main
                    className={clsx(
                        DOCUMENT_CONTAINER_CLASS,
                        "relative w-full",
                    )}
                >
                    <SecondaryNavigation />
                    <div
                        className={clsx(
                            "relative mx-auto",
                            sideBarOpen && "xl:translate-x-[160px]", // half of sidebar
                            "transition-margin h-full transform pt-24 duration-300 ease-in-out",
                        )}
                        style={{ width: A4_PAGE_WIDTH_PIXELS }}
                    >
                        {children}
                    </div>
                    <HelpButton />
                    <AdminControlPanel topPosition={120}>
                        <AdminBuilderSettings />
                    </AdminControlPanel>
                </main>
            </div>
        </>
    );
};
