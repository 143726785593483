import { IconButton } from "@/components/Button/IconButton";
import { Input } from "@/components/form/Input";
import { InputLabel } from "@/components/form/InputLabel";
import { useAppDispatch, useAppSelector } from "@/hooks/types";
import {
    PreferencesStep,
    PreferenceTitle,
} from "@/pages/Preferences/components";
import { JobRoleList } from "@/pages/Preferences/components/JobRoleList";
import { jobRoles } from "@/pages/Preferences/constants";
import { getUserPreferenceJobRoles } from "@/store/wizard/selectors";
import { setWizardUserPreference } from "@/store/wizard/slice";
import { PreferenceOptions } from "@/types";
import { Plus } from "lucide-react";
import { useCallback, useState, useEffect } from "react";

export const PreferencesJobRole = () => {
    const dispatch = useAppDispatch();
    const selectedJobRoles = useAppSelector(getUserPreferenceJobRoles);
    const [otherChecked, setOtherChecked] = useState(false);
    const [otherJobRole, setOtherJobRole] = useState("");
    const [additionalJobRoles, setAdditionalJobRoles] =
        useState<PreferenceOptions>([]);
    const [roles, setRoles] = useState<PreferenceOptions>([]);

    useEffect(() => {
        const uniqueRoles = new Map();
        jobRoles.forEach(role => uniqueRoles.set(role.value, role));
        selectedJobRoles.forEach(role => {
            if (!uniqueRoles.has(role)) {
                uniqueRoles.set(role, { label: role, value: role });
            }
        });
        setRoles(Array.from(uniqueRoles.values()));
    }, [selectedJobRoles]);

    const onJobRoleClick = useCallback(
        (jobRole: string, selected: boolean) => {
            let newJobRoles = [...selectedJobRoles];
            if (selected) {
                newJobRoles.push(jobRole);
            } else {
                newJobRoles = newJobRoles.filter(
                    selectedJobRole => selectedJobRole !== jobRole,
                );
            }

            dispatch(
                setWizardUserPreference({
                    preference: "jobRoles",
                    value: newJobRoles,
                }),
            );
        },
        [dispatch, selectedJobRoles],
    );

    const handleOtherChange = useCallback(
        (checked: boolean) => {
            setOtherChecked(checked);
            if (!checked) {
                setOtherJobRole("");
            }
        },
        [setOtherChecked, setOtherJobRole],
    );

    const handleOtherJobRoleChange = useCallback(
        (value: string) => {
            setOtherJobRole(value);
        },
        [setOtherJobRole],
    );

    const persistOtherJobRole = useCallback(() => {
        if (otherJobRole) {
            if (!additionalJobRoles.some(role => role.value === otherJobRole)) {
                setAdditionalJobRoles([
                    ...additionalJobRoles,
                    { label: otherJobRole, value: otherJobRole },
                ]);
                dispatch(
                    setWizardUserPreference({
                        preference: "jobRoles",
                        value: [...selectedJobRoles, otherJobRole],
                    }),
                );
                setOtherJobRole("");
            }
        }
    }, [additionalJobRoles, dispatch, otherJobRole, selectedJobRoles]);

    return (
        <PreferencesStep>
            <PreferenceTitle title="What type of role(s) are you currently working in or most interested in pursuing?" />
            <div className="h-[200px] overflow-y-scroll bg-gradient-to-b from-transparent from-95% to-black/10 px-2 py-2 pb-4 lg:h-[250px]">
                <div className="grid gap-x-6 gap-y-4 md:grid-cols-3 xl:grid-cols-3">
                    <JobRoleList
                        onJobRoleClick={onJobRoleClick}
                        selectedJobRoles={selectedJobRoles}
                        showOther
                        otherChecked={otherChecked}
                        onOtherClick={handleOtherChange}
                        roles={roles}
                    />
                </div>
            </div>
            {otherChecked && (
                <div className="mt-4 w-64">
                    <InputLabel label="Enter your job role type" />
                    <div className="mt-2 flex items-center gap-2">
                        <Input
                            type="text"
                            value={otherJobRole}
                            onChange={(_, value) =>
                                handleOtherJobRoleChange(value)
                            }
                            className="w-full rounded border px-2 py-1"
                            onBlur={persistOtherJobRole}
                            maxLength={30}
                        />
                        <IconButton
                            size="6"
                            className="!h-8 !min-h-8 !w-8 !min-w-8"
                            onClick={persistOtherJobRole}
                            disabled={
                                !otherJobRole ||
                                additionalJobRoles.some(
                                    role => role.value === otherJobRole,
                                )
                            }
                        >
                            <Plus size={16} />
                        </IconButton>
                    </div>
                </div>
            )}
        </PreferencesStep>
    );
};
