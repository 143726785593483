import { EditorToolbarButton } from "@/components/Editor/EditorToolbarButton";
import { AppText } from "@/components/Text/AppText";
import clsx from "clsx";
import {
    AlignCenter,
    AlignLeft,
    AlignRight,
    Bold,
    CommandIcon,
    ItalicIcon,
    Link2,
    Redo2,
    Underline,
    Undo2,
    List,
    ListOrdered,
    AlignJustify,
} from "lucide-react";

const hotkeysRows = [
    { command: "Bold", hotkey: "B", icon: <Bold size={16} /> },
    { command: "Italics", hotkey: "I", icon: <ItalicIcon size={16} /> },
    { command: "Underline", hotkey: "U", icon: <Underline size={16} /> },
    { command: "Insert Link", hotkey: "K", icon: <Link2 size={16} /> },
    { command: "Undo", hotkey: "Z", icon: <Undo2 size={16} /> },
    { command: "Redo", hotkey: "Y", icon: <Redo2 size={16} /> },
    {
        command: "Align Left",
        hotkey: "Shift + L",
        icon: <AlignLeft size={16} />,
    },
    {
        command: "Align Centre",
        hotkey: "Shift + E",
        icon: <AlignCenter size={16} />,
    },
    {
        command: "Align Right",
        hotkey: "Shift + R",
        icon: <AlignRight size={16} />,
    },
    {
        command: "Align Justify",
        hotkey: "Shift + J",
        icon: <AlignJustify size={16} />,
    },
    {
        command: "Ordered List",
        hotkey: "Shift + 7",
        icon: <ListOrdered size={16} />,
    },
    {
        command: "Bullet List",
        hotkey: "Shift + 8",
        icon: <List size={16} />,
    },
];

interface HotkeyButtonProps {
    children: React.ReactNode;
    isFirstButton?: boolean;
}

const HotkeyButton = ({ children, isFirstButton }: HotkeyButtonProps) => (
    <span
        className={clsx(
            "mr-2 flex min-w-6 items-center justify-center rounded px-2 py-1 ring-2 ring-neutral-200",
            isFirstButton ? "mr-2" : "ml-2",
        )}
    >
        {children}
    </span>
);

interface HotkeysTableRowProps {
    icon: React.ReactNode;
    command: string;
    hotkey: string;
}

const HotkeysTableRow = ({ icon, command, hotkey }: HotkeysTableRowProps) => (
    <tr className="hover:bg-neutral-100">
        <td className="whitespace-nowrap py-2 pl-1 text-neutral-900">
            <EditorToolbarButton
                onClick={() => {}}
                isActive={true}
            >
                {icon}
            </EditorToolbarButton>
        </td>
        <td className="whitespace-nowrap text-neutral-700">
            <AppText variant="regular">{command}</AppText>
        </td>
        <td className="whitespace-nowrap text-neutral-700">
            <AppText
                variant="regular"
                className="flex items-center"
            >
                <HotkeyButton isFirstButton>Control</HotkeyButton> +{" "}
                <HotkeyButton>{hotkey}</HotkeyButton>
            </AppText>
        </td>
        <td className="whitespace-nowrap text-neutral-700">
            <AppText
                variant="regular"
                className="flex items-center"
            >
                <HotkeyButton isFirstButton>
                    Cmd
                    <CommandIcon
                        className="mx-[1px]"
                        size={16}
                    />
                </HotkeyButton>
                + <HotkeyButton>{hotkey}</HotkeyButton>
            </AppText>
        </td>
    </tr>
);

export const HotkeysTable = () => (
    <table className="min-w-full border-separate border-spacing-0">
        <thead className="sticky top-0 z-[1]">
            <tr>
                <th
                    scope="col"
                    className="z-10 border-b border-gray-300 bg-white bg-opacity-75 py-3.5 pr-2 text-neutral-900 backdrop-blur backdrop-filter"
                >
                    <AppText variant="labelsbuttons">Button</AppText>
                </th>
                <th
                    scope="col"
                    className="z-10 border-b border-gray-300 bg-white bg-opacity-75 text-neutral-900 backdrop-blur backdrop-filter"
                >
                    <AppText variant="labelsbuttons">Command</AppText>
                </th>
                <th
                    scope="col"
                    className="z-10 border-b border-gray-300 bg-white bg-opacity-75 text-neutral-900 backdrop-blur backdrop-filter"
                >
                    <AppText variant="labelsbuttons">Windows</AppText>
                </th>
                <th
                    scope="col"
                    className="z-10 border-b border-gray-300 bg-white bg-opacity-75 text-neutral-900 backdrop-blur backdrop-filter"
                >
                    <AppText variant="labelsbuttons">macOS</AppText>
                </th>
            </tr>
        </thead>
        <tbody className="">
            {hotkeysRows.map((row, index) => (
                <HotkeysTableRow
                    key={index}
                    icon={row.icon}
                    command={row.command}
                    hotkey={row.hotkey}
                />
            ))}
        </tbody>
    </table>
);
