import { TextClassMap } from "@/components/Text/types";

export const appTextClassMap: TextClassMap = {
    "headings": {
        element: "h1", elementClassName: "text-app-headings-1-mobile md:text-app-headings-1-desktop"
    },
    "subheadings": {
        element: "h2", elementClassName: "text-app-subheadings-2-mobile md:text-app-subheadings-2-desktop"
    },
    "contextheading": {
        element: "h3", elementClassName: "text-app-contextheadings-3-mobile md:text-app-contextheadings-3-desktop"
    },
    "labelsbuttons": {
        element: "span", elementClassName: "text-app-labelsbuttons-4-mobile md:text-app-labelsbuttons-4-desktop"
    },
    "regular": {
        element: "p", elementClassName: "text-app-regular-5-mobile md:text-app-regular-5-desktop"
    },
    "footnote": {
        element: "span", elementClassName: "text-app-footnote-6-mobile md:text-app-footnote-6-desktop"
    },
};

export const resumeTextClassMap: TextClassMap = {
    "large-1": {
        element: "h1", elementClassName: "text-resume-large-1"
    },
    "large-2": {
        element: "h2", elementClassName: "text-resume-large-2"
    },
    "large-3": {
        element: "h3", elementClassName: "text-resume-large-3"
    },
    "large-4": {
        element: "h4", elementClassName: "text-resume-large-4"
    },
    "large-5": {
        element: "p", elementClassName: "text-resume-large-5"
    },
    "regular-1": {
        element: "h1", elementClassName: "text-resume-regular-1"
    },
    "regular-2": {
        element: "h2", elementClassName: "text-resume-regular-2"
    },
    "regular-3": {
        element: "h3", elementClassName: "text-resume-regular-3"
    },
    "regular-4": {
        element: "h4", elementClassName: "text-resume-regular-4"
    },
    "regular-5": {
        element: "p", elementClassName: "text-resume-regular-5"
    },
    "small-1": {
        element: "h1", elementClassName: "text-resume-small-1"
    },
    "small-2": {
        element: "h2", elementClassName: "text-resume-small-2"
    },
    "small-3": {
        element: "h3", elementClassName: "text-resume-small-3"
    },
    "small-4": {
        element: "h4", elementClassName: "text-resume-small-4"
    },
    "small-5": {
        element: "p", elementClassName: "text-resume-small-5"
    },
};

export const headingClassMap = {
    "heading-1": {
        element: "h1", elementClassName: "text-heading-1"
    },
    "heading-2": {
        element: "h2", elementClassName: "text-heading-2"
    },
    "heading-3": {
        element: "h3", elementClassName: "text-heading-3"
    },
    "heading-4": {
        element: "h4", elementClassName: "text-heading-4"
    },
    "heading-5": {
        element: "h5", elementClassName: "text-heading-5"
    },
    "heading-6": {
        element: "h6", elementClassName: "text-heading-6"
    },
};