import { StripeProduct, StripeSubscriptionStatus, UserPlan } from "@/types/billing";
import { apiRequest } from "./request";

type PaymentStatusResponse = {
    status: "completed" | "failed" | "processing";
    planDetails: { endDate: string, id: string, name: string; };
    planStatus: StripeSubscriptionStatus;
};

const initiateStripeCheckout = (data: { priceId: string, redirectUrl: string; stripeCustomerId: string; trialPeriodDays?: number; }) => apiRequest<{ id: string; }>("/billing/initiate-session", "POST", data);

const getPortalSession = (data: { returnUrl: string; stripeCustomerId: string; }) => apiRequest<{ url: string; }>("/billing/portal-session", "POST", data);

const getUserPlan = (usedId: string) => apiRequest<UserPlan>(`/billing/get-plan/${usedId}`, "GET");

const getPaymentStatus = (checkoutSessionId: string) => apiRequest<PaymentStatusResponse>(`/billing/get-payment-status/${checkoutSessionId}`, "GET");

const getStripeProducts = () => apiRequest<StripeProduct[]>("/billing/products", "GET");

export default {
    initiateStripeCheckout,
    getPortalSession,
    getUserPlan,
    getPaymentStatus,
    getStripeProducts,
};
