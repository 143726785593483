import templateApi from "@/api/template";
import sectionApi from "@/api/section";
import documentTypeApi from "@/api/documentType";
import { Input } from "@/components/form/Input";
import { Suspense, useCallback, useMemo, useState } from "react";
import { Await, useLoaderData, useNavigate, useParams } from "react-router-dom";
import { useAppDispatch } from "@/hooks/types";
import { templateSchema } from "./schema/template";
import { InputLabel } from "@/components/form/InputLabel";
import {
    NotificationMessageType,
    NotificationType,
    Section,
    Template,
    DocumentType,
} from "@/types";
import { addNotification } from "@/store/app/slice";
import { Loader } from "lucide-react";
import { Formik } from "formik";
import { Dropdown } from "@/components/Dropdown/Dropdown";
import { InputError } from "@/components/form/InputError";
import { ColumnLayout } from "@/types/resume";
import { LayoutToggles } from "@/components/Sidebar/Assistant/LayoutToggles";
import { AdminNavigationItem } from "@/types/admin";
import { AdminFormContainer } from "@/pages/Admin/AdminFormContainer";
import { AdminFormFooter } from "@/pages/Admin/AdminFormFooter";
import { TemplateSectionsGrid } from "@/pages/Admin/Templates/TemplateSectionsGrid";

const templateFormNavItems: AdminNavigationItem[] = [
    { id: "details", label: "Details" },
    { id: "sections", label: "Sections Layout" },
];

export const TemplateForm = () => {
    const { getAdminTemplate } = useLoaderData() as {
        getAdminTemplate: Promise<Template>;
    };
    const getAllSections = sectionApi.getAllSections;
    const getDocTypes = documentTypeApi.getAllDocumentTypes;

    return (
        <Suspense fallback={<Loader />}>
            <Await
                resolve={Promise.all([
                    getAdminTemplate,
                    getAllSections(),
                    getDocTypes(),
                ])}
                errorElement={<div>Failed to load template</div>}
            >
                {([template, sections, docTypes]) => (
                    <TemplateFormFields
                        template={template}
                        sections={sections.results}
                        documentTypes={docTypes.results}
                    />
                )}
            </Await>
        </Suspense>
    );
};

export const TemplateFormFields = ({
    template,
    sections,
    documentTypes,
}: {
    template?: Template;
    sections: Section[];
    documentTypes: DocumentType[];
}) => {
    const initialValues: Partial<Template> = useMemo(
        () => ({
            name: template?.name || "",
            sections: template?.sections || [],
            documentType: template?.documentType || "",
            columnLayout: template?.columnLayout || ColumnLayout.SINGLE,
        }),
        [template],
    );
    const [activeTab, setActiveTab] = useState<string>(
        templateFormNavItems[0].id,
    );
    const dispatch = useAppDispatch();
    const { templateId: id } = useParams();
    const templateId = id !== "new" ? id : undefined;
    const navigate = useNavigate();

    const handleError = useCallback(
        (err: unknown, title: string, messageType: NotificationMessageType) => {
            let errorMessage;
            if (typeof err === "string") {
                errorMessage = err;
            } else if (err instanceof Error) {
                errorMessage = err.message;
            }

            dispatch(
                addNotification({
                    title,
                    desc: errorMessage,
                    messageType,
                    type: NotificationType.ERROR,
                }),
            );
        },
        [dispatch],
    );

    const handleSubmit = async (template: Partial<Template>) => {
        try {
            await templateApi.saveTemplate(template, templateId).then(() => {
                if (!templateId) {
                    navigate("/admin/templates", { replace: true }); // push user back to templates list
                }

                dispatch(
                    addNotification({
                        title: "Successfully saved!",
                        desc: "Your details are successfully saved!!",
                        messageType:
                            NotificationMessageType.ADMIN_TEMPLATE_SAVE,
                        type: NotificationType.SUCCESS,
                    }),
                );
            });
        } catch (err: unknown) {
            handleError(
                err,
                "Failed to save",
                NotificationMessageType.ADMIN_TEMPLATE_SAVE,
            );
        }
    };

    const handleDelete = useCallback(async () => {
        try {
            if (!templateId) {
                navigate("/admin/templates", { replace: true }); // push user back to templates list
                return;
            }

            if (template?.name === "resume") {
                dispatch(
                    addNotification({
                        title: "This template cannot be deleted in the admin",
                        desc: "For safety reasons this functionality has been removed. Please contact a developer to delete the template.",
                        messageType:
                            NotificationMessageType.ADMIN_TEMPLATE_DELETE,
                        type: NotificationType.WARNING,
                    }),
                );
                return;
            }

            await templateApi.deleteTemplate(templateId).then(() => {
                navigate("/admin/templates", { replace: true });
                dispatch(
                    addNotification({
                        title: "Successfully deleted",
                        desc: "User successfully deleted",
                        messageType:
                            NotificationMessageType.ADMIN_TEMPLATE_DELETE,
                        type: NotificationType.SUCCESS,
                    }),
                );
            });
        } catch (e) {
            handleError(
                e,
                "Failed to delete",
                NotificationMessageType.ADMIN_TEMPLATE_DELETE,
            );
        }
    }, [handleError, navigate, templateId, dispatch]);

    const documentTypesList = useMemo(() => {
        return documentTypes.map(({ id, name }) => ({
            id,
            title: name,
        }));
    }, [documentTypes]);

    return (
        <AdminFormContainer
            navigationItems={templateFormNavItems}
            onNavigationItemClick={id => setActiveTab(id)}
            activeItemId={activeTab}
            pageTitle={template?.name || "New Template"}
        >
            <Formik
                initialValues={initialValues}
                validationSchema={templateSchema}
                onSubmit={async values => {
                    await handleSubmit(values);
                }}
            >
                {({
                    values,
                    errors,
                    handleChange,
                    submitForm,
                    setFieldValue,
                }) => (
                    <>
                        <div className="px-8">
                            {activeTab === "details" && (
                                <div className="min-h-80">
                                    <div className="col-span-1 max-w-96 px-8 py-2">
                                        <InputLabel
                                            label="Name"
                                            htmlFor="name"
                                            required
                                        />
                                        <Input
                                            type="text"
                                            name="name"
                                            id="name"
                                            value={values.name}
                                            formikChange={handleChange}
                                            error={errors.name}
                                        />
                                    </div>
                                    <div className="col-span-1 px-8 py-2">
                                        <InputLabel
                                            label="Document Type"
                                            htmlFor="documentType"
                                            required
                                        />
                                        <Dropdown
                                            items={documentTypesList}
                                            title={
                                                documentTypes.find(
                                                    dt =>
                                                        dt.id ===
                                                        values.documentType,
                                                )?.name ??
                                                "Select Document Type"
                                            }
                                            activeId={values.documentType}
                                            handleChange={id => {
                                                setFieldValue(
                                                    "documentType",
                                                    id,
                                                );
                                            }}
                                        />
                                        <InputError
                                            error={errors.documentType}
                                        />
                                    </div>
                                </div>
                            )}
                            {activeTab === "sections" && (
                                <>
                                    <div className="col-span-1 max-w-64">
                                        <InputLabel
                                            label="Column Layout"
                                            htmlFor="columnLayout"
                                            required
                                        />
                                        <LayoutToggles
                                            activeLayout={values.columnLayout}
                                            onLayoutChange={layout =>
                                                setFieldValue(
                                                    "columnLayout",
                                                    layout,
                                                )
                                            }
                                        />
                                        <InputError
                                            error={errors.columnLayout}
                                        />
                                    </div>
                                    <div className="mb-16 w-full">
                                        <TemplateSectionsGrid
                                            sectionConfigs={sections}
                                            templateSections={
                                                template?.sections
                                            }
                                            columnLayout={values.columnLayout}
                                            onSave={sections =>
                                                setFieldValue(
                                                    "sections",
                                                    sections,
                                                )
                                            }
                                        />
                                    </div>
                                </>
                            )}
                            <AdminFormFooter
                                onSave={submitForm}
                                listHref="/admin/templates"
                                onDelete={handleDelete}
                            />
                        </div>
                    </>
                )}
            </Formik>
        </AdminFormContainer>
    );
};
