import { SwitchToggle } from "@/components/SwitchToggle/SwitchToggle";

interface SwitchFieldProps {
    enabled: boolean;
    onToggle: (isEnabled: boolean) => void;
}

export const SwitchField = ({ enabled, onToggle }: SwitchFieldProps) => (
    <div className="flex justify-end">
        <SwitchToggle
            enabled={enabled}
            onToggle={onToggle}
        />
    </div>
);
