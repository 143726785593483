import {
    Button,
    ButtonColourStyles,
    ButtonVariant,
} from "@/components/Button/Button";
import { IconButton } from "@/components/Button/IconButton";
import { PdfDocumentViewer } from "@/components/PdfDocument/PdfDocumentViewer";
import { Page, Section } from "@/types";
import { Resume } from "@/types/resume";
import { PDFDownloadLink } from "@react-pdf/renderer";
import { DownloadIcon, LoaderCircle } from "lucide-react";

interface PdfDownloadButtonProps {
    resume: Resume;
    sectionsConfig: Section[];
    pages: Page[];
    imageSrc: string;
    onError: (error: Error) => void;
    buttonText: string;
    responsive?: boolean;
    isLoading?: boolean;
}

export const PdfDownloadButton = ({
    resume,
    sectionsConfig,
    pages,
    imageSrc,
    onError,
    buttonText,
    responsive,
    isLoading,
}: PdfDownloadButtonProps) => {
    return (
        <PDFDownloadLink
            document={
                <PdfDocumentViewer
                    resume={resume}
                    sectionsConfig={sectionsConfig}
                    pages={pages}
                    imageSrc={imageSrc}
                />
            }
            fileName={resume?.name}
        >
            {({ loading, error }) => {
                if (error) onError(error);
                return (
                    <>
                        <Button
                            variant={
                                responsive
                                    ? ButtonVariant.OUTLINE
                                    : ButtonVariant.SOLID
                            }
                            color={
                                responsive
                                    ? ButtonColourStyles.OUTLINE_PRIMARY
                                    : ButtonColourStyles.SOLID_PRIMARY
                            }
                            disabled={loading || !resume || isLoading}
                            className="hidden lg:flex"
                            leftIcon={
                                loading ? (
                                    <LoaderCircle
                                        className="animate-spin"
                                        size={20}
                                    />
                                ) : (
                                    <DownloadIcon size={20} />
                                )
                            }
                        >
                            {buttonText}
                        </Button>
                        {responsive && (
                            <IconButton
                                disabled={loading || !resume || isLoading}
                                variant={ButtonVariant.OUTLINE}
                                color={ButtonColourStyles.OUTLINE_PRIMARY}
                                className="flex lg:hidden"
                            >
                                {loading ? (
                                    <LoaderCircle
                                        className="animate-spin"
                                        size={20}
                                    />
                                ) : (
                                    <DownloadIcon />
                                )}
                            </IconButton>
                        )}
                    </>
                );
            }}
        </PDFDownloadLink>
    );
};
