import { TitleWrapper } from "@/components/EditorComponents/TitleWrapper";
import { titleVariantMap } from "@/components/EditorComponents/constants";
import { FieldIcon } from "@/components/FieldIcon/FieldIcon";
import { EditorContext } from "@/context/EditorContext";
import { useAppDispatch } from "@/hooks/types";
import { decodeHtml } from "@/pages/ResumeBuilder/utils";
import { setActiveEntities } from "@/store/template/slice";
import { EditorContextType } from "@/types/editor";
import { ResumeStyles } from "@/types/resume";
import { Editor, EditorContent } from "@tiptap/react";
import { useContext, useEffect, useMemo, useState, useCallback } from "react";

interface TitleProps {
    variant: string;
    value?: string;
    id: string;
    sectionId: string;
    propType: "header" | "body";
    itemId?: string;
    icon?: string;
    placeholder?: string;
    isStaticHeader?: boolean;
    stylesConfig: ResumeStyles;
}

export const Title = ({
    variant,
    id,
    value,
    sectionId,
    propType,
    itemId,
    icon,
    placeholder,
    isStaticHeader,
    stylesConfig,
}: TitleProps) => {
    const { setActiveEditor, addEditor, getEditor } = useContext(
        EditorContext,
    ) as EditorContextType;
    const editorId = useMemo(
        () => `${sectionId}-${id}${itemId ? `-${itemId}` : ""}`,
        [sectionId, id, itemId],
    );
    const [localEditor, setLocalEditor] = useState<Editor | null>(
        getEditor(editorId) || null,
    );
    const dispatch = useAppDispatch();

    const initializeEditor = useCallback(() => {
        const currEditor = getEditor(editorId);
        if (!currEditor) {
            const titleVariant = titleVariantMap[variant];
            const newEditor = addEditor(
                editorId,
                "",
                itemId,
                propType,
                sectionId,
                id,
                placeholder,
                titleVariant,
                undefined,
                isStaticHeader,
            );
            const decodedValue = decodeHtml(value ?? "");
            newEditor.commands.setContent(decodedValue);

            // If a heading tag is not at the start of the content, set the heading level
            if (!decodedValue.startsWith("<h")) {
                newEditor.commands.setHeading({ level: titleVariant ?? 1 });
            }

            setLocalEditor(newEditor);
        } else {
            setLocalEditor(currEditor);
        }
    }, [
        addEditor,
        editorId,
        getEditor,
        id,
        isStaticHeader,
        itemId,
        placeholder,
        propType,
        sectionId,
        value,
        variant,
    ]);

    useEffect(() => {
        initializeEditor();
    }, [initializeEditor]);

    const onFocus = useCallback(() => {
        setActiveEditor(editorId);
        dispatch(
            setActiveEntities({
                activeSection: sectionId,
                activeItemId: itemId,
            }),
        );
    }, [setActiveEditor, editorId, dispatch, sectionId, itemId]);

    return (
        <TitleWrapper
            isStaticHeader={isStaticHeader}
            stylesConfig={stylesConfig}
            variant={variant}
        >
            {icon && (
                <FieldIcon
                    name={icon}
                    stylesConfig={stylesConfig}
                />
            )}
            <EditorContent
                editor={localEditor}
                onFocus={onFocus}
            />
        </TitleWrapper>
    );
};
