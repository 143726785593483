import { Text } from "@react-email/components";

export const EmailHeading = ({
    children,
    noMarginBottom,
}: {
    noMarginBottom?: boolean;
    children: React.ReactNode;
}) => (
    <Text
        style={{
            fontSize: "32px",
            fontWeight: "600",
            color: "#303330",
            lineHeight: "36px",
            margin: noMarginBottom ? "0" : "0 0 16px",
        }}
    >
        {children}
    </Text>
);
