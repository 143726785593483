import { HtmlToPdf } from "@/components/PdfDocument/HtmlToPdf";
import { PdfSectionContainer } from "@/components/PdfDocument/PdfSectionContainer";
import { getHtmlStringWithValues } from "@/components/PdfDocument/utils/getHtmlStringWithValues";
import {
    getSectionHeadingMarginYStyle,
    getSectionItemMarginYStyle,
} from "@/components/PdfDocument/utils/getStyles";
import {
    DATE_LOCATION_ALIGNMENT_KEY,
    DATE_LOCATION_CONTENT_KEY,
    DATE_LOCATION_LAYOUT_KEY,
    DATE_LOCATION_POSITION_KEY,
    GLOBAL_STYLE_KEY,
} from "@/constants/resume";
import { DEFAULT_IMAGE_SRC } from "@/store/resume/constants";
import {
    getDateLocationContentStyle,
    getDateLocationLayoutAlignment,
    getDateLocationPositionByColumnLayout,
} from "@/store/sections/utils";
import { Section, SectionDetails } from "@/types";
import {
    ColumnLayout,
    ComponentPropsObject,
    ResumeStyles,
} from "@/types/resume";
import {
    getSectionFieldsConfig,
    getVisibleProps,
    transformLayoutFieldsToProps,
} from "@/utils/section";
import { objectToCssString } from "@/utils/string";

interface PdfSectionProps {
    details: SectionDetails;
    config: Section;
    resumeStyles: ResumeStyles;
    isStaticHeader: boolean;
    columnIndex: number;
    isTwoColumn: boolean;
    imageSrc?: string;
    isSplitSection: boolean;
}

export const PdfSection = ({
    details,
    config,
    resumeStyles,
    isStaticHeader,
    columnIndex,
    isTwoColumn,
    imageSrc,
    isSplitSection,
}: PdfSectionProps) => {
    const { header, body, hiddenFields, layout } = details;
    const { headerFields, bodyFields, titleComp, component, layoutFields } =
        config;

    const globalStyles = resumeStyles?.[GLOBAL_STYLE_KEY];
    const resumeLevelDateLocationPosition = globalStyles?.dateLocationPosition;
    const dateLocationPosition = getDateLocationPositionByColumnLayout(
        isTwoColumn ? ColumnLayout.DOUBLE : ColumnLayout.SINGLE,
        columnIndex,
        resumeLevelDateLocationPosition,
    );

    // Override the header image with the imageSrc prop if it exists.
    // This is the signed URL version of the image set by the user.
    // Apply default image if imageSrc is not provided. Image will be hidden if hiddenFields is set.
    const headerWithImage: ComponentPropsObject = {
        ...header,
        image: imageSrc || DEFAULT_IMAGE_SRC,
    };

    const headerVisibleProps = getVisibleProps(headerWithImage, hiddenFields);
    const layoutProps = transformLayoutFieldsToProps(layoutFields, layout);

    layoutProps[DATE_LOCATION_POSITION_KEY] = dateLocationPosition;
    layoutProps[DATE_LOCATION_LAYOUT_KEY] = globalStyles?.dateLocationLayout;
    layoutProps[DATE_LOCATION_ALIGNMENT_KEY] = getDateLocationLayoutAlignment(
        dateLocationPosition,
        globalStyles?.dateLocationLayout,
    );
    layoutProps[DATE_LOCATION_CONTENT_KEY] =
        getDateLocationContentStyle(dateLocationPosition);

    const headerFieldsConfig = getSectionFieldsConfig(
        headerFields,
        headerVisibleProps,
    );

    const titleHtmlCompWithValues = getHtmlStringWithValues(
        titleComp,
        headerWithImage,
        layoutProps,
        hiddenFields,
        headerFieldsConfig,
        resumeStyles,
    );

    const sectionHeadingMargin = getSectionHeadingMarginYStyle(
        globalStyles?.sectionSpacingSize,
        isStaticHeader,
    );

    const headerHtml = `<div style="${objectToCssString(sectionHeadingMargin)}">${titleHtmlCompWithValues}</div>`;

    const bodyHtml = body
        .map((item, index) => {
            if (Object.keys(item).length === 0) return null;
            const isLastItem = index === body.length - 1;
            const itemSpacing = getSectionItemMarginYStyle(
                globalStyles?.sectionSpacingSize,
                isLastItem,
            );
            const bodyItemWrapperStyle = objectToCssString(itemSpacing);
            const itemHiddenFields = item.hiddenFields ?? [];
            const itemVisibleProps = getVisibleProps(item, itemHiddenFields);
            const bodyItemFieldsConfig = getSectionFieldsConfig(
                bodyFields,
                itemVisibleProps,
            );
            const bodyItemHtml = getHtmlStringWithValues(
                component,
                item,
                layoutProps,
                itemHiddenFields,
                bodyItemFieldsConfig,
                resumeStyles,
            );

            return `<div style="${bodyItemWrapperStyle}">${bodyItemHtml}</div>`;
        })
        .filter(Boolean)
        .join("");

    return (
        <PdfSectionContainer
            resumeStyles={resumeStyles}
            isStaticHeader={!!isStaticHeader}
            activeWidth={details.activeWidth}
        >
            {!isSplitSection && (
                <HtmlToPdf
                    html={headerHtml}
                    documentStyles={resumeStyles}
                    isStaticHeader={isStaticHeader}
                />
            )}
            <HtmlToPdf
                html={bodyHtml}
                documentStyles={resumeStyles}
                isStaticHeader={isStaticHeader}
            />
        </PdfSectionContainer>
    );
};
