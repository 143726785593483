import clsx from "clsx";
import { useMemo } from "react";

interface TooltopProps {
    text: string;
    showArrow?: boolean;
    className?: string;
    positionClassName?: string;
    size?: "small" | "default";
    yPosition?: number;
}

/**
 * Ensure this component is wrapped in a `relative group` container
 */
export const Tooltip = ({
    text,
    showArrow,
    className,
    positionClassName,
    size = "default",
    yPosition,
}: TooltopProps) => {
    const sizePositionClassName = useMemo(
        () =>
            className ?? size === "default"
                ? `-translate-x-1/2 translate-y-[${yPosition ?? 80}%]`
                : `-translate-x-1/2 translate-y-[${yPosition ?? 100}%]`,
        [className, size, yPosition],
    );
    return (
        <span
            className={clsx(
                positionClassName ?? sizePositionClassName,
                className,
                "invisible absolute left-1/2 z-[100] rounded-lg bg-neutral-700 text-center text-neutral-50 opacity-0 shadow-tooltip transition-[opacity] ease-in group-hover:visible group-hover:opacity-100 group-hover:delay-500",
                size === "small"
                    ? "max-w-24 px-2 py-1 text-app-labelsbuttons-4-mobile md:text-app-labelsbuttons-4-desktop"
                    : "min-w-32 max-w-48 px-3 py-2 text-app-labelsbuttons-4-mobile md:text-app-labelsbuttons-4-desktop",
            )}
        >
            {showArrow && (
                <div
                    className={clsx(
                        size === "default" ? "h-4 w-4" : "h-3 w-3",
                        "absolute -top-1 left-1/2 z-[-1] -translate-x-1/2 rotate-45 bg-neutral-700",
                    )}
                />
            )}
            {text}
        </span>
    );
};
