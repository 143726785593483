import { EmailHeading } from "@/email/components/EmailHeading";
import { EmailWrapper } from "@/email/components/EmailWrapper";
import { User } from "@/types";
import { Section, Text } from "@react-email/components";

interface FeedbackEmailProps {
    user?: User;
    url: string;
    feedback: string;
}

const FeedbackEmail = ({ user, feedback, url }: FeedbackEmailProps) => {
    return (
        <EmailWrapper preview="1Template User Feedback">
            <EmailHeading>User Feedback</EmailHeading>
            <Section>
                {user && (
                    <Text>
                        User: {user?.email} ({user?.id})
                    </Text>
                )}
                {url && <Text>URL: {url}</Text>}
                {feedback && <Text>Feedback: {feedback}</Text>}
            </Section>
        </EmailWrapper>
    );
};

export default FeedbackEmail;
