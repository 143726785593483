import { useEffect, useState } from 'react';

export const useNavigatorOnline = () => {
    const [online, setOnline] = useState(navigator.onLine);

    useEffect(() => {
        if (window.navigator.onLine !== online) {
            setOnline(window.navigator.onLine);
            return;
        }

        function handleOnlineStatus() {
            setOnline(window.navigator.onLine);
        }

        window.addEventListener('online', handleOnlineStatus);
        window.addEventListener('offline', handleOnlineStatus);

        return () => {
            window.removeEventListener('online', handleOnlineStatus);
            window.removeEventListener('offline', handleOnlineStatus);
        };
    }, [online, setOnline]);

    const isOnline = online === true;
    const isOffline = online === false;
    return { isOnline, isOffline };
};