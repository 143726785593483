import { Button } from "@react-email/components";

export const EmailButton = ({
    children,
    href,
}: {
    children: React.ReactNode;
    href: string;
}) => (
    <Button
        href={href}
        style={{
            backgroundColor: "#2D2FC7",
            borderRadius: "8px",
            color: "#fff",
            fontSize: "16px",
            fontWeight: 700,
            padding: "8px 24px",
            margin: "24px 0",
        }}
    >
        {children}
    </Button>
);
