export enum GA_EVENT_CATEGORIES {
    BUTTON = "button",
    NAVIGATION = "navigation",
    FORM = "form",
    MODAL = "modal",
    STRIPE = "stripe",
    ERROR = "error",
    NOTIFICATION = "notification",
}

export enum GA_EVENT_ACTIONS {
    CLICK = "click",
    SUBMIT = "submit",
    OPEN = "open",
    CLOSE = "close",
    ERROR = "error",
}

export enum META_EVENT_ACTIONS {
    COMPLETED_REGISTRATION = "CompletedRegistration",
    INITIATE_CHECKOUT = "InitiateCheckout",
    START_TRIAL = "StartTrial",
    SUBSCRIBE = "Subscribe",
}

export enum META_CUSTOM_EVENT_ACTIONS {
    UPGRADE = "Upgrade",
    MANAGE = "Manage",
    CANCEL_PURCHASE = "CancelPurchase",
}
