import { Checkbox } from "@/components/form/Checkbox";
import { jobRoles } from "@/pages/Preferences/constants";
import { PreferenceOptions } from "@/types";

interface JobRoleListProps {
    onJobRoleClick: (jobRole: string, checked: boolean) => void;
    selectedJobRoles: string[];
    showOther?: boolean;
    otherChecked?: boolean;
    onOtherClick?: (checked: boolean) => void;
    roles?: PreferenceOptions;
}

export const JobRoleList = ({
    onJobRoleClick,
    selectedJobRoles,
    showOther,
    otherChecked,
    onOtherClick,
    roles = jobRoles,
}: JobRoleListProps) => {
    return (
        <>
            {roles.map(({ label, value }) => (
                <Checkbox
                    key={value}
                    label={label}
                    id={value}
                    checked={selectedJobRoles.includes(value)}
                    onChange={checked => onJobRoleClick(value, checked)}
                />
            ))}
            {showOther && onOtherClick && (
                <Checkbox
                    label="Other"
                    id="other"
                    checked={otherChecked ?? false}
                    onChange={checked => onOtherClick(checked)}
                />
            )}
        </>
    );
};
