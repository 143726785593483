import { Img } from "@react-email/components";

export const EmailLogo = () => (
    <Img
        src="https://s3.ap-southeast-2.amazonaws.com/1template.com/static/logo/1template-logo-full-white.png"
        height={32}
        alt="1Template"
        style={{ margin: "0 auto 36px" }}
    />
);
