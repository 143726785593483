import { UserPlan } from "@/types/billing";
import { Tokens } from "./token";

export interface RegisterRequest {
    firstName: string;
    lastName: string;
    email: string;
    password: string;
    preferences?: UserPreferences;
    acceptedTerms: boolean;
    marketingOptIns: MarketingOptIn[];
    recaptchaToken: string;
}

export interface LoginRequest {
    email: string;
    password: string;
    recaptchaToken: string;
}

export interface LoginAndRegisterResponse {
    tokens: Tokens;
    user: User;
    verifyCode: string;
    plan: UserPlan;
}

export interface ChangePasswordRequest {
    oldPassword: string;
    newPassword: string;
}

export interface ChangeEmailRequest {
    currentEmail: string;
    newEmail: string;
    code: string;
}

export interface VerifyChangeEmailRequest {
    currentEmail: string;
    newEmail: string;
    password: string;
}

export interface ChangeNamesRequest {
    firstName: string;
    lastName: string;
}

export interface UpdatedOptInsRequest {
    channel: MarketingChannels;
    optedIn: boolean;
}

export type UserRole = "user" | "admin" | "superAdmin";

export interface MarketingOptIn {
    channel: MarketingChannels;
    optedIn: boolean;
    timestamp: Date;
    source: MarketingSources;
}

export const USER_FLAGS = {
    HAS_SEEN_RESUME_BUILDER_TOUR: "hasSeenResumeBuilderTour",
} as const;

export type UserFlagKey = keyof typeof USER_FLAGS;

export interface UserFlags {
    [key: string]: boolean | number | string;
}

export interface User {
    firstName: string;
    lastName: string;
    email: string;
    password?: string;
    role: UserRole;
    isEmailVerified?: boolean;
    id: string;
    loginMethod: "email" | "google" | "linkedin";
    preferences?: UserPreferences;
    acceptedTerms: boolean;
    marketingOptIns: MarketingOptIn[];
    stripeCustomerId: string;
    accountDeletionRequestedAt: Date | null;
    scheduledDeletionDate: Date | null;
    createdAt: Date;
    flags: UserFlags;
}

export interface UserPreferences {
    experienceLevel: string;
    industries: string[];
    jobTypes: string[];
    jobRoles: string[];
}

export enum MarketingChannels {
    EMAIL = "email",
}

export enum MarketingSources {
    SIGNUP = "signup",
    PROFILE = "profile",
}