import { Resume } from "@/types";
import { DocumentVisibility } from "@/types/resume";

const visibilityOrder: Record<string, number> = {
    [DocumentVisibility.PUBLIC]: 1,
    [DocumentVisibility.PRIVATE]: 2,
    [DocumentVisibility.UNLISTED]: 3,
};

const sortFunctions: Record<string, (a: Resume, b: Resume) => number> = {
    "1": (a, b) => new Date(b.updatedAt).getTime() - new Date(a.updatedAt).getTime(),
    "2": (a, b) => a.name.localeCompare(b.name),
    "3": (a, b) => {
        const countA = Number(a.templateUsageCount) || 0;
        const countB = Number(b.templateUsageCount) || 0;
        return countB - countA;
    },
    "4": (a, b) => {
        const orderA = visibilityOrder[a.visibility || ""] || Infinity;
        const orderB = visibilityOrder[b.visibility || ""] || Infinity;
        return orderA - orderB;
    },
    "5": (a, b) => new Date(b.createdAt).getTime() - new Date(a.createdAt).getTime(),
};

export const sortResumes = (resumes: Resume[], sortBy: string) => {
    const sortFunction = sortFunctions[sortBy];
    return sortFunction ? [...resumes].sort(sortFunction) : resumes;
};

export const formatTemplateTimestamps = (resumes: Resume[]): Resume[] => {
    return resumes.map(template => {
        return {
            ...template,
            createdAt: new Date(template.createdAt),
            updatedAt: new Date(template.updatedAt),
        };
    });
};
