import {
    Button,
    ButtonColourStyles,
    ButtonVariant,
} from "@/components/Button/Button";
import { AppText } from "@/components/Text/AppText";
import { useAppSelector } from "@/hooks/types";
import { ChangeEmail } from "@/pages/Profile/ChangeEmail";
import { ChangeNames } from "@/pages/Profile/ChangeNames";
import { ChangePreferences } from "@/pages/Profile/ChangePreferences";
import { getUserDetails } from "@/store/user/selectors";
import { AppRoutes } from "@/types/routes";

export const ProfileDetails = () => {
    const userEmail = useAppSelector(getUserDetails)?.email;
    return (
        <div className="flex flex-col">
            <AppText
                variant="headings"
                className="mb-8 hidden text-neutral-900 md:block"
            >
                Profile
            </AppText>
            <div className="mb-8 md:mb-0">
                <AppText
                    variant="subheadings"
                    className="mb-4 text-neutral-900"
                >
                    Basic info
                </AppText>
                <ChangeNames />
            </div>
            <div className="mb-8 md:mb-0">
                <AppText
                    className="mb-4 text-neutral-900 "
                    variant="subheadings"
                >
                    Change Email
                </AppText>
                <AppText
                    className="mb-4 text-neutral-700"
                    variant="labelsbuttons"
                >
                    Before you can update your email, we’ll need to verify your
                    new email address.
                </AppText>
                <AppText
                    className="mb-4 mt-2 block text-neutral-700 md:mt-0"
                    variant="labelsbuttons"
                >
                    Current email: {userEmail}
                </AppText>
                <ChangeEmail />
            </div>
            <div className="mb-8 md:mb-0">
                <ChangePreferences />
            </div>
            <div className="mb-8 md:mb-0">
                <AppText
                    variant="subheadings"
                    className="mb-4 text-neutral-900"
                >
                    Preferences
                </AppText>
                <AppText
                    className="mb-4 block text-neutral-700"
                    variant="labelsbuttons"
                >
                    Manage and update your account preferences.
                </AppText>
                <Button
                    to={`/${AppRoutes.Preferences}?redirect=${AppRoutes.Profile}`}
                    className="float-end mb-4"
                    variant={ButtonVariant.SOLID}
                    color={ButtonColourStyles.SOLID_GREY}
                >
                    Manage preferences
                </Button>
            </div>
        </div>
    );
};
