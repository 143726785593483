import {
    Button,
    ButtonColourStyles,
    ButtonVariant,
} from "@/components/Button/Button";
import clsx from "clsx";
import { CheckIcon, Sparkles } from "lucide-react";
import { useCallback, useEffect, useState } from "react";
import { loadStripe } from "@stripe/stripe-js/pure";
import { useAppSelector } from "@/hooks/types";
import { StripeProduct } from "@/types/billing";
import { LoadingSpinner } from "@/components/LoadingSpinner/LoadingSpinner";
import { AppText } from "@/components/Text/AppText";
import { useStripe } from "@/hooks/useStripe";
import {
    getProducts,
    getProductsError,
    getProductsLoading,
} from "@/store/billing/selectors";
import { Alert } from "@/components/Alert/Alert";
import { formatCapitalisedTitle } from "@/utils/string";
import { FREE_PLAN_MAX_RESUMES } from "@/constants/plans";
import { trackMetaEvent } from "@/utils/tracking";
import { META_EVENT_ACTIONS } from "@/types/tracking";
import { translate } from "@/helper/translate";

const freePlan: StripeProduct = {
    id: "free",
    name: "Free",
    description: "Free plan",
    price: 0,
    currency: "",
    currencySymbol: "",
    interval: "",
    metadata: {},
    features: [
        "Complete app access",
        "Unlimited resume edits",
        "Unlimited previews",
        `Up to ${FREE_PLAN_MAX_RESUMES} resumes`,
    ],
    priceId: "",
};

const getDescription = (product: StripeProduct) => {
    if (product.id === "free") {
        return "Get started at no cost. Perfect for exploring our tools and features.";
    } else if (product.interval === "month") {
        return `Unlock unlimited access for ongoing resume building and ${translate("customisation")}.`;
    } else if (product.interval === "week") {
        return "Weekly access to build and download your resume hassle-free.";
    }
    return "";
};

export const Plans = ({
    activePlan = "free",
    showFreePlan = false,
    isProfile = false,
    source = "",
    trialPeriodDays,
}: {
    activePlan?: string;
    showFreePlan?: boolean;
    isProfile?: boolean;
    source?: "" | "upgrade" | "download" | "preview";
    trialPeriodDays?: number;
}) => {
    const [loading, setLoading] = useState(false);
    const stripeProducts = useAppSelector(getProducts);
    const loadingProducts = useAppSelector(getProductsLoading);
    const errorProducts = useAppSelector(getProductsError);
    const [error, setError] = useState<string | null>(null);

    const { getStripeProducts, createStripeCheckoutSession } = useStripe();

    const onPlanSelect = useCallback(
        async (priceId: string) => {
            setError(null);
            const stripe = await loadStripe(
                import.meta.env.VITE_STRIPE_PUBLISHABLE_KEY,
            );
            const redirectUrl = isProfile
                ? `${window.location.origin}${window.location.pathname}?tab=plan`
                : `${window.location.origin}${window.location.pathname}?source=${source}`;
            try {
                setLoading(true);
                const sessionId = await createStripeCheckoutSession(
                    priceId,
                    redirectUrl,
                    trialPeriodDays,
                );

                trackMetaEvent(META_EVENT_ACTIONS.INITIATE_CHECKOUT, {
                    content_category: "Plan selection",
                    content_name: "Select plan",
                    num_items: 1,
                    content_type: "product",
                    content_ids: [priceId],
                });

                const res = await stripe?.redirectToCheckout({
                    sessionId,
                });

                if (res?.error) {
                    console.error(
                        "Error initiating Stripe checkout:",
                        res.error,
                    );
                }
            } catch (error) {
                console.error("Error initiating Stripe checkout:", error);
                setError(error as string);
            } finally {
                setLoading(false);
            }
        },
        [isProfile, source, createStripeCheckoutSession, trialPeriodDays],
    );

    useEffect(() => {
        if (stripeProducts === null && !loadingProducts && !errorProducts)
            getStripeProducts();
    }, [getStripeProducts, stripeProducts, loadingProducts, errorProducts]);

    if (errorProducts || error) {
        return (
            <div className="flex h-[400px] items-center justify-center">
                <Alert
                    message="Something went wrong, please reload and try again."
                    type="error"
                />
            </div>
        );
    }
    return (
        <div
            className={clsx(
                "mb:mt-0 grid gap-0 gap-y-4 lg:gap-4",
                loading || loadingProducts
                    ? "grid-cols-1"
                    : showFreePlan
                      ? "grid-cols-1 lg:grid-cols-3"
                      : "grid-cols-1 lg:grid-cols-2",
                "items-end",
            )}
        >
            {loading || loadingProducts ? (
                <div className="flex min-h-[476px] items-center justify-center">
                    <LoadingSpinner />
                </div>
            ) : (
                stripeProducts &&
                [freePlan, ...stripeProducts].map(product => {
                    if (!showFreePlan && product.id === "free") {
                        return null;
                    }
                    const isPopular = product.metadata.popular;
                    const isClickable =
                        product.id !== "free" && product.id !== activePlan;
                    return (
                        <div
                            key={product.id}
                            className={clsx(
                                "overflow-hidden rounded-xl rounded-b-xl px-2 pb-2",
                                product.id !== "free" &&
                                    isClickable &&
                                    "cursor-pointer",
                                !isProfile &&
                                    "transition-transform duration-300 ease-in-out",
                                isPopular
                                    ? "bg-primary-700"
                                    : "self-end bg-neutral-100",
                            )}
                            onClick={() =>
                                isClickable && onPlanSelect(product.priceId)
                            }
                        >
                            {isPopular && (
                                <div className="flex h-12 items-center justify-center rounded-t-xl bg-primary-700 group-hover:border-primary-500">
                                    {isPopular && (
                                        <AppText
                                            variant="contextheading"
                                            className="text-neutral-50"
                                        >
                                            MOST POPULAR
                                        </AppText>
                                    )}
                                </div>
                            )}
                            <div
                                className={clsx(
                                    activePlan === product.id &&
                                        product.id !== "free"
                                        ? "border-primary-700 bg-primary-50"
                                        : "border-neutral-300",
                                    product.id !== "free" &&
                                        "group-hover:border-primary-500",
                                    "col-span-1 overflow-hidden rounded-xl p-4 px-5",
                                    isPopular && "relative shadow-lg",
                                    isPopular
                                        ? "bg-neutral-50"
                                        : "bg-neutral-100",
                                )}
                            >
                                <div className="mb-2 w-fit py-1 text-neutral-900">
                                    <AppText variant="subheadings">
                                        {product.name.toUpperCase()} PLAN
                                    </AppText>
                                </div>
                                <AppText
                                    variant="regular"
                                    className="text-neutral-900"
                                >
                                    {getDescription(product)}
                                </AppText>
                                <div className="my-4 flex items-center gap-x-1">
                                    <AppText
                                        variant="headings"
                                        className="text-neutral-900"
                                    >
                                        {product.currencySymbol}
                                        {product.price || "Free"}
                                    </AppText>
                                    {product.interval && (
                                        <AppText
                                            variant="labelsbuttons"
                                            className="leading-5 text-neutral-900"
                                        >
                                            {" "}
                                            (
                                            {formatCapitalisedTitle(
                                                product.interval,
                                            )}
                                            )
                                        </AppText>
                                    )}
                                </div>
                                <div
                                    className={clsx(
                                        "h-1 w-full border-b-2 border-dashed",
                                        isPopular
                                            ? "border-primary-700"
                                            : "border-neutral-300",
                                    )}
                                />
                                <ul
                                    role="list"
                                    className="mb-6 mt-6 space-y-2"
                                >
                                    {product.features.map(feature => (
                                        <li
                                            key={`${product.id}-${feature}`}
                                            className={clsx(
                                                "flex items-center gap-x-2",
                                                product.id === "free" &&
                                                    feature.includes(
                                                        "hidden",
                                                    ) &&
                                                    "opacity-0",
                                            )}
                                        >
                                            <span className="flex h-6 w-6 items-center justify-center rounded-full bg-primary-700 text-white">
                                                <CheckIcon
                                                    className="h-4 w-4"
                                                    strokeWidth={3}
                                                />
                                            </span>
                                            <AppText
                                                variant="regular"
                                                className="text-neutral-700"
                                            >
                                                {feature}
                                            </AppText>
                                        </li>
                                    ))}
                                    {trialPeriodDays && (
                                        <li
                                            className={clsx(
                                                "flex items-center gap-x-2",
                                                product.id === "free" &&
                                                    "opacity-0",
                                            )}
                                        >
                                            <span className="flex h-6 w-6 items-center justify-center rounded-full bg-primary-700 text-white">
                                                <Sparkles
                                                    className="h-4 w-4"
                                                    strokeWidth={2}
                                                />
                                            </span>
                                            <AppText
                                                variant="regular"
                                                className={clsx(
                                                    "text-neutral-700",
                                                )}
                                            >
                                                {trialPeriodDays}-Day Free Trial
                                            </AppText>
                                        </li>
                                    )}
                                </ul>
                                {activePlan && activePlan === product.id ? (
                                    <Button
                                        color={
                                            ButtonColourStyles.SOLID_SOFT_INDIGO
                                        }
                                        className="pointer-events-none w-full cursor-not-allowed"
                                    >
                                        Active
                                    </Button>
                                ) : activePlan && activePlan !== "free" ? (
                                    <Button
                                        color={
                                            ButtonColourStyles.SOLID_SOFT_INDIGO
                                        }
                                        variant={ButtonVariant.SOLID}
                                        className="w-full"
                                        onClick={() =>
                                            onPlanSelect(product.priceId)
                                        }
                                    >
                                        Switch to this Plan
                                    </Button>
                                ) : (
                                    <Button
                                        color={
                                            isPopular
                                                ? ButtonColourStyles.SOLID_PRIMARY
                                                : ButtonColourStyles.SOLID_WHITE
                                        }
                                        className={clsx(
                                            "w-full",
                                            !isPopular &&
                                                "hover:bg-neutral-50/80",
                                        )}
                                        onClick={() =>
                                            onPlanSelect(product.priceId)
                                        }
                                    >
                                        Select Plan
                                    </Button>
                                )}
                            </div>
                        </div>
                    );
                })
            )}
        </div>
    );
};
