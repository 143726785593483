import { Tab } from "@headlessui/react";

interface TabButtonProps {
    children: React.ReactNode;
}

export const TabButton = ({ children }: TabButtonProps) => {
    return (
        <Tab
            className="w-full rounded px-3 py-2 text-app-labelsbuttons-4-mobile text-primary-900 outline-none hover:bg-primary-50 hover:text-primary-900 
        data-[selected]:bg-primary-900 data-[selected]:text-neutral-50 data-[selected]:hover:bg-primary-700 md:text-app-labelsbuttons-4-desktop"
        >
            {children}
        </Tab>
    );
};
