import {
    ButtonVariant,
    ButtonColourStyles,
    Button,
} from "@/components/Button/Button";
import { AppText } from "@/components/Text/AppText";
import { Tooltip } from "@/components/Tooltip/Tooltip";
import { useAppDispatch, useAppSelector } from "@/hooks/types";
import { setShowPlansModal } from "@/store/app/slice";
import {
    getUserActivePlan,
    getUserHasUsedTrial,
    getUserPlanEndingSoon,
    getUserPlanStatusIssue,
    getUserTrialDaysRemaining,
} from "@/store/user/selectors";
import { UserPlan } from "@/types/billing";
import { AppRoutes } from "@/types/routes";
import { getIsAResumePreviewPage, getIsProfilePage } from "@/utils/routes";
import { pluralise } from "@/utils/string";
import { Clock, CreditCard, SparklesIcon } from "lucide-react";
import { useCallback, useMemo } from "react";
import { useNavigate } from "react-router-dom";

const getButtonType = (
    userPlan: UserPlan | undefined,
    planStatusIssue: boolean,
    isEndingSoon: boolean,
) => {
    if (planStatusIssue) {
        return "issue";
    }

    if (isEndingSoon) {
        return "ending";
    }

    if (userPlan?.planStatus === "trialing") {
        return "trial";
    }

    if (userPlan?.isSubscribed) {
        return "active";
    }

    return "upgrade";
};

export const PlanStatusButton = () => {
    const userPlan = useAppSelector(getUserActivePlan);
    const isEndingSoon = useAppSelector(getUserPlanEndingSoon);
    const planStatusIssue = useAppSelector(getUserPlanStatusIssue);
    const trialDaysRemaining = useAppSelector(getUserTrialDaysRemaining);
    const dispatch = useAppDispatch();
    const navigate = useNavigate();
    const hasUsedTrial = useAppSelector(getUserHasUsedTrial);
    const trialPeriodDays = import.meta.env.VITE_STRIPE_TRIAL_PERIOD_DAYS;
    const canApplyTrial = useMemo(() => {
        return Number(trialPeriodDays) > 0 && !hasUsedTrial;
    }, [trialPeriodDays, hasUsedTrial]);
    const isAResumePreviewPage = getIsAResumePreviewPage();
    const isProfilePage = getIsProfilePage();

    const handleClick = useCallback(
        (goToProfile: boolean = false) => {
            if (goToProfile) {
                navigate(`${AppRoutes.Profile}?tab=plan`);
            } else {
                dispatch(setShowPlansModal("upgrade"));
            }
        },
        [dispatch, navigate],
    );

    if (userPlan?.planStatus === null) {
        // Plan status is null before we have fetched the plan. If no plan, it will be 'inactive'
        return null;
    }

    const buttonType = getButtonType(userPlan, planStatusIssue, !!isEndingSoon);

    switch (buttonType) {
        case "issue":
            return (
                <Button
                    variant={ButtonVariant.SOLID}
                    color={ButtonColourStyles.SOLID_ALERT}
                    onClick={() => handleClick(true)}
                    leftIcon={<CreditCard className="h-5 w-5" />}
                    className="relative"
                >
                    <AppText variant="labelsbuttons">Billing Error</AppText>
                    <Tooltip
                        text="Please update your billing details"
                        showArrow
                        className="w-52"
                        positionClassName="translate-y-[70%] -translate-x-1/2"
                    />
                </Button>
            );
        case "ending":
            return (
                <Button
                    variant={ButtonVariant.SOLID}
                    color={ButtonColourStyles.SOLID_SOFT_INDIGO}
                    onClick={() => handleClick(true)}
                    leftIcon={<Clock className="h-5 w-5" />}
                    className="relative"
                >
                    <AppText variant="labelsbuttons">
                        {userPlan?.details?.daysBeforeEnd}{" "}
                        {pluralise(
                            userPlan?.details?.daysBeforeEnd ?? 0,
                            "Day",
                            "Days",
                        )}{" "}
                        Left
                    </AppText>
                    <Tooltip
                        text={`${userPlan?.details?.daysBeforeEnd ?? 0} ${pluralise(userPlan?.details?.daysBeforeEnd ?? 0, "day", "days")} left of your plan`}
                        showArrow
                        className="w-52"
                        positionClassName="translate-y-[70%] -translate-x-1/2"
                    />
                </Button>
            );
        case "trial":
            return (
                <Button
                    variant={ButtonVariant.SOLID}
                    color={ButtonColourStyles.SOLID_SOFT_INDIGO}
                    onClick={() => handleClick(true)}
                    className="relative"
                >
                    <AppText variant="labelsbuttons">Plan Active</AppText>
                    <Tooltip
                        text={`${trialDaysRemaining ?? 0} ${pluralise(trialDaysRemaining ?? 0, "day", "days")} left of your free trial`}
                        showArrow
                        className="w-52"
                        positionClassName="translate-y-[70%] -translate-x-1/2"
                    />
                </Button>
            );
        case "active":
            return (
                <Button
                    variant={ButtonVariant.SOLID}
                    color={ButtonColourStyles.SOLID_SOFT_INDIGO}
                    onClick={() => handleClick(true)}
                >
                    <AppText variant="labelsbuttons">Plan Active</AppText>
                </Button>
            );
        default:
            return (
                <>
                    {canApplyTrial &&
                        (isAResumePreviewPage || isProfilePage) && (
                            <Button
                                variant={ButtonVariant.OUTLINE}
                                color={ButtonColourStyles.OUTLINE_SOFT_INDIGO}
                                onClick={() => handleClick(false)}
                                className="hidden lg:flex"
                            >
                                <AppText variant="labelsbuttons">
                                    Free Trial Promotion
                                </AppText>
                            </Button>
                        )}
                    <div
                        className="group relative flex h-9 cursor-pointer items-center justify-center gap-2 rounded-lg bg-gradient-to-r from-[#0901FF] to-[#010057] px-4 py-2 text-white transition-all duration-300 lg:min-w-[140px]"
                        onClick={() => handleClick(false)}
                    >
                        <SparklesIcon className="h-5 w-5 transition-transform duration-300 group-hover:animate-pulse" />
                        <AppText variant="labelsbuttons">Upgrade</AppText>
                    </div>
                </>
            );
    }
};
