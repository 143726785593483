import { AdminNavigationItem } from "@/types/admin";
import { ToggleState } from "@/types/section";

export const HEADER_STRING = "[header]";
export const CONTENT_STRING = "[content]";

export const layoutFieldTypes = [
    {
        id: "dropdown",
        name: "Dropdown",
    },
    // {
    //     id: "toggle",
    //     name: "Toggle",
    // },
];

export const fieldToggleStateOptions = [
    {
        id: ToggleState.ON.toString(),
        name: "On - Defaults to on during resume creation",
    },
    {
        id: ToggleState.OFF.toString(),
        name: "Off - Defaults to off during resume creation",
    },
    {
        id: ToggleState.DISABLED.toString(),
        name: "Disabled - Field toggle hidden",
    },
];

export const layoutFieldToggleStateOptions = [
    {
        id: ToggleState.ON.toString(),
        name: "On - Show field in sidebar",
    },
    {
        id: ToggleState.DISABLED.toString(),
        name: "Disabled - Hide field in sidebar",
    },
];

export const supportedWidthOptions = [
    { id: "full", name: "Full" },
    { id: "half", name: "Half" },
    { id: "one-third", name: "One Third" },
    {
        id: "two-thirds",
        name: "Two Thirds",
    },
];

export const sectionTagOptions = [
    { id: "recommended", name: "Recommended" },
];


export const sectionFormNavItems: AdminNavigationItem[] = [
    { id: "details", label: "Details" },
    { id: "layout", label: "Layout" },
    { id: "header", label: "Header fields" },
    { id: "body", label: "Body fields" },
    { id: "layout-fields", label: "Layout fields" },
];
