interface AdminTableBodyRowProps {
    children: React.ReactNode;
    onClick?: () => void;
}

export const AdminTableBodyRow = ({
    children,
    onClick,
}: AdminTableBodyRowProps) => {
    return (
        <tr
            onClick={onClick}
            className="cursor-pointer transition duration-150 ease-in-out hover:bg-gray-50"
        >
            {children}
        </tr>
    );
};
