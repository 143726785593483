import { Template } from "@/types";
import { PayloadAction, createSlice } from "@reduxjs/toolkit";

interface TemplateState {
    activeSection: string | undefined;
    activeTemplate: Template | undefined;
    activeItemId: string | null;
    templates: Template[];
}

const initialState: TemplateState = {
    activeSection: undefined, // current editing section
    activeTemplate: undefined,
    activeItemId: null,
    templates: [],
};

const templateSlice = createSlice({
    name: "template",
    initialState,
    reducers: {
        setActiveEntities: (state, { payload }: PayloadAction<{ activeSection: string, activeItemId?: string; }>) => {
            return {
                ...state,
                ...payload,
            };
        },
        setActiveSection: (
            state,
            { payload }: PayloadAction<string | undefined>,
        ) => {
            return {
                ...state,
                activeSection: payload,
            };
        },
        setActiveTemplate: (
            state,
            { payload }: PayloadAction<Template | undefined>,
        ) => {
            return {
                ...state,
                activeTemplate: payload,
            };
        },
        setActiveItemId: (
            state,
            { payload }: PayloadAction<string | null>,
        ) => {
            return {
                ...state,
                activeItemId: payload,
            };
        },
        setTemplates: (state, { payload }: PayloadAction<Template[]>) => {
            return {
                ...state,
                templates: payload,
            };
        }
    },
});

export const {
    setActiveSection,
    setActiveTemplate,
    setActiveItemId,
    setTemplates,
    setActiveEntities
} = templateSlice.actions;
export const { reducer: templateReducer } = templateSlice;
