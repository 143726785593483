const buttonCommandMap = {
    "undo": {
        mac: "⌘+Z",
        windows: "CTRL+Z",
    },
    "redo": {
        mac: "⌘+Z",
        windows: "CTRL+Y",
    },
    "bold": {
        mac: "⌘+B",
        windows: "CTRL+B",
    },
    "italic": {
        mac: "⌘+I",
        windows: "CTRL+I",
    },
    "underline": {
        mac: "⌘+U",
        windows: "CTRL+U",
    },
    "link": {
        mac: "⌘+K",
        windows: "CTRL+K",
    },
    "align-left": {
        mac: "⌘+Shift+L",
        windows: "CTRL+Shift+L",
    },
    "align-center": {
        mac: "⌘+Shift+E",
        windows: "CTRL+Shift+E",
    },
    "align-right": {
        mac: "⌘+Shift+R",
        windows: "CTRL+Shift+R",
    },
    "align-justify": {
        mac: "⌘+Shift+J",
        windows: "CTRL+Shift+J",
    },
    "ordered-list": {
        mac: "⌘+Shift+7",
        windows: "CTRL+Shift+7",
    },
    "bullet-list": {
        mac: "⌘+Shift+8",
        windows: "CTRL+Shift+8",
    },

};

export const getButtonCommand = (button: string, isMacPlatform: boolean) => {
    const platform = isMacPlatform ? "mac" : "windows";
    const commands = buttonCommandMap[button];
    if (!commands) return "";
    const command = commands[platform];
    return command ?? "";
};