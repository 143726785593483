import resumeApi from "@/api/resume";
import { UploadImage } from "@/components/Images/UploadImage";
import { PROFILE_IMAGE_KEY } from "@/constants/resume";
import { useAppDispatch, useAppSelector } from "@/hooks/types";
import { useUpdateTemplate } from "@/hooks/useUpdateTemplate";
import { DEFAULT_HEADER_IMAGE_PATH } from "@/store/resume/constants";
import {
    getActiveResumeSections,
    getActiveResumeStyles,
    getDefaultHeaderImage,
} from "@/store/resume/selectors";
import { removeResumeImage } from "@/store/resume/slice";
import { ResumeStyles, StyleConfig } from "@/types/resume";
import { useCallback, useMemo } from "react";
import { useParams } from "react-router-dom";

interface ImageProps {
    src: string;
    sectionId: string;
    propId: string;
    propType: "header" | "body";
    editable: boolean;
    showPreview: boolean;
    triggerType?: "button" | "iconButton";
    inlineStyles?: string;
    stylesConfig?: ResumeStyles;
}

export const Image = ({
    src,
    sectionId,
    propId,
    propType,
    editable,
    showPreview,
    triggerType,
    inlineStyles,
    stylesConfig,
}: ImageProps) => {
    const activeResumeSections = useAppSelector(getActiveResumeSections);
    const activeResumeStyles = useAppSelector(getActiveResumeStyles);
    const { updateField, updateStyles } = useUpdateTemplate();
    const { id } = useParams();
    const defaultHeaderImage = useAppSelector(getDefaultHeaderImage);
    const dispatch = useAppDispatch();

    const imageClassName = useMemo(
        () => activeResumeStyles?.[sectionId]?.className,
        [activeResumeStyles, sectionId],
    );

    const handleSaveImage = useCallback(
        (imageURL: string, imageStyles?: Record<string, any>) => {
            if (!activeResumeSections || !sectionId) return;
            updateField(imageURL, propId, propType, sectionId, undefined, () => {
                if (imageStyles) {
                    updateStyles(PROFILE_IMAGE_KEY, imageStyles);
                }
            });
        },
        [activeResumeSections, sectionId, updateField, updateStyles, propId, propType],
    );

    const handleRemoveImage = useCallback(
        (previousImageUrl?: string) => {
            if (previousImageUrl && id) {
                if (previousImageUrl.includes(DEFAULT_HEADER_IMAGE_PATH)) {
                    return;
                }
                resumeApi.deleteResumeImage(id, previousImageUrl).then(() => {
                    dispatch(removeResumeImage(previousImageUrl));
                    return;
                });
            } else if (!activeResumeSections || !sectionId || !src || !id)
                return;
            if (src.includes(DEFAULT_HEADER_IMAGE_PATH)) return; // Do not delete the default image

            if (!previousImageUrl) {
                resumeApi.deleteResumeImage(id, src).then(() => {
                    // Set the image to being the default value
                    updateField(
                        defaultHeaderImage ?? "",
                        propId,
                        propType,
                        sectionId,
                    );
                    dispatch(removeResumeImage(src));
                });
            }
        },
        [
            id,
            activeResumeSections,
            sectionId,
            src,
            updateField,
            defaultHeaderImage,
            propId,
            propType,
            dispatch,
        ],
    );

    const imageInlineStyles = useMemo(() => {
        const profileImageStyles = stylesConfig?.[PROFILE_IMAGE_KEY] ?? {};

        let inline = inlineStyles ?? "";
        Object.keys(profileImageStyles).forEach(key => {
            inline += `${key}: ${profileImageStyles[key as keyof StyleConfig]};`;
        });
        return inline;
    }, [inlineStyles, stylesConfig]);

    return (
        <UploadImage
            src={src}
            parentClassName={imageClassName ?? ""}
            handleSaveImage={imageUrl => handleSaveImage(imageUrl)}
            editable={editable}
            showPreview={showPreview}
            handleRemoveImage={handleRemoveImage}
            triggerType={triggerType}
            inlineStyles={imageInlineStyles}
        />
    );
};
