import { EnhancedStore, Middleware } from "@reduxjs/toolkit";
import { debounce } from "lodash";
import resumeApi from "@/api/resume";
import { RootState, store } from "@/store/store";
import { addNotification } from "@/store/app/slice";
import { NotificationType } from "@/types/app";
import { NotificationMessageType } from "@/types";

enum ActionTypes {
    UPDATE_SECTION_BODY_FIELD = "resume/updateSectionBodyField",
    UPDATE_SECTION_HEADER_FIELD = "resume/updateSectionHeaderField",
}

interface SaveQueueItem {
    sectionId: string;
    fieldKey: "header" | "body";
    updates: Record<string, any>;
    itemId?: string;
    resumeId: string;
}

// Track save queue outside of middleware instance
const saveQueue: SaveQueueItem[] = [];
let isSaving = false;

const processQueue = async () => {
    if (isSaving || saveQueue.length === 0) return;
    isSaving = true;

    try {
        // Group updates by section
        const latestUpdates = saveQueue.reduce((acc, curr) => {
            const key = `${curr.resumeId}-${curr.sectionId}-${curr.fieldKey}-${curr.itemId || ""}`;
            acc[key] = curr;
            return acc;
        }, {} as Record<string, SaveQueueItem>);

        // Clear queue before processing
        saveQueue.length = 0;

        // Process all unique updates
        await Promise.all(
            Object.values(latestUpdates).map(async (update) => {
                await resumeApi.updateResumeSection(update.resumeId, update.sectionId, {
                    fieldKey: update.fieldKey,
                    itemId: update.itemId,
                    value: update.updates,
                });
            })
        );
    } catch (error) {
        console.error("Failed to save updates:", error);
        store.dispatch(
            addNotification({
                type: NotificationType.ERROR,
                title: "Failed to save changes",
                desc: "Please try again.",
                messageType: NotificationMessageType.RESUME_UPDATE_FIELD,
            })
        );
    }

    isSaving = false;
    if (saveQueue.length > 0) {
        processQueue();
    }
};

const debouncedProcessQueue = debounce(processQueue, 2000);

export const saveFieldsMiddleware: (store: EnhancedStore<object>) => Middleware = (store) => (next: any) => (action: any) => {
    const result = next(action);

    if (
        action.type === ActionTypes.UPDATE_SECTION_BODY_FIELD ||
        action.type === ActionTypes.UPDATE_SECTION_HEADER_FIELD
    ) {
        const state: RootState = store.getState();
        const resumeId = state.resume.activeResume?.id;

        if (!resumeId) return result;

        saveQueue.push({
            resumeId,
            sectionId: action.payload.sectionId,
            fieldKey: action.type.includes("Body") ? "body" : "header",
            updates: action.payload.value,
            itemId: action.payload.itemId,
        });

        debouncedProcessQueue();
    }

    return result;
};