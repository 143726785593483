import { headingClassMap } from "@/components/Text/constants";
import clsx from "clsx";
import { createElement } from "react";

interface HeadingTextProps {
    children: string;
    variant:
        | "heading-1"
        | "heading-2"
        | "heading-3"
        | "heading-4"
        | "heading-5"
        | "heading-6";
    className?: string;
}

export const HeadingText = ({
    children,
    variant,
    className,
}: HeadingTextProps) => {
    const { element, elementClassName } = headingClassMap[variant];
    return createElement(
        element,
        { className: clsx(elementClassName, className) },
        children,
    );
};
