import { pdfStyles } from "@/components/PdfDocument/styles/pdfStyles";
import { Link } from "@react-pdf/renderer";

export interface PdfLinkProps {
    href: string;
    children: React.ReactNode;
}

export const PdfLink = ({ href, children }: PdfLinkProps) => (
    <Link
        src={href}
        style={pdfStyles.link}
    >
        {children}
    </Link>
);
