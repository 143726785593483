import clsx from "clsx";
import { useEffect, useRef, useState } from "react";
import { motion } from "framer-motion";

interface CodeInputProps {
    length?: number;
    onComplete: (pin: string) => void;
    disabled?: boolean;
    success?: boolean;
}

export const CodeInput = ({
    length = 4,
    onComplete,
    disabled,
    success,
}: CodeInputProps) => {
    const [code, setCode] = useState<string[]>(Array(length).fill(""));
    const inputs = useRef<HTMLInputElement[]>([]);
    const [canSubmit, setCanSubmit] = useState(false);

    useEffect(() => {
        if (code.every(digit => digit !== "") && !success && canSubmit) {
            const fullCode = code.join("");
            onComplete(fullCode);
            setCanSubmit(false);
        }
    }, [canSubmit, code, onComplete, success]);

    const handleChange = (index: number, value: string) => {
        setCanSubmit(true);
        const newCode = [...code];
        newCode[index] = value;
        setCode(newCode);

        if (value && index < length - 1) {
            inputs.current[index + 1].focus();
        }
    };

    const handlePaste = e => {
        e.preventDefault();
        const pastedData = e.clipboardData
            .getData("text/plain")
            .slice(0, length);
        const newCode = [...code];
        for (let i = 0; i < pastedData.length; i++) {
            newCode[i] = pastedData[i];
        }
        setCode(newCode);
        if (pastedData.length === length) {
            inputs.current[length - 1].focus();
            setCanSubmit(true);
        } else {
            inputs.current[pastedData.length].focus();
        }
    };

    const handleKeyDown = (
        index: number,
        e: React.KeyboardEvent<HTMLInputElement>,
    ) => {
        if (e.key === "Backspace" && !code[index] && index > 0) {
            inputs.current[index - 1].focus();
        }
    };

    return (
        <div className="my-12 flex justify-center">
            {code.map((digit, index) => (
                <motion.input
                    key={index}
                    size={1}
                    maxLength={1}
                    type="number"
                    onKeyDown={e => handleKeyDown(index, e)}
                    disabled={disabled}
                    onChange={e => handleChange(index, e.target.value)}
                    onPaste={handlePaste}
                    min={0}
                    max={9}
                    value={digit}
                    ref={ref =>
                        (inputs.current[index] = ref as HTMLInputElement)
                    }
                    className={clsx(
                        success ? "ring-primary-500" : "ring-neutral-100",
                        "text-app-headings-1-mobile md:text-app-headings-1-desktop h-20 w-12 rounded-xl border-none p-2 text-center ring-2 ring-neutral-100 [appearance:textfield] focus:ring-2 focus:ring-primary-700 disabled:bg-neutral-100/50 lg:h-24 lg:w-16 lg:p-5 [&::-webkit-inner-spin-button]:appearance-none [&::-webkit-outer-spin-button]:appearance-none",
                    )}
                    animate={
                        success
                            ? {
                                  scale: [1, 1.1, 1],
                                  borderColor: [
                                      "#6482FC",
                                      "#6482FC",
                                      "#6482FC",
                                  ],
                              }
                            : {}
                    }
                    transition={{ duration: 0.5 }}
                    style={{ marginRight: index === length - 1 ? "0" : "16px" }}
                />
            ))}
        </div>
    );
};
