import { useAppDispatch, useAppSelector } from "@/hooks/types";
import { PageInfo } from "@/pages/ResumeBuilder/PageInfo";
import { setShowSideBar } from "@/store/app/slice";
import { getPages } from "@/store/pages/selectors";
import { getPageHeightByPageNumber } from "@/store/pages/utils";
import {
    getActiveResumeSections,
    getActiveResumeStyles,
} from "@/store/resume/selectors";
import { getSections } from "@/store/sections/selectors";
import { Page } from "@/types";
import { GripVertical } from "lucide-react";
import { useState, useEffect, useCallback } from "react";

const toTwoDecimalPlaces = (num: number) => Math.round(num * 100) / 100;

export const PageHeightDebugger = () => {
    const sections = useAppSelector(getSections);
    const pages = useAppSelector(getPages);
    const resumeSections = useAppSelector(getActiveResumeSections);
    const resumeStyles = useAppSelector(getActiveResumeStyles);

    const [localPages, setLocalPages] = useState<Page[]>([]);
    const dispatch = useAppDispatch();
    useEffect(() => {
        setLocalPages(pages);
    }, [pages]);
    useEffect(() => {
        dispatch(setShowSideBar(false));
    }, [dispatch]);

    const page1Col1Height = toTwoDecimalPlaces(localPages[0]?.columnHeights[0]);
    const page1Col2Height = toTwoDecimalPlaces(localPages[0]?.columnHeights[1]);
    const page2Col1Height = toTwoDecimalPlaces(localPages[1]?.columnHeights[0]);
    const page2Col2Height = toTwoDecimalPlaces(localPages[1]?.columnHeights[1]);
    const page3Col1Height = toTwoDecimalPlaces(localPages[2]?.columnHeights[0]);
    const page3Col2Height = toTwoDecimalPlaces(localPages[2]?.columnHeights[1]);

    const [isDragging, setIsDragging] = useState(false);
    const [position, setPosition] = useState(0);
    const [startX, setStartX] = useState(0);

    const handleMouseDown = useCallback(
        (e: React.MouseEvent) => {
            setIsDragging(true);
            setStartX(e.clientX - position);
        },
        [position],
    );

    const handleMouseMove = useCallback(
        (e: React.MouseEvent) => {
            if (isDragging) {
                const newPosition = e.clientX - startX;
                setPosition(newPosition);
            }
        },
        [isDragging, startX],
    );

    const handleMouseUp = useCallback(() => {
        setIsDragging(false);
    }, []);

    useEffect(() => {
        if (isDragging) {
            document.addEventListener("mouseup", handleMouseUp);
            document.addEventListener("mousemove", handleMouseMove as any);
        }
        return () => {
            document.removeEventListener("mouseup", handleMouseUp);
            document.removeEventListener("mousemove", handleMouseMove as any);
        };
    }, [isDragging, handleMouseMove, handleMouseUp]);

    return (
        <div
            className="fixed right-0 top-32 z-[1] max-h-[calc(100vh-40px)] cursor-move overflow-y-auto rounded-l-lg bg-white px-2 pb-32 ring-2 ring-primary-900"
            style={{ transform: `translateX(${position}px)` }}
            onMouseDown={handleMouseDown}
        >
            <GripVertical className="absolute left-0 top-0 h-6 w-6 rounded-lg text-neutral-900 " />
            <div className="space-y-1">
                <PageInfo
                    pageNumber={1}
                    col1Height={page1Col1Height}
                    col2Height={page1Col2Height}
                    maxHeight={getPageHeightByPageNumber(0, resumeStyles ?? {})}
                    pageSections={pages[0]?.sections}
                    resumeSections={resumeSections ?? {}}
                    sections={sections}
                    pageItems={pages[0]?.items}
                />
                <PageInfo
                    pageNumber={2}
                    col1Height={page2Col1Height}
                    col2Height={page2Col2Height}
                    maxHeight={getPageHeightByPageNumber(1, resumeStyles ?? {})}
                    pageSections={pages[1]?.sections}
                    resumeSections={resumeSections ?? {}}
                    sections={sections}
                    pageItems={pages[1]?.items}
                />
                <PageInfo
                    pageNumber={3}
                    col1Height={page3Col1Height}
                    col2Height={page3Col2Height}
                    maxHeight={getPageHeightByPageNumber(2, resumeStyles ?? {})}
                    pageSections={pages[2]?.sections}
                    resumeSections={resumeSections ?? {}}
                    sections={sections}
                    pageItems={pages[2]?.items}
                />
            </div>
        </div>
    );
};
