import { getEnv } from "@/utils/env";
import { useMemo } from "react";
import { Helmet } from "react-helmet";

interface PageHelmetProps {
    title: string;
    prefix?: string;
    description?: string;
    url?: string;
    robotsOverride?: string;
}

export const PageHelmet = ({
    title,
    description,
    url,
    prefix,
    robotsOverride,
}: PageHelmetProps) => {
    const env = getEnv();
    const isNonProdEnv = env !== "production";
    const prefixContent = prefix ? `${prefix}${prefix && " | "}` : "";
    const titleContent = `${prefixContent}1Template | ${title}`;
    const favIconPath = useMemo(() => {
        if (env === "production") {
            return "/favicon.ico";
        } else if (env === "staging") {
            return "/favicon-staging.ico";
        } else {
            return "/favicon-dev.ico";
        }
    }, [env]);

    // Determine robots content
    const robotsContent =
        robotsOverride ||
        (isNonProdEnv ? "noindex, nofollow" : "index, follow");

    return (
        <Helmet>
            <title>{titleContent}</title>
            {description && (
                <meta
                    name="description"
                    content={description}
                />
            )}
            {url && (
                <link
                    rel="canonical"
                    href={url}
                />
            )}
            <meta
                name="robots"
                content={robotsContent}
            />
            <link
                rel="icon"
                href={favIconPath}
                media={
                    env === "production"
                        ? "(prefers-color-scheme: light)"
                        : undefined
                }
            />
            {env === "production" && (
                <link
                    rel="icon"
                    href="/favicon-dark.ico"
                    media="(prefers-color-scheme: dark)"
                />
            )}
        </Helmet>
    );
};
