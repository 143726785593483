import clsx from "clsx";

interface NavItemProps {
    title: string;
    children: React.ReactNode;
    onClick: () => void;
    className?: string;
    ariaLabel?: string;
}

export const NavItem = ({
    children,
    onClick,
    className,
    ariaLabel,
}: NavItemProps) => {
    return (
        <button
            type="button"
            className={clsx(
                "group relative mb-3 w-full rounded-lg p-2",
                className,
            )}
            onClick={onClick}
            aria-label={ariaLabel}
        >
            {children}
        </button>
    );
};
