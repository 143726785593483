import { AppText } from "@/components/Text/AppText";

interface SideBarHeaderProps {
    title: string;
}

export const SideBarHeader = ({ title }: SideBarHeaderProps) => {
    return (
        <div className="mx-4 bg-white pb-4">
            <AppText variant="subheadings">{title}</AppText>
        </div>
    );
};
