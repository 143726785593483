import { SidebarStyles } from "./Styles/SidebarStyles";
import { SectionSettings } from "./SectionSettings/SectionSettings";
import { Assistant } from "./Assistant/Assistant";
import { useAppSelector } from "@/hooks/types";
import { SideBarState } from "@/types/app";
import { getShowSideBar, getSideBarState } from "@/store/app/selectors";
import clsx from "clsx";
import { BuilderTourStep } from "@/components/Onboarding/builder";

export type SideBarTabName =
    | "options"
    | "styles"
    | "rearrange"
    | "add-section"
    | "edit-section";

export const Sidebar = () => {
    const sideBarState = useAppSelector(getSideBarState);
    const showSideBar = useAppSelector(getShowSideBar);

    const getTabComponent = () => {
        switch (sideBarState) {
            case SideBarState.STYLES:
                return <SidebarStyles />;
            case SideBarState.ASSISTANT:
                return <Assistant />;
            case SideBarState.EDIT:
                return <SectionSettings />;
            default:
                return null;
        }
    };

    return (
        <div
            className={clsx(
                "fixed bottom-0 top-navigationHeight z-20 flex w-side-bar transform flex-col border-r-[1px] border-t-[1px] border-neutral-300 border-r-neutral-300 bg-white py-4 transition-transform duration-300 ease-in-out",
                showSideBar ? "translate-x-0" : "-translate-x-[360px]",
                BuilderTourStep.SIDE_BAR_CONTAINER,
            )}
        >
            {getTabComponent()}
        </div>
    );
};
