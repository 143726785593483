import { Alert } from "@/components/Alert/Alert";
import { LoadingSpinner } from "@/components/LoadingSpinner/LoadingSpinner";
import { Modal } from "../../Modal/Modal";
import { LaunchPromotion } from "@/components/Plans/LaunchPromotion";
import { Plans } from "@/components/Plans/Plans";
import { SubscribeSuccess } from "@/components/Modals/PlansModal/SubscribeSuccess";
import { AppText } from "@/components/Text/AppText";
import { useAppDispatch, useAppSelector } from "@/hooks/types";
import { useStripe } from "@/hooks/useStripe";
import { getShowPlansModal } from "@/store/app/selectors";
import { setShowPlansModal } from "@/store/app/slice";
import {
    getUserActivePlanName,
    getUserHasUsedTrial,
    getUserPlanStatusIssue,
} from "@/store/user/selectors";
import { ModalWidth } from "@/types/app";
import {
    GA_EVENT_ACTIONS,
    GA_EVENT_CATEGORIES,
    META_CUSTOM_EVENT_ACTIONS,
    META_EVENT_ACTIONS,
} from "@/types/tracking";
import {
    trackGAEvent,
    trackMetaCustomEvent,
    trackMetaEvent,
} from "@/utils/tracking";
import { useCallback, useEffect, useMemo, useRef, useState } from "react";
import { useSearchParams } from "react-router-dom";
import { ModalTypes } from "@/types/modal";
import { MONTHLY_PLAN_PRICE, WEEKLY_PLAN_PRICE } from "@/constants/plans";
import { openModal } from "@/store/modal/slice";

export const PlansModal = () => {
    const openReason = useAppSelector(getShowPlansModal);
    const activePlan = useAppSelector(getUserActivePlanName);
    const hasUsedTrial = useAppSelector(getUserHasUsedTrial);
    const dispatch = useAppDispatch();
    const [searchParams, setSearchParams] = useSearchParams();
    const [portalUrl, setPortalUrl] = useState<string | null>(null);
    const showSuccessRef = useRef(searchParams.get("res") === "success");
    const isUpgrade = useMemo(() => openReason === "upgrade", [openReason]);
    const planStatusIssue = useAppSelector(getUserPlanStatusIssue);
    const { createCustomerPortalSession, getAndUpdateUserPlan } = useStripe();
    const [loading, setLoading] = useState(false);
    const trialPeriodDays = import.meta.env.VITE_STRIPE_TRIAL_PERIOD_DAYS;
    const canApplyTrial = useMemo(() => {
        return Number(trialPeriodDays) > 0 && !hasUsedTrial;
    }, [trialPeriodDays, hasUsedTrial]);
    const [isClosing, setIsClosing] = useState(false);
    const lastModalWidthRef = useRef<ModalWidth>("4xl");
    const getUserPlan = useCallback(async () => {
        return await getAndUpdateUserPlan();
    }, [getAndUpdateUserPlan]);

    useEffect(() => {
        if (planStatusIssue) {
            const returnUrl = `${window.location.origin}${window.location.pathname}?source=${openReason}&refresh=true`;
            createCustomerPortalSession(returnUrl).then(setPortalUrl);
        }
    }, [planStatusIssue, createCustomerPortalSession, openReason]);

    useEffect(() => {
        if (openReason) {
            dispatch(openModal({ modalType: ModalTypes.PLANS }));
        }
    }, [dispatch, openReason]);

    useEffect(() => {
        const source = searchParams.get("source");
        const res = searchParams.get("res"); // either success or cancel
        const shouldRefresh = searchParams.get("refresh") === "true";
        let timeout: NodeJS.Timeout;
        if (source) {
            dispatch(
                setShowPlansModal(source as "download" | "upgrade" | "preview"),
            );

            if (res === "success" || shouldRefresh) {
                setLoading(true);
                getUserPlan().then(plan => {
                    setLoading(false);
                    if (plan?.isSubscribed) {
                        showSuccessRef.current = true;

                        trackMetaEvent(META_EVENT_ACTIONS.SUBSCRIBE, {
                            value:
                                plan.details?.interval === "Weekly"
                                    ? WEEKLY_PLAN_PRICE
                                    : MONTHLY_PLAN_PRICE,
                        });

                        if (plan.details?.trialEndDate) {
                            trackMetaEvent(META_EVENT_ACTIONS.START_TRIAL, {
                                value:
                                    plan.details?.interval === "Weekly"
                                        ? WEEKLY_PLAN_PRICE
                                        : MONTHLY_PLAN_PRICE,
                            });
                        }

                        timeout = setTimeout(() => {
                            setSearchParams({}, { replace: true });
                            if (source === "preview") {
                                // Trigger a hard reload to ensure we get the latest plan and document details
                                window.location.reload();
                            }
                        }, 2500);
                    }
                });
            }
            trackGAEvent(
                GA_EVENT_CATEGORIES.STRIPE,
                GA_EVENT_ACTIONS.SUBMIT,
                res === "success" ? "subscribe-success" : "subscribe-canceled",
            );
            if (res === "cancel") {
                trackMetaCustomEvent(META_CUSTOM_EVENT_ACTIONS.CANCEL_PURCHASE);
            }
            setSearchParams({}, { replace: true });
        }
        return () => clearTimeout(timeout);
    }, [searchParams, dispatch, setSearchParams, getUserPlan]);

    const modalWidth = useMemo(() => {
        if (isClosing) {
            return lastModalWidthRef.current;
        }

        let newWidth: ModalWidth = "4xl";
        if (planStatusIssue) {
            newWidth = "md";
        } else if (showSuccessRef.current) {
            newWidth = "lg";
        } else if (isUpgrade) {
            newWidth = "2xl";
        }

        lastModalWidthRef.current = newWidth;
        return newWidth;
    }, [isUpgrade, planStatusIssue, showSuccessRef, isClosing]);

    const modalTitle = useMemo(() => {
        if (planStatusIssue) {
            return "Inactive Plan";
        }
        if (openReason === "download") {
            return "Choose a plan.";
        }
        return "Upgrade";
    }, [openReason, planStatusIssue]);

    return (
        <Modal
            modalType={ModalTypes.PLANS}
            open={!!openReason}
            onClose={() => {
                setIsClosing(true);
                dispatch(setShowPlansModal(""));
            }}
            afterClose={() => {
                showSuccessRef.current = false;
                setIsClosing(false);
            }}
            title={modalTitle}
            width={modalWidth as ModalWidth}
            mobileFullScreen
        >
            {loading ? (
                <div className="flex min-h-40 items-center justify-center">
                    <LoadingSpinner />
                </div>
            ) : (
                <>
                    {showSuccessRef.current ? (
                        <SubscribeSuccess message="Upgrade Successful!" />
                    ) : (
                        <div className="flex flex-col gap-4">
                            {planStatusIssue ? (
                                <Alert
                                    type="error"
                                    actionLabel="Update billing information"
                                    onAction={() => {
                                        window.location.href = portalUrl || "#";
                                    }}
                                    message={
                                        <AppText variant="labelsbuttons">
                                            There&apos;s an issue with your
                                            subscription. Please update your
                                            payment details.
                                        </AppText>
                                    }
                                />
                            ) : (
                                <>
                                    <AppText
                                        variant="contextheading"
                                        className="text-neutral-900"
                                    >
                                        {openReason === "download"
                                            ? "You need a plan to download resumes."
                                            : "Select a plan to get full access."}
                                    </AppText>
                                    <LaunchPromotion />
                                    <Plans
                                        activePlan={activePlan}
                                        showFreePlan={openReason === "download"}
                                        source={openReason}
                                        trialPeriodDays={
                                            canApplyTrial
                                                ? trialPeriodDays
                                                : undefined
                                        }
                                    />
                                </>
                            )}
                        </div>
                    )}
                </>
            )}
        </Modal>
    );
};
