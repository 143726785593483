type FeatureFlags = {
    VITE_FEATURE_STRIPE: boolean;
    VITE_FEATURE_GOOGLE_SSO: boolean;
};

const flags: FeatureFlags = {
    VITE_FEATURE_STRIPE: import.meta.env.VITE_FEATURE_STRIPE === 'true' || false,
    VITE_FEATURE_GOOGLE_SSO: import.meta.env.VITE_FEATURE_GOOGLE_SSO === 'true' || false,
};

export const isFeatureEnabled = (flag: keyof FeatureFlags): boolean => {
    return flags[flag];
};