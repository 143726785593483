import { EmailButton } from "@/email/components/EmailButton";
import { EmailHeading } from "@/email/components/EmailHeading";
import { EmailLink } from "@/email/components/EmailLink";
import { EmailText } from "@/email/components/EmailText";
import { EmailWrapper } from "@/email/components/EmailWrapper";
import { Container, Section } from "@react-email/components";

const DeleteRequestConfirmationEmail = ({
    loginUrl = "https://app.1template.io",
}: {
    loginUrl: string;
}) => {
    return (
        <EmailWrapper preview="1Template Account Deletion Confirmation">
            <Section>
                <EmailHeading>
                    Confirmation of Account Deletion Request
                </EmailHeading>
                <Section>
                    <EmailText>
                        We&apos;ve received your request to delete your
                        1Template account. Your account is now scheduled for
                        deletion and will be permanently removed in 30 days.
                    </EmailText>
                    <Container
                        style={{
                            border: "1px solid #e0e0e0",
                            borderRadius: "4px",
                            padding: "16px 16px 4px",
                            backgroundColor: "#f8f8f8",
                            margin: "16px 0",
                        }}
                    >
                        <EmailText>
                            <strong>Please note:</strong> As part of the account
                            deletion process, we&apos;ve immediately cancelled
                            the renewal of any active subscriptions linked to
                            your account.
                        </EmailText>
                    </Container>
                    <EmailText noMargin>
                        Changed your mind? No worries. You can reactivate your
                        account by simply logging in within the next 30 days.
                    </EmailText>
                    <EmailButton href={loginUrl}>
                        Login to your account
                    </EmailButton>
                    <EmailText>
                        After the 30-day period, we&apos;ll permanently delete
                        all your account information, and it won&apos;t be
                        recoverable. If you need any help or have questions,
                        please reach out to us.
                    </EmailText>
                    <EmailText>
                        If you did not make this request, please contact us at{" "}
                        <EmailLink href="mailto:hello@1template.io">
                            hello@1template.io
                        </EmailLink>
                        .
                    </EmailText>
                </Section>
            </Section>
        </EmailWrapper>
    );
};

export default DeleteRequestConfirmationEmail;
