/* eslint-disable @typescript-eslint/no-explicit-any */
import { AppDispatch, RootState } from "@/store/store";
import { Resume, Section, User } from "@/types";
import { AxiosResponse } from "axios";

import { useDispatch, useSelector } from "react-redux";
import type { TypedUseSelectorHook } from "react-redux";

export type GetApiFunc = (...args: any[]) => Promise<AxiosResponse>;
export type DeleteApiFunc = (...args: any[]) => Promise<AxiosResponse>;
export type SaveApiFunc = (...args: any[]) => Promise<AxiosResponse>;

export type ApiFunc = GetApiFunc | DeleteApiFunc | SaveApiFunc;

export type GetResumeResponse = {
    results: Resume[];
};

export type SaveResumeResponse = Resume;

export type DeleteResumeResponse = {
    results: any[];
    id: string;
};

export type GetSectionsResponse = Section[];
export type GetSectionResponse = Section;

export type UploadImageResponse = {
    url: string;
};

export type GetUserResponse = User;
export type GetUsersResponse = {
    results: User[];
};

export type DataResponse =
    | GetResumeResponse
    | SaveResumeResponse
    | DeleteResumeResponse
    | GetSectionsResponse
    | UploadImageResponse
    | GetUserResponse
    | GetSectionResponse
    | GetUsersResponse;

// Use throughout your app instead of plain `useDispatch` and `useSelector`
export const useAppDispatch: () => AppDispatch = useDispatch;
export const useAppSelector: TypedUseSelectorHook<RootState> = useSelector;
