import { Section } from "@/types";
import { ColumnLayout, SectionDetails } from "@/types/resume";

/**
 * Calculate the true section width when the section is rendered out into the view.
 * 
 * Priority order is:
 * 1. If the section is a static header, return full width.
 * 2. If the column layout is single, return the width based on the active width.
 * 3. If the column layout is double, return the width based on column index and column layout.
 */
export const getSectionContainerWidth = (columnLayout?: ColumnLayout, sectionDetails?: SectionDetails, sectionConfig?: Section) => {
    if (!sectionDetails || !sectionConfig || !columnLayout || sectionConfig.isStaticHeader) return "col-span-6";

    if (columnLayout === ColumnLayout.SINGLE) {
        switch (sectionDetails.activeWidth) {
            case "one-third":
                return "col-span-2";
            case "two-thirds":
                return "col-span-4";
            case "full":
                return "col-span-6";
            case "half":
                return "col-span-3";
            default:
                return "col-span-6";
        }
    } else {
        return sectionDetails?.columnIndex === 1
            ? "col-span-2"
            : columnLayout === ColumnLayout.DOUBLE
                ? "col-span-4"
                : "col-span-6";
    }
};