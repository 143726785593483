import { PageColumnSection } from "@/components/Grid/PageColumnSection";
import { getColumnStyle } from "@/components/PdfDocument/utils/getStyles";
import { useAppSelector } from "@/hooks/types";
import { getPageSectionsByColumnIndex } from "@/store/pages/selectors";
import clsx from "clsx";

interface PageColumnProps {
    pageNumber: number;
    className: string;
    numberOfColumns: number;
    columnIndex: number;
}

export const PageColumn = ({
    className,
    numberOfColumns,
    columnIndex,
    pageNumber,
}: PageColumnProps) => {
    const columnStyles = getColumnStyle(numberOfColumns === 2, columnIndex);
    const columnSectionIds = useAppSelector(state =>
        getPageSectionsByColumnIndex(state, pageNumber, columnIndex),
    );

    return (
        <div
            style={columnStyles}
            className={clsx(className)}
        >
            {columnSectionIds.map(sectionId => (
                <PageColumnSection
                    key={`${pageNumber}-${columnIndex}-${sectionId}`}
                    sectionId={sectionId}
                    pageNumber={pageNumber}
                />
            ))}
        </div>
    );
};
