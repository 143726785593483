import { TextElementType } from "@/components/PdfDocument/types/pdfTypes";
import { getTextStyle } from "@/components/PdfDocument/utils/getStyles";
import { GLOBAL_STYLE_KEY } from "@/constants/resume";
import { ResumeStyles } from "@/types/resume";

interface TitleWrapperProps {
    children: React.ReactNode;
    isStaticHeader?: boolean;
    stylesConfig?: ResumeStyles;
    variant: TextElementType;
}

export const TitleWrapper = ({
    children,
    isStaticHeader,
    stylesConfig,
    variant,
}: TitleWrapperProps) => {
    const underlineSectionHeadings =
        stylesConfig?.[GLOBAL_STYLE_KEY]?.underlineSectionHeadings;
    const shouldUnderline =
        variant === "h2" && !isStaticHeader && underlineSectionHeadings;
    const titleStyle = getTextStyle(
        stylesConfig?.[GLOBAL_STYLE_KEY]?.headingFontSize ?? "",
        variant,
        true,
    );

    return (
        <div
            style={{
                wordBreak: "break-word",
                borderBottom: shouldUnderline ? "2px solid #21252A" : "",
                ...titleStyle,
            }}
        >
            {children}
        </div>
    );
};
