import { Container } from "@react-email/components";

export const EmailContainer = ({ children }: { children: React.ReactNode }) => (
    <Container
        style={{
            background: "#fff",
            padding: "24px",
            width: "100%",
            borderRadius: "12px",
        }}
    >
        {children}
    </Container>
);
