import { Modal } from "../../Modal/Modal";
import { AppText } from "@/components/Text/AppText";
import { ModalTypes } from "@/types/modal";
import { ModalFooter } from "@/components/Modal/ModalFooter";
import { getIsModalOpen } from "@/store/modal/selectors";
import { useAppDispatch, useAppSelector } from "@/hooks/types";
import { getUserActivePlan } from "@/store/user/selectors";
import { closeModal } from "@/store/modal/slice";
import { setShowPlansModal } from "@/store/app/slice";
import { useCallback } from "react";
import {
    FREE_PLAN_MAX_RESUMES,
    WEEKLY_PLAN_MAX_RESUMES,
} from "@/constants/plans";

const PlanLimitModal = () => {
    const isOpen = useAppSelector(getIsModalOpen(ModalTypes.PLAN_LIMIT));
    const userPlan = useAppSelector(getUserActivePlan);
    const dispatch = useAppDispatch();

    const handleUpgrade = useCallback(() => {
        dispatch(setShowPlansModal("upgrade"));
    }, [dispatch]);

    const planLimit =
        userPlan?.isSubscribed && userPlan?.details?.interval === "Weekly"
            ? WEEKLY_PLAN_MAX_RESUMES
            : FREE_PLAN_MAX_RESUMES;

    return (
        <Modal
            open={isOpen}
            modalType={ModalTypes.PLAN_LIMIT}
            title="Upgrade to Continue"
            width="md"
            footer={
                <ModalFooter
                    primaryActionText="Upgrade"
                    primaryActionOnClick={handleUpgrade}
                    secondaryActionText="Cancel"
                    secondaryActionOnClick={() =>
                        dispatch(closeModal(ModalTypes.PLAN_LIMIT))
                    }
                />
            }
        >
            <div className="flex flex-col justify-center">
                <AppText variant="regular">
                    You&apos;ve reached the {planLimit}-resume limit for your{" "}
                    {userPlan?.details?.interval?.toLowerCase() || "free"} plan.
                    Upgrade to create more resumes.
                </AppText>
            </div>
        </Modal>
    );
};

export default PlanLimitModal;
