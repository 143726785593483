import { LayoutToggles } from "@/components/Sidebar/Assistant/LayoutToggles";
import { SideBarField } from "@/components/Sidebar/SideBarField";
import { SideBarFieldContainer } from "@/components/Sidebar/SideBarFieldContainer";
import { ColourPicker } from "@/components/Sidebar/Styles/ColourPicker";
import { FontSelector } from "@/components/Sidebar/Styles/FontSelector";
import { FontSizeSelector } from "@/components/Sidebar/Styles/FontSizeSelector";
import { SwitchField } from "@/components/SwitchToggle/SwitchField";
import { DateFormatDropdown } from "@/components/Sidebar/Styles/DateFormatDropdown";
import { DateFormat, PageMarginSize, SectionSpacingSize } from "@/types/resume";
import { StepSlider } from "@/components/RangeSlider/StepSlider";
import { translate } from "@/helper/translate";

interface ResumeStyleOptionsProps {
    onFontChange: (font: string) => void;
    font: string;
    onFontSizeChange: (size: string) => void;
    fontSize: string;
    onAccentColourChange: (colour: string) => void;
    accentColour: string;
    onIconsToggle: (isEnabled: boolean) => void;
    showIcons: boolean;
    onUnderlineToggle: (isEnabled: boolean) => void;
    underlineHeadings: boolean;
    onDateLocationPositionChange: (layoutOption: "row" | "column") => void;
    dateLocationPosition: "row" | "column";
    onDateLocationLayoutChange: (layoutOption: "row" | "column") => void;
    dateLocationLayout: "row" | "column";
    dateFormat: DateFormat;
    onDateFormatChange: (format: DateFormat) => void;
    headingFontSize: string;
    onHeadingFontSizeChange: (size: string) => void;
    pageMarginSize: PageMarginSize;
    onPageMarginSizeChange: (size: PageMarginSize) => void;
    sectionSpacingSize: SectionSpacingSize;
    onSectionSpacingSizeChange: (size: SectionSpacingSize) => void;
}

export const ResumeStyleOptions = ({
    font,
    onFontChange,
    fontSize,
    onFontSizeChange,
    accentColour,
    onAccentColourChange,
    showIcons,
    onIconsToggle,
    underlineHeadings,
    onUnderlineToggle,
    dateFormat,
    onDateFormatChange,
    headingFontSize,
    onHeadingFontSizeChange,
    pageMarginSize,
    onPageMarginSizeChange,
    sectionSpacingSize,
    onSectionSpacingSizeChange,
}: ResumeStyleOptionsProps) => (
    <SideBarFieldContainer>
        <SideBarField title="Page Layout">
            <LayoutToggles />
        </SideBarField>
        <SideBarField title="Font">
            <FontSelector
                activeFont={font}
                onFontChange={onFontChange}
            />
        </SideBarField>
        <SideBarField title="Heading Font Size">
            <FontSizeSelector
                onSizeChange={onHeadingFontSizeChange}
                size={headingFontSize}
                isHeading
            />
        </SideBarField>
        <SideBarField title="Text Font Size">
            <FontSizeSelector
                onSizeChange={onFontSizeChange}
                size={fontSize}
            />
        </SideBarField>
        <SideBarField title={`Accent ${translate("colour", "title")}`}>
            <ColourPicker
                onChange={(colour: string) => onAccentColourChange(colour)}
                activeColour={accentColour}
            />
        </SideBarField>
        <SideBarField title="Date Format">
            <DateFormatDropdown
                onChange={(format: DateFormat) => onDateFormatChange(format)}
                activeFormat={dateFormat}
            />
        </SideBarField>
        <SideBarField title="Show Icons">
            <SwitchField
                enabled={!!showIcons}
                onToggle={(isEnabled: boolean) => onIconsToggle(isEnabled)}
            />
        </SideBarField>
        <SideBarField title="Underline Headings">
            <SwitchField
                enabled={!!underlineHeadings}
                onToggle={(isEnabled: boolean) => onUnderlineToggle(isEnabled)}
            />
        </SideBarField>
        <SideBarField
            title="Page Margin"
            vertical
        >
            <div className="mx-auto mt-1 w-full px-5">
                <StepSlider
                    value={pageMarginSize}
                    onChange={(value: number) =>
                        onPageMarginSizeChange(value as PageMarginSize)
                    }
                    steps={5}
                    maxLabel="Wide"
                    minLabel="Narrow"
                />
            </div>
        </SideBarField>
        <SideBarField
            title="Section Spacing"
            vertical
        >
            <div className="mx-auto mt-1 w-full px-5">
                <StepSlider
                    value={sectionSpacingSize}
                    onChange={(value: number) =>
                        onSectionSpacingSizeChange(value as SectionSpacingSize)
                    }
                    steps={5}
                    maxLabel="More"
                    minLabel="Less"
                />
            </div>
        </SideBarField>
    </SideBarFieldContainer>
);
