import { ApiPaginationRequest, ApiPaginationResponse, AssistantItem } from "@/types";
import { apiRequest } from "./request";

const getAssistantItem = (id: string) => apiRequest<AssistantItem>(`/assistant/${id}`, "GET");

const getAllAssistantItems = ({
    page = 1,
    limit = 50,
    sortBy = "",
    search = "",
}: ApiPaginationRequest) => {
    const requestParams: ApiPaginationRequest = { page, limit, sortBy };
    if (search) {
        requestParams.search = search;
    }
    return apiRequest<ApiPaginationResponse<AssistantItem[]>>(
        `/assistant`,
        "GET",
        requestParams,
    );
};

const getUserAssistantItems = () =>
    apiRequest<ApiPaginationResponse<AssistantItem[]>>(`/assistant/user`, "GET");

const saveAssistantItem = (data: Partial<AssistantItem>, id?: string) =>
    apiRequest<AssistantItem>(
        id ? `/assistant/${id}` : "/assistant",
        id ? "PATCH" : "POST",
        data,
    );

const deleteAssistantItem = (id: string) => apiRequest(`/assistant/${id}`, "DELETE");

export default {
    saveAssistantItem,
    getAssistantItem,
    getAllAssistantItems,
    deleteAssistantItem,
    getUserAssistantItems
};
