import sectionApi from "@/api/section";
import documentTypeApi from "@/api/documentType";
import assistantApi from "@/api/assistant";
import { useEffect, useState, useCallback, useRef } from "react";
import {
    AssistantItem,
    DocumentType,
    NotificationMessageType,
    Section,
} from "@/types";
import { AdminListHeader } from "@/pages/Admin/AdminListHeader";
import {
    AssistantItemSectionContentType,
    AssistantItemStatus,
    AssistantItemTypes,
} from "@/types/assistant";
import { Copy, Trash2 } from "lucide-react";
import { useNavigate } from "react-router-dom";
import { handleAdminError } from "@/pages/Admin/utils/handleAdminError";
import { useAppDispatch } from "@/hooks/types";
import { Checkbox } from "@/components/form/Checkbox";
import { AdminSearchBar } from "@/pages/Admin/components/AdminSearchBar";
import { BulkActions } from "@/pages/Admin/components/AdminBulkActions";
import {
    AdminTable,
    AdminTableHeader,
    AdminTableHeaderCell,
    AdminTableBody,
    AdminTableBodyCell,
    AdminTableBodyRow,
} from "@/pages/Admin/components/Table";
import { Pagination } from "@/pages/Admin/components/AdminPagination";
import { LoadingOverlay } from "@/pages/Admin/components/AdminLoadingOverlay";
import { AdminNoResults } from "@/pages/Admin/components/AdminNoResults";
import { AdminPip } from "@/pages/Admin/components/AdminPip";

type SortColumn = "name" | "type" | "jobRoleType" | "status" | "updatedAt";

const columnNames = [
    { key: "name", label: "Name", width: 240 },
    { key: "jobRoleType", label: "Job Role", width: 200 },
    { key: "type", label: "Type", width: 100 },
    { key: "itemTypeName", label: "Type Name", width: 120 },
    { key: "contentType", label: "Content Type", width: 120 },
    { key: "status", label: "Status", width: 120 },
    { key: "updatedAt", label: "Last Updated", width: 150 },
    { key: "actions", label: "Actions", width: 80 },
];

const itemsPerPage = 50;

export const AssistantItems = () => {
    const [assistantItems, setAssistantItems] = useState<AssistantItem[]>([]);
    const [totalItems, setTotalItems] = useState(0);
    const [currentPage, setCurrentPage] = useState(1);
    const [isLoading, setIsLoading] = useState(false);
    const [sortColumn, setSortColumn] = useState<SortColumn>("updatedAt");
    const [sortDirection, setSortDirection] = useState<"asc" | "desc">("desc");
    const [searchTerm, setSearchTerm] = useState("");
    const [docTypes, setDocTypes] = useState<DocumentType[]>([]);
    const [sectionConfigs, setSectionConfigs] = useState<Section[]>([]);
    const [selectedItems, setSelectedItems] = useState<string[]>([]);
    const navigate = useNavigate();
    const dispatch = useAppDispatch();
    const hasInitialised = useRef(false);

    const fetchAssistantItems = useCallback(
        async (
            page: number,
            sortCol: SortColumn = sortColumn,
            sortDir: "asc" | "desc" = sortDirection,
            search: string = searchTerm,
        ) => {
            setIsLoading(true);
            try {
                const result = await assistantApi.getAllAssistantItems({
                    page: page,
                    limit: itemsPerPage,
                    sortBy: `${sortCol}:${sortDir}`,
                    search: search || undefined,
                });
                setAssistantItems(result.results);
                setTotalItems(result.totalResults);
                setCurrentPage(page);
            } catch (error) {
                handleAdminError(
                    dispatch,
                    error,
                    "Failed to fetch assistant items",
                    NotificationMessageType.ADMIN_ASSISTANT_ITEM_SAVE,
                );
            } finally {
                setIsLoading(false);
            }
        },
        [dispatch, searchTerm, sortColumn, sortDirection],
    );

    const handleSort = useCallback(
        (column: SortColumn) => {
            if (
                ![
                    "id",
                    "name",
                    "type",
                    "jobRoleType",
                    "status",
                    "updatedAt",
                ].includes(column)
            ) {
                return;
            }
            const newSortDirection =
                sortColumn === column && sortDirection === "asc"
                    ? "desc"
                    : "asc";
            setSortColumn(column);
            setSortDirection(newSortDirection);
            setCurrentPage(1);
            fetchAssistantItems(1, column, newSortDirection);
        },
        [sortColumn, sortDirection, fetchAssistantItems],
    );

    useEffect(() => {
        const fetchData = async () => {
            const [sections, documentTypes] = await Promise.all([
                sectionApi.getAllSections(),
                documentTypeApi.getAllDocumentTypes(),
            ]);
            setSectionConfigs(sections.results);
            setDocTypes(documentTypes.results);
        };
        fetchData();
    }, []);

    useEffect(() => {
        if (!hasInitialised.current) {
            hasInitialised.current = true;
            fetchAssistantItems(1);
        }
    }, [fetchAssistantItems]);

    const handleRowClick = (itemId: string) => {
        navigate(`${itemId}`);
    };

    const handleClone = useCallback(
        (e: React.MouseEvent, itemId: string) => {
            e.stopPropagation();
            const itemCopy: AssistantItem = JSON.parse(
                JSON.stringify(assistantItems.find(item => item.id === itemId)),
            );
            itemCopy.name = "CLONED ITEM";
            delete itemCopy.id;
            delete itemCopy.createdAt;
            delete itemCopy.updatedAt;

            try {
                assistantApi
                    .saveAssistantItem(itemCopy as AssistantItem)
                    .then(clonedItem => {
                        navigate(`/admin/assistant/${clonedItem._id}`);
                    });
            } catch (err: unknown) {
                handleAdminError(
                    dispatch,
                    err,
                    "Failed to clone",
                    NotificationMessageType.ADMIN_ASSISTANT_ITEM_SAVE,
                );
            }
        },
        [dispatch, assistantItems, navigate],
    );

    const handleDelete = useCallback(
        (e: React.MouseEvent, itemId: string) => {
            e.stopPropagation();
            try {
                assistantApi.deleteAssistantItem(itemId);
                setAssistantItems(prev =>
                    prev.filter(item => item.id !== itemId),
                );
            } catch (err: unknown) {
                handleAdminError(
                    dispatch,
                    err,
                    "Failed to delete",
                    NotificationMessageType.ADMIN_ASSISTANT_ITEM_DELETE,
                );
            }
        },
        [dispatch],
    );

    const handleSearchChange = useCallback(
        (term: string) => {
            if (searchTerm === term) return;
            setSearchTerm(term);
            setCurrentPage(1);
            fetchAssistantItems(1, sortColumn, sortDirection, term);
        },
        [searchTerm, fetchAssistantItems, sortColumn, sortDirection],
    );

    const handlePageChange = (page: number) => {
        fetchAssistantItems(page);
    };

    const totalPages = Math.ceil(totalItems / itemsPerPage);

    const getItemTypeName = (item: AssistantItem) => {
        if (item.type === AssistantItemTypes.SECTION) {
            return sectionConfigs.find(section => section.id === item.section)
                ?.name;
        }
        return docTypes.find(docType => docType.id === item.documentType)?.name;
    };

    const handleSelectItem = (itemId: string) => {
        setSelectedItems(prev =>
            prev.includes(itemId)
                ? prev.filter(id => id !== itemId)
                : [...prev, itemId],
        );
    };

    const handleSelectAll = () => {
        setSelectedItems(
            selectedItems.length === assistantItems.length
                ? []
                : assistantItems.map(item => item.id),
        );
    };

    const handleBulkDelete = useCallback(async () => {
        if (selectedItems.length === 0) return;
        try {
            await Promise.all(
                selectedItems.map(itemId =>
                    assistantApi.deleteAssistantItem(itemId),
                ),
            );
            setSelectedItems([]);
            fetchAssistantItems(currentPage);
        } catch (error) {
            handleAdminError(
                dispatch,
                error,
                "Failed to delete",
                NotificationMessageType.ADMIN_ASSISTANT_ITEM_DELETE,
            );
        }
    }, [selectedItems, dispatch, fetchAssistantItems, currentPage]);

    const handleBulkStatusUpdate = async (status: AssistantItemStatus) => {
        if (!status) return;

        try {
            await Promise.all(
                selectedItems.map(itemId => {
                    const item = assistantItems.find(
                        item => item.id === itemId,
                    );
                    if (!item) return;
                    const {
                        updatedAt: _updatedAt,
                        createdAt: _createdAt,
                        id,
                        ...rest
                    } = item;
                    return assistantApi.saveAssistantItem(
                        {
                            ...rest,
                            status,
                        },
                        id,
                    );
                }),
            );

            setSelectedItems([]);
            fetchAssistantItems(currentPage);
        } catch (err: unknown) {
            handleAdminError(
                dispatch,
                err,
                "Failed to update status",
                NotificationMessageType.ADMIN_ASSISTANT_ITEM_SAVE,
            );
        }
    };

    return (
        <div className="h-full rounded-lg bg-white px-6 pt-2 ">
            <AdminListHeader
                title="Assistant Items"
                description="Assistant items are the building blocks of your assistant. They are used to provide guidance and advice to users."
                buttonText="Add Assistant Item"
                to="new"
            />
            <div className="py-4">
                <div className="mb-4 flex items-center justify-between">
                    <div className="ml-3 flex items-center space-x-4">
                        <Checkbox
                            id="select-all"
                            label={`${selectedItems.length} selected`}
                            checked={
                                selectedItems.length === assistantItems.length
                            }
                            onChange={handleSelectAll}
                            className="w-36"
                        />
                        <BulkActions
                            selectedCount={selectedItems.length}
                            showStatusUpdate={true}
                            onStatusUpdate={handleBulkStatusUpdate}
                            onDelete={handleBulkDelete}
                        />
                    </div>
                    <div className="flex items-center">
                        <span className="text-sm text-gray-400">
                            {totalItems} items
                        </span>
                    </div>
                    <div className="h-9 w-96">
                        <AdminSearchBar
                            setSearchTerm={handleSearchChange}
                            placeholder="Search by name or job role"
                        />
                    </div>
                </div>
                <div className="relative overflow-x-auto">
                    <LoadingOverlay isLoading={isLoading} />
                    <AdminTable>
                        <AdminTableHeader>
                            <th className="w-3"></th>
                            {columnNames.map(({ key, label, width }) => (
                                <AdminTableHeaderCell
                                    key={key}
                                    label={label}
                                    sortKey={key}
                                    currentSortColumn={sortColumn}
                                    currentSortDirection={sortDirection}
                                    onSort={key =>
                                        handleSort(key as SortColumn)
                                    }
                                    isSortable={
                                        key !== "actions" &&
                                        key !== "itemTypeName" &&
                                        key !== "contentType"
                                    }
                                    width={width}
                                />
                            ))}
                        </AdminTableHeader>
                        <AdminTableBody>
                            {assistantItems.map(item => (
                                <AdminTableBodyRow
                                    key={item.id}
                                    onClick={() => handleRowClick(item.id)}
                                >
                                    <AdminTableBodyCell
                                        onClick={e => {
                                            e.stopPropagation();
                                        }}
                                        className="flex items-center justify-center !pl-2 !pr-0"
                                    >
                                        <Checkbox
                                            checked={selectedItems.includes(
                                                item.id,
                                            )}
                                            onChange={() =>
                                                handleSelectItem(item.id)
                                            }
                                            id={item.id}
                                            label=""
                                            borderless
                                            className="h-5 w-5"
                                        />
                                    </AdminTableBodyCell>
                                    {columnNames.map(({ key, width }) => (
                                        <AdminTableBodyCell
                                            key={key}
                                            width={width}
                                        >
                                            {key === "name" && (
                                                <span className="text-neutral-900">
                                                    {item.name}
                                                </span>
                                            )}
                                            {key === "jobRoleType" && (
                                                <span className="text-neutral-900">
                                                    {item.jobRoleType}
                                                </span>
                                            )}
                                            {key === "contentType" && (
                                                <AdminPip
                                                    colour={
                                                        item.properties
                                                            .contentType ===
                                                        AssistantItemSectionContentType.DEFAULT
                                                            ? "bg-neutral-200"
                                                            : item.properties
                                                                    .contentType ===
                                                                AssistantItemSectionContentType.EXAMPLE
                                                              ? "bg-error-100"
                                                              : ""
                                                    }
                                                    blank={
                                                        !item.properties
                                                            .contentType
                                                    }
                                                >
                                                    {item.properties
                                                        .contentType ?? "-"}
                                                </AdminPip>
                                            )}
                                            {key === "type" && (
                                                <AdminPip
                                                    colour={
                                                        item.type ===
                                                        AssistantItemTypes.SECTION
                                                            ? "bg-alert-300"
                                                            : "bg-primary-100"
                                                    }
                                                >
                                                    {item.type}
                                                </AdminPip>
                                            )}
                                            {key === "itemTypeName" &&
                                                getItemTypeName(item)}
                                            {key === "status" && (
                                                <AdminPip
                                                    colour={
                                                        item.status ===
                                                        AssistantItemStatus.DRAFT
                                                            ? "bg-neutral-300"
                                                            : "bg-success-300"
                                                    }
                                                >
                                                    {item.status}
                                                </AdminPip>
                                            )}
                                            {key === "updatedAt" &&
                                                new Date(
                                                    item.updatedAt,
                                                ).toLocaleString("en-GB", {
                                                    day: "2-digit",
                                                    month: "2-digit",
                                                    year: "numeric",
                                                    hour: "2-digit",
                                                    minute: "2-digit",
                                                })}
                                            {key === "actions" && (
                                                <div className="flex gap-4">
                                                    <button
                                                        onClick={e =>
                                                            handleClone(
                                                                e,
                                                                item.id,
                                                            )
                                                        }
                                                        className="text-primary-600 hover:text-primary-900"
                                                    >
                                                        <Copy className="h-5 w-5" />
                                                    </button>
                                                    <button
                                                        onClick={e =>
                                                            handleDelete(
                                                                e,
                                                                item.id,
                                                            )
                                                        }
                                                        className="text-error-600 hover:text-error-900"
                                                    >
                                                        <Trash2 className="h-5 w-5" />
                                                    </button>
                                                </div>
                                            )}
                                        </AdminTableBodyCell>
                                    ))}
                                </AdminTableBodyRow>
                            ))}
                        </AdminTableBody>
                    </AdminTable>
                    {assistantItems.length === 0 && !isLoading && (
                        <AdminNoResults />
                    )}
                </div>
                <div className="mt-4 flex justify-end">
                    <Pagination
                        currentPage={currentPage}
                        totalPages={totalPages}
                        onPageChange={handlePageChange}
                    />
                </div>
            </div>
        </div>
    );
};
