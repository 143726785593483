import { Modal } from "@/components/Modal/Modal";
import { ResumeName } from "@/components/ResumeName/ResumeName";
import { useAppDispatch, useAppSelector } from "@/hooks/types";
import { getIsModalOpen, getModalProps } from "@/store/modal/selectors";
import { closeModal } from "@/store/modal/slice";
import { getResumeById } from "@/store/resume/selectors";
import { ModalTypes } from "@/types/modal";
import { useCallback } from "react";

const RenameResumeModal = () => {
    const isOpen = useAppSelector(getIsModalOpen(ModalTypes.RENAME_RESUME));
    const modalProps = useAppSelector(getModalProps(ModalTypes.RENAME_RESUME));
    const dispatch = useAppDispatch();
    const resume = useAppSelector(state =>
        getResumeById(state, modalProps?.resumeId),
    );

    const onClose = useCallback(() => {
        dispatch(closeModal(ModalTypes.RENAME_RESUME));
    }, [dispatch]);

    return !resume ? null : (
        <Modal
            modalType={ModalTypes.RENAME_RESUME}
            open={isOpen}
            title="Rename Resume"
            width="lg"
        >
            <div className="mt-6">
                <ResumeName
                    resumeName={resume.name}
                    resumeId={resume.id}
                    showSaveButton
                    onSave={onClose}
                />
            </div>
        </Modal>
    );
};

// Modals have to be exported as Default for lazy loading
export default RenameResumeModal;
