import { ReactNode } from "react";

export type { Section } from "./section";
export type {
    User,
    ChangePasswordRequest,
    RegisterRequest,
    LoginAndRegisterResponse,
    LoginRequest,
    UserRole,
    ChangeEmailRequest
} from "./user";
export type { Resume, ResumeSections, SectionDetails } from "./resume";
export type { Token } from "./token";
export type { AssistantSectionItem, AssistantDocumentItem, AssistantItem } from "./assistant";

export {
    type NotificationDetails,
    NotificationMessageType,
    NotificationType,
} from "./app";

export interface UsingReactChildren {
    children: ReactNode;
}

export type GenericObject = object;

export type { Page, Measurement } from "./pages";

export type { Template } from "./template";

export type { DocumentType } from "./documentType";

export enum ErrorTypes {
    notFound = "notFound",
    unauthorized = "unauthorized",
    forbidden = "forbidden",
    internalServerError = "internalServerError",
}

export type { PreferenceOption, PreferenceOptions } from "./wizard";

export interface ApiPaginationResponse<T> {
    results: T;
    page: number;
    limit: number;
    totalPages: number;
    totalResults: number;
}

export interface ApiPaginationRequest {
    page?: number;
    limit?: number;
    sortBy?: string;
    search?: string;
}
