import { StripeProduct } from "@/types/billing";
import { PayloadAction, createSlice } from "@reduxjs/toolkit";

interface BillingState {
    products: StripeProduct[] | null;
    isLoading: boolean;
    error: string | null;
}

const initialState: BillingState = {
    products: null,
    isLoading: false,
    error: null,
};

const billingSlice = createSlice({
    name: "billing",
    initialState,
    reducers: {
        setProducts: (state, { payload }: PayloadAction<StripeProduct[]>) => {
            state.products = payload;
            state.isLoading = false;
            state.error = null;
        },
        setProductsLoading: (state) => {
            state.isLoading = true;
            state.error = null;
        },
        setProductsError: (state, { payload }: PayloadAction<string>) => {
            state.isLoading = false;
            state.error = payload;
        },
    },
});

export const { setProducts, setProductsLoading, setProductsError } = billingSlice.actions;
export const { reducer: billingReducer } = billingSlice;
