import { appTextClassMap } from "@/components/Text/constants";
import clsx from "clsx";
import React, { createElement } from "react";

interface AppTextProps {
    children: React.ReactNode;
    variant:
        | "headings"
        | "subheadings"
        | "contextheading"
        | "labelsbuttons"
        | "regular"
        | "footnote";
    className?: string;
}

export const AppText = ({ children, variant, className }: AppTextProps) => {
    const { element, elementClassName } = appTextClassMap[variant];
    return createElement(
        element,
        { className: clsx(elementClassName, className) },
        children,
    );
};
