import {
    MIN_YEAR,
    monthOptions,
} from "@/components/DatePeriodPicker/dateFormatMap";
import { Dropdown } from "@/components/Dropdown/Dropdown";
import { useMemo } from "react";

interface DateDropdownsProps {
    month: string;
    year: string;
    onMonthChange: (value: string) => void;
    onYearChange: (value: string) => void;
    disabled: boolean;
}

/**
 * value has the following suitable formats / values
 * "MM/YYYY" - month and year
 * "YYYY" - only year
 */
export const DateDropdowns = ({
    month,
    year,
    onMonthChange,
    onYearChange,
    disabled,
}: DateDropdownsProps) => {
    const maxYear = new Date().getFullYear() + 5;
    const yearOptions = useMemo(() => {
        const allYears = Array.from(
            { length: maxYear - MIN_YEAR + 1 },
            (_, i) => maxYear - i,
        ).map(year => ({ id: year.toString(), title: year.toString() }));

        return [{ id: "0000", title: "-" }, ...allYears];
    }, [maxYear]);
    const yearValue = year
        ? yearOptions.find(ye => ye.id === year)?.title
        : "-";
    const monthValue = month
        ? monthOptions.find(mo => mo.id === month)?.title
        : "-";

    return (
        <div className="flex justify-between">
            <Dropdown
                items={monthOptions}
                activeId={month || "00"}
                title={monthValue}
                handleChange={id => {
                    onMonthChange(id);
                }}
                disabled={disabled}
            />
            <Dropdown
                items={yearOptions}
                activeId={year}
                title={yearValue}
                handleChange={id => {
                    onYearChange(id);
                }}
                disabled={disabled}
            />
        </div>
    );
};
