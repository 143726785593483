import { Svg, Path, Circle, Rect } from "@react-pdf/renderer";

export const PhonePdfIcon = ({ size }: { size: number }) => (
    <Svg
        style={{ marginRight: 4, marginTop: 2 }}
        height={size}
        width={size}
        viewBox="0 0 24 24"
        stroke="#000"
        strokeWidth="2"
        strokeLineCap="round"
        strokeLinejoin="round"
    >
        <Path d="M22 16.92v3a2 2 0 0 1-2.18 2 19.79 19.79 0 0 1-8.63-3.07 19.5 19.5 0 0 1-6-6 19.79 19.79 0 0 1-3.07-8.67A2 2 0 0 1 4.11 2h3a2 2 0 0 1 2 1.72 12.84 12.84 0 0 0 .7 2.81 2 2 0 0 1-.45 2.11L8.09 9.91a16 16 0 0 0 6 6l1.27-1.27a2 2 0 0 1 2.11-.45 12.84 12.84 0 0 0 2.81.7A2 2 0 0 1 22 16.92z" />
    </Svg>
);

export const AtSignPdfIcon = ({ size }: { size: number }) => (
    <Svg
        style={{ marginRight: 4, marginTop: 2 }}
        height={size}
        width={size}
        viewBox="0 0 24 24"
        stroke="#000"
        strokeWidth="2"
        strokeLineCap="round"
        strokeLinejoin="round"
    >
        <Path
            stroke="#000"
            d="M16 8v5a3 3 0 0 0 6 0v-1a10 10 0 1 0-4 8"
        />
        <Circle
            cx="12"
            cy="12"
            r="4"
            stroke="#000"
        />
    </Svg>
);

export const MapPinPdfIcon = ({ size }: { size: number }) => (
    <Svg
        style={{ marginRight: 4, marginTop: 2 }}
        height={size}
        width={size}
        viewBox="0 0 24 24"
        stroke="#000"
        strokeWidth="2"
        strokeLineCap="round"
        strokeLinejoin="round"
    >
        <Path d="M20 10c0 6-8 12-8 12s-8-6-8-12a8 8 0 0 1 16 0Z" />
        <Circle
            cx="12"
            cy="10"
            r="3"
        />
    </Svg>
);

export const CalendarPdfIcon = ({ size }: { size: number }) => (
    <Svg
        style={{ marginRight: 4, marginTop: 2 }}
        height={size}
        width={size}
        viewBox="0 0 24 24"
        stroke="#000"
        strokeWidth="2"
        strokeLineCap="round"
        strokeLinejoin="round"
    >
        <Rect
            width="18"
            height="18"
            x="3"
            y="4"
            rx="2"
        />
        <Path d="M8 2v4" />
        <Path d="M16 2v4" />
        <Path d="M3 10h18" />
    </Svg>
);

export const LinkPdfIcon = ({ size }: { size: number }) => (
    <Svg
        style={{ marginRight: 4, marginTop: 2 }}
        height={size}
        width={size}
        viewBox="0 0 24 24"
        stroke="#000"
        strokeWidth="2"
        strokeLineCap="round"
        strokeLinejoin="round"
    >
        <Path d="M14 11a5 5 0 0 0-7.54-.54l-3 3a5 5 0 0 0 7.07 7.07l1.71-1.71" />
        <Path d="M10 13a5 5 0 0 0 7.54.54l3-3a5 5 0 0 0-7.07-7.07l-1.72 1.71" />
    </Svg>
);

export const TagPdfIcon = ({ size }: { size: number }) => (
    <Svg
        style={{ marginRight: 4, marginTop: 2 }}
        height={size}
        width={size}
        viewBox="0 0 24 24"
        stroke="#000"
        strokeWidth="2"
        strokeLineCap="round"
        strokeLinejoin="round"
    >
        <Path d="M12.586 2.586A2 2 0 0 0 11.172 2H4a2 2 0 0 0-2 2v7.172a2 2 0 0 0 .586 1.414l8.704 8.704a2.426 2.426 0 0 0 3.42 0l6.58-6.58a2.426 2.426 0 0 0 0-3.42z" />
        <Circle
            cx="7.5"
            cy="7.5"
            r=".5"
            stroke="#000"
            fill="#000"
        />
    </Svg>
);
