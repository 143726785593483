import { useAppSelector } from "@/hooks/types";
import { MemoEditableMapper } from "@/components/EditorComponents/EditableMapper";
import { decodeHtml } from "@/pages/ResumeBuilder/utils";
import { getActiveResume } from "@/store/resume/selectors";
import { getVisibleProps } from "@/utils/section";
import { useMemo } from "react";
import { getSectionConfigById } from "@/store/sections/selectors";
import { SectionFieldsConfig } from "@/types/section";
import { ComponentPropsObject, ResumeStyles } from "@/types/resume";

interface EditableSectionHeaderProps {
    sectionId: string;
    fieldsConfig?: SectionFieldsConfig;
    isStaticHeader?: boolean;
    layoutProps?: ComponentPropsObject;
    sectionConfigId: string;
    stylesConfig: ResumeStyles;
}

export const EditableSectionHeader = ({
    sectionId,
    fieldsConfig,
    isStaticHeader,
    layoutProps,
    sectionConfigId,
    stylesConfig,
}: EditableSectionHeaderProps) => {
    const activeResume = useAppSelector(getActiveResume);
    const sectionConfig = useAppSelector(state =>
        getSectionConfigById(state, sectionConfigId),
    );
    const titleComp = useMemo(
        () => decodeHtml(sectionConfig?.titleComp),
        [sectionConfig?.titleComp],
    );
    const sectionDetails = useMemo(
        () => activeResume?.sections?.[sectionId],
        [activeResume?.sections, sectionId],
    );
    const visibleProps = useMemo(
        () =>
            getVisibleProps(
                sectionDetails?.header ?? {},
                sectionDetails?.hiddenFields ?? [],
            ),
        [sectionDetails?.header, sectionDetails?.hiddenFields],
    );

    return (
        <MemoEditableMapper
            html={titleComp}
            sectionId={sectionId}
            visibleProps={visibleProps}
            propType="header"
            fieldsConfig={fieldsConfig}
            isStaticHeader={isStaticHeader}
            layoutProps={layoutProps}
            stylesConfig={stylesConfig}
        />
    );
};
