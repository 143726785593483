import classnames from "classnames";
import templateApi from "@/api/template";
import { useEffect, useState } from "react";
import { NavLink } from "../../../components/NavLink";
import { Template, DocumentType } from "@/types";
import documentTypeApi from "@/api/documentType";

export const Templates = () => {
    const [templates, setTemplates] = useState<Template[]>([]);
    const [docTypes, setDocTypes] = useState<DocumentType[]>([]);

    useEffect(() => {
        const getAllTemplates = async () => {
            const response = await templateApi.getAllTemplates();
            setTemplates(response.results);
        };
        const getAllDocumentTypes = async () => {
            const response = await documentTypeApi.getAllDocumentTypes();
            setDocTypes(response.results);
        };
        getAllTemplates();
        getAllDocumentTypes();
    }, []);

    return (
        <div className="px-4 sm:px-6 lg:px-8">
            <div className="sm:flex sm:items-center">
                <div className="sm:flex-auto">
                    <h1 className="text-base font-semibold leading-6 text-gray-900">
                        Templates
                    </h1>
                    <p className="mt-2 text-sm text-gray-700">
                        Templates available to build documents from.
                    </p>
                </div>
                <div className="mt-4 sm:ml-16 sm:mt-0 sm:flex-none">
                    <NavLink
                        href="new"
                        className="block rounded-md bg-indigo-600 px-3 py-2 text-center text-white shadow-sm hover:bg-indigo-500 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-indigo-600"
                    >
                        Add template
                    </NavLink>
                </div>
            </div>
            <div className="mt-8 flow-root">
                <div className="-mx-4 -my-2 sm:-mx-6 lg:-mx-8">
                    <div className="inline-block  py-2 align-middle">
                        <table className=" border-separate border-spacing-0">
                            <thead>
                                <tr>
                                    <th
                                        scope="col"
                                        className="sticky top-0 z-10 border-b border-gray-300 bg-white bg-opacity-75 py-3.5 pl-4 pr-3 text-left text-sm font-semibold text-gray-900 backdrop-blur backdrop-filter sm:pl-6 lg:pl-8"
                                    >
                                        id
                                    </th>
                                    <th
                                        scope="col"
                                        className="sticky top-0 z-10 border-b border-gray-300 bg-white bg-opacity-75 py-3.5 pl-4 pr-3 text-left text-sm font-semibold text-gray-900 backdrop-blur backdrop-filter sm:pl-6 lg:pl-8"
                                    >
                                        Name
                                    </th>
                                    <th
                                        scope="col"
                                        className="sticky top-0 z-10 border-b border-gray-300 bg-white bg-opacity-75 py-3.5 pl-4 pr-3 text-left text-sm font-semibold text-gray-900 backdrop-blur backdrop-filter sm:pl-6 lg:pl-8"
                                    >
                                        documentType
                                    </th>
                                </tr>
                            </thead>
                            <tbody>
                                {templates &&
                                    templates.map((template, index) => (
                                        <tr key={template.name}>
                                            <td
                                                className={classnames(
                                                    index !==
                                                        templates.length - 1
                                                        ? "border-b border-gray-200"
                                                        : "",
                                                    "whitespace-nowrap py-4 pl-4 pr-3 text-sm font-medium text-gray-900 sm:pl-6",
                                                )}
                                            >
                                                <NavLink
                                                    href={`${template.id}`}
                                                    className="text-primary-600 hover:text-primary-900"
                                                >
                                                    {template.id}
                                                </NavLink>
                                            </td>
                                            <td
                                                className={classnames(
                                                    index !==
                                                        templates.length - 1
                                                        ? "border-b border-gray-200"
                                                        : "",
                                                    "whitespace-nowrap py-4 pl-4 pr-3 text-sm font-medium text-gray-900 sm:pl-6",
                                                )}
                                            >
                                                <NavLink
                                                    href={`${template.id}`}
                                                    className="text-primary-600 hover:text-primary-900"
                                                >
                                                    {template.name}
                                                </NavLink>
                                            </td>
                                            <td
                                                className={classnames(
                                                    index !==
                                                        templates.length - 1
                                                        ? "border-b border-gray-200"
                                                        : "",
                                                    "whitespace-nowrap py-4 pl-4 pr-3 text-sm font-medium text-gray-900 sm:pl-6 lg:pl-8",
                                                )}
                                            >
                                                {docTypes
                                                    ? docTypes.find(
                                                          docType =>
                                                              docType.id ===
                                                              template.documentType,
                                                      )?.name
                                                    : template.documentType}{" "}
                                                ({template.documentType})
                                            </td>
                                        </tr>
                                    ))}
                            </tbody>
                        </table>
                    </div>
                </div>
            </div>
        </div>
    );
};
