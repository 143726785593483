import {
    Button,
    ButtonColourStyles,
    ButtonVariant,
} from "@/components/Button/Button";
import { IconButton } from "@/components/Button/IconButton";
import { Modal } from "@/components/Modal/Modal";
import { Input } from "@/components/form/Input";
import { InputLabel } from "@/components/form/InputLabel";
import { useAppDispatch } from "@/hooks/types";
import { closeModal, openModal } from "@/store/modal/slice";
import { ModalTypes } from "@/types/modal";
import { LayoutFieldOption } from "@/types/section";
import { Trash2 } from "lucide-react";
import { useEffect, useState } from "react";

interface LayoutFieldOptionsModalProps {
    isOpen: boolean;
    onClose: () => void;
    onSave: (options: LayoutFieldOption[]) => void;
    currentOptions: LayoutFieldOption[];
    activeFieldIndex: number | null;
}

export const LayoutFieldOptionsModal = ({
    isOpen,
    onClose,
    onSave,
    currentOptions,
    activeFieldIndex,
}: LayoutFieldOptionsModalProps) => {
    const [options, setOptions] = useState<null | LayoutFieldOption[]>(null);
    const dispatch = useAppDispatch();

    useEffect(() => {
        if (isOpen) {
            dispatch(
                openModal({ modalType: ModalTypes.ADMIN_LAYOUT_FIELD_OPTIONS }),
            );
        }
    }, [isOpen, dispatch]);

    const handleClose = () => {
        dispatch(closeModal(ModalTypes.ADMIN_LAYOUT_FIELD_OPTIONS));
        onClose();
    };

    const onAddOption = () => {
        setOptions(currOptions => {
            if (currOptions === null) return [{} as LayoutFieldOption];
            return [...currOptions, {} as LayoutFieldOption];
        });
    };

    const onRemoveOption = (index: number) => {
        if (options === null) return;
        setOptions(currOptions => {
            if (currOptions === null) return null;
            const nextOptions = currOptions.filter((_, i) => i !== index);
            if (nextOptions.length === 0) return null;
            return nextOptions;
        });
    };

    const onSaveOptions = () => {
        onSave(options);
        setOptions(null);
        handleClose();
    };

    const onInputChange = (index: number, key: string, value: string) => {
        setOptions(prevOptions => {
            const newOptions = prevOptions === null ? [] : [...prevOptions];
            newOptions[index] = {
                ...newOptions[index],
                [key]: value,
            };
            return newOptions;
        });
    };

    useEffect(() => {
        if (activeFieldIndex === null) {
            setOptions(null);
        } else {
            setOptions(currentOptions);
        }
    }, [currentOptions, activeFieldIndex]);

    return (
        <Modal
            modalType={ModalTypes.ADMIN_LAYOUT_FIELD_OPTIONS}
            open={isOpen}
            onClose={handleClose}
            title="Layout Field Options"
        >
            <div className="flex flex-col">
                {options?.map((option, index) => (
                    <div
                        key={`option-${index}`}
                        className="flex items-center gap-4"
                    >
                        <InputLabel
                            label="Name"
                            htmlFor={`options.${index}.name`}
                            required
                            description="Option name"
                        />
                        <Input
                            type="text"
                            name={`options.${index}.name`}
                            id={`options.${index}.name`}
                            value={option.name}
                            onChange={(_, value) =>
                                onInputChange(index, "name", value)
                            }
                        />
                        <InputLabel
                            label="Value"
                            htmlFor={`options.${index}.value`}
                            required
                            description="Option value"
                        />
                        <Input
                            type="text"
                            name={`options.${index}.value`}
                            id={`options.${index}.value`}
                            value={option.value}
                            onChange={(_, value) =>
                                onInputChange(index, "value", value)
                            }
                        />
                        <IconButton
                            color={ButtonColourStyles.SOLID_RED}
                            onClick={() => onRemoveOption(index)}
                        >
                            <Trash2 size={16} />
                        </IconButton>
                    </div>
                ))}
            </div>
            <div className="mt-4 flex justify-end gap-3">
                <Button
                    variant={ButtonVariant.OUTLINE}
                    color={ButtonColourStyles.OUTLINE_GREY}
                    onClick={onAddOption}
                >
                    Add Option
                </Button>
                <Button onClick={onSaveOptions}>Save</Button>
            </div>
        </Modal>
    );
};
