import {
    Button,
    ButtonColourStyles,
    ButtonVariant,
} from "@/components/Button/Button";
import { HotkeysTable } from "@/components/Modals/HelpGuideModal/HotkeysTable";
import { Modal } from "@/components/Modal/Modal";
import { ModalHeader } from "@/components/Modal/ModalHeader";
import { AppText } from "@/components/Text/AppText";
import {
    Bold,
    Calendar,
    Camera,
    ChevronDown,
    ChevronUp,
    Bot,
    Edit3,
    Eye,
    FilePlus2,
    HelpCircleIcon,
    Layout,
    LayoutList,
    MoveIcon,
    Paintbrush,
    Plus,
    PlusSquare,
    Settings2,
    SquareMenuIcon,
    Trash2,
    Book,
} from "lucide-react";
import { useCallback, useState } from "react";
import { ModalTypes } from "@/types/modal";
import { useAppDispatch, useAppSelector } from "@/hooks/types";
import { getIsModalOpen } from "@/store/modal/selectors";
import { closeModal, openModal } from "@/store/modal/slice";
import MenuGuideTable from "@/components/Modals/HelpGuideModal/MenuGuideTable";
import { translate } from "@/helper/translate";
import { getUserDetails } from "@/store/user/selectors";
import { sendFeedbackEmail } from "@/email/send";

const navigationItems = [
    {
        id: "creating-resume",
        label: "Creating a New Resume",
        icon: <FilePlus2 size={20} />,
    },
    {
        id: "editing-resume",
        label: "Editing your Resume",
        icon: <Edit3 size={20} />,
    },
    {
        id: "assistant",
        label: "Using the Assistant",
        icon: <Bot size={20} />,
    },
    {
        id: "styling",
        label: "Styling your Resume",
        icon: <Paintbrush size={20} />,
    },
    { id: "layout", label: "Rearranging Sections", icon: <Layout size={20} /> },
    { id: "preview", label: "Previewing your Resume", icon: <Eye size={20} /> },
    {
        id: "add-remove-section",
        label: "Add/Remove Sections",
        icon: <PlusSquare size={20} />,
    },
    {
        id: "context-menus",
        label: "Context Menus",
        icon: <SquareMenuIcon size={20} />,
    },
    { id: "hotkeys", label: "Hotkeys", icon: <Bold size={20} /> },
    { id: "contact", label: "Contact Us", icon: <HelpCircleIcon size={20} /> },
];

const profileSectionContextMenuItems = [
    {
        icon: <Camera size={16} />,
        name: "Add/Edit Photo",
        description: "Add or edit a photo to the profile section.",
    },
    {
        icon: <Settings2 size={16} />,
        name: "Section Settings",
        description:
            "Open the section settings menu in the sidebar for the profile section.",
    },
    {
        icon: <LayoutList size={16} />,
        name: "Section Presets",
        description:
            "Open the section presets menu to adjust the layout of the profile section.",
    },
];

const otherSectionContextMenuItems = [
    {
        icon: <Plus size={16} />,
        name: "New Item",
        description: "Add a new item to the section.",
    },
    {
        icon: <ChevronUp size={16} />,
        name: "Move Item Up",
        description: "Move the selected item up in the section.",
    },
    {
        icon: <ChevronDown size={16} />,
        name: "Move Item Down",
        description: "Move the selected item down in the section.",
    },
    {
        icon: <Settings2 size={16} />,
        name: "Section Settings",
        description:
            "Open the section settings menu in the sidebar for the selected section.",
    },
    {
        icon: <Bot size={16} />,
        name: "Section Assistant",
        description:
            "Open the section assistant menu in the sidebar for the selected section.",
    },
    {
        icon: <Calendar size={16} />,
        name: "Date Picker",
        description:
            "If the section you are editing contains a date, you can click on the calendar icon or the date field to open the date picker.",
    },
    {
        icon: <LayoutList size={16} />,
        name: "Section Presets",
        description:
            "Open the section presets menu to adjust how dates and locations are displayed. This will apply to all sections in the resume.",
    },
    {
        icon: <Trash2 size={16} />,
        name: "Delete Section",
        description: "Delete the selected section or item.",
    },
];

const HelpContentContainer = ({ children }: { children: React.ReactNode }) => (
    <div className="h-[500px] pt-4">{children}</div>
);

const HelpGuideModal = () => {
    const isOpen = useAppSelector(getIsModalOpen(ModalTypes.HELP_GUIDE));
    const [activeNavItem, setActiveNavItem] = useState(navigationItems[0].id);
    const dispatch = useAppDispatch();
    const [feedback, setFeedback] = useState("");
    const [isSubmitting, setIsSubmitting] = useState(false);
    const [submitStatus, setSubmitStatus] = useState<{
        type: "success" | "error";
        message: string;
    } | null>(null);
    const user = useAppSelector(getUserDetails);

    const handleSubmitFeedback = async (e: React.FormEvent) => {
        e.preventDefault();
        setIsSubmitting(true);
        setSubmitStatus(null);

        try {
            await sendFeedbackEmail(feedback, window.location.href, user);
            setFeedback("");
            setSubmitStatus({
                type: "success",
                message: "Thank you for your feedback!",
            });
        } catch (error) {
            setSubmitStatus({
                type: "error",
                message: "Failed to send feedback. Please try again.",
            });
        } finally {
            setIsSubmitting(false);
        }
    };

    const onClose = useCallback(() => {
        dispatch(closeModal(ModalTypes.HELP_GUIDE));
    }, [dispatch]);

    const startTour = useCallback(() => {
        onClose();
        dispatch(openModal({ modalType: ModalTypes.TOUR }));
    }, [dispatch, onClose]);

    return (
        <Modal
            title=""
            open={isOpen}
            width="4xl"
            noHeader
            modalType={ModalTypes.HELP_GUIDE}
        >
            <div className="fixed bottom-0 top-0 flex w-64 flex-col overflow-y-scroll py-6">
                <div className="flex grow flex-col gap-y-5 overflow-y-auto border-r border-gray-200 bg-white">
                    <nav className="flex h-full flex-1 flex-col">
                        <AppText
                            variant="subheadings"
                            className="mb-3"
                        >
                            Help Guide
                        </AppText>
                        <ul
                            role="list"
                            className="flex flex-1 flex-col justify-between gap-y-3 overflow-scroll"
                        >
                            <li>
                                <ul
                                    role="list"
                                    className="space-y-2 pr-6"
                                >
                                    {navigationItems.map(item => (
                                        <li key={item.label}>
                                            <Button
                                                leftIcon={item.icon}
                                                color={
                                                    activeNavItem === item.id
                                                        ? ButtonColourStyles.SOLID_SOFT_INDIGO
                                                        : ButtonColourStyles.SOLID_WHITE
                                                }
                                                onClick={() =>
                                                    setActiveNavItem(item.id)
                                                }
                                                className="!h-8 w-full !justify-start !px-2 text-left leading-4"
                                            >
                                                {item.label}
                                            </Button>
                                        </li>
                                    ))}
                                </ul>
                            </li>
                            <li className="pr-6">
                                <Button
                                    variant={ButtonVariant.OUTLINE}
                                    leftIcon={<Book size={20} />}
                                    color={ButtonColourStyles.OUTLINE_PRIMARY}
                                    onClick={startTour}
                                    className="!h-8 w-full !px-2 text-left leading-4"
                                >
                                    Builder Tutorial
                                </Button>
                            </li>
                        </ul>
                    </nav>
                </div>
            </div>
            <main className="max-h-[700px] pl-64">
                <div className="pb-6 pl-6">
                    {activeNavItem === "creating-resume" && (
                        <div>
                            <ModalHeader
                                title="Creating a New Resume"
                                onClose={onClose}
                            />
                            <HelpContentContainer>
                                <AppText
                                    variant="regular"
                                    className="mb-2"
                                >
                                    When you create a new resume, you will be
                                    prompted to modify the document to suit your
                                    preferences before you enter the Resume
                                    Builder.
                                </AppText>
                                <AppText
                                    variant="regular"
                                    className="mb-2"
                                >
                                    <strong>Modifying Preferences:</strong> You
                                    can always modify your preferences after
                                    entering the Resume Builder.
                                </AppText>
                                <AppText variant="regular">
                                    <strong>Default Resume:</strong> You will
                                    receive a default resume to edit once you
                                    have entered the Resume Builder.
                                </AppText>
                            </HelpContentContainer>
                        </div>
                    )}
                    {activeNavItem === "editing-resume" && (
                        <div>
                            <ModalHeader
                                title="Editing your Resume"
                                onClose={onClose}
                            />
                            <HelpContentContainer>
                                <AppText
                                    variant="regular"
                                    className="mb-2"
                                >
                                    Once you have entered into the Resume
                                    Builder, simply click on the text you wish
                                    to modify and begin typing to make changes.
                                </AppText>
                                <AppText
                                    variant="regular"
                                    className="mb-2"
                                >
                                    You can also format the style of the text by
                                    highlighting the text you wish to format and
                                    selecting one of the icons (bold, italics,
                                    underline, or hyperlink) in the toolbar at
                                    the top of the screen.
                                </AppText>
                                <AppText
                                    variant="regular"
                                    className="mb-2"
                                >
                                    A full list of hotkeys can be found{" "}
                                    <span
                                        className="cursor-pointer"
                                        onClick={() =>
                                            setActiveNavItem("hotkeys")
                                        }
                                    >
                                        <strong>here</strong>
                                    </span>
                                    .
                                </AppText>
                            </HelpContentContainer>
                        </div>
                    )}
                    {activeNavItem === "assistant" && (
                        <div>
                            <ModalHeader
                                title="Using the Assistant"
                                onClose={onClose}
                            />
                            <HelpContentContainer>
                                <AppText
                                    variant="regular"
                                    className="mb-2"
                                >
                                    The Assistant tab provides key insights on
                                    how to complete your resume. Select the
                                    Assistant icon (
                                    <Bot
                                        size={16}
                                        className="mb-1 inline-block"
                                    />
                                    ) in the sidebar and toggle between ‘Resume’
                                    and ‘Section’.
                                </AppText>
                                <AppText
                                    variant="regular"
                                    className="mb-2"
                                >
                                    The Resume tab will automatically be
                                    selected. You can scroll down and use the
                                    accordions to expand each sub-heading.
                                </AppText>
                                <AppText
                                    variant="regular"
                                    className="mb-2"
                                >
                                    To use the Section tab, select a section to
                                    view further information. You will need to
                                    select a Section in order for the insights
                                    to appear.
                                </AppText>
                            </HelpContentContainer>
                        </div>
                    )}
                    {activeNavItem === "styling" && (
                        <>
                            <ModalHeader
                                title="Styling your Resume"
                                onClose={onClose}
                            />
                            <HelpContentContainer>
                                <AppText
                                    variant="regular"
                                    className="mb-2"
                                >
                                    1Template makes it easy for you to style
                                    your resume. To do so, select the paintbrush
                                    icon (insert paintbrush icon here) in the
                                    sidebar and choose from the following:
                                </AppText>
                                <ul>
                                    <li>
                                        <AppText
                                            variant="regular"
                                            className="mb-2"
                                        >
                                            <strong>Layout:</strong> Change from
                                            full page to split page layout
                                        </AppText>
                                    </li>
                                    <li>
                                        <AppText
                                            variant="regular"
                                            className="mb-2"
                                        >
                                            <strong>Font:</strong> Change the
                                            font type for the resume
                                        </AppText>
                                    </li>
                                    <li>
                                        <AppText
                                            variant="regular"
                                            className="mb-2"
                                        >
                                            <strong>Font Size:</strong> Change
                                            the document level font sizing
                                        </AppText>
                                    </li>
                                    <li>
                                        <AppText
                                            variant="regular"
                                            className="mb-2"
                                        >
                                            <strong>
                                                Accent{" "}
                                                {translate("colour", "title")}:
                                            </strong>{" "}
                                            Background{" "}
                                            {translate("colour", "title")}{" "}
                                            within the header section
                                        </AppText>
                                    </li>
                                    <li>
                                        <AppText
                                            variant="regular"
                                            className="mb-2"
                                        >
                                            <strong>Show Icons:</strong> Toggle
                                            whether icons will be displayed
                                        </AppText>
                                    </li>
                                    <li>
                                        <AppText
                                            variant="regular"
                                            className="mb-2"
                                        >
                                            <strong>Underline Heading:</strong>{" "}
                                            Toggle whether to display an
                                            underline on section headings
                                        </AppText>
                                    </li>
                                </ul>
                            </HelpContentContainer>
                        </>
                    )}
                    {activeNavItem === "layout" && (
                        <div>
                            <ModalHeader
                                title="Changing the layout"
                                onClose={onClose}
                            />
                            <HelpContentContainer>
                                <AppText
                                    variant="regular"
                                    className="mb-2"
                                >
                                    To rearrange the layout positioning of the
                                    sections you have included in your resume,
                                    click on the Rearrange button (
                                    <MoveIcon
                                        size={16}
                                        className="mb-1 inline-block"
                                    />
                                    ) at the top of the screen.
                                </AppText>
                                <AppText
                                    variant="regular"
                                    className="mb-2"
                                >
                                    The rearrange modal will appear. You will
                                    then be able to to drag and drop each
                                    section (excluding the Header section) to
                                    suit your desired layout structure. Use the
                                    page layout toggles to set your desired
                                    layout.
                                </AppText>
                            </HelpContentContainer>
                        </div>
                    )}
                    {activeNavItem === "preview" && (
                        <div>
                            <ModalHeader
                                title="Previewing your Resume"
                                onClose={onClose}
                            />
                            <HelpContentContainer>
                                <AppText
                                    variant="regular"
                                    className="mb-2"
                                >
                                    To preview your resume, click on the eye
                                    icon (
                                    <Eye
                                        size={16}
                                        className="mb-1 inline"
                                    />
                                    ) at the top of the screen.
                                </AppText>
                                <AppText
                                    variant="regular"
                                    className="mb-2"
                                >
                                    The preview modal will appear. You will be
                                    able to scroll through each page of your
                                    resume. Use the zoom buttons to zoom in and
                                    out of the resume.
                                </AppText>
                            </HelpContentContainer>
                        </div>
                    )}
                    {activeNavItem === "add-remove-section" && (
                        <div>
                            <ModalHeader
                                title="Add/Remove Sections"
                                onClose={onClose}
                            />
                            <HelpContentContainer>
                                <AppText
                                    variant="regular"
                                    className="mb-2"
                                >
                                    To add a new section, click on the Add
                                    Section Icon (
                                    <PlusSquare
                                        size={16}
                                        className="mb-1 inline"
                                    />
                                    ) at at the top of the screen.
                                </AppText>
                                <AppText
                                    variant="regular"
                                    className="mb-2"
                                >
                                    You will then be prompted to select what
                                    section you would like to include in your
                                    resume by clicking on the section. Once you
                                    have clicked on a section it will
                                    automatically be applied to the bottom of
                                    your resume.
                                </AppText>
                                <AppText
                                    variant="regular"
                                    className="mb-2"
                                >
                                    To delete a section in your resume, click on
                                    the section you would like to remove and use
                                    the Bin icon (
                                    <Trash2
                                        size={16}
                                        className="mb-1 inline"
                                    />
                                    ) from the hovering menu.
                                </AppText>
                            </HelpContentContainer>
                        </div>
                    )}
                    {activeNavItem === "context-menus" && (
                        <div>
                            <ModalHeader
                                title="Context Menus"
                                onClose={onClose}
                            />
                            <HelpContentContainer>
                                <AppText variant="regular">
                                    Across the builder there are several types
                                    of context menus available.
                                </AppText>
                                <AppText
                                    variant="contextheading"
                                    className="py-2 text-neutral-700"
                                >
                                    Section and Section Item Context Menus
                                </AppText>
                                <AppText variant="regular">
                                    When you select (click on) a section, a
                                    floating menu will appear, offering a
                                    variety of shortcuts to enhance your editing
                                    experience. Depending on the section the
                                    menu you will see will differ.
                                </AppText>
                                <AppText
                                    variant="regular"
                                    className="pt-4 !font-semibold text-neutral-700"
                                >
                                    Profile Section Context Menu
                                </AppText>
                                <AppText variant="regular">
                                    Hovering over the profile section will
                                    display the profile section context menu
                                    where you can customise the content
                                    displayed in the profile section. The
                                    following actions are available:
                                </AppText>
                                <MenuGuideTable
                                    items={profileSectionContextMenuItems}
                                />
                                <AppText
                                    variant="regular"
                                    className="pt-4 !font-semibold text-neutral-700"
                                >
                                    All Other Section Context Menus
                                </AppText>
                                <AppText variant="regular">
                                    Selecting a section or a section item other
                                    than the profile section will display the
                                    section context menu. The following actions
                                    are available:
                                </AppText>
                                <MenuGuideTable
                                    items={otherSectionContextMenuItems}
                                />
                            </HelpContentContainer>
                        </div>
                    )}
                    {activeNavItem === "hotkeys" && (
                        <div>
                            <ModalHeader
                                title="Hotkeys"
                                onClose={onClose}
                            />
                            <HelpContentContainer>
                                <AppText variant="regular">
                                    With text highlighted within a section, you
                                    can use the following hotkeys to format your
                                    text:
                                </AppText>
                                <div className="relative mt-4 h-[450px] overflow-y-scroll">
                                    <HotkeysTable />
                                </div>
                            </HelpContentContainer>
                        </div>
                    )}
                    {activeNavItem === "contact" && (
                        <div>
                            <ModalHeader
                                title="Contact Us"
                                onClose={onClose}
                            />
                            <div className="h-[500px]">
                                <AppText variant="regular">
                                    For any inquiries, please reach out to us
                                    at:
                                </AppText>
                                <a href="mailto:hello@1template.io">
                                    <AppText
                                        variant="labelsbuttons"
                                        className="text-primary-500 underline hover:text-primary-600"
                                    >
                                        hello@1template.io
                                    </AppText>
                                </a>

                                <form
                                    onSubmit={handleSubmitFeedback}
                                    className="mt-4 space-y-4"
                                >
                                    <div>
                                        <AppText
                                            variant="regular"
                                            className="mb-2"
                                        >
                                            Or send us feedback directly:
                                        </AppText>
                                        <textarea
                                            value={feedback}
                                            onChange={e =>
                                                setFeedback(e.target.value)
                                            }
                                            className="w-full rounded-md border border-gray-300 p-2"
                                            rows={4}
                                        />
                                    </div>
                                    <Button
                                        type="submit"
                                        disabled={
                                            isSubmitting || !feedback.trim()
                                        }
                                        color={ButtonColourStyles.SOLID_PRIMARY}
                                    >
                                        {isSubmitting
                                            ? "Sending..."
                                            : "Send Feedback"}
                                    </Button>
                                    {submitStatus && (
                                        <AppText
                                            variant="regular"
                                            className={
                                                submitStatus.type === "success"
                                                    ? "text-success-600"
                                                    : "text-error-600"
                                            }
                                        >
                                            {submitStatus.message}
                                        </AppText>
                                    )}
                                </form>
                            </div>
                        </div>
                    )}
                </div>
            </main>
        </Modal>
    );
};

// Modals have to be exported as Default for lazy loading
export default HelpGuideModal;
