import { Button } from "@/components/Button/Button";
import { AppText } from "@/components/Text/AppText";
import { PreferencesStep } from "@/pages/Preferences/components";
import { useWizard } from "react-use-wizard";
import { ChevronRight } from "lucide-react";

export const PreferencesWelcome = ({
    skipButtonRef,
}: {
    skipButtonRef: React.MutableRefObject<HTMLDivElement | null>;
}) => {
    const { nextStep } = useWizard();

    const onGetStarted = () => {
        nextStep();
        skipButtonRef.current?.classList.remove("opacity-0");
    };

    return (
        <PreferencesStep>
            <div className="flex flex-col justify-center overflow-hidden pb-4 lg:pb-8 xl:pb-0">
                <AppText
                    variant="headings"
                    className="inline-block px-0 text-center text-primary-700 lg:h-16 lg:px-40 xl:h-24"
                >
                    Welcome to 1Template!
                </AppText>
                <div className="flex flex-col py-2 pb-2 lg:h-[250px] lg:px-2">
                    <AppText
                        variant="subheadings"
                        className="inline-block text-center leading-8 lg:h-16 lg:px-52"
                    >
                        Let&apos;s build a standout resume to set you on the
                        path to success.
                    </AppText>
                    <AppText
                        variant="labelsbuttons"
                        className="mt-6 py-4 text-center text-neutral-700 lg:px-40 xl:py-0"
                    >
                        Before we begin, let&apos;s set up your preferences.
                    </AppText>

                    <Button
                        onClick={() => {
                            onGetStarted();
                        }}
                        className="z-[100] !mx-auto mt-12"
                        rightIcon={<ChevronRight size={16} />}
                    >
                        Get Started
                    </Button>
                </div>
            </div>
        </PreferencesStep>
    );
};
