import Accordion from "@/components/Accordion/Accordion";
import { AppText } from "@/components/Text/AppText";
import { AssistantItemDocumentType } from "@/types/assistant";

export const DocumentAssistantItem = ({
    properties,
    defaultOpen,
}: {
    properties: AssistantItemDocumentType;
    defaultOpen?: boolean;
}) => {
    return properties.isExpandable ? (
        <Accordion
            title={properties.title}
            width="full"
            variant="white"
            key={properties.title}
            defaultOpen={defaultOpen}
        >
            <div
                className="text-app-regular-5-mobile text-neutral-900 md:text-app-regular-5-desktop"
                dangerouslySetInnerHTML={{
                    __html: properties.content,
                }}
            />
        </Accordion>
    ) : (
        <div
            key={properties.title}
            className="px-4 text-app-regular-5-mobile text-neutral-900 md:text-app-regular-5-desktop"
        >
            <AppText
                variant="contextheading"
                className="py-2"
            >
                {properties.title}
            </AppText>
            <div
                key={properties.title}
                className="text-app-regular-5-mobile text-neutral-900 md:text-app-regular-5-desktop"
                dangerouslySetInnerHTML={{
                    __html: properties.content,
                }}
            />
        </div>
    );
};
