import { AppText } from "@/components/Text/AppText";

export const InfoItem = ({
    label,
    value,
}: {
    label: string;
    value: string;
}) => (
    <div className="grid grid-cols-2 gap-2">
        <AppText
            variant="labelsbuttons"
            className="text-neutral-700"
        >
            {label}
        </AppText>
        <AppText
            variant="labelsbuttons"
            className="text-neutral-900"
        >
            {value}
        </AppText>
    </div>
);
