import { AppText } from "@/components/Text/AppText";
import { Checkbox } from "@/components/form/Checkbox";

interface PresetOptionProps {
    id: string;
    name: string;
    preview: React.ReactNode;
    active: boolean;
    onClick: () => void;
}

export const PresetOption = ({
    id,
    name,
    active,
    preview,
    onClick,
}: PresetOptionProps) => (
    <div
        className="group flex cursor-pointer flex-col items-center justify-between"
        onClick={onClick}
    >
        {preview}
        <AppText
            className="mb-1 mt-2"
            variant="labelsbuttons"
        >
            {name}
        </AppText>
        <Checkbox
            borderless
            id={id}
            checked={active}
            label=""
            onChange={onClick}
            className="ml-2 !h-6 group-hover:opacity-90"
        />
    </div>
);
